import { Injectable } from "@angular/core";
import { TimeParserUtil } from "src/app/utility/time-parser-utility";

@Injectable()
export class DateTimeUtil {

	dashboardLoadedTime = new Date();
	
	constructor(private timeParser: TimeParserUtil) {}

	getCurrentTime() {
		let currentTime = new Date();
		return `${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()}`;
	}

	getUTCDateFromString(date: string, isStartDate: boolean) {
		const splitDate = date.split(" ");
		const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		const monthIndex = month.findIndex((element) => element === splitDate[1]);
		let utcInMs = 0;
		if (isStartDate) {
			const dateObject = {
				year: +splitDate[2],
				month: monthIndex + 1,
				day: +splitDate[0],
				hour: splitDate[3] ? +splitDate[3] : 0,
				minute: splitDate[4] ? +splitDate[4] : 0,
				second: 0
			};
			utcInMs = this.timeParser.convertDateTimeToGMT(dateObject);
		} else {
			const selectedDate = { year: +splitDate[2], month: monthIndex + 1, day: +splitDate[0] };
			const currentTime =
				this.timeParser.getDaysDifferenceFromCurrentDate(selectedDate) >= 0
					? this.getCurrentTime().split(":")
					: "23:59:59".split(":");
			const dateObject = {
				...selectedDate,
				hour: splitDate[3] ? +splitDate[3] : +currentTime[0],
				minute: splitDate[4] ? +splitDate[4] : +currentTime[1],
				second: 0
			};
			utcInMs = this.timeParser.convertDateTimeToGMT(dateObject);
		}
		return utcInMs;
	}

	getDashboardLoadedTime() {
		return `${this.dashboardLoadedTime.getHours()}:${this.dashboardLoadedTime.getMinutes()}:${this.dashboardLoadedTime.getSeconds()}`;
	}

	getDashboardLoadedTimeUTCDateFromString(date: string, isStartDate: boolean) {
		const splitDate = date.split(" ");
		const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		const monthIndex = month.findIndex((element) => element === splitDate[1]);
		let utcInMs = 0;
		if (isStartDate) {
			const dateObject = {
				year: +splitDate[2],
				month: monthIndex + 1,
				day: +splitDate[0],
				hour: splitDate[3] ? +splitDate[3] : 0,
				minute: splitDate[4] ? +splitDate[4] : 0,
				second: 0
			};
			utcInMs = this.timeParser.convertDateTimeToGMT(dateObject);
		} else {
			const selectedDate = { year: +splitDate[2], month: monthIndex + 1, day: +splitDate[0] };
			const currentTime =
				this.timeParser.getDaysDifferenceFromCurrentDate(selectedDate) >= 0
					? this.getDashboardLoadedTime().split(":")
					: "23:59:59".split(":");
			const dateObject = {
				...selectedDate,
				hour: splitDate[3] ? +splitDate[3] : +currentTime[0],
				minute: splitDate[4] ? +splitDate[4] : +currentTime[1],
				second: 0
			};
			utcInMs = this.timeParser.convertDateTimeToGMT(dateObject);
		}
		return utcInMs;
	}
}
