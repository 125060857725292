/**
 *  Reference URL: https://github.com/scttcper/ngx-toastr
 *  Current version: 6.5.0
 */
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
@Injectable({
	providedIn: "root"
})
export class ToasterService {
	constructor(private toastr: ToastrService) {}

	/**
	 * @param message - Required
	 * @param title - optional
	 * @param seconds - optional : toaster visible time on screen
	 * @param position - optional : position to display the toaster
	 */
	showSuccessMessage(message: string, title?: string, seconds?: number, position?: string) {
		const titleCard = title ? title : "Success";
		const displaySeconds = seconds ? seconds : 5000;
		const toastPosition = position ? position : "toast-top-right";
		this.toastr.success(message, titleCard, {
			timeOut: displaySeconds,
			closeButton: true,
			progressBar: true,
			positionClass: toastPosition
		});
	}

	/**
	 * @param message - required
	 * @param title - optional
	 * @param seconds - optional : toaster visible time on screen
	 * @param position - optional : position to display the toaster
	 */
	showErrorMessage(message: string, title?: string, seconds?: number, position?: string) {
		const titleCard = title ? title : "Error";
		const displaySeconds = seconds ? seconds : 10000;
		const toastPosition = position ? position : "toast-top-right";
		this.toastr.error(message, titleCard, {
			timeOut: displaySeconds,
			closeButton: true,
			progressBar: true,
			positionClass: toastPosition
		});
	}

	/**
	 * @param message - required
	 * @param title - optional
	 * @param seconds - optional : toaster visible time on screen
	 * @param position - optional : position to display the toaster
	 */
	showWarningMessage(message: string, title?: string, seconds?: number, position?: string) {
		const titleCard = title ? title : "Warning";
		const displaySeconds = seconds ? seconds : 10000;
		const toastPosition = position ? position : "toast-top-right";
		this.toastr.warning(message, titleCard, {
			timeOut: displaySeconds,
			closeButton: true,
			progressBar: true,
			positionClass: toastPosition
		});
	}

	/**
	 * @param message - required
	 * @param title - optional
	 * @param seconds - optional : toaster visible time on screen
	 * @param position - optional : position to display the toaster
	 */
	showInfoMessage(message: string, title?: string, seconds?: number, position?: string) {
		const titleCard = title ? title : "Info";
		const displaySeconds = seconds ? seconds : 10000;
		const toastPosition = position ? position : "toast-top-right";
		this.toastr.info(message, titleCard, {
			timeOut: displaySeconds,
			closeButton: true,
			progressBar: true,
			positionClass: toastPosition
		});
	}
}
