import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "filterNetworkRange"
})
export class FilterNetworkRangePipe implements PipeTransform {
	transform(items: any[], searchText: string): any[] {
		if (!items || !searchText) {
			return items;
		}
		searchText = searchText.toLowerCase();
		return items.filter((item: any) => {
			if (item.name.toLowerCase().includes(searchText)) {
				return true;
			}
			const networkRangeDetails = [
				item.lanDefaultIpDetails?.[0]?.ipDetails,
				item.lanStaticIpDetails,
				item.vlanOnLinkIpDetails,
				item.vlanStaticIpDetails
			];
			return networkRangeDetails?.some((ipDetails) => {
				return ipDetails && ipDetails?.some((ip: string) => ip.toLowerCase().includes(searchText));
			});
		});
	}
}
