import { Injectable } from "@angular/core";
import { TimeParserUtil } from "../../../utility/time-parser-utility";
import * as Highcharts from "highcharts";

@Injectable()
export class LineChartUtils {
	constructor(private timeParser: TimeParserUtil) {}

	parseSeriesData(chartData: Array<any>) {
		if (chartData.length) {
			chartData.forEach((element: any) => {
				if (element.marker) {
					element.marker.states = {};
					if (element.marker.normal) {
						if (element.marker.normal.hasOwnProperty("enabled")) {
							element.marker.states.normal = {
								enabled: element.marker.normal.enabled
							};
						}
						if (element.marker.normal.isImage) {
							element.marker.symbol = "(url" + element.marker.normal.image + ")";
							element.marker.height = element.marker.normal.height;
							element.marker.width = element.marker.normal.width;
						} else {
							element.marker.symbol = element.marker.normal.symbol;
							element.marker.radius = element.marker.normal.radius;
							element.marker.fillColor = element.marker.normal.color;
							element.marker.lineColor = element.marker.normal.borderColor;
							element.marker.lineWidth = element.marker.normal.borderWidth;
						}
					}
					if (element.marker.onHover) {
						element.marker.states.hover = {};
						if (element.marker.onHover.hasOwnProperty("enabled")) {
							element.marker.states.hover.enabled = element.marker.onHover.enabled;
						}
						if (element.marker.onHover.isImage) {
							element.marker.states.hover.symbol = "(url" + element.marker.onHover.image + ")";
							element.marker.states.hover.height = element.marker.onHover.height;
							element.marker.states.hover.width = element.marker.onHover.width;
						} else {
							element.marker.states.hover.symbol = element.marker.onHover.symbol;
							element.marker.states.hover.radius = element.marker.onHover.radius;
							element.marker.states.hover.fillColor = element.marker.onHover.color;
							element.marker.states.hover.lineColor = element.marker.onHover.borderColor;
							element.marker.states.hover.lineWidth = element.marker.onHover.borderWidth;
							element.marker.states.hover.lineWidthPlus = element.marker.onHover.borderWidthPlus;
							element.marker.states.hover.radiusPlus = element.marker.onHover.radiusPlus;
						}
					}
				}
				if (element.fillColor) {
					if (element.fillColor.gradientStops && element.fillColor.gradientStops.length) {
						const stops: any = [];
						element.fillColor.gradientStops.forEach((stopElement: any) => {
							let stopPosition: any = stopElement.hasOwnProperty("stopPosition")
								? stopElement.stopPosition
								: 0;
							let colors: any = Highcharts.getOptions().colors;
							let color: any = stopElement.color ? stopElement.color : colors[0];
							let opacity: any = stopElement.hasOwnProperty("opacity") ? stopElement.opacity : 0;
							stops.push([stopPosition, Highcharts.color(color).setOpacity(opacity).get("rgba")]);
						});
						element.fillColor.stops = stops;
					}
				}
			});
		}
		return chartData;
	}

	getUTCDateFromString(dateInStr: string, isStartDate: boolean, isEndDate: boolean) {
		const splitDate = dateInStr.split("/");
		let utcInMs = 0;
		if (isStartDate) {
			// utcInMs = new Date(Date.UTC(+splitDate[2], monthIndex, +splitDate[0], 0, 0, 0)).getTime();
			const dateObject = {
				year: +splitDate[2],
				month: +splitDate[0],
				day: +splitDate[1],
				hour: 0,
				minute: 0,
				second: 0
			};
			utcInMs = this.timeParser.convertDateTimeToGMT(dateObject);
		} else {
			//check the difference b/w current date and selected date. If selected date in past set time as 23:59:59 else set current time
			const selectedDate = { year: +splitDate[2], month: +splitDate[0], day: +splitDate[1] };
			const currentTime =
				this.timeParser.getDaysDifferenceFromCurrentDate(selectedDate) >= 0
					? this.timeParser.getCurrentLocalTime()?.split(":")
					: "23:59:59".split(":");
			const dateObject = {
				...selectedDate,
				hour: +currentTime[0],
				minute: +currentTime[1],
				second: +currentTime[2]
			};
			utcInMs = this.timeParser.convertDateTimeToGMT(dateObject);
			// utcInMs = new Date(Date.UTC(+splitDate[2], monthIndex, +splitDate[0], 23, 59, 59)).getTime();
		}
		// return utcInMs / 1000; // divide by 1000 to convert ms to sec
		return utcInMs;
	}

	parseDrilldownSplineChartResponse(
		response: any,
		isDrilldownEnabled: boolean,
		drilldownId: string,
		isPeakBWSelected?: boolean
	): any {
		let parsedResponse: any;
		if (drilldownId && drilldownId.length) {
			switch (drilldownId) {
				case "edgeUtilisationWidget": {
					parsedResponse = this.parseEdgeUtilisationWidgetResponse(response, isDrilldownEnabled);
					break;
				}
				case "senWanNetworkUtilisationWidget": {
					parsedResponse = this.parseNetworkUtilisationWanChartDataResponse(response, isDrilldownEnabled);
					break;
				}
				case "senMgtPortNetworkUtilisationWidget": {
					parsedResponse = this.parseLineChartDataResponse(
						response,
						this.getSenNetworkUtilisationMgtPortDataProps(),
						isDrilldownEnabled
					);
					break;
				}
				case "senSelectedTenantUtilisationWidget": {
					parsedResponse = this.parseLineChartDataResponse(
						response,
						this.getSelectedTenantUtilisationDataProps(),
						isDrilldownEnabled
					);
					break;
				}
				case "senDeviceHealthCPUUtilisation": {
					parsedResponse = this.parseLineChartDataResponse(
						response,
						this.getSenDeviceHealthDataProps(),
						isDrilldownEnabled
					);
					break;
				}

				case "senDeviceHealthDiskMemoryUtilisation": {
					parsedResponse = this.parseLineChartDataResponse(
						response,
						this.getSenDeviceHealthDataProps(),
						isDrilldownEnabled
					);
					break;
				}
				case "senTopAppsByUsersLegendAppWidget": {
					parsedResponse = this.parseTopAppsByUsersLegendAppDataProps(response, isDrilldownEnabled);
					break;
				}
				case "siteWANNetworkUtilization": {
					parsedResponse = this.parseLineChartDataResponse(
						response,
						{},
						isDrilldownEnabled,
						isPeakBWSelected
					);
					break;
				}
				case "siteLANNetworkUtilization": {
					parsedResponse = this.parseLineChartDataResponse(response, {}, isDrilldownEnabled);
					break;
				}
				case "networkHealth": {
					parsedResponse = this.parseNetworkHealthChartReponse(response, {}, isDrilldownEnabled);
					break;
				}
				case "packetLoss": {
					parsedResponse = this.parseNetworkHealthChartReponse(response, {}, isDrilldownEnabled);
					break;
				}
			}
			if (parsedResponse.parsedData && parsedResponse.parsedData.length) {
				parsedResponse.parsedData = this.parseSeriesData(parsedResponse.parsedData);
			}
		}
		return parsedResponse;
	}

	private getEdgeUtilisationDownlinkDataProps(): any {
		const props = {
			name: "",
			data: [],
			color: "#3b658c",
			marker: {
				onHover: {
					isImage: false,
					symbol: "circle",
					radius: 3,
					color: "#FFFFFF",
					borderColor: "#657EF0",
					borderWidth: 1,
					radiusPlus: 1,
					borderWidthPlus: 6
				},
				normal: {
					isImage: false,
					symbol: "circle",
					radius: 3,
					color: "#FFFFFF",
					borderColor: "#657EF0",
					borderWidth: 2
				}
			}
		};
		return props;
	}

	private getEdgeUtilisationUplinkDataProps(): any {
		const props = {
			name: "",
			data: [],
			color: "#C97245",
			marker: {
				onHover: {
					isImage: false,
					symbol: "circle",
					radius: 3,
					color: "#FFFFFF",
					borderColor: "#C97245",
					borderWidth: 1,
					radiusPlus: 1,
					borderWidthPlus: 6
				},
				normal: {
					isImage: false,
					symbol: "circle",
					radius: 3,
					color: "#FFFFFF",
					borderColor: "#C97245",
					borderWidth: 2
				}
			}
		};
		return props;
	}

	parseEdgeUtilisationWidgetResponse(response: any, isDrilldownEnabled: boolean): any {
		let parsedResponse: any = [];
		let combinedData: any = [];
		if (response.length) {
			response.forEach((splineDataElement: any) => {
				if (splineDataElement.name && splineDataElement.name.length) {
					if (splineDataElement.name.toLowerCase() === "uplink") {
						let parsedUplinkData = this.getEdgeUtilisationUplinkDataProps();
						parsedUplinkData.name = splineDataElement.name;
						parsedUplinkData.id = splineDataElement.id ? splineDataElement.id : null;
						if (splineDataElement.value && splineDataElement.value.length) {
							let uplinkData: any = [];
							splineDataElement.value.forEach((element: any) => {
								uplinkData.push({
									name: element.name ? element.name : "",
									x: element.x,
									id: element.id ? element.id : element.name,
									y: element.value ? element.value : 0,
									drilldown: isDrilldownEnabled,
									displayValue: element.displayValue ? element.displayValue : ""
								});
								combinedData.push(element.value);
							});
							parsedUplinkData.data = uplinkData;
							parsedResponse.push(parsedUplinkData);
						}
					} else if (splineDataElement.name.toLowerCase() === "downlink") {
						let parsedDownlinkData = this.getEdgeUtilisationDownlinkDataProps();
						parsedDownlinkData.name = splineDataElement.name;
						parsedDownlinkData.id = splineDataElement.id ? splineDataElement.id : null;
						if (splineDataElement.value && splineDataElement.value.length) {
							let downlinkData: any = [];
							splineDataElement.value.forEach((element: any) => {
								downlinkData.push({
									name: element.name ? element.name : "",
									x: element.x || element.name,
									id: element.id ? element.id : element.name,
									y: element.value ? element.value : 0,
									drilldown: isDrilldownEnabled,
									displayValue: element.displayValue ? element.displayValue : ""
								});
								combinedData.push(element.value);
							});
							parsedDownlinkData.data = downlinkData;
							parsedResponse.push(parsedDownlinkData);
						}
					}
				}
			});
		}
		return { parsedData: parsedResponse, combinedData: combinedData };
	}

	parseNetworkUtilisationWanChartDataResponse(
		response: any,
		isDrilldownEnabled: boolean,
		isPeakBWSelected?: boolean
	): any {
		let parsedResponse: any = [];
		let combinedData: any = []; // To normalize the y-axis value
		if (response.length) {
			response.forEach((element: any) => {
				let dataProps: any = {};
				if (element.displayName && element.displayName.toLowerCase() === "egress") {
					dataProps = this.getNetworkUtilisationWanEgressDataProps();
					dataProps.name = "Egress";
				} else if (element.displayName && element.displayName.toLowerCase() === "ingress") {
					dataProps = this.getNetworkUtilisationWanIngressDataProps();
					dataProps.name = "Ingress";
				}
				dataProps["id"] = element.id ? element.id : null;
				let data: any = [];
				if (element.value && element.value.length) {
					element.value.forEach((dataElement: any) => {
						let point: any = {
							name: dataElement.name ? dataElement.name : "",
							id: dataElement.id ? dataElement.id : dataElement.name,
							y: dataElement.value ? dataElement.value : 0,
							drilldown: isDrilldownEnabled ? isDrilldownEnabled : false,
							displayValue: dataElement.displayValue ? dataElement.displayValue : ""
						};
						// If drilldown not enabled conisder it as time in ms
						if (!isDrilldownEnabled) {
							if (isPeakBWSelected === undefined || !isPeakBWSelected) {
								point.x = dataElement?.x;
							}
						}
						data.push(point);
						combinedData.push(dataElement.value);
					});
				}
				dataProps.data = data;
				parsedResponse.push(dataProps);
			});
		}
		return { parsedData: parsedResponse, combinedData: combinedData };
	}

	getNetworkUtilisationWanIngressDataProps(): any {
		const props = {
			name: "",
			color: "#3b658c",
			marker: {
				onHover: {
					isImage: false,
					symbol: "circle",
					radius: 2,
					color: "#FFFFFF",
					borderColor: "#3b658c",
					// borderColor: "#657EF0",
					borderWidth: 1,
					radiusPlus: 0,
					borderWidthPlus: 6
				}
			},
			data: []
		};
		return props;
	}

	getNetworkUtilisationWanEgressDataProps(): any {
		const props = {
			name: "",
			color: "#C97245",
			marker: {
				onHover: {
					isImage: false,
					symbol: "circle",
					radius: 2,
					color: "#FFFFFF",
					borderColor: "#C97245",
					borderWidth: 1,
					radiusPlus: 0,
					borderWidthPlus: 3
				}
			},
			data: []
		};
		return props;
	}

	parseLineChartDataResponse(
		response: any,
		dataProperties: any,
		isDrilldownEnabled?: boolean,
		isPeakBWSelected?: boolean
	): any {
		let parsedResponse: any = [];
		let combinedData: any = [];
		if (response.length) {
			response.forEach((element: any) => {
				let dataProps: any = { ...dataProperties };
				dataProps.name = element.name;
				dataProps["id"] = element.id ? element.id : null;
				dataProps["breadcrumbDisplayName"] = element?.breadcrumbDisplayName;
				let data: any = [];
				if (element.value && element.value.length) {
					element.value.forEach((dataElement: any) => {
						let point: any = {
							name: dataElement.name ? dataElement.name : "",
							id: dataElement.id ? dataElement.id : dataElement.name,
							y: dataElement.value ? dataElement.value : 0,
							drilldown: isDrilldownEnabled ? isDrilldownEnabled : false,
							displayValue: dataElement.displayValue ? dataElement.displayValue : ""
						};
						// If drilldown not enabled conisder it as time in ms
						if (!isDrilldownEnabled) {
							if (isPeakBWSelected === undefined || !isPeakBWSelected) {
								point.x = dataElement?.x;
							}
						}
						data.push(point);
						combinedData.push(dataElement.value);
					});
				}
				dataProps.data = data;
				parsedResponse.push(dataProps);
				let colors: any = ["#2D4B9A", "#4E9A9B", "#D76C39", "#D1A73B", "#9674a3"];
				for (let index = 0; index < parsedResponse.length; index++) {
					parsedResponse[index]["color"] = colors[index];
				}
			});
		}
		return { parsedData: parsedResponse, combinedData: combinedData };
	}

	getSenNetworkUtilisationMgtPortDataProps(): any {
		const props = {
			name: "",
			color: "#3b658c",
			marker: {
				onHover: {
					isImage: false,
					symbol: "circle",
					radius: 2,
					color: "#FFFFFF",
					borderColor: "#657EF0",
					borderWidth: 1,
					radiusPlus: 0,
					borderWidthPlus: 6
				}
			},
			data: []
		};
		return props;
	}

	private getSelectedTenantUtilisationDataProps(): any {
		const props = {
			name: "",
			type: "area",
			color: "#2D4B9A",
			data: [],
			fillColor: {
				linearGradient: [0, 0, 0, 300],
				gradientStops: [
					{
						color: "#3b658c",
						stopPosition: 0,
						opacity: 0.4
					},
					{
						color: "#3b658c",
						stopPosition: 1,
						opacity: 0
					}
				]
			}
		};
		return props;
	}

	private getSenDeviceHealthDataProps(): any {
		const props = {
			name: "",
			type: "area",
			color: "#2D4B9A",
			data: [],
			fillColor: {
				linearGradient: [0, 0, 0, 300],
				gradientStops: [
					{
						color: "#3b658c",
						stopPosition: 0,
						opacity: 0.4
					},
					{
						color: "#3b658c",
						stopPosition: 1,
						opacity: 0
					}
				]
			}
		};
		return props;
	}

	parseTopAppsByUsersLegendAppDataProps(response: any, isDrilldownEnabled: boolean, colors?: any): any {
		let parsedResponse: any = [];
		let combinedData: any = [];
		if (response.length) {
			response.forEach((element: any, index: any) => {
				let dataProps = this.getTopAppsByUsersLegendAppDataProps(
					colors && colors.length && index < colors.length ? colors[index] : null
				);
				dataProps.name = element.name;
				dataProps.id = element.id ? element.id : null;
				let data: any = [];
				if (element.value && element.value.length) {
					element.value.forEach((dataElement: any) => {
						data.push({
							name: dataElement.name ? dataElement.name : "",
							id: dataElement.id ? dataElement.id : dataElement.name,
							x: dataElement?.x,
							y: dataElement.value ? dataElement.value : 0,
							drilldown: isDrilldownEnabled ? isDrilldownEnabled : false,
							displayValue: dataElement.displayValue ? dataElement.displayValue : ""
						});
						combinedData.push(dataElement.value);
					});
				}
				dataProps.data = data;
				parsedResponse.push(dataProps);
			});
		}
		return { parsedData: parsedResponse, combinedData: combinedData };
	}

	getTopAppsByUsersLegendAppDataProps(color: string): any {
		const props: any = {
			name: "",
			marker: {
				onHover: {
					isImage: false,
					symbol: "circle",
					radius: 2,
					color: "#FFFFFF",
					borderWidth: 1,
					radiusPlus: 2,
					borderWidthPlus: 6
				},
				normal: {
					enabled: false,
					symbol: "square"
				}
			},
			data: []
		};
		if (color) {
			props["color"] = color;
			props.marker.onHover["borderColor"] = color;
		}
		return props;
	}

	parseNetworkHealthChartReponse(
		response: any,
		dataProperties: any,
		isDrilldownEnabled?: boolean,
		isPeakBWSelected?: boolean
	): any {
		let parsedResponse: any = [];
		let combinedData: any = [];
		if (response.length) {
			response.forEach((element: any) => {
				let dataProps: any = { ...dataProperties };
				dataProps.name = element.name;
				dataProps["id"] = element.id ? element.id : null;
				dataProps["breadcrumbDisplayName"] = element?.breadcrumbDisplayName;
				let data: any = [];
				if (element.value && element.value.length) {
					element.value.forEach((dataElement: any) => {
						let point: any = {
							name: dataElement.name ? dataElement.name : "",
							id: dataElement.id ? dataElement.id : dataElement.name,
							y: dataElement.value ? dataElement.value : 0,
							drilldown: isDrilldownEnabled ? isDrilldownEnabled : false,
							displayValue: dataElement.displayValue ? dataElement.displayValue : ""
						};
						// If drilldown not enabled conisder it as time in ms
						if (!isDrilldownEnabled) {
							if (isPeakBWSelected === undefined || !isPeakBWSelected) {
								point.x = dataElement?.x;
							}
						}
						data.push(point);
						combinedData.push(dataElement.value);
					});
				}
				dataProps.data = data;
				parsedResponse.push(dataProps);
				let colors: any = ["#2D4B9A", "#4E9A9B", "#D76C39", "#D1A73B", "#9674a3"];
				for (let index = 0; index < parsedResponse.length; index++) {
					parsedResponse[index]["color"] = colors[index];
				}
			});
		}
		return { parsedData: parsedResponse, combinedData: combinedData };
	}
}
