import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { API_URLS } from "../models/utils";
import { AgHttpService } from "./ag-http-service";
import { ApiUrlService } from "./api-url.service";

@Injectable({
	providedIn: "root"
})
export class DeviceManagementService {
	public selectedCustomerId: any;
	apiUrls!: API_URLS;

	constructor(private agHttpService: AgHttpService, private apiUrlService: ApiUrlService) {
		this.apiUrls = this.apiUrlService.getAPIUrls();
	}

	getDevice(deviceId: number): Observable<any> {
		//const url = `http://localhost:8090/device-mgmt/ci/${deviceId}`
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/${deviceId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getCustomers(): Observable<any> {
		const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/customer/customers`;
		return this.agHttpService.makeGetRequest(url);
	}

	getCustomersByLoggedInUser(): Observable<any> {
		const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/customer/customers/user`;
		return this.agHttpService.makeGetRequest(url);
	}

	getSites(customerId: number): Observable<any> {
		/*let response = {
			responseCode: 0,
		  
			data: [
				{
					id: 95,
					siteGroupType: 3,
					displayNameWithLocation: 'TestOfflineSite | Physical'
				},
				{
					id: 98,
					siteGroupType: 3,
					displayNameWithLocation: 'New New 1 | Physical'
				},
				{
					id: 100,
					siteGroupType: 4,
					displayNameWithLocation: 'New 4 | Virtual'          
				}
			]
		};

		return of(response);*/
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site/sites/customer/${customerId}`;
		//const url = `http://localhost:8091/customer-mgmt/site/sites/customer/${customerId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	// getVendors(): Observable<any> {
	//   // call api
	//   return of({reponseCode: 0, data: [{id: 1, name: 'vendor1'}, {id: 2, name: 'vendor2'}]});
	// }

	getdeviceType(): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/types`;
		return this.agHttpService.makeGetRequest(url);
	}

	getdeviceModel(): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/oem`;
		return this.agHttpService.makeGetRequest(url);
	}

	getAllDevices(pageIndex: number, pageSize: number, searchTerm?: string, sort?: string): Observable<any> {
		let queryString = `page=${pageIndex}&size=${pageSize}`;
		if (sort) {
			queryString += `&${sort}`;
		} else {
			queryString += `&sort=id,desc`;
		}
		if (searchTerm) {
			queryString = queryString + `&term=${searchTerm}`;
		}
		let customerId = this.selectedCustomerId;
		queryString = this.selectedCustomerId ? queryString + `&customerId=${customerId}` : queryString;
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/cis?${queryString}`;
		return this.agHttpService.makeGetRequest(url);
	}

	createCpe(body: any): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/create/cpe`;
		//const url = `http://localhost:8090/device-mgmt/ci/create/cpe`
		return this.agHttpService.makePostRequest(url, body);
	}

	deviceInitConfiguration(deviceId: string, body: any): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/applications/deploy/initial/config/${deviceId}`;
		return this.agHttpService.makePostRequest(url, body);
	}

	getDeviceKey(deviceId: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/device/key/${deviceId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	updateCIWorkfowStatus(ciId: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/status/${ciId}`;
		return this.agHttpService.makePutRequest(url);
	}

	deleteDevice(deviceId: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/${deviceId}/deactivate`;
		return this.agHttpService.makePutRequest(url);
	}

	checkDeviceNameAvailability(deviceName: string): Observable<any> {
		// Typeid is 1 for CPE
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/name/availability/${deviceName}/1`;
		return this.agHttpService.makeGetRequest(url);
	}

	deviceCount(): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/count`;
		return this.agHttpService.makeGetRequest(url);
	}

	getDeviceTypeDetails(): Observable<any> {
		/*let response = {
			responseCode: 0,
			message: '',
			traceId: 'f1a9bb54-77ab-43dc-a0f1-174909697c48',
			'data': [
				{
					id: 1,
					name: 'Data Center',
					code: 'DC',
					classification: 'SITE'
				},
				{
					id: 2,
					name: 'Branch Office',
					code: 'BO',
					classification: 'SITE'
				},
				{
					id: 3,
					name: 'Physical',
					code: 'PHYSICAL',
					classification: 'LOCATION'
				},
				{
					id: 4,
					name: 'Virtual',
					code: 'VIRTUAL',
					classification: 'LOCATION'
				}
			]
		}

		return of(response);
		let response = [
			{
				id: "virtual",
				name: "Virtual"
			},
			{
				id: "physical",
				name: "Physical"
			}
		];
		return response;*/
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site/types`;
		//const url =  `http://localhost:8091/customer-mgmt/site/types`
		return this.agHttpService.makeGetRequest(url);
	}

	getCloudTypeDetails(): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/cloud-providers`
		return this.agHttpService.makeGetRequest(url);
	}

	getDeviceType(): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/types/ce`;
		return this.agHttpService.makeGetRequest(url);
	}
}
