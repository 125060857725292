import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimSpaces'
})
export class TrimSpacesPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || typeof value !== 'string') {
      return value;
    }
    return value.replace(/\s+/g, '').trim();
  }
}
