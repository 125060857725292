export enum Role {
	standardUser = "standard user",
	internalMasterUser = "master user",
	customerMasterUser = "customer master user",
	masterUserReadOnly = "master user read only",

	// Customer
	canCreateCustomer = "canCreateCustomer",
	canViewCustomer = "canViewCustomer",
	canEditCustomer = "canEditCustomer",
	canDeactivateCustomer = "canDeactivateCustomer",
	canActivateCustomer = "canActivateCustomer",
	canListCustomers = "canListCustomers",
	canDeleteCustomer = "canDeleteCustomer",

	// User
	canCreateUser = "canCreateUser",
	canEditUser = "canEditUser",
	canViewUser = "canViewUser",
	canListUsers = "canListUsers",
	canActivateDeactiavateUser = "canActivateDeactiavateUser",
	canResetPassword = "canResetPassword",
	canDeleteRole = "canDeleteRole",
	canCreateRole = "canCreateRole",
	canEditRole = "canEditRole",
	canCreatePrivilege = "canCreatePrivilege",
	canEditPrivilege = "canEditPrivilege",
	canEnableAssignRole = "canEnableAssignRole",
	canDeletePrivilege = "canDeletePrivilege",

	// Site
	canCreateSite = "canCreateSite",
	canViewSite = "canViewSite",
	canDeactivateSite = "canDeactivateSite",
	canActivateSite = "canActivateSite",
	canListSites = "canListSites",
	canListSite = "canListSite",
	canDeleteSite = "canDeleteSite",
	canEditSite = "canEditSite",
	canCheckSiteNameAvailability = "canCheckSiteNameAvailability",
	canMonitorDevice = "canMonitorDevice",
	canViewSiteConfigList = "canViewSiteConfigList",

	//device
	canListDevices = "canListDevices",
	canCreateDevice = "canCreateDevice",
	canEditDevice = "canEditDevice",
	canViewDevice = "canViewDevice",
	canDeleteDevice = "canDeleteDevice",
	canDeactivateDevice = "canDeactivateDevice",
	canActivateDevice = "canActivateDevice",
	canEnableBulkDevice = "canEnableBulkDevice",
	canEnableSSH = "canEnableSsh",
	canViewDeviceConfigList = "canViewDeviceConfigList",
	canViewDeviceConfig = "canViewDeviceConfig",

	// Configuration Item
	canCreateConfigurationItem = "canCreateConfigurationItem",
	canViewConfigurationItem = "canViewConfigurationItem",
	canListConfigurationItems = "canListConfigurationItems",
	canDeleteConfigurationItem = "canDeleteConfigurationItem",
	canCheckDeviceNameAvailability = "canCheckDeviceNameAvailability",
	canEditConfigurationItem = "canEditConfigurationItem",

	// SEN
	canSubmitCpeSenMapping = "canSubmitCpeSenMapping",
	canListServiceEdge = "canListServiceEdge",
	canCreateServiceEdge = "canCreateServiceEdge",
	canViewServiceEdge = "canViewServiceEdge",
	canMonitorServiceEdge = "canMonitorServiceEdge",
	canEditserviceEdge = "canEditserviceEdge",
	canViewSENConfigList = "canViewSENConfigList",
	canViewServiceEdgeConfig = "canViewServiceEdgeConfig",

	// Policy
	canViewNetworkConfiguration = "canViewNetworkConfiguration",
	canApplyAclNetworkConfiguration = "canApplyAclNetworkConfiguration",
	canViewAccessPolicyFlow = "canViewAccessPolicyFlow",
	canViewApplicationsList = "canViewApplicationsList",
	canCreateSlaNetworkConfiguration = "canCreateSlaNetworkConfiguration",
	canApplySlaNetworkConfiguration = "canApplySlaNetworkConfiguration",
	canViewSlaNetworkConfiguration = "canViewSlaNetworkConfiguration",
	canDeleteSlaRuleRecord = "canDeleteSlaRuleRecord",
	canApplyFirewall = "canApplyFirewall",
	canApplyTrafficForwarding = "canApplyTrafficForwarding",
	canApplyQOS = "canApplyQOS",

	// Objects
	canCreatePrivateApplication = "canCreatePrivateApplication",
	canEditPrivateApplication = "canEditPrivateApplication",
	canCreateSaaSApplication = "canCreateSaaSApplication",
	canViewSaaSApplication = "canViewSaaSApplication",
	canEditSaaSApplication = "canEditSaaSApplication",
	canDeleteSaaSApplication = "canDeleteSaaSApplication",
	canCreateURLCategory = "canCreateURLCategory",
	canEditURLCategory = "canEditURLCategory",
	canDeleteURLCategory = "canDeleteURLCategory",
	canViewURLCategory = "canViewURLCategory",
	canEnableWUA = "canEnableWUA",
	canEnableURLF = "canEnableURLF",
	canEnableCustomApp = "canEnableCustomApp",
	//QOS Profile
	canEnableProfile = "canEnableProfile",
	canViewProfile = "canViewProfile",
	canAddProfile = "canAddProfile",
	canDeleteProfile = "canDeleteProfile",
	canMapProfile = "canMapProfile",

	// Firmware
	canUpgradeDeviceFirmware = "canUpgradeDeviceFirmware",
	canDowngradeDeviceFirmware = "canDowngradeDeviceFirmware",
	canUploadDeviceFirmware = "canUploadDeviceFirmware",
	canMapCustomerToFirmware = "canMapCustomerToFirmware",

	// Logs
	canViewTrafficFlows = "canViewTrafficFlows",
	canViewLogs = "canViewLogs",

	// Remote upgrade
	canEnableSoftwareMgmt = "canEnableSoftwareMgmt",
	canEnableUpgrade = "canEnableUpgrade",

	// SLA Reports
	canEnableAvailabilityReport = "canEnableAvailabilityReport",
	canViewAvailabilityReport = "canViewAvailabilityReport",
	canDownloadReport = "canDownloadReport",

	// Others
	canCreateControllerInstance = "canCreateControllerInstance",

	canEnablePacketCapture = "canEnablePacketCapture",
	canSubmitTcpDump = "canSubmitTcpDump",
	canDownloadTcpDump = "canDownloadTcpDump",

	//Agent
	canListAgentUsers = "canListAgentUsers",
	canCreateAgentUser = "canCreateAgentUser",
	canEditAgentUser = "canEditAgentUser",
	canDeleteAgentUser = "canDeleteAgentUser",
	canViewAgentUser = "canViewAgentUser",
	canSendAgentInvitation = "canSendAgentInvitation",
	canCreateTunnelConfiguration = "canCreateTunnelConfiguration",

	canEnableSessionFlow = "canEnableSessionFlow"
}
