import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "keyvalue"
})
export class KeysPipe implements PipeTransform {
	transform(value: any): any {
		return Object.keys(value);
	}
}
