import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: "app-network-range-list",
	templateUrl: "./network-range-list.component.html",
	styleUrls: ["./network-range-list.component.scss"]
})
export class NetworkRangeListComponent {
	@Input() ipRangeDetails!: any;
	@Output() onCloseNetworkRangeDetails: any = new EventEmitter<string>();
	@ViewChild("ipRangeDetailsTemplate", { static: false }) ipRangeDetailsTemplate!: TemplateRef<any>;
	dialogReference!: MatDialogRef<any>;
	searchText: any = "";

	constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef) {}

	ngAfterViewInit() {
		this.dialogReference = this.dialog.open(this.ipRangeDetailsTemplate, {
			width: "800px",
			disableClose: true
		});
	}

	onClickClose() {
		this.dialogReference.close();
		this.onCloseNetworkRangeDetails.emit();
	}
}
