import { Injectable } from "@angular/core";
import { TimeParserUtil } from "../../../utility/time-parser-utility";

@Injectable()
export class XrangeChartUtils {
	constructor(private timeParser: TimeParserUtil) {}

	getUTCDateFromString(dateInStr: string, isStartDate: boolean, isEndDate: boolean) {
		const splitDate = dateInStr.split("/");
		let utcInMs = 0;
		if (isStartDate) {
			const dateObject = {
				year: +splitDate[2],
				month: +splitDate[0],
				day: +splitDate[1],
				hour: 0,
				minute: 0,
				second: 0
			};
			utcInMs = this.timeParser.convertDateTimeToGMT(dateObject);
		} else {
			const selectedDate = { year: +splitDate[2], month: +splitDate[0], day: +splitDate[1] };
			const currentTime =
				this.timeParser.getDaysDifferenceFromCurrentDate(selectedDate) >= 0
					? this.timeParser.getCurrentLocalTime()?.split(":")
					: "23:59:59".split(":");
			const dateObject = {
				...selectedDate,
				hour: +currentTime[0],
				minute: +currentTime[1],
				second: +currentTime[2]
			};
			utcInMs = this.timeParser.convertDateTimeToGMT(dateObject);
		}
		return utcInMs;
	}

	parseAvailabilityReportDetails(response: any) {
		const parsedResponse = response?.data?.details?.map((siteData: any, index: number) => ({
			statusData: siteData?.status?.map((deviceDetails: any) => ({
				x: deviceDetails?.startTime,
				x2: deviceDetails?.endTime,
				y: index,
				color: getDeviceAvailabilityColors(deviceDetails?.status),
				availability: siteData.availablity,
				status: deviceDetails?.status,
				category: siteData?.linkName
					? `${siteData.siteName} | ${siteData.linkName} | ${siteData.availablity}%`
					: `${siteData.siteName} | ${siteData.availablity}%`
			})),
			label: siteData?.linkName
				? `${siteData.siteName} | ${siteData.linkName} | ${siteData.availablity}%`
				: `${siteData.siteName} | ${siteData.availablity}%`
		}));
		return parsedResponse;
	}
}

function getDeviceAvailabilityColors(status: number): string {
	switch (status) {
		case 1:
			return "#23c161";
		case 0:
			return "#c00606";
		case 2:
			return "lightgray";
		default:
			return "#ffffff";
	}
}
