<div class="row mt-3 pb-3 device-list">
	<div class="col-12 col-md-12 col-sm-12">
		<div class="card p-3">
			<div class="col-md-12 mb-0">
				<div class="col-md-12 d-flex alignitems-center">
					<div class="col-md-6">
						<h2 class="page-title">Service Edges</h2>
					</div>
					<div class="col-md-6 d-flex justify-content-end">
						<!-- <div class="col-md-5 px-2 mt-2">
                            <app-customer-select-list *ngIf="showCustomerSelect"
                                [selectedCustomer]="customerValueSelected" [data]="customerSelectList"
                                [showCustomerDropDown]="showCustomerSelect" [isShowAll]="true"
                                (selectValue)="onSelectCustomer($event)"></app-customer-select-list>
                        </div> -->
						<div class="mt-2">
							<app-search-input
								[searchTerm]="searchTerm"
								(setSearchValue)="search($event)"
								[dataParams]="dataParams"
							></app-search-input>
						</div>
						<ng-container *ngIf="loggedInUser?.userType?.toLowerCase() === 'internal' && canShowAddBtn && flags?.isEnableAdd">
							<div>
								<button
									appTooltip="Add new edge"
									placement="left"
									aria-label="icon that displays tooltip to add edge"
									class="custom-btn btn-config btn btn-outline-primary my-2 add-button-margin-con"
									[routerLink]="['/service-edge-management', 'action', 'create-service-edge']"
									color="primary"
								>
									<span>
										<i class="fa fa-plus" aria-hidden="true"></i>
										Add
									</span>
								</button>
							</div>
						</ng-container>
					</div>
				</div>
				<div class="col-md-12">
					<app-data-table
						(linkClicked)="onLinkClicked($event)"
						(editClicked)="onEditClicked($event)"
						[isExternalUser]="loggedInUser?.userType?.toLowerCase() !== 'internal'"
						(pageChanged)="onPageChanged($event)"
						[totalSize]="totalSize"
						[pageSize]="pageSize"
						[pageIndex]="pageIndex"
						[isClientSidePagination]="false"
						(detailsClicked)="onDetailsClicked($event)"
						(configurationsClicked)="onConfigurationsClicked($event)"
						[columns]="columns"
						[data]="data"
						[flags]="flags"
						[showPaginator]="!showProgress"
						[isDataLoading]="showProgress || refreshProgress"
					></app-data-table>
				</div>
			</div>
		</div>
	</div>

	<ng-template #fsKeyTemplate>
		<div class="attributes-dialog">
			<div class="dialog-header bg-header">
				<div class="col-md-12 d-flex justify-content-between align-items-center">
					<div class="col-md-11 p-all-12">Registration Key</div>
					<div class="col-md-1 text-end">
						<button mat-icon-button class="close-button" [mat-dialog-close]="true">
							<mat-icon class="text-end attributes-cls-btn">cancel</mat-icon>
						</button>
					</div>
				</div>
			</div>
			<div mat-dialog-content>
				<div class="row-flex">
					<span class="attribute-key">ID:</span>
					<p class="ms-2 attribute-value">{{ fsDetails?.fsId }}</p>
				</div>
				<div class="row-flex">
					<span class="attribute-key">Key:</span>
					<p class="break-word attribute-value">
						{{ maskedKey }}
						<button *ngIf="fsDetails?.fsKey" id="copied-button" class="btn copy-button rounded-circle">
							<span class="ms-2 cursor-pointe copy-icon" (click)="onCopy()">
								<i class="fa fa-files-o" aria-hidden="true"></i>
							</span>
						</button>
					</p>
				</div>
			</div>
			<div mat-dialog-actions class="d-flex justify-content-end gap-2">
				<button mat-dialog-close type="button" class="btn btn-primary custom-btn btn-config">Close</button>
			</div>
		</div>
	</ng-template>
	<div class="device-offline-overlay" *ngIf="isShowOverlayCard">
		<div class="card overlay-text align-items-center">
			<mat-icon class="warning-icon">report_problem</mat-icon>
			<span class="offline-text">{{ SERVICE_EDGE_OFFLINE_MONITORING_MSG }}</span>
			<button class="btn btn-primary custom-btn align-self-end" (click)="isShowOverlayCard = false">Okay</button>
		</div>
	</div>
</div>

<ng-template #tenentCellTemplate let-element="element" let-column="column">
	<ng-container *ngIf="column.label === 'Tenant'">
		<ng-container *ngIf="element?.tenants?.length; else emptyTemplate">
			<a [appTooltip]="tenantTooltip" placement="right" customClass="service-edge-tenant-popover">
				{{ element?.tenants?.length }} Customer(s)
			</a>
			<div #tenantTooltip hidden>
				<ul class="list-group list-group-flush">
					<li class="list-group-item gotham-medium text-start" *ngFor="let tenant of element?.tenants">
						{{ tenant?.displayName }}
					</li>
				</ul>
			</div>
		</ng-container>
		<ng-template #emptyTemplate>
			<div>-</div>
		</ng-template>
	</ng-container>
</ng-template>

