import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "nullable"
})
export class NullablePipe implements PipeTransform {
	transform(value: any, arg?: string): string {
		return value ? value : arg ? arg : "-";
	}
}
