import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MainComponent } from "./components/main/main.component";
import { LogoutComponent } from "./utility/logout";
import { AuthGuard } from "./shared/helpers";
import { Role } from "./shared/models/role";

const routes: Routes = [
	{
		path: "",
		component: MainComponent,
		children: [
			{
				path: "",
				redirectTo: "dashboard",
				pathMatch: "full"
			},
			{
				path: "dashboard",
				loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule)
			},
			{
				path: "customer-management",
				loadChildren: () =>
					import("./customer-management/customer-management.module").then((m) => m.CustomerManagementModule)
			},
			{
				path: "site-management",
				loadChildren: () =>
					import("./sites-management/sites-management.module").then((m) => m.SitesManagementModule)
			},
			{
				path: "device-management",
				loadChildren: () =>
					import("./device-management/device-management.module").then((m) => m.DeviceManagementModule)
			},
			{
				path: "service-edge-management",
				loadChildren: () =>
					import("./service-edges-management/service-edges-management.module").then(
						(m) => m.ServiceEdgesManagementModule
					)
			},
			{
				path: "network-management",
				loadChildren: () =>
					import("./network-configuration-management/network-configuration-management.module").then(
						(m) => m.NetworkConfigurationManagementModule
					)
			},
			{
				path: "configuration-management",
				loadChildren: () =>
					import("./configuration-management/configuration-management.module").then(
						(m) => m.ConfigurationManagementModule
					)
			},
			{
				path: "service-edge-configuration-management",
				loadChildren: () =>
					import("./service-edge-configuration-management/service-edge-configuration-management.module").then(
						(m) => m.ServiceEdgeConfigurationManagementModule
					)
			},
			{
				path: "user-management",
				canActivate: [AuthGuard],
				data: {
					roles: [Role.canListUsers]
				},
				loadChildren: () =>
					import("./user-management/user-management.module").then((m) => m.UserManagementModule)
			},
			{
				path: "account-management",
				loadChildren: () =>
					import("./account-management/account-management.module").then((m) => m.AccountManagementModule)
			},
			{
				path: "monitoring",
				loadChildren: () => import("./monitoring/monitoring.module").then((m) => m.MonitoringModule)
			},
			{
				path: "config",
				loadChildren: () => import("./config/config.module").then((m) => m.ConfigModule)
			},
			{
				path: "forbidden",
				loadChildren: () => import("./forbidden/forbidden.module").then((m) => m.ForbiddenModule)
			},
			{
				path: "profiles",
				loadChildren: () => import("./profile/profile.module").then((m) => m.ProfileModule)
			},
			{
				path: "operations",
				loadChildren: () => import("./operations/operations.module").then((m) => m.OperationsModule)
			},
			{
				path: "report-management",
				loadChildren: () =>
					import("./sla-reports/report-management.module").then((m) => m.ReportManagementModule)
			},
			{
				path: "agent",
				loadChildren: () => import("./agent/agent.module").then((m) => m.AgentModule)
			}
		]
	},
	{
		path: "",
		redirectTo: "/dashboard",
		pathMatch: "full"
	},
	{ path: "logout", component: LogoutComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
