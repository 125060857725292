import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from "@angular/core";
import { Table } from "primeng/table";
import { TableLazyLoadOptions } from "../../models/table";
import { ToasterService } from "../../toaster.service";

interface Flags {
	isLoading: boolean;
	isErrorFetchingData: boolean;
}

interface DataSource {
	tableDataSource: any[];
	clonedTableDataSource: any[];
	selectedRows: any[];
}

interface ColumnConfig {
	columnDef: any[];
	displayColumns?: any[];
}
interface Settings {
	data: any[];
	columnConfig: ColumnConfig;
	loaderFlags: Flags;
	length: number;
	hidePagination?: boolean;
	isClientSidePagination?: boolean;
	paginator?: any;
	noRecordsErrorMessage?: string;
	resetSelectedData?: boolean;
	enableCheckbox?: boolean;
	enableRadioBtn?: boolean;
	recordsPerPage?: number[];
	pageSize?: number;
	defaultSelectedRows?: any;
	isRowCheckBoxSelectable?: string;
	searchText?: string;
}

interface Paginator {
	pageIndex: number;
	pageSize: number;
	pageSizeOptions?: any[];
}
@Component({
	selector: "app-custom-data-table",
	templateUrl: "./custom-data-table.component.html",
	styleUrls: ["./custom-data-table.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomDataTableComponent implements OnInit, OnChanges {
	@Input() settings!: Settings;
	@Output() onPageChange: EventEmitter<TableLazyLoadOptions> = new EventEmitter<TableLazyLoadOptions>();
	@Output() rowSelect: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild("pdataTable", { static: false }) table!: Table;
	dataSource: DataSource = {
		tableDataSource: [],
		clonedTableDataSource: [],
		selectedRows: []
	};
	errorMessage!: string;
	pageNumber!: any;
	paginator: Paginator = {
		pageIndex: 0,
		pageSize: 5,
		pageSizeOptions: [5, 10, 50, 100]
	};
	sortFields = {
		sortField: "",
		direction: ""
	};
	isHeaderSelectRowChecked = false;
	isLoading = false;
	first = 0;
	skeletonCount = Array.from({ length: 5 }).map((_, i) => `Item #${i}`);
	pageNumberErrorMessage: string = "";
	
	constructor(private toasterService: ToasterService, private cd: ChangeDetectorRef) {}

	ngOnInit() {
		this.paginator.pageSizeOptions = this.settings?.recordsPerPage || this.paginator.pageSizeOptions;
		this.paginator.pageSize = this.settings?.pageSize || this.paginator.pageSize;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes?.["settings"]?.currentValue) {
			if (!changes?.["settings"]?.currentValue?.loaderFlags?.isLoading) {
				this.pageNumber = "";
				this.pageNumberErrorMessage = "";
				if (changes?.["settings"]?.currentValue?.data) {
					this.dataSource.clonedTableDataSource = structuredClone(changes?.["settings"]?.currentValue?.data);
					this.settings.length = changes?.["settings"]?.currentValue?.length;
					const dataSource = structuredClone(changes?.["settings"]?.currentValue?.data);
					this.dataSource.tableDataSource = [...dataSource];
					if (this.settings.isClientSidePagination) {
						if (this.table) {
							this.table.first = 0;
						}
					} else {
						const paginator = changes?.["settings"]?.currentValue?.paginator;
						this.paginator.pageIndex = paginator?.pageIndex;
						this.paginator.pageSize = paginator?.pageSize;
					}
					if (changes?.["settings"]?.currentValue?.enableCheckbox) {
						if (this.isHeaderSelectRowChecked && !this.settings.resetSelectedData) {
							if (this.dataSource.tableDataSource?.length) {
								this.dataSource.selectedRows.push(...this.dataSource.tableDataSource);
							}
						} else if (!this.dataSource.selectedRows.length) {
							this.dataSource.selectedRows = [];
							this.isHeaderSelectRowChecked = false;
						}
					} else {
						if (changes?.["settings"]?.currentValue?.defaultSelectedRows) {
							this.dataSource.selectedRows = changes?.["settings"]?.currentValue?.defaultSelectedRows;
						}
					}

					if (this.paginator.pageIndex === 0) {
						this.first = 0;
					}
					this.cd.detectChanges();
				}
			}
		}
	}

	onGoToPage() {
		this.pageNumberErrorMessage = "";
		const lastPage = Math.ceil(this.settings.length / this.paginator.pageSize);
		let message = `Enter a page number less than or equal to ${lastPage}`;
		if (this.paginator.pageIndex + 1 === Number(this.pageNumber)) {
			this.pageNumberErrorMessage = `You are already on page ${this.pageNumber}`;
			return;
		}
		if (this.pageNumber > lastPage) {
			this.pageNumberErrorMessage = message;
			return;
		} else {
			if (this.settings.isClientSidePagination) {
				this.settings.loaderFlags.isLoading = true;
				this.dataSource.tableDataSource = [...structuredClone(this.dataSource.clonedTableDataSource)];
				this.first = (this.pageNumber - 1) * this.paginator.pageSize;
				this.settings.loaderFlags.isLoading = false;
			} else {
				this.paginator.pageIndex = this.pageNumber - 1;
				this.first = this.paginator.pageIndex * this.paginator.pageSize;
				this.onPageChange.emit({
					pageIndex: this.paginator.pageIndex,
					pageSize: this.paginator.pageSize,
					...this.sortFields
				});
			}
		}
		this.pageNumber = "";
	}

	setPaginatorValues(event: any) {
		this.pageNumber = "";
		this.paginator.pageIndex = Math.floor(event.first / event.rows);
		this.paginator.pageSize = event.rows;
		this.settings.loaderFlags.isLoading = true;
		this.first = event.first;
		this.cd.detectChanges();
	}

	onPageSizeChange(event: any) {
		this.pageNumber = "";
		this.first = 0;
		this.paginator.pageIndex = 0;
		this.paginator.pageSize = +event.target.value;
		if (this.settings.isClientSidePagination) {
			this.settings.loaderFlags.isLoading = true;
			this.table.first = 0;
			this.dataSource.tableDataSource = [...structuredClone(this.dataSource.clonedTableDataSource)];
			this.settings.loaderFlags.isLoading = false;
			this.cd.detectChanges();
		} else {
			this.triggerPageChange();
		}
	}

	// Will be triggered in case of page change/sort
	onLoadData(event: any): void {
		if (event.sortOrder) {
			this.sortFields.sortField = event.sortField;
			if (event.sortOrder === 1) {
				this.sortFields.direction = "asc";
			} else {
				this.sortFields.direction = "desc";
			}
		} else {
			this.sortFields.sortField = "";
			this.sortFields.direction = "";
		}
		this.setPaginatorValues(event);
		this.triggerPageChange();
	}

	triggerPageChange() {
		this.onPageChange.emit({
			pageIndex: this.paginator.pageIndex,
			pageSize: this.paginator.pageSize,
			...this.sortFields
		});
	}

	headerCheckboxToggle(event: any) {
		this.isHeaderSelectRowChecked = event?.checked;
		this.onRowSelection();
	}

	onPageChangeEvent(event: any) {
		if (this.settings.isClientSidePagination) {
			this.first = event.first;
		}
	}

	validatePageNumber(event: any): void {
		const pattern = /^[1-9]\d*$/;
		const inputValue = event?.target?.value + event.key;
		if (!pattern.test(inputValue)) {
			event.preventDefault();
		}
	}

	onRowSelection() {
		this.rowSelect.emit(this.dataSource.selectedRows);
	}

	isRowSelectable = (rowData: any): boolean => {
		let isRowSelectable = true;
		if (this.settings?.isRowCheckBoxSelectable) {
			isRowSelectable = rowData?.data[this.settings?.isRowCheckBoxSelectable];
		}
		return isRowSelectable;
	};

	getTooltipMessage(rowData: any, column: any): string {
		return  rowData[column?.header] ? rowData[column?.header].toString() : "";
	}
}
