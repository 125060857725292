import { Component, ChangeDetectorRef } from "@angular/core";
import { DataSharedService } from "../../services/data-shared.service";
import { ConfigurationManagementService } from "src/app/configuration-management/configuration-management.service";
import { IResponse } from "../../models/utils";
import { GlobalConstants } from "../../utils/global-constants";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

interface DataSource {
	profileList: any[];
	clonedProfileList: any[];
	columnConfig: any;
}

@Component({
	selector: "app-profile-list-popup",
	templateUrl: "./profile-list-popup.component.html",
	styleUrls: ["./profile-list-popup.component.scss"]
})
export class ProfileListPopupComponent {
	isPopupOpen: boolean = false;
	isSaveInProgress: boolean = false;
	showProgress: boolean = false;
	isFetchErr = false;
	searchTerm = "";
	totalSize: any;
	customerId!: number;
	paginator = {
		pageIndex: 0,
		pageSize: 5
	};
	defaultSelectedProfile: any;
	dataSource: DataSource = {
		profileList: [],
		clonedProfileList: [],
		columnConfig: {}
	};
	selectedProfile: any;
	deviceId!: number;

	constructor(
		private configManagementService: ConfigurationManagementService,
		private cd: ChangeDetectorRef,
		public dataReference: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef
	) {
		this.customerId = dataReference?.data?.customerId;
		this.deviceId = dataReference?.data?.deviceId;
		this.defaultSelectedProfile = dataReference?.data?.selectedProfile;
	}

	ngOnInit(): void {
		this.getQosProfile();
	}

	getQosProfile() {
		this.showProgress = true;
		this.dataSource.profileList = [];
		this.dataSource.clonedProfileList = [];

		this.configManagementService.getQoSProfileByCustomer(this.customerId).subscribe({
			next: (response: IResponse) => {
				this.showProgress = false;
				if (response?.responseCode === GlobalConstants.RESPONSE_SUCCESS_CODE && response.data?.length) {
					// Find the index of the object with defaultProfile: true
					const defaultProfileIndex = response.data.findIndex(
						(profile: any) => profile.defaultProfile === true
					);

					// If the object is found (index is not -1)
					if (defaultProfileIndex !== -1) {
						// Remove the object from its current position
						const defaultProfileObject = response.data.splice(defaultProfileIndex, 1)[0];

						// Insert the object at the 0th index
						response.data.unshift(defaultProfileObject);
					}
					this.dataSource.profileList = response.data;
					this.dataSource.clonedProfileList = structuredClone(response.data);
				}
			},
			error: (err: any) => {
				this.showProgress = false;
				this.isFetchErr = true;
			}
		});

		const columnDef = [
			{ header: "select", displayName: "Select", type: "radio" },
			{ header: "name", displayName: "Profile name" },
			{
				header: "highest",
				displayName: "Highest (in %)"
			},
			{
				header: "high",
				displayName: "High (in %)"
			},
			{
				header: "medium",
				displayName: "Medium (in %)"
			},
			{
				header: "low",
				displayName: "Low (in %)"
			}
		];

		this.dataSource.columnConfig = {
			columnDef: columnDef,
			displayColumns: columnDef.map((column: any) => column.header)
		};
	}

	onSave() {
		this.dialogRef.close(this.selectedProfile);
	}

	onRowSelected(selectedRow: any) {
		this.selectedProfile = selectedRow;
	}

	search(searchString: any) {
		this.searchTerm = searchString;
		this.showProgress = true;
		this.cd.detectChanges();
		// based on search string filter the list by name, highest, high, medium, low
		this.dataSource.profileList = searchString
			? this.dataSource.clonedProfileList?.filter((item: any) => {
					if (
						item.name.toLowerCase().includes(searchString.toLowerCase()) ||
						item.highest.toString().includes(searchString) ||
						item.high.toString().includes(searchString) ||
						item.medium.toString().includes(searchString) ||
						item.low.toString().includes(searchString)
					) {
						return item;
					}
			  })
			: structuredClone(this.dataSource.clonedProfileList);
		this.showProgress = false;
		this.cd.detectChanges();
	}
}
