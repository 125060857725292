import { Component, Input } from "@angular/core";
import { animate, AUTO_STYLE, state, style, transition, trigger } from "@angular/animations";

const DEFAULT_DURATION = 300;
@Component({
	selector: "app-toggle-content",
	templateUrl: "./toggle-content.component.html",
	styleUrls: ["./toggle-content.component.scss"],
	animations: [
		trigger("expand", [
			state("true", style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
			state("false", style({ height: "0", visibility: "hidden" })),
			transition("true => false", animate(DEFAULT_DURATION + "ms ease-in")),
			transition("false => true", animate(DEFAULT_DURATION + "ms ease-out"))
		])
	]
})
export class ToggleContentComponent {
	@Input() expand = false;
}
