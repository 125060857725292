import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TableColumns } from "../../models/table";
import { PrimeTableColumns, PrimeTableSettings } from "./prime-table.interface";
import { LazyLoadEvent } from "primeng/api";

@Component({
	selector: "app-prime-table",
	templateUrl: "./prime-table.component.html",
	styleUrls: ["./prime-table.component.scss"]
})
export class PrimeTableComponent {
  /*interface for columns need 
  columnheader type - ?
  custom template ?
  pagination ?
  flags ?
  records per page ?
  */



	@Input("columns") columns!: PrimeTableColumns[];
	@Input("flags") flags: any;
	@Input("data") data!: Array<Object> | any;
  	@Input("filterOptions") filterOptions!: any;
	@Input("settings") settings!: PrimeTableSettings;
	@Input()
	public severity!: (param: any) => string;


	@Output() onRowUnSelect: EventEmitter<Element> = new EventEmitter();
	@Output() onRowSelect: EventEmitter<Element> = new EventEmitter();
	@Output() onHeaderCheckboxToggle: EventEmitter<Element> = new EventEmitter();
	@Output() onLazyLoadEvent: EventEmitter<LazyLoadEvent> = new EventEmitter();

  selectedRows: any; 

	constructor() {}

	ngOnInit() {}

	rowSelect(event: any) {
		event.selectedRows = this.selectedRows;
		this.onRowSelect.emit(event);
	}

	rowUnSelect(event: any) {
		event.selectedRows = this.selectedRows;
		this.onRowUnSelect.emit(event);
	}

	headerCheckboxToggle(event: any) {
		event.selectedRows = this.selectedRows;
		this.onHeaderCheckboxToggle.emit(event);
	}

	getSeverity(label: any) {
    return "warning";//this.severity(label);
  }


  showSteps(step: any) {
    console.log(step)
  }

  filter_func(event: any) {
    console.log(event);
  }

  customSort(event:any) {

  }

  onLazyLoad(event:LazyLoadEvent) {
	console.log(event);
	this.onLazyLoadEvent.emit(event);
  }
}
