import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output
} from "@angular/core";
import { map, Observable, Subscription } from "rxjs";
import { ProgressRef } from "./progress-ref";
import { ProgressService } from "./progress.service";
import { ProgressState } from "./progress.interface";

@Component({
	selector: "app-progress-bar",
	templateUrl: "./progress-bar.component.html",
	styleUrls: ["./progress-bar.component.scss"],
	host: {
		role: "progressbar",
		"[attr.spinnerPosition]": "spinnerPosition",
		"[attr.direction]": "direction",
		"[attr.thick]": "thick",
		"[attr.fixed]": "fixed"
	},
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent implements OnInit, OnChanges, OnDestroy {
	private _started!: Subscription;
	private _completed!: Subscription;

	/** Progress bar worker */
	progressRef!: ProgressRef;

	/** Stream that emits progress state */
	state$!: Observable<{ active: boolean; transform: string }>;

	/** Creates a new instance if id is not already exists */
	@Input() id = "root";

	/** Initializes inputs from the global config */
	@Input() min: number = this._ngProgress.config.min;
	@Input() max: number = this._ngProgress.config.max;
	@Input() ease: string = this._ngProgress.config.ease;
	@Input() color: string = this._ngProgress.config.color;
	@Input() speed: number = this._ngProgress.config.speed;
	@Input() thick: boolean = this._ngProgress.config.thick;
	@Input() fixed: boolean = this._ngProgress.config.fixed;
	@Input() meteor: boolean = this._ngProgress.config.meteor;
	@Input() spinner: boolean = this._ngProgress.config.spinner;
	@Input() trickleSpeed: number = this._ngProgress.config.trickleSpeed;
	@Input() debounceTime: number = this._ngProgress.config.debounceTime;
	@Input() trickleFunc: (n: number) => number = this._ngProgress.config.trickleFunc;
	@Input() spinnerPosition: "left" | "right" = this._ngProgress.config.spinnerPosition;
	@Input() direction: "ltr+" | "ltr-" | "rtl+" | "rtl-" = this._ngProgress.config.direction;
	@Output() started = new EventEmitter();
	@Output() completed = new EventEmitter();

	get isStarted() {
		return this.progressRef?.isStarted;
	}

	constructor(private _ngProgress: ProgressService) {}

	ngOnChanges() {
		// Update progress bar config when inputs change
		this.progressRef?.setConfig({
			max: this.max > 0 && this.max <= 100 ? this.max : 100,
			min: this.min < 100 && this.min >= 0 ? this.min : 0,
			speed: this.speed,
			trickleSpeed: this.trickleSpeed,
			trickleFunc: this.trickleFunc,
			debounceTime: this.debounceTime
		});
	}

	ngOnInit() {
		// Get progress bar service instance
		this.progressRef = this._ngProgress.ref(this.id, {
			max: this.max,
			min: this.min,
			speed: this.speed,
			trickleSpeed: this.trickleSpeed,
			debounceTime: this.debounceTime
		});

		// Subscribe to progress state
		this.state$ = this.progressRef.state.pipe(
			map((state: ProgressState) => ({
				active: state.active,
				transform: `translate3d(${state.value}%,0,0)`
			}))
		);

		// Subscribes to started and completed events on demand
		if (this.started.observed) {
			this._started = this.progressRef.started.subscribe(() => this.started.emit());
		}
		if (this.completed.observed) {
			this._completed = this.progressRef.completed.subscribe(() => this.completed.emit());
		}
	}

	ngOnDestroy() {
		this._started?.unsubscribe();
		this._completed?.unsubscribe();
		this.progressRef?.destroy();
	}

	start() {
		this.progressRef.start();
	}

	complete() {
		this.progressRef.complete();
	}

	inc(n?: number) {
		this.progressRef.inc(n);
	}

	set(n: number) {
		this.progressRef.set(n);
	}
}
