<div class="custom-data-table">
	<p-table
		[hidden]="settings.loaderFlags.isLoading"
		#pdataTable
		[columns]="settings.columnConfig.columnDef"
		[value]="dataSource.tableDataSource"
		[paginator]="!settings.hidePagination"
		[lazy]="!settings.isClientSidePagination"
		[(selection)]="dataSource.selectedRows"
		[first]="first"
		[lazyLoadOnInit]="false"
		[customSort]="true"
		[rows]="paginator.pageSize"
		(onHeaderCheckboxToggle)="headerCheckboxToggle($event)"
		(onLazyLoad)="onLoadData($event)"
		[totalRecords]="settings.length"
		(onPage)="onPageChangeEvent($event)" [rowSelectable]="isRowSelectable">		
		<ng-template pTemplate="header" let-columns>
			<tr>
				<ng-container *ngFor="let column of columns">
					<th
					[style.min-width]="column?.width"
					[style.max-width]="column?.width"
					[pSortableColumn]="column.header"
					[pSortableColumnDisabled]="!column.sort">					
						<div [ngSwitch]="column.type" >
							<ng-container *ngSwitchCase="'checkbox'">
								<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
							</ng-container>
							<ng-container *ngSwitchDefault>
								{{ column.displayName }}  <p-sortIcon  [field]="column.header" *ngIf="column.sort"></p-sortIcon>
							</ng-container>
						</div>					
				</th>
				</ng-container>	
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData let-columns="columns">
			<tr *ngIf="!settings.loaderFlags.isLoading" [pSelectableRow]="rowData">
				<ng-container *ngFor="let column of columns">
					<ng-container *ngIf="column.header !== 'actions'; else actionColumnTemplate">
						<ng-container *ngIf="!column?.cellTemplate; else displayTemplate">
							<td [ngClass]="column?.cellClass" [style.min-width]="column?.width" [style.max-width]="column?.width"
								[ngStyle]="column?.cellStyle">
								<div [ngSwitch]="column.type">
									<ng-container *ngSwitchCase="'radio'">
										<p-tableRadioButton [value]="rowData" (click)="onRowSelection()"></p-tableRadioButton>
									</ng-container>
									<ng-container *ngSwitchCase="'checkbox'">
										<p-tableCheckbox [value]="rowData" (click)="onRowSelection()"
											[disabled]="!rowData[column.disableField]"></p-tableCheckbox>
									</ng-container>
									<ng-container *ngSwitchDefault>
										<span [appTooltip]="!column?.breakWord ? getTooltipMessage(rowData, column) : ''"
											[ngClass]="column?.breakWord ?  'break-word' : 'ellipsis'">
											{{ rowData[column.header] | nullable }}
										</span>
									</ng-container>
								</div>
							</td>
						</ng-container>
						<ng-template #displayTemplate>
							<td [ngClass]="column?.cellClass" [style.min-width]="column?.width" [style.max-width]="column?.width"
								[ngStyle]="column?.cellStyle">
								<ng-template [ngTemplateOutletContext]="{ element: rowData, column: column }"
									[ngTemplateOutlet]="column.cellTemplate"></ng-template>
							</td>
						</ng-template>
					</ng-container>
					<ng-template #actionColumnTemplate>
						<td [ngClass]="column?.cellClass" [style.min-width]="column?.width" [style.max-width]="column?.width"
							[ngStyle]="column?.cellStyle">
							<button mat-button [matMenuTriggerFor]="actionMenus">
								<i appTooltip="More options" id="more-button" class="fa fa-ellipsis-h"></i>
							</button>
							<mat-menu #actionMenus="matMenu">
								<ng-container *ngIf="column.cellTemplate; else defaultActionMenus">
									<ng-template
										[ngTemplateOutletContext]="{ element: rowData, column: column }"
										[ngTemplateOutlet]="column.cellTemplate"></ng-template>
								</ng-container>
								<ng-template #defaultActionMenus>
									<ng-container *ngFor="let btn of column.actionMenus">
										<button mat-menu-item>
											<i *ngIf="btn?.icon" class="fa {{ btn.icon }}" aria-hidden="true"></i>
											{{ btn.name }}
										</button>
									</ng-container>
								</ng-template>
							</mat-menu>
						</td>
					</ng-template>
				</ng-container>
			</tr>
		</ng-template>

		<ng-template pTemplate="sorticon" field="col.field" let-sortOrder>
			<ng-container *ngIf="sortOrder === 0">
				<span class="ms-2 sort-icon">
					<i class="fa fa-sort" aria-hidden="true"></i>
				</span>
			</ng-container>
			<ng-container *ngIf="sortOrder === 1">
				<span class="ms-2 sort-icon">
					<i class="fa fa-sort-asc" aria-hidden="true"></i>
				</span>
			</ng-container>
			<ng-container *ngIf="sortOrder === -1">
				<span class="ms-2 sort-icon">
					<i class="fa fa-sort-desc" aria-hidden="true"></i>
				</span>
			</ng-container>
		</ng-template>

		<ng-template pTemplate="paginatorleft">
			<div class="form-group d-flex align-items-center gap-2 justify-content-end paginator-container">
				<label class="paginator-page-label" for="page">Page</label>
				<input
					id="page"
					[(ngModel)]="pageNumber"
					class="paginator-text-input col-md-2 text-center"
					type="text"
					(keypress)="validatePageNumber($event)" />
				<span class="paginator-btn-container" [appTooltip]="!pageNumber ? 'Please enter a page number' : ''">
					<button
						(click)="onGoToPage()"
						[disabled]="!pageNumber"
						[ngClass]="!pageNumber ? 'disabled-btn' : ''"
						class="paginator-btn paginator-btn-outline-primary">
						GO
					</button>
				</span>
				<select
					(change)="onPageSizeChange($event)"
					class="records-per-page-container"
					aria-label="records per page"
					[(ngModel)]="paginator.pageSize">
					<option [value]="pageNumber" *ngFor="let pageNumber of paginator.pageSizeOptions">
						{{ pageNumber }}
					</option>
				</select>
			</div>
			<div *ngIf="pageNumberErrorMessage" class="page-number-error-message">
				<span class="text-danger">{{ pageNumberErrorMessage }}</span>
			</div>
		</ng-template>

		<ng-template pTemplate="emptymessage">
			<ng-container *ngIf="!settings.loaderFlags.isLoading">
				<ng-container *ngIf="dataSource.clonedTableDataSource.length; else customErrorMessage">
					<td class="no-records" [colSpan]="settings.columnConfig.columnDef.length">
						No matching records were found!
					</td>
				</ng-container>
				<ng-template #customErrorMessage>
					<td class="no-records" [colSpan]="settings.columnConfig.columnDef.length">
						<ng-container *ngIf="!settings.loaderFlags.isErrorFetchingData;else errTemplate">						
						{{ settings.noRecordsErrorMessage ? settings.noRecordsErrorMessage : "No data found!" }}
						</ng-container>
					</td>
				</ng-template>
			</ng-container>
		</ng-template>
	</p-table>

	<ng-container *ngIf="settings.loaderFlags.isLoading">
		<p-table [value]="skeletonCount" [columns]="settings.columnConfig.columnDef">
			<ng-template pTemplate="header" let-columns>
				<tr>					
					<th
						*ngFor="let col of columns"
						[pSortableColumn]="col.header"
						[pSortableColumnDisabled]="!col.sort">
						{{ col.displayName }}
						<p-sortIcon [field]="col.header" *ngIf="col.sort"></p-sortIcon>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData let-columns="columns">
				<tr class="mat-row cpe-mapping-table-skeleton">
					<td *ngFor="let column of settings?.columnConfig?.columnDef">
						<ngx-skeleton-loader
							[count]="1"
							appearance="line"
							animation="progress"
							[theme]="{
								'background-color': '#cacaca7a',
								'border-radius': '5px'
							}"></ngx-skeleton-loader>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="sorticon" field="col.field" let-sortOrder>
				<ng-container *ngIf="sortOrder === 0">
					<span class="ms-2 sort-icon">
						<i class="fa fa-sort" aria-hidden="true"></i>
					</span>
				</ng-container>
				<ng-container *ngIf="sortOrder === 1">
					<span class="ms-2 sort-icon">
						<i class="fa fa-sort-asc" aria-hidden="true"></i>
					</span>
				</ng-container>
				<ng-container *ngIf="sortOrder === -1">
					<span class="ms-2 sort-icon">
						<i class="fa fa-sort-desc" aria-hidden="true"></i>
					</span>
				</ng-container>
			</ng-template>
		</p-table>
	</ng-container>

	<ng-template #errTemplate>
		Unable to fetch records at the moment. Please try again later.
	</ng-template>

</div>
