import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { INFO_MESSAGES } from '../../utils/global-constants';

@Component({
	selector: 'app-carousel-table',
	templateUrl: './carousel-table.component.html',
	styleUrls: ['./carousel-table.component.scss']
})

export class CarouselTableComponent implements OnInit {
	NO_DATA_FOUND = INFO_MESSAGES.NO_DATA_FOUND;

	@Input() rowHeaders: string[] = [];
	@Input() data: any = [];
	// @Input() isLoading: boolean = true;

	@ViewChild('slickModal') slickModal!: SlickCarouselComponent;

	slideConfig: any = {
		"slidesToShow": 4, "slidesToScroll": 1, arrows: false,
		pauseOnHover: true, swipe: false
	};


	next() {
		this.slickModal.slickNext();
	}

	constructor() { }

	ngOnInit(): void {	
	}
}
