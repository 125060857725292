<div>
  <button id="btn-container" class="date-btn custom-transparent-button">
    <span *ngIf="!flags?.isRangeSelected && !flags.isDateTimeStamp  && !flags.isYesterdaySelected">
      {{ date?.fromDate }}
      <span *ngIf="date?.toDate">- {{ date?.toDate }}</span>
    </span>
    <span *ngIf="flags.isDateTimeStamp">
      {{dashboardModel?.startDateTime}} - {{dashboardModel?.endDateTime}}
    </span>
    <span *ngIf="flags.isYesterdaySelected">
      {{ date.fromDate }}
    </span>
    <span>
      <p-calendar #calendar (onClose)="onCloseDateRangePicker()" dateFormat="dd M yy"
        [ngClass]="{'hide-inputput-box' :!flags?.isRangeSelected }" [(ngModel)]="selectedCustomRange.rangeDate"
        selectionMode="range" [maxDate]="maxDate" [showTime]="true" class="date-time-picker"></p-calendar>
    </span>
    <i id="date-icon" class="fa fa-calendar ms-1" aria-hidden="true"></i>
    <div *ngIf="showDropdown && !calendar?.overlayVisible" id="filter-date">
      <table class="date-align">
        <ng-container *ngFor="let element of dateFilters; let i = index; let isEven = even; let isLast = last;">
          <ng-container *ngIf="isEven && !isLast">
            <tr class="tr">
              <td (click)="onDateSelected(element)" class="td">
                <div [ngClass]="{ 'selected-filter-date': selectedDate === element.value }">
                  {{ element.label }}
                </div>
              </td>
              <td (click)="onDateSelected(dateFilters[i + 1])" class="td">
                <div [ngClass]="{ 'selected-filter-date': selectedDate === dateFilters[i + 1].value }">
                  {{ dateFilters[i + 1].label }}
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="isLast && isEven">
            <tr>
              <td style="text-align: center;" colspan="2" class="center-align" (click)="onDateSelected(element)">
                <div [ngClass]="{ 'selected-filter-date': selectedDate === element.value }">
                  {{ element.label }}
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </table>
    </div>
  </button>
  <div *ngIf="flags.isMaxDayRangeError && !showDropdown" class="position-relative">
    <span class="text-danger">Date range cannot exceed 30 days.</span>
  </div>
  <div
    *ngIf="flags.isTimeDifference && !flags.isDateInvalid && !showDropdown && flags?.isDateRangeSelected && !calendar?.overlayVisible"
    class="position-relative">
    <span class="text-danger">Time difference should be 5 minutes.</span>
  </div>
  <div *ngIf="flags.isDateInvalid && !showDropdown && !calendar?.overlayVisible" class="position-relative">
    <span class="text-danger">End time should be greater than start time.</span>
  </div>
  <div *ngIf="flags?.isStartAndEndDateSelected && !showDropdown && flags?.isRangeSelected && !calendar?.overlayVisible"
    class="position-relative">
    <span class="text-danger">Please select date range.</span>
  </div>
</div>