<ng-container *ngIf="state$ | async; let state">
	<div class="ng-progress-bar" [attr.active]="state.active" [style.transition]="'opacity ' + speed + 'ms ' + ease">
		<div class="ng-bar-placeholder">
			<div
				class="ng-bar progress-bar-striped"
				[style.transform]="state.transform"
				[style.backgroundColor]="color"
				[style.transition]="state.active ? 'all ' + speed + 'ms ' + ease : 'none'">
				<div
					*ngIf="meteor"
					class="ng-meteor"
					[style.boxShadow]="'0 0 10px ' + color + ', 0 0 5px ' + color"></div>
			</div>
		</div>
		<div *ngIf="spinner" class="ng-spinner">
			<div class="ng-spinner-icon" [style.borderTopColor]="color" [style.borderLeftColor]="color"></div>
		</div>
	</div>
</ng-container>
