import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { AgHttpService } from "../shared/services/ag-http-service";
import { ApiUrlService } from "../shared/services/api-url.service";
declare var window: any;
@Component({
	selector: "app-logout-page",
	template: `
		<ng-template #logoutLoaderPopup>
			<div class="modal-body clearfix">
				<div class="logoutCenter"><mat-spinner [diameter]="40" [value]="value"></mat-spinner></div>
				<div class="logmsg">{{ logoutMsg }}</div>
			</div>
		</ng-template>
	`,
	styles: [
		`
			.logoutCenter {
				padding: 10px;
				width: 40px !important;
				float: left;
			}
			.logmsg {
				margin-top: 20px;
				margin-left: 75px;
				font-size: 14px;
			}
			::ng-deep .modal {
				margin-top: 170px !important;
			}
		`
	]
})
export class LogoutComponent implements OnInit {
	value: any;
	logoutLoader: any;
	logoutLoaderPopupRef: any;
	logoutMsg = "Logout Inprogress ...";
	keycloakContextPath!: string;
	keycloakURL!: string;

	constructor(
		private httpSvc: AgHttpService,
		private keycloakService: KeycloakService,
		private apiUrlService: ApiUrlService
	) {
		this.keycloakContextPath = this.apiUrlService.getEnvUrlByKey("keycloakContextPath");
		this.keycloakURL = this.apiUrlService.getEnvUrlByKey("keycloakURL");
	}

	ngOnInit() {
		//const userPreferenceRequest = localStorage.getItem('userPreferenceRequest');
		window.localStorage.clear();
		window.sessionStorage.clear();
		const exMins = 0;
		const currentDate = new Date();
		currentDate.setTime(currentDate.getTime() + exMins * 60 * 1000);
		document.cookie = "KEYCLOAKID=; path=/;expires=" + currentDate.toUTCString();
		sessionStorage.removeItem("breadcrumbs"); // clear breadcrumbs on logout
		this.keycloakService.logout(this.keycloakContextPath);
		// this.logoutSession();
	}

	logoutSession() {
		window.location.href = `${this.keycloakURL}/auth/realms/master/protocol/openid-connect/logout?redirect_uri=${this.keycloakContextPath}`;
	}

	logoutToken() {
		return this.httpSvc.makeGetRequest(window.config.keycloakAuthUrl + "authentication/token/logout");
	}
}
