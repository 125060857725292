<div class="breadcrumbs-container" *ngIf="breadcrumbs && breadcrumbs.length > 1 && breadcrumbs[0]?.isHomeUrl">
	<span *ngFor="let breadcrumb of breadcrumbs; let last = last">
		<span *ngIf="breadcrumb?.isHomeUrl" class="active-breadcrumb">
			<i class="fa fa-home" aria-hidden="true" (click)="onClickBreadcrumb(breadcrumb)"></i>
		</span>
		<span
			(click)="onClickBreadcrumb(breadcrumb)"
			*ngIf="!breadcrumb?.isHomeUrl"
			[ngClass]="{
				'active-breadcrumb': !last
			}">
			{{ breadcrumb.title }}
		</span>
		<i class="fa fa-caret-right" aria-hidden="true" *ngIf="!last"></i>
	</span>
</div>
