import { GlobalConstants } from "../global-constants";

export function findLeastSizeUnitByBytes(bytes: any[]) {
	// From the collection of bytes array find the least value (consider only non zero value)

	const minBytes = bytes
		.filter(function (x) {
			return x > 0;
		})
		.sort((n1, n2) => n1 - n2)[0];

	const sizes = GlobalConstants.unitsInBytes;

	const i = Math.min(Math.floor(Math.log(minBytes) / Math.log(1024)), sizes.length - 1);

	const leastSizeUnit = i >= 0 ? sizes[i] : sizes[0];

	return leastSizeUnit;
}

export function valueFormatterByBytes(formatterUnit: string, value: number) {
	let formattedvalue = 0;
	switch (formatterUnit) {
		case GlobalConstants.bytes.value:
			formattedvalue = value;
			break;
		case GlobalConstants.KB.value:
			formattedvalue = value * 0.001;
			break;
		case GlobalConstants.MB.value:
			formattedvalue = value * 1e-6;
			break;
		case GlobalConstants.GB.value:
			formattedvalue = value * 1e-9;
			break;
		case GlobalConstants.TB.value:
			formattedvalue = value * 1e-12;
			break;
		default:
			formattedvalue = value;
	}
	return formattedvalue ? Number(formattedvalue.toFixed(2)) : formattedvalue;
}

export function findLeastSizeUnitByBits(bits: number[]): { label: string; value: string } {
	const minBytes = bits
		.filter(function (x) {
			return x > 0;
		})
		.sort((n1, n2) => n1 - n2)[0];

	const sizes = GlobalConstants.unitsInBits;

	const i = Math.min(Math.floor(Math.log(minBytes) / Math.log(1024)), sizes.length - 1);

	const leastSizeUnit = i >= 0 ? sizes[i] : sizes[0];

	return leastSizeUnit;
}

export function findMaxSizeUnitByBits(bits: number[]): { label: string; value: string } {
	const maxBits = bits
		.filter(function (x) {
			return x > 0;
		})
		.sort((n1, n2) => n2 - n1)[0];

	const sizes = GlobalConstants.unitsInBits;

	const i = Math.min(Math.floor(Math.log(maxBits) / Math.log(1000)), sizes.length - 1);

	const maxSizeUnit = i >= 0 ? sizes[i] : sizes[0];

	return maxSizeUnit;
}
export function valueFormatterByBits(formatterUnit: string, value: number) {
	let formattedvalue = 0;
	switch (formatterUnit) {
		case GlobalConstants.bps.value:
			formattedvalue = value;
			break;
		case GlobalConstants.Kbps.value:
			formattedvalue = value * 1e-3;
			break;
		case GlobalConstants.Mbps.value:
			formattedvalue = value * 1e-6;
			break;
		case GlobalConstants.Gbps.value:
			formattedvalue = value * 1e-9;
			break;
		case GlobalConstants.Tbps.value:
			formattedvalue = value * 1e-12;
			break;
		default:
			formattedvalue = value;
	}
	return formattedvalue ? Number(formattedvalue.toFixed(2)) : formattedvalue;
}

/**
 * Copilot coverage
 * Converts the given number of bytes to a human-readable format.
 * @param bytes - The number of bytes to convert.
 * @returns A string representing the converted value with the appropriate unit.
 */
export function convertByetes(bytes: number): string {
	if (bytes <= 0 || isNaN(bytes)) {
		return "-";
	}
	const sizes = ["B", "KB", "MB", "GB", "TB"];
	const i = Math.floor(Math.log(bytes) / Math.log(1024));
	const convertedValue = (bytes / Math.pow(1024, i)).toFixed(2);
	const unit = sizes[i];
	return `${convertedValue} ${unit}`;
}
