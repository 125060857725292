import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
	name: "filterTable"
})
export class FilterTablePipe implements PipeTransform {
	transform(list: any[], value: string) {
		// let us return only items that contain the value string, regardless of case
		const sites: any = {};
		let result = value
			? list.filter((item) => JSON.stringify(item).toLowerCase().includes(value.toLowerCase()))
			: list;

		return result.map((x) => {
			const siteSpan = sites[x.site] ? 0 : result.filter((y) => y.site === x.site).length;
			sites[x.site] = true;
			return { ...x, siteSpan };
		});
	}
}
