import { MatDialog } from "@angular/material/dialog";
import { cloneDeep, isEqual } from "lodash";
import { INFO_MESSAGES } from "./global-constants";
import { ConfirmDialogComponent } from "src/app/common/confirm-dialog/confirm-dialog.component";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";

@Injectable()
export class ResetActionValidator {
	constructor(private dialog: MatDialog, private router: Router) {}

	validateResetAction(createdFormValues: any, updatedFormValues: any): boolean {
		if (isEqual(createdFormValues, updatedFormValues)) {
			this.dialog.open(ConfirmDialogComponent, {
				data: {
					title: "Confirm Action",
					message: INFO_MESSAGES.RESET_CHANGE_CHECK_MESSAGE,
					modalProps: {
						action: "Okay"
					}
				}
			});
			return true;
		} else {
			return false;
		}
	}

	parseFormValues(formValues: any): any {
		const parsedValues = cloneDeep(formValues);
		function processObject(obj: any) {
			for (const key in obj) {
				if (typeof obj[key] === "object" && obj[key] !== null) {
					processObject(obj[key]);
				} else if (typeof obj[key] === "string" && obj[key] === "") {
					obj[key] = null;
				}
			}
		}
		processObject(parsedValues);
		return parsedValues;
	}

	validateFormCancelAction(createdFormValues: any, updatedFormValues: any, navigateUrl: string, state?: any) {
		let create = this.parseFormValues(createdFormValues);
		let update = this.parseFormValues(updatedFormValues);
		if (isEqual(create, update)) {
			const dialogRef = this.dialog.open(ConfirmDialogComponent, {
				data: {
					title: "Confirm Action",
					message: INFO_MESSAGES.FORM_CANCEL_CONFIRMATION_MESSAGE
				},
				maxWidth: "400px"
			});

			dialogRef.afterClosed().subscribe((agreed: boolean) => {
				if (agreed) {
					if (state) {
						this.router.navigateByUrl(navigateUrl, { state: { selectedTab: state } });
					} else {
						this.router.navigateByUrl(navigateUrl);
					}
				}
			});
			return true;
		} else {
			return false;
		}
	}
}
