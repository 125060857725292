<div class="confirm-dialog">
	<div class="dialog-header">
		<h6 class="header-text">{{ title }}</h6>
		<!-- <button mat-icon-button aria-label="close dialog" mat-dialog-close>
            <mat-icon class="close-icon">close</mat-icon>
        </button> -->
	</div>
	<!-- <div mat-dialog-content class="body">
        <span (click)="onEventAction();">
            <span [innerHTML]="message"> </span>
        </span>
    </div> -->
	<div mat-dialog-content class="body">
		<span [innerHTML]="message" *ngIf="!flags.isEnableEvent"></span>
		<div *ngIf="flags.isEnableEvent">
			<span *ngFor="let message of customTemplateMessage.message">
				<span *ngIf="message.isNewLine">
					<br />
				</span>
				<span *ngIf="!message.routeEvent">
					{{ message.name }}
				</span>
				<span *ngIf="message.routeEvent" class="table-link" (click)="onEventAction()">
					{{ message.name }}
				</span>
			</span>
		</div>
		<div *ngIf="hint" class="hint">
			<span>
				{{ hint }}
			</span>
		</div>
	</div>
	<div mat-dialog-actions class="d-flex justify-content-end gap-2">
		<ng-container *ngIf="!flags.isEnableOkayButton">
			<button class="btn btn-primary custom-btn btn-config" (click)="onCancel()">No</button>
			<button class="btn btn-primary custom-btn btn-config" (click)="onConfirm()">Yes</button>
		</ng-container>
		<ng-container *ngIf="flags.isEnableOkayButton">
			<button class="btn btn-primary custom-btn btn-config" (click)="onCancel()">Okay</button>
		</ng-container>
	</div>
</div>
