import { APIUrlConstants } from "../utils/api-url-constants";

export interface MultiselectDropdown {
	label: string;
	value?: any;
	id?: any;
}
export interface IpConfig {
	version: string;
	isCidr?: boolean;
	multi?: boolean;
	combo?: boolean;
	nonZeroSegmentRequired?: boolean;
	supportWithOrWithoutCidr?: boolean;
}
export enum IpVersion {
	V4 = "IPv4",
	V6 = "IPv6",
	DUAL = "Dual"
}

export interface PortConfig {
	isRange?: boolean;
	multi?: boolean;
	combo?: boolean;
}

export interface DataParams {
	showSearchInput?: boolean; // to show search input box
	searchDivClass?: string;
	// can add the further params if required to pass the more data
}

export interface IDataSource {
	[key: string]: any;
}

export interface IResponse {
	responseCode: number;
	message: string;
	traceId: string;
	data: any;
}

export interface ENV_URLS {
	production: boolean;
	keyCloakConfigFile: string;
	keycloakURL: string;
	keycloakContextPath: string;
	basePath: string;
	deviceBasePath: string;
	customerMgmtBasePath: string;
	monitoringServiceBasePath: string;
	networkMgmtBasePath: string;
	securityMgmtBasePath: string;
	userMgmtBasePath: string;
	fsBasePath: string;
	remoteUpgradeBasePath: string;
	agentServiceBasePath: string;
}

export interface API_URLS {
	[APIUrlConstants.BASE_PATH]: string;
	[APIUrlConstants.DEVICE_MANAGEMENT_BASEPATH]: string;
	[APIUrlConstants.CUSTOMER_MANAGEMENT_BASEPATH]: string;
	[APIUrlConstants.MONITORING_SERVICE_BASEPATH]: string;
	[APIUrlConstants.NETWORK_MANAGEMENT_BASEPATH]: string;
	[APIUrlConstants.SECURITY_MANAGEMENT_BASEPATH]: string;
	[APIUrlConstants.USER_MANAGEMENT_BASEPATH]: string;
	[APIUrlConstants.REMOTE_UPGRADE_BASEPATH]: string;
	[APIUrlConstants.DEVICE_MANAGEMENT_URL]: string;
	[APIUrlConstants.CUSTOMER_MANAGEMENT_URL]: string;
	[APIUrlConstants.MONITORING_SERVICE_URL]: string;
	[APIUrlConstants.NETWORK_MANAGEMENT_URL]: string;
	[APIUrlConstants.SECURITY_MANAGEMENT_URL]: string;
	[APIUrlConstants.USER_MANAGEMENT_URL]: string;
	[APIUrlConstants.FS_BASE_PATH]: string;
	[APIUrlConstants.REMOTE_UPGRADE_URL]: string;
	[APIUrlConstants.AGENT_SERVICE_BASEPATH]: string;
}

export enum DeviceStatus {
	online = "Online",
	offline = "Offline",
	partiallyOnline = "Partially Online"
}
