import { APP_INITIALIZER, NgModule, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { MainComponent } from "./components/main/main.component";
import { initializeKeycloak } from "./core/authentication/keycloak/keycloak-init.factory";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { ConfigInitService } from "./core/authentication/keycloak/config-init.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { SharedModule } from "./shared/shared.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { ServiceWorkerModule } from "@angular/service-worker";
import { LogoutComponent } from "./utility/logout";
import { PreviousRouteService } from "./shared/services/route-service";
import { CustomHttpInterceptor } from "./common/http.interceptor";
import { InitService } from "./core/authentication/keycloak/init-service";
import { BreadCrumbsComponent } from "./components/breadcrumbs/breadcrumbs.component";
import { BreadcrumbsService } from "./components/breadcrumbs/breadcrumbs.service";
import { LineChartUtils } from "./common/charts/line-chart/line-chart-utils";
import { CalendarModule } from 'primeng/calendar';

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		MainComponent,
		LogoutComponent,
		BreadCrumbsComponent
	],
	imports: [
		BrowserModule,
		SharedModule,
		AppRoutingModule,
		KeycloakAngularModule,
		HttpClientModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot({ preventDuplicates: true }),
		ServiceWorkerModule.register("ngsw-worker.js", {
			enabled: !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: "registerWhenStable:30000"
		}),
		CalendarModule
	],
	providers: [
		ConfigInitService,
		PreviousRouteService,
		BreadcrumbsService,
		{
			provide: APP_INITIALIZER,
			useFactory: initializeKeycloak,
			multi: true,
			deps: [KeycloakService, ConfigInitService, InitService]
		},
		{ provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
		LineChartUtils
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
