import { Injectable } from "@angular/core";
import { map, Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { Site } from "../shared/models/sites-spaed";
import { AgHttpService } from "../shared/services/ag-http-service";
import { HttpHeaders } from "@angular/common/http";
import { API_URLS } from "src/app/shared/models/utils";
import { ApiUrlService } from "src/app/shared/services/api-url.service";

@Injectable({
	providedIn: "root"
})
export class SitesManagementService {
	apiUrls!: API_URLS;
	public selectedCustomerId: any;
	public siteListResponse: any = [
		{
			commentCount: 18,
			currentRouterUnits: [
				{
					currentDeployment: null,
					downtimes: null,
					id: 10,
					identifyingMac: "00:1e:42:54:74:6a",
					manufacturerSerialNumber: "1117027130",
					model: {
						id: 4,
						name: "Teltonika-RUT950"
					},
					nfEndpointUUID: null,
					nfJwtExpiry: null,
					rmsId: 0,
					sshHostKeyMismatch: false,
					transientDetails: {
						availableRAM: 0,
						controlPlaneConnected: false,
						dataPlaneConnected: false,
						deviceClock: 0,
						emittedEssids: null,
						freeFlash: 0,
						lastDataParsed: 0,
						lastUpdateReceived: 0,
						maxFreeFlash: 0,
						offlineSince: 0,
						onlineSince: 0,
						pidofFaultMonitor: 0,
						pidofZitiTunneler: 0,
						rpcFailed: false,
						temperature: 0,
						totalRAM: 0,
						upSince: 0,
						visibleAPs: null
					},
					vettedBy: null,
					virtualAddress: "10.0.0.78",
					whitelistedServices: []
				}
			],
			id: 11,
			lanIPaddressPrefix: 16,
			lanIPaddressRange: "192.168.0.1",
			latitude: 12.74539,
			longitude: 79.44057,
			name: "testAPI4",
			requestedAt: "2023-05-15T07:54:13.764Z[UTC]",
			requestedBy: {
				email: "<TODO>",
				enabled: true,
				id: 1,
				username: "admin"
			},
			shutdown: false
		},
		{
			commentCount: 4,
			currentRouterUnits: [
				{
					currentDeployment: null,
					downtimes: null,
					id: 7,
					identifyingMac: "00:1e:42:53:9a:ee",
					manufacturerSerialNumber: "1117036473",
					model: {
						id: 4,
						name: "Teltonika-RUT950"
					},
					nfEndpointUUID: "3285d8d8-68e5-45ef-9e7b-b48d24da7b9b",
					nfJwtExpiry: null,
					rmsId: 0,
					sshHostKeyMismatch: false,
					transientDetails: {
						availableRAM: 85060,
						cellularStats: {
							cellID: -1,
							cellularCarrierType: "HSDPA and H",
							cellularNetwork:
								"+SGCELLINFOEX:WCDMA,404,64,13357481,463,203,53673,3203,1,10782,9832,-91,8,255,90",
							iccid: "N/A",
							imei: "868159050831192",
							imsi: "N/A",
							lteRsrp: null,
							lteRsrq: null,
							lteSinr: null,
							neighbourCells: ["not supported", ""],
							networkLinkState: "N/A",
							operatorName: "N/A",
							servingCell:
								"^CELLINFO:WCDMA,404,64,13357481,463,203,53673,3203,1,10782,9832,-91,10,255,90",
							signalLevel: null,
							simState: "not inserted",
							wcdmaEcio: null,
							wcdmaRscp: null
						},
						controlPlaneConnected: true,
						cpeName: "RUT950",
						dataPlaneConnected: false,
						deviceClock: 1685360730,
						emittedEssids: [
							{
								associatedStations: [],
								essid: "RUT950_9AF0"
							}
						],
						freeFlash: 1780,
						hostName: "Teltonika-RUT950.com",
						lastDataParsed: 1685360781605,
						lastUpdateReceived: 1685360781605,
						maxFreeFlash: 2496,
						offlineSince: 0,
						onlineSince: 1685337887,
						pidofFaultMonitor: 3472,
						pidofZitiTunneler: 0,
						rpcFailed: false,
						temperature: 41,
						totalRAM: 125984,
						upSince: 1685337754,
						uplinks: [
							{
								uciName: "wan4",
								name: "eth0.1",
								macAddress: "00:1e:42:53:9a:ee",
								up: true,
								linkUp: false,
								ipv4configurationMethod: "DHCP",
								rx: {
									bytes: 0,
									dropped: 0,
									errors: 0,
									packets: 0,
									mcast: 0,
									overrun: 0
								},
								tx: {
									bytes: 0,
									dropped: 0,
									errors: 0,
									packets: 0,
									carrier: 0,
									collisions: 0
								},
								outboundSourceIPv4address: null,
								uciDisabled: false
							},
							{
								uciName: "wan2",
								name: "wwan0",
								macAddress: "72:fb:a6:7e:4d:25",
								up: true,
								linkUp: true,
								ipv4configurationMethod: "None",
								rx: {
									bytes: 0,
									dropped: 0,
									errors: 0,
									packets: 0,
									mcast: 0,
									overrun: 0
								},
								tx: {
									bytes: 0,
									dropped: 0,
									errors: 4020,
									packets: 0,
									carrier: 0,
									collisions: 0
								},
								outboundSourceIPv4address: null,
								uciDisabled: false
							},
							{
								uciName: "wan",
								name: "eth1",
								macAddress: "00:1e:42:53:9a:ef",
								up: true,
								linkUp: true,
								ipv4configurationMethod: "DHCP",
								rx: {
									bytes: 8100502,
									dropped: 91828,
									errors: 0,
									packets: 113315,
									mcast: 0,
									overrun: 0
								},
								tx: {
									bytes: 5557890,
									dropped: 0,
									errors: 0,
									packets: 19113,
									carrier: 0,
									collisions: 0
								},
								outboundSourceIPv4address: "115.99.43.229",
								servingISP: "unknown",
								uciDisabled: false
							}
						],
						visibleAPs: [],
						wanOperatingMode: "Failover",
						wifiWANenabled: false
					},
					vettedBy: null,
					virtualAddress: "10.0.0.14",
					whitelistedServices: []
				}
			],
			id: 13,
			lanIPaddressPrefix: 24,
			lanIPaddressRange: "192.168.7.1",
			latitude: 9.41472,
			longitude: 78.52467,
			name: "473",
			requestedAt: "2023-05-18T14:24:56.467Z[UTC]",
			requestedBy: {
				email: "<TODO>",
				enabled: true,
				id: 1,
				username: "admin"
			},
			shutdown: false
		},
		{
			commentCount: 3,
			currentRouterUnits: [
				{
					currentDeployment: null,
					downtimes: null,
					id: 12,
					identifyingMac: "00:1e:42:3f:08:8f",
					manufacturerSerialNumber: "1116371747",
					model: {
						id: 4,
						name: "Teltonika-RUT950"
					},
					nfEndpointUUID: null,
					nfJwtExpiry: null,
					rmsId: 0,
					sshHostKeyMismatch: true,
					transientDetails: {
						availableRAM: 0,
						controlPlaneConnected: false,
						dataPlaneConnected: false,
						deviceClock: 0,
						emittedEssids: null,
						freeFlash: 0,
						lastDataParsed: 0,
						lastUpdateReceived: 0,
						maxFreeFlash: 0,
						offlineSince: 0,
						onlineSince: 0,
						pidofFaultMonitor: 0,
						pidofZitiTunneler: 0,
						rpcFailed: false,
						temperature: 0,
						totalRAM: 0,
						upSince: 0,
						visibleAPs: null
					},
					vettedBy: null,
					virtualAddress: "10.0.0.94",
					whitelistedServices: []
				}
			],
			id: 14,
			lanIPaddressPrefix: 0,
			latitude: 15.90761,
			longitude: 76.6031,
			name: "747",
			requestedAt: "2023-05-18T14:34:39.475Z[UTC]",
			requestedBy: {
				email: "<TODO>",
				enabled: true,
				id: 1,
				username: "admin"
			},
			shutdown: false
		},
		{
			commentCount: 5,
			currentRouterUnits: [
				{
					currentDeployment: null,
					downtimes: null,
					id: 8,
					identifyingMac: "00:1e:42:30:25:3d",
					manufacturerSerialNumber: "1106250752",
					model: {
						id: 4,
						name: "Teltonika-RUT950"
					},
					nfEndpointUUID: "8590c7d7-52ed-4b35-96f0-f40f4c09c575",
					nfJwtExpiry: "2023-05-26T01:44:03.56Z[UTC]",
					rmsId: 0,
					sshHostKeyMismatch: false,
					transientDetails: {
						availableRAM: 0,
						controlPlaneConnected: false,
						dataPlaneConnected: false,
						deviceClock: 0,
						emittedEssids: null,
						freeFlash: 0,
						lastDataParsed: 0,
						lastUpdateReceived: 0,
						maxFreeFlash: 0,
						offlineSince: 0,
						onlineSince: 0,
						pidofFaultMonitor: 0,
						pidofZitiTunneler: 0,
						rpcFailed: false,
						temperature: 0,
						totalRAM: 0,
						upSince: 0,
						visibleAPs: null
					},
					vettedBy: null,
					virtualAddress: "10.0.0.110",
					whitelistedServices: []
				}
			],
			id: 16,
			lanIPaddressPrefix: 24,
			lanIPaddressRange: "192.168.20.1",
			latitude: 12.91597,
			longitude: 77.63062,
			name: "Test_site_14",
			requestedAt: "2023-05-24T07:13:47.762Z[UTC]",
			requestedBy: {
				email: "<TODO>",
				enabled: true,
				id: 1,
				username: "admin"
			},
			shutdown: false
		},
		{
			commentCount: 3,
			currentRouterUnits: [
				{
					currentDeployment: null,
					downtimes: null,
					id: 2,
					identifyingMac: "00:16:3e:8a:e4:d5",
					manufacturerSerialNumber: "0123498765",
					model: {
						id: 2,
						name: "vCPE"
					},
					nfEndpointUUID: null,
					nfJwtExpiry: null,
					rmsId: 0,
					sshHostKeyMismatch: false,
					transientDetails: {
						availableRAM: 0,
						controlPlaneConnected: false,
						dataPlaneConnected: false,
						deviceClock: 0,
						emittedEssids: null,
						freeFlash: 0,
						lastDataParsed: 0,
						lastUpdateReceived: 0,
						maxFreeFlash: 0,
						offlineSince: 0,
						onlineSince: 0,
						pidofFaultMonitor: 0,
						pidofZitiTunneler: 0,
						rpcFailed: false,
						temperature: 0,
						totalRAM: 0,
						upSince: 0,
						visibleAPs: null
					},
					vettedBy: null,
					virtualAddress: "10.0.0.82",
					whitelistedServices: []
				}
			],
			id: 17,
			lanIPaddressPrefix: 0,
			latitude: 40.56211,
			longitude: 86.1121,
			name: "test_site_5",
			requestedAt: "2023-05-24T07:14:56.514Z[UTC]",
			requestedBy: {
				email: "<TODO>",
				enabled: true,
				id: 1,
				username: "admin"
			},
			shutdown: false
		},
		{
			commentCount: 4,
			currentRouterUnits: [
				{
					currentDeployment: null,
					downtimes: null,
					id: 5,
					identifyingMac: "00:1e:42:5c:5b:a5",
					manufacturerSerialNumber: "1115481168",
					model: {
						id: 4,
						name: "Teltonika-RUT950"
					},
					nfEndpointUUID: "ccfb7cf1-e7ab-4d88-a8bc-c7c049d6f254",
					nfJwtExpiry: null,
					rmsId: 0,
					sshHostKeyMismatch: false,
					transientDetails: {
						availableRAM: 95356,
						cellularStats: {
							cellID: 184597,
							cellularCarrierType: "TDD LTE",
							cellularNetwork:
								"+SGCELLINFOEX:LTE,TDD LTE,405,869,184597,339,721,21,19,40,100,38948,38948,-51,-85,-15,109,1,4,255,-,-,4,-,1,-,0,0,0",
							iccid: "89918690400613355647",
							imei: "868159052219164",
							imsi: "405869158781222",
							lteRsrp: -85,
							lteRsrq: -15,
							lteSinr: 1.7,
							neighbourCells: ["not supported", ""],
							networkLinkState: "registered (home)",
							operatorName: "JIO 4G Jio",
							servingCell:
								"^CELLINFO:LTE,TDD LTE,405,869,184597,339,721,21,19,40,100,38948,38948,-51,-85,-15,128,5,4,255,-,-,4,-,1,-,0,0,0",
							signalLevel: -51,
							simState: "inserted",
							wcdmaEcio: null,
							wcdmaRscp: null
						},
						controlPlaneConnected: true,
						cpeName: "RUT950",
						dataPlaneConnected: false,
						deviceClock: 1685360692,
						emittedEssids: [
							{
								associatedStations: [],
								essid: "RUT950_5BA7"
							}
						],
						freeFlash: 1784,
						hostName: "Teltonika-RUT950.com",
						lastDataParsed: 1685360738240,
						lastUpdateReceived: 1685360738240,
						maxFreeFlash: 2496,
						offlineSince: 0,
						onlineSince: 1685352079,
						pidofFaultMonitor: 15280,
						pidofZitiTunneler: 0,
						rpcFailed: false,
						temperature: 41,
						totalRAM: 125984,
						upSince: 1685097387,
						uplinks: [
							{
								uciName: "wan4",
								name: "eth0.1",
								macAddress: "00:1e:42:5c:5b:a5",
								up: true,
								linkUp: true,
								ipv4configurationMethod: "DHCP",
								rx: {
									bytes: 0,
									dropped: 0,
									errors: 0,
									packets: 0,
									mcast: 0,
									overrun: 0
								},
								tx: {
									bytes: 231294,
									dropped: 0,
									errors: 0,
									packets: 1107,
									carrier: 0,
									collisions: 0
								},
								outboundSourceIPv4address: null,
								uciDisabled: false
							},
							{
								uciName: "wan",
								name: "eth1",
								macAddress: "00:1e:42:5c:5b:a6",
								up: true,
								linkUp: true,
								ipv4configurationMethod: "DHCP",
								rx: {
									bytes: 104954772,
									dropped: 0,
									errors: 0,
									packets: 289415,
									mcast: 0,
									overrun: 0
								},
								tx: {
									bytes: 257815761,
									dropped: 0,
									errors: 0,
									packets: 353246,
									carrier: 0,
									collisions: 0
								},
								outboundSourceIPv4address: "121.242.115.253",
								servingISP: "unknown",
								uciDisabled: false
							},
							{
								uciName: "wan2",
								name: "wwan0",
								macAddress: "5a:27:53:65:d8:76",
								up: true,
								linkUp: true,
								ipv4configurationMethod: "None",
								rx: {
									bytes: 3063843,
									dropped: 0,
									errors: 0,
									packets: 63702,
									mcast: 0,
									overrun: 0
								},
								tx: {
									bytes: 3107757,
									dropped: 0,
									errors: 0,
									packets: 69586,
									carrier: 0,
									collisions: 0
								},
								outboundSourceIPv4address: "157.51.160.229",
								servingISP: "unknown",
								uciDisabled: false
							},
							{
								uciName: "wan3",
								name: "wlan0",
								macAddress: "00:1e:42:5c:5b:a7",
								up: true,
								linkUp: true,
								ipv4configurationMethod: "DHCP",
								rx: {
									bytes: 106297,
									dropped: 0,
									errors: 0,
									packets: 1484,
									mcast: 0,
									overrun: 0
								},
								tx: {
									bytes: 35165,
									dropped: 0,
									errors: 0,
									packets: 404,
									carrier: 0,
									collisions: 0
								},
								outboundSourceIPv4address: "203.199.244.70",
								servingISP: "unknown",
								uciDisabled: false,
								wifi: {
									associatedBSSID: "00:1E:42:5D:FC:0A",
									associatedESSID: "RUT950_FC0A",
									bitrate: "28.9 MBit/s",
									channel: 6,
									encryption: "WPA2 PSK (TKIP, CCMP)",
									noisedBm: -90,
									phy: "phy0",
									signaldBm: 0,
									supports80211ac: false,
									supports80211ax: false,
									supports80211b: true,
									supports80211g: true,
									supports80211n: true,
									supportsVirtualAPs: true,
									txPowerdBm: 20
								}
							}
						],
						visibleAPs: [],
						wanOperatingMode: "Failover",
						wifiWANenabled: true
					},
					vettedBy: null,
					virtualAddress: "10.0.0.50",
					whitelistedServices: []
				}
			],
			id: 18,
			lanIPaddressPrefix: 24,
			lanIPaddressRange: "192.168.17.1",
			latitude: 9.53818,
			longitude: 78.6014,
			name: "Lab_CPE",
			requestedAt: "2023-05-26T06:18:37.437Z[UTC]",
			requestedBy: {
				email: "<TODO>",
				enabled: true,
				id: 1,
				username: "admin"
			},
			shutdown: false
		}
	];
	constructor(private agHttpService: AgHttpService, private apiUrlService: ApiUrlService) {
		this.apiUrls = this.apiUrlService.getAPIUrls();
	}

	getSite(id: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site/${id}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getCountries(): Observable<any> {
		const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/customer/list/customer/auxiliary-data`;
		return this.agHttpService.makeGetRequest(url).pipe(map((res: any) => res));
	}

	getAllSites(page = 0, size = 10, searchTerm?: string, sort?: string): Observable<any> {
		let queryString = `page=${page}&size=${size}`;
		if (sort) {
			queryString += `&${sort}`;
		} else {
			queryString += `&sort=id,desc`;
		}
		if (searchTerm) {
			queryString = queryString + `&term=${searchTerm}`;
		}
		let customerId = this.selectedCustomerId;
		queryString = this.selectedCustomerId ? queryString + `&customerId=${customerId}` : queryString;
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site?${queryString}`;
		return this.agHttpService.makeGetRequest(url);
	}

	uploadFirmwareImage(formData: FormData) {
		const url = `${this.apiUrls.REMOTE_UPGRADE_URL}/firmware/upload`;
		return this.agHttpService.makePostRequest(url, formData);
	}

	mapFirmwareToCustomer(payload: any) {
		const url = `${this.apiUrls.REMOTE_UPGRADE_URL}/firmware/map/create`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	deleteFirmwareMapping(mappingId: any) {
		const url = `${this.apiUrls.REMOTE_UPGRADE_URL}/firmware/map/delete/${mappingId}`;
		return this.agHttpService.makeDeleteRequest(url);
	}

	getFirmwareList(customerId?: any, model?: any) {
		if (customerId && model) {
			const url = `${this.apiUrls.REMOTE_UPGRADE_URL}/firmware/map/list/model/${model}/customer/${customerId}`;
			return this.agHttpService.makeGetRequest(url);
		} else if (customerId) {
			const url = `${this.apiUrls.REMOTE_UPGRADE_URL}/firmware/map/list/customer/${customerId}`;
			return this.agHttpService.makeGetRequest(url);
		} else {
			const url = `${this.apiUrls.REMOTE_UPGRADE_URL}/firmware/map/all`;
			return this.agHttpService.makeGetRequest(url);
		}
	}

	getFirmwareListBy(customerId?: any, model?: any) {
		if (customerId && model) {
			const url = `${this.apiUrls.REMOTE_UPGRADE_URL}/firmware/map/list/by/model/${model}/customer/${customerId}`;
			return this.agHttpService.makeGetRequest(url);
		} else return this.getFirmwareList(customerId, model);
	}

	getFirmwareDetailsById(firmwareId: number) {
		const url = `${this.apiUrls.REMOTE_UPGRADE_URL}/firmware/get/${firmwareId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	downloadFirmware(url: string) {
		return this.agHttpService.makeDownloadFileRequestWithoutHeaders(url);
	}

	getAllFirmwareList() {
		const url = `${this.apiUrls.REMOTE_UPGRADE_URL}/firmware/list`;
		return this.agHttpService.makeGetRequest(url);
	}

	getFirmwareListByModel(model: any) {
		const url = `${this.apiUrls.REMOTE_UPGRADE_URL}/firmware/list/${model}`;
		return this.agHttpService.makeGetRequest(url);
	}

	deleteFirmwareById(firmwareId: any) {
		const url = `${this.apiUrls.REMOTE_UPGRADE_URL}/firmware/delete/${firmwareId}`;
		return this.agHttpService.makeDeleteRequest(url);
	}

	upgradeDevicesFirmware(payload: any) {
		const url = `${this.apiUrls.REMOTE_UPGRADE_URL}/firmware/upgrade`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getLatestVersions() {
		const url = `${this.apiUrls.REMOTE_UPGRADE_URL}/firmware/latest-versions`;
		return this.agHttpService.makeGetRequest(url);
	}

	getLatestVersionsByCustomer() {
		const url = `${this.apiUrls.REMOTE_UPGRADE_URL}/firmware/latest-versions/customer/${this.selectedCustomerId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getUpgradeStatus() {
		const url = `${environment.spaedBasePath}/device/upgrade`;
		return this.agHttpService.makeGetRequest(url);
	}

	getSitesOfAllCustomers(): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site/list/all/sites`;
		return this.agHttpService.makeGetRequest(url);
	}

	getTypes(): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site/types`;
		return this.agHttpService.makeGetRequest(url);
	}

	getCustomers(): Observable<any> {
		const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/customer/customers`;
		return this.agHttpService.makeGetRequest(url);
	}

	addSite(site: any): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site`;
		return this.agHttpService.makePostRequest(url, site);
		//return of({})
	}
	updateSite(site: any): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site`;
		return this.agHttpService.makePostRequest(url, site);
	}
	deleteSite(siteId: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site/${siteId}`;
		return this.agHttpService.makeDeleteRequest(url);
	}

	validateSiteName(customerName: string, siteName: string): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site/name-exists/${customerName}/${siteName}`;
		return this.agHttpService.makeGetRequest(url);
	}
	getUnUsedDevices(): Observable<any> {
		let unusedDevice = [
			{
				currentDeployment: null,
				downtimes: null,
				id: 9,
				identifyingMac: "00:1e:42:51:c5:4e",
				manufacturerSerialNumber: "1115483249",
				model: {
					id: 4,
					name: "Teltonika-RUT950"
				},
				nfEndpointUUID: "28a47a51-e3b7-4349-a3e3-5bfaccd3050d",
				nfJwtExpiry: null,
				rmsId: 0,
				sshHostKeyMismatch: false,
				transientDetails: {
					availableRAM: 0,
					controlPlaneConnected: false,
					dataPlaneConnected: false,
					deviceClock: 0,
					emittedEssids: null,
					freeFlash: 0,
					lastDataParsed: 0,
					lastUpdateReceived: 0,
					maxFreeFlash: 0,
					offlineSince: 0,
					onlineSince: 0,
					pidofFaultMonitor: 0,
					pidofZitiTunneler: 0,
					rpcFailed: false,
					temperature: 0,
					totalRAM: 0,
					upSince: 0,
					visibleAPs: null
				},
				vettedBy: null,
				virtualAddress: "10.0.0.18",
				whitelistedServices: []
			},
			{
				currentDeployment: null,
				downtimes: null,
				id: 11,
				identifyingMac: "00:1e:42:58:d3:c8",
				manufacturerSerialNumber: "1115485512",
				model: {
					id: 4,
					name: "Teltonika-RUT950"
				},
				nfEndpointUUID: null,
				nfJwtExpiry: null,
				rmsId: 0,
				sshHostKeyMismatch: true,
				transientDetails: {
					availableRAM: 94988,
					cellularStats: {
						cellID: -1,
						cellularCarrierType: "HSDPA and H",
						cellularNetwork:
							"+SGCELLINFOEX:WCDMA,404,64,13350180,164,203,46372,2331,1,10782,9832,-82,18,255,84",
						iccid: "N/A",
						imei: "868159052238743",
						imsi: "N/A",
						lteRsrp: null,
						lteRsrq: null,
						lteSinr: null,
						neighbourCells: ["not supported", ""],
						networkLinkState: "N/A",
						operatorName: "N/A",
						servingCell: "^CELLINFO:WCDMA,404,64,13350180,164,203,46372,2331,1,10782,9832,-82,28,255,84",
						signalLevel: null,
						simState: "not inserted",
						wcdmaEcio: null,
						wcdmaRscp: null
					},
					controlPlaneConnected: true,
					cpeName: "RUT950",
					dataPlaneConnected: false,
					deviceClock: 1685519727,
					emittedEssids: [
						{
							associatedStations: [],
							essid: "RUT950_D3CA"
						}
					],
					freeFlash: 2024,
					hostName: "Teltonika-RUT950.com",
					lastDataParsed: 1685519784005,
					lastUpdateReceived: 1685519784005,
					maxFreeFlash: 2496,
					offlineSince: 0,
					onlineSince: 1685440057,
					pidofFaultMonitor: 3291,
					pidofZitiTunneler: 0,
					rpcFailed: false,
					temperature: 41,
					totalRAM: 125984,
					upSince: 1685357603,
					uplinks: [
						{
							uciName: "wan",
							name: "eth1",
							macAddress: "00:1e:42:58:d3:c9",
							up: true,
							linkUp: true,
							ipv4configurationMethod: "Static",
							rx: {
								bytes: 37971931,
								dropped: 5848,
								errors: 0,
								packets: 321780,
								mcast: 0,
								overrun: 0
							},
							tx: {
								bytes: 61650514,
								dropped: 0,
								errors: 0,
								packets: 227604,
								carrier: 0,
								collisions: 0
							},
							outboundSourceIPv4address: "203.199.243.193",
							servingISP: "unknown",
							uciDisabled: false
						},
						{
							uciName: "wan4",
							name: "eth0.1",
							macAddress: "00:1e:42:58:d3:c8",
							up: true,
							linkUp: false,
							ipv4configurationMethod: "DHCP",
							rx: {
								bytes: 0,
								dropped: 0,
								errors: 0,
								packets: 0,
								mcast: 0,
								overrun: 0
							},
							tx: {
								bytes: 0,
								dropped: 0,
								errors: 0,
								packets: 0,
								carrier: 0,
								collisions: 0
							},
							outboundSourceIPv4address: null,
							uciDisabled: false
						},
						{
							uciName: "wan2",
							name: "wwan0",
							macAddress: "1e:ae:26:67:eb:56",
							up: true,
							linkUp: true,
							ipv4configurationMethod: "None",
							rx: {
								bytes: 0,
								dropped: 0,
								errors: 0,
								packets: 0,
								mcast: 0,
								overrun: 0
							},
							tx: {
								bytes: 0,
								dropped: 0,
								errors: 28620,
								packets: 0,
								carrier: 0,
								collisions: 0
							},
							outboundSourceIPv4address: null,
							uciDisabled: false
						}
					],
					visibleAPs: [],
					wanOperatingMode: "Failover",
					wifiWANenabled: false
				},
				vettedBy: null,
				virtualAddress: "10.0.0.114",
				whitelistedServices: []
			},
			{
				currentDeployment: null,
				downtimes: null,
				id: 6,
				identifyingMac: "00:16:3e:24:62:62",
				manufacturerSerialNumber: "11223334444",
				model: {
					id: 2,
					name: "vCPE"
				},
				nfEndpointUUID: "313f4cea-c9eb-4cb9-bb8c-1c91cb4d8c38",
				nfJwtExpiry: "2023-05-20T08:31:50.23Z[UTC]",
				rmsId: 0,
				sshHostKeyMismatch: false,
				transientDetails: {
					availableRAM: 0,
					controlPlaneConnected: false,
					dataPlaneConnected: false,
					deviceClock: 0,
					emittedEssids: null,
					freeFlash: 0,
					lastDataParsed: 0,
					lastUpdateReceived: 0,
					maxFreeFlash: 0,
					offlineSince: 0,
					onlineSince: 0,
					pidofFaultMonitor: 0,
					pidofZitiTunneler: 0,
					rpcFailed: false,
					temperature: 0,
					totalRAM: 0,
					upSince: 0,
					visibleAPs: null
				},
				vettedBy: null,
				virtualAddress: "10.0.0.102",
				whitelistedServices: []
			},
			{
				currentDeployment: null,
				downtimes: null,
				id: 1,
				identifyingMac: "00:1e:42:5d:fc:08",
				manufacturerSerialNumber: "1117035688",
				model: {
					id: 4,
					name: "Teltonika-RUT950"
				},
				nfEndpointUUID: "d0a652f3-15d0-4e24-b7c7-4a3e07c1b1b0",
				nfJwtExpiry: null,
				rmsId: 0,
				sshHostKeyMismatch: false,
				transientDetails: {
					availableRAM: 92708,
					cellularStats: {
						cellID: -1,
						cellularCarrierType: "HSDPA and H",
						cellularNetwork:
							"+SGCELLINFOEX:WCDMA,404,64,13350180,164,203,46372,2331,65501,10782,9832,-78,14,255,78",
						iccid: "N/A",
						imei: "868159050732713",
						imsi: "N/A",
						lteRsrp: null,
						lteRsrq: null,
						lteSinr: null,
						neighbourCells: ["not supported", ""],
						networkLinkState: "N/A",
						operatorName: "N/A",
						servingCell:
							"^CELLINFO:WCDMA,404,64,13350180,164,203,46372,2331,65501,10782,9832,-78,21,255,78",
						signalLevel: null,
						simState: "not inserted",
						wcdmaEcio: null,
						wcdmaRscp: null
					},
					controlPlaneConnected: true,
					cpeName: "RUT950",
					dataPlaneConnected: false,
					deviceClock: 1685519731,
					emittedEssids: [
						{
							associatedStations: [
								{
									inactive: 1590,
									mac: "00:1E:42:5C:5B:A7",
									noise: -91,
									rx: {
										channelBandwidthMHz: 20,
										mcs: 9,
										rate: 26000000,
										shortGuardInterval: false
									},
									signal: 13,
									tx: {
										channelBandwidthMHz: 20,
										mcs: 2,
										rate: 21700000,
										shortGuardInterval: true
									}
								}
							],
							essid: "RUT950_FC0A"
						}
					],
					freeFlash: 2408,
					hostName: "Teltonika-RUT950.com",
					lastDataParsed: 1685519780799,
					lastUpdateReceived: 1685519780799,
					maxFreeFlash: 3008,
					offlineSince: 0,
					onlineSince: 1685440057,
					pidofFaultMonitor: 3190,
					pidofZitiTunneler: 4796,
					rpcFailed: false,
					temperature: 0,
					totalRAM: 125984,
					upSince: 1682646299,
					uplinks: [
						{
							uciName: "wan",
							name: "eth1",
							macAddress: "00:1e:42:5d:fc:09",
							up: true,
							linkUp: true,
							ipv4configurationMethod: "DHCP",
							rx: {
								bytes: 3220238572,
								dropped: 0,
								errors: 0,
								packets: 8347419,
								mcast: 0,
								overrun: 0
							},
							tx: {
								bytes: 2435596657,
								dropped: 0,
								errors: 0,
								packets: 7413070,
								carrier: 0,
								collisions: 0
							},
							outboundSourceIPv4address: "203.199.244.70",
							servingISP: "unknown",
							uciDisabled: false
						},
						{
							uciName: "wan2",
							name: "wwan0",
							macAddress: "26:c9:ef:3c:2a:43",
							up: true,
							linkUp: true,
							ipv4configurationMethod: "None",
							rx: {
								bytes: 0,
								dropped: 0,
								errors: 0,
								packets: 0,
								mcast: 0,
								overrun: 0
							},
							tx: {
								bytes: 0,
								dropped: 0,
								errors: 508800,
								packets: 0,
								carrier: 0,
								collisions: 0
							},
							outboundSourceIPv4address: null,
							uciDisabled: false
						},
						{
							uciName: "wan4",
							name: "eth0.1",
							macAddress: "00:1e:42:5d:fc:08",
							up: true,
							linkUp: true,
							ipv4configurationMethod: "DHCP",
							rx: {
								bytes: 131647154,
								dropped: 0,
								errors: 0,
								packets: 2264183,
								mcast: 1674189,
								overrun: 0
							},
							tx: {
								bytes: 34422558,
								dropped: 0,
								errors: 0,
								packets: 598609,
								carrier: 0,
								collisions: 0
							},
							outboundSourceIPv4address: "203.199.244.70",
							servingISP: "unknown",
							uciDisabled: false
						}
					],
					visibleAPs: [],
					wanOperatingMode: "Failover",
					wifiWANenabled: false
				},
				vettedBy: null,
				virtualAddress: "10.0.0.10",
				whitelistedServices: []
			},
			{
				currentDeployment: null,
				downtimes: null,
				id: 17,
				identifyingMac: "00:16:3e:66:79:4f",
				manufacturerSerialNumber: "1000020000",
				model: {
					id: 2,
					name: "vCPE"
				},
				nfEndpointUUID: null,
				nfJwtExpiry: null,
				rmsId: 0,
				sshHostKeyMismatch: false,
				transientDetails: {
					availableRAM: 0,
					controlPlaneConnected: true,
					dataPlaneConnected: false,
					deviceClock: 0,
					emittedEssids: null,
					freeFlash: 0,
					lastDataParsed: 0,
					lastUpdateReceived: 0,
					maxFreeFlash: 0,
					offlineSince: 0,
					onlineSince: 1685440057,
					pidofFaultMonitor: 0,
					pidofZitiTunneler: 0,
					rpcFailed: false,
					temperature: 0,
					totalRAM: 0,
					upSince: 0,
					visibleAPs: null
				},
				vettedBy: null,
				virtualAddress: "10.0.0.130",
				whitelistedServices: []
			},
			{
				currentDeployment: null,
				downtimes: null,
				id: 3,
				identifyingMac: "08:00:27:43:2e:45",
				manufacturerSerialNumber: "1223337777",
				model: {
					id: 2,
					name: "vCPE"
				},
				nfEndpointUUID: "d6a780ec-96cc-430a-92bd-ebde6ec8af50",
				nfJwtExpiry: "2023-05-13T02:18:42.65Z[UTC]",
				rmsId: 0,
				sshHostKeyMismatch: false,
				transientDetails: {
					availableRAM: 0,
					controlPlaneConnected: true,
					dataPlaneConnected: false,
					deviceClock: 0,
					emittedEssids: null,
					freeFlash: 0,
					lastDataParsed: 0,
					lastUpdateReceived: 0,
					maxFreeFlash: 0,
					offlineSince: 0,
					onlineSince: 1685440057,
					pidofFaultMonitor: 0,
					pidofZitiTunneler: 0,
					rpcFailed: false,
					temperature: 0,
					totalRAM: 0,
					upSince: 0,
					visibleAPs: null
				},
				vettedBy: null,
				virtualAddress: "10.0.0.34",
				whitelistedServices: []
			}
		];
		const url = `${environment.spaedBasePath}/devices/unused`;
		return this.agHttpService.makeGetRequest(url);
		//return of(unusedDevice);
	}
	getDownloadConfigReport(payload: any): Observable<any> {
		const url = `${environment.spaedBasePath}/device/config/download`;
		const headers = "application/json";
		return this.agHttpService.makeDownloadFilePostRequestWithProgress(url, headers, payload);
	}

	public getCommonStates(countryCode: number): Observable<any> {
		const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/common/states/auxiliary-data/${countryCode}`;
		return this.agHttpService.makeGetRequest(url).pipe(map((res: any) => res.data.states));
	}

	public getCommonCities(state: number): Observable<any> {
		const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/common/cities/list/${state}`;
		return this.agHttpService.makeGetRequest(url).pipe(map((res: any) => res));
	}

	getAllMtcDevice(): Observable<any> {
		let customerId = this.selectedCustomerId;
		const payload = {
			customerId: customerId
		};
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/devices/customer`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getAllUpgradeSites(): Observable<any> {
		let customerId = this.selectedCustomerId;
		let url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site/upgrade/sites?&customerId=${customerId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getSiteType() {
		let siteType = [
			{ id: 1, name: "Data Center", code: "DC", type: "LOCATION" },
			{ id: 2, name: "Branch Office", code: "BO", type: "LOCATION" }
		];
		return siteType;
	}

	saveorupdateTunnelConfig(payload: any): Observable<any> {
		let customerId = this.selectedCustomerId;
		payload.customerId = customerId;
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site/tunnelconfiguration/save`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getTunnelConfigById(siteId: any, customerId: any): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site/tunnelconfigdetail?customerId=${customerId}&siteId=${siteId}`;
		return this.agHttpService.makeGetRequest(url);
	}
}
