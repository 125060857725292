import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

@Injectable({
	providedIn: "root"
})
export class WebsocketService {
	public webSocketSubject$!: WebSocketSubject<any>;
	public socketDisconnectedSubject$ = new Subject<any>();
	public socketCustomCallSubject$ = new Subject<any>();
	constructor() {}

	public sendSocketDisconnectedMessage() {
		this.socketDisconnectedSubject$.next({});
	}
	public onSocketDisconnectedMessage(): Observable<any> {
		return this.socketDisconnectedSubject$.asObservable();
	}

	public sendCustomCallMessage(data: string) {
		this.socketCustomCallSubject$.next(data);
	}
	public onSocketCustomCallMessage(): Observable<any> {
		return this.socketCustomCallSubject$.asObservable();
	}

	public connect(url: string) {
		this.webSocketSubject$ = webSocket({
			url,
			deserializer: (events) => {
				return events.data;
			},
			serializer: (value: string) => value,
			openObserver: {
				next: (e) => {}
			},
			closeObserver: {
				next: (event) => {
					if (!event.wasClean) {
						this.sendSocketDisconnectedMessage();
					}
				}
			}
		});
	}
	public getMessages() {
		return this.webSocketSubject$.asObservable();
	}
	public sendMessages(socketMessage: any) {
		return this.webSocketSubject$.next(socketMessage);
	}
	public closeConnection() {
		this.webSocketSubject$.complete();
	}
}
