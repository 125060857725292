<div [hidden]="isDataLoading">
	<div #dataTable class="table-responsive">
		<table
			id="custom-table"
			matSort
			(matSortChange)="onSort($event)"
			class="table custom-table-stripped"
			aria-label="Data table"
			mat-table
			[dataSource]="dataSource">
			<ng-container *ngFor="let column of columns; let index = index; trackBy: trackByFn">
				<ng-container *ngIf="!column?.isCustomTemplate; else customColumnTemplate">
					<ng-container *ngIf="column['field'] !== 'action'" [matColumnDef]="column['field']">
						<th
							[ngStyle]="{ 'width.%': 100 / columns.length }"
							mat-header-cell
							*matHeaderCellDef
							mat-sort-header
							[disabled]="column['sort'] ? false : true">
							{{ column["label"] }}
							<span class="ms-2 sort-icon" *ngIf="column['sort']">
								<i
									[ngClass]="
										sortFields.field === column['field'] && sortFields.direction
											? sortFields.direction
											: 'fa-sort'
									"
									class="fa"
									aria-hidden="true"></i>
							</span>
						</th>

						<td
							class="align-middle"
							[ngClass]="{ 'table-link': column.isLink }"
							mat-cell
							*matCellDef="let element; let rowIndex = index">
							<span
								(click)="onLinkClicked(column, element)"
								*ngIf="column['field'] !== 'fsKey' && column['field'] !== 'wan'">
								<span
									*ngIf="
										column.field !== 'devices' &&
										column.fieldId !== 'devicesStatus' &&
										!column['isToggleBox'] &&
										column['field'].toLowerCase() !== 'utilization' &&
										column['field'] !== 'noOfCustomers' &&
										column['field'] !== 'assetStatus'
									">
									<span *ngIf="column['field'].toLowerCase() === 'online'">
										<i
											[ngClass]="{ 'online-green': element.online, offline: !element.online }"
											class="fa fa-circle"
											aria-hidden="true"></i>
									</span>
									<span *ngIf="column['field'].toLowerCase() === 'online'">
										<span *ngIf="element.decommissioned">Inactive</span>
										<span *ngIf="!element.decommissioned">
											{{
												column["field"].toLowerCase() === "online" &&
													(element[column["field"]] ? "Online" : "Offline")
											}}
										</span>
									</span>
									<span class="break-word" *ngIf="column['field'].toLowerCase() !== 'online'">
										{{ element[column["field"]] || "-" }}
									</span>
								</span>

								<span
									*ngIf="
										column.field !== 'devices' &&
										column.fieldId !== 'devicesStatus' &&
										column.fieldId !== 'online'
									">
									<span
										*ngIf="
											column['field'].toLowerCase() === 'utilization' && element[column['field']]
										">
										<i class="fa fa-download" aria-hidden="true"></i>
										{{
											element[column["field"]].download
												? element[column["field"]].download + " | "
												: "-
										| "
										}}
										<i class="fa fa-upload" aria-hidden="true"></i>
										{{ element[column["field"]].upload ? element[column["field"]].upload : "-" }}
									</span>
									<span
										*ngIf="
											column['field'].toLowerCase() === 'utilization' && !element[column['field']]
										">
										{{ "-" }}
									</span>

									<span *ngIf="column['field'] === 'noOfCustomers'">
										{{ element[column["field"]] ? element[column["field"]] + " customers" : "-" }}
									</span>
									<span *ngIf="column['field'] === 'assetStatus'">
										<div class="">
											<span *ngIf="!isTrackingActionVisible(element[column['field']].action)">
												{{ element[column["field"]].name }}
											</span>
											<span
												class="ms-1"
												*ngIf="isTrackingActionVisible(element[column['field']].action)">
												<span *ngIf="element[column['field']].name === 'Allocated'">
													<img
														src="./assets/icons/device-status/Configure.svg"
														(click)="onworkFlowClicked(element)"
														[matTooltip]="
															'Change status to ' + element[column['field']].action
														"
														class="device-status-icon cursor-pointer" />
												</span>
												<span
													*ngIf="
														element[column['field']].name === 'Configuration In Progress'
													">
													<img
														src="./assets/icons/device-status/Shipping.svg"
														(click)="onworkFlowClicked(element)"
														[matTooltip]="
															'Change status to ' + element[column['field']].action
														"
														class="device-status-icon cursor-pointer" />
												</span>
												<span *ngIf="element[column['field']].name === 'Shipped'">
													<img
														src="./assets/icons/device-status/Received.svg"
														(click)="onworkFlowClicked(element)"
														[matTooltip]="
															'Change status to ' + element[column['field']].action
														"
														class="device-status-icon cursor-pointer" />
												</span>
												<span *ngIf="element[column['field']].name === 'Delivered'">
													<img
														src="./assets/icons/device-status/Approve.svg"
														(click)="onworkFlowClicked(element)"
														[matTooltip]="
															'Change status to ' + element[column['field']].action
														"
														class="device-status-icon cursor-pointer" />
												</span>
												<span *ngIf="element[column['field']].name === 'Activated'">
													<img
														src="./assets/icons/device-status/Approved.svg"
														matTooltip="Activated"
														class="device-status-approved-icon" />
												</span>
											</span>
										</div>
									</span>
								</span>

								<ng-container *ngIf="column['isToggleBox']">
									<div class="form-check form-switch">
										<input
											class="form-check-input"
											#toggleCheckBox
											type="checkbox"
											role="switch"
											id="flexSwitchCheckChecked"
											(change)="onToggleClicked(element, $event)"
											[attr.checked]="element[column['field']] ? true : null" />
										<label
											class="form-check-label fs-13 gotham-book-regular"
											for="flexSwitchCheckChecked">
											{{ element[column["field"]] ? "Active" : "Inactive" }}
										</label>
									</div>
								</ng-container>
								<ng-container>
									<ng-container *ngIf="column.field === 'devices'">
										<div *ngFor="let device of element[column.field]; trackBy: trackByFn">
											<span class="break-word">
												<!-- <span
													(click)="onClickDeviceConfigure(element, device)"
													class="table-link"
													matTooltip="Click here to configure WAN and LAN"
												>
													{{ device?.ciDisplayName }}
												</span> -->
												{{ device?.ciDisplayName }}
											</span>
										</div>
										<div *ngIf="!element[column.field]">
											<span class="sml-1r">{{ "-" }}</span>
										</div>
									</ng-container>
								</ng-container>
							</span>
						</td>
					</ng-container>

					<ng-container *ngIf="column['field'] === 'action'" [matColumnDef]="column['field']">
						<th [ngStyle]="{ 'width.%': 2 }" mat-header-cell *matHeaderCellDef>{{ column["label"] }}</th>
						<td class="td-actions" mat-cell *matCellDef="let element; let i = index">
							<div class="d-flex justif-content-center align-items-center">
								<div class="button-row actions">
									<div
										class="float-button-container action-container z-100 action-{{
											i
										}} action-container-width">
										<div class="button-container border-bottom" *ngIf="showRoleAssign">
											<button
												(click)="OnAssignClicked(element)"
												type="button"
												class="text-start custom-button">
												<div class="icon-container">
													<i class="fa fa-pencil" aria-hidden="true"></i>
													<span class="gotham-book-regular icon-lable">Assign</span>
												</div>
											</button>
										</div>
										<div *ngIf="!flags?.isHideViewAction" class="button-container border-bottom">
											<button
												(click)="onDetailsClicked(element)"
												type="button"
												class="text-start custom-button">
												<div class="icon-container">
													<i class="fa fa-eye" aria-hidden="true"></i>
													<span class="gotham-book-regular icon-lable">View</span>
												</div>
											</button>
										</div>
										<ng-container
											*ngIf="(flags?.isEnableCreate && !isExternalUser) || flags?.isEnableEdit">
											<div class="button-container" >
												<button
													(click)="onEditClicked(element)"
													type="button"
													class="text-start custom-button">
													<div class="icon-container">
														<i class="fa fa-pencil" aria-hidden="true"></i>
														<span class="gotham-book-regular icon-lable">Edit</span>
													</div>
												</button>
											</div>
											<div
												class="button-container"
												*ngIf="(flags?.isEnableDelete && !element?.decommissioned) || !flags?.isDisableDelete">
												<button
													(click)="onDelete(element)"
													type="button"
													class="text-start custom-button">
													<div class="icon-container">
														<i class="fa fa-trash" aria-hidden="true"></i>
														<span class="gotham-book-regular icon-lable">
															{{ flags?.deleteLabel ? flags?.deleteLabel : "Delete" }}
														</span>
													</div>
												</button>
											</div>
											<div class="button-container" *ngIf="flags?.isEnableRegistrationKey">
												<button
													(click)="onClickRegistrationKey(element, 'key')"
													type="button"
													class="text-start custom-button">
													<div class="icon-container">
														<span>
															<i class="fa fa-key" aria-hidden="true"></i>
															<span class="gotham-book-regular icon-lable">
																Registration key
															</span>
														</span>
													</div>
												</button>
											</div>
											<div class="button-container" *ngIf="flags?.isEnableDeactivate">
												<button
													(click)="onChangeStatus(element)"
													type="button"
													class="text-start custom-button">
													<div class="icon-container">
														<span *ngIf="element?.active; else deactivateTemplate">
															<img
																src="./assets/icons/deactivate-customer.svg"
																class="activate-deactivate-icon cursor-pointer" />
														</span>
														<ng-template #deactivateTemplate>
															<i class="fa fa-check-circle-o" aria-hidden="true"></i>
														</ng-template>
														<span
															class="gotham-book-regular"
															*ngIf="element?.active; else activate">
															Deactivate
														</span>
														<ng-template class="gotham-book-regular icon-lable" #activate>
															<span class="icon-lable">Activate</span>
														</ng-template>
													</div>
												</button>
											</div>
										</ng-container>
										<div class="button-container" *ngIf="flags?.isEnableConfigurations">
											<button
												(click)="onConfigurations(element)"
												type="button"
												class="text-start custom-button">
												<div class="icon-container">
													<i
														class="fa fa-{{
															flags?.ConfigurationsIcon
																? flags?.ConfigurationsIcon
																: 'gear'
														}}"
														aria-hidden="true"></i>
													<span class="gotham-book-regular icon-lable">
														{{
															flags?.ConfigurationsLabel
																? flags?.ConfigurationsLabel
																: "Configurations"
														}}
													</span>
												</div>
											</button>
										</div>
										<div class="button-container" *ngIf="flags?.isEnableConfigurationsTwo">
											<button
												(click)="onConfigurationsTwo(element)"
												type="button"
												class="text-start custom-button">
												<div class="icon-container">
													<i
														class="fa fa-{{
															flags?.ConfigurationsMenuTwoFontIcon
																? flags?.ConfigurationsMenuTwoFontIcon
																: 'gear'
														}}"
														aria-hidden="true"></i>
													<span class="gotham-book-regular icon-lable">
														{{
															flags?.ConfigurationsMenuTwoLabel
																? flags?.ConfigurationsMenuTwoLabel
																: "Configurations"
														}}
													</span>
												</div>
											</button>
										</div>

										<div class="button-container" *ngIf="flags?.isEnableConfigurationsThree">
											<button
												(click)="onConfigurationsThree(element)"
												type="button"
												class="text-start custom-button">
												<div class="icon-container">
													<i
														class="fa fa-{{
															flags?.ConfigurationsMenuThreeFontIcon
																? flags?.ConfigurationsMenuThreeFontIcon
																: 'gear'
														}}"
														aria-hidden="true"></i>
													<span class="gotham-book-regular icon-lable">
														{{
															flags?.ConfigurationsMenuThreeLabel
																? flags?.ConfigurationsMenuThreeLabel
																: "Configurations"
														}}
													</span>
												</div>
											</button>
										</div>
										<div class="button-container" *ngIf="element?.online && flags?.isEnableSSH">
											<button
												(click)="onClickLaunchWebConsole(element)"
												type="button"
												class="text-start custom-button">
												<div class="icon-container">
													<span>
														<img
															src="./assets/icons/web-console-icon.svg"
															class="console-icon cursor-pointer" />
														<span class="gotham-book-regular icon-lable">
															Launch Web Console
														</span>
													</span>
												</div>
											</button>
										</div>
										<div class="button-container" *appHasPrivilege="role.canViewServiceEdgeConfig">
											<button
												(click)="onConfigurations(element)"
												type="button"
												class="text-start custom-button">
												<div class="icon-container">
													<span>
														<i class="fa fa-gear" aria-hidden="true"></i>
														<span class="gotham-book-regular icon-lable">
															View Configuration
														</span>
													</span>
												</div>
											</button>
										</div>
										<div
											class="button-container"
											*ngIf="
												flags?.isEnableTunnelConfiguration &&
												element?.agentService &&
												element?.typeId == 1
											">
											<button
												(click)="onConfigureTunnel(element)"
												type="button"
												class="text-start custom-button">
												<div class="icon-container">
													<i class="fa fa-gear" aria-hidden="true"></i>
													<span class="gotham-book-regular icon-lable">
														{{ "Tunnel Configuration" }}
													</span>
												</div>
											</button>
										</div>
										<div
											class="button-container"
											*ngIf="
												flags?.isStrictModestatus && element?.active && element?.agentService
											">
											<button
												(click)="onConfigurestrictMode(element)"
												type="button"
												class="text-start custom-button">
												<div class="icon-container">
													<i
														*ngIf="element?.strictMode; else lock"
														class="fa fa-unlock"
														aria-hidden="true"></i>
													<ng-template #lock>
														<i class="fa fa-lock" aria-hidden="true"></i>
													</ng-template>
													<span
														class="gotham-book-regular icon-lable"
														*ngIf="element?.strictMode; else activate">
														Disable Strict Mode
													</span>
													<ng-template class="gotham-book-regular icon-lable" #activate>
														Enable Strict Mode
													</ng-template>
												</div>
											</button>
										</div>
										<div class="button-container" *ngIf="flags?.isEnableFirmwareDelete || flags?.isEnableFirmwareMappingDelete">
											<button (click)="onDelete(element)" type="button" class="text-start custom-button">
												<div class="icon-container">
													<i class="fa fa-trash" aria-hidden="true"></i>
													<span class="gotham-book-regular icon-lable">
														{{ flags?.deleteLabel ? flags?.deleteLabel : "Delete" }}
													</span>
												</div>
											</button>
										</div>
										<div class="pointer"></div>
									</div>
								</div>

								<span class="cursor-pointer">
									<i
										appTooltip="More options"
										(click)="onButtonClicked($event, i)"
										id="more-button"
										class="fa fa-ellipsis-h"></i>
								</span>
							</div>
						</td>
					</ng-container>
				</ng-container>
				<ng-template #customColumnTemplate>
					<ng-container [matColumnDef]="column['field']">
						<th
							mat-header-cell
							*matHeaderCellDef
							mat-sort-header
							[disabled]="column['sort'] ? false : true">
							{{ column["label"] }}
							<span class="ms-2 sort-icon" *ngIf="column['sort']">
								<i
									[ngClass]="
										sortFields.field === column['field'] && sortFields.direction
											? sortFields.direction
											: 'fa-sort'
									"
									class="fa"
									aria-hidden="true"></i>
							</span>
						</th>
						<td
							class="align-middle"
							[ngClass]="{ 'table-header-name': index === 0, 'table-link': column.isLink }"
							mat-cell
							*matCellDef="let element; let rowIndex = index">
							<ng-template
								[ngTemplateOutletContext]="{ element: element, column: column }"
								[ngTemplateOutlet]="column.cellCustomTemplate"></ng-template>
						</td>
					</ng-container>
				</ng-template>
			</ng-container>

			<tr class="header-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr class="mat-row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>

		<div *ngIf="filteredData.length === 0" class="no-data">{{ NO_DATA_FOUND }}</div>
	</div>
	<div class="mat-custom-style-paginator d-flex justify-content-center align-items-center" *ngIf="showPaginator">
		<div class="form-group d-flex align-items-center gap-2">
			<label class="paginator-page-label" for="page">Page</label>
			<input
				id="page"
				[(ngModel)]="page"
				class="paginator-text-input text-center"
				type="text"
				(keypress)="validatePageNumber($event)" />
			<span class="paginator-btn-container" [appTooltip]="!page ? 'Please enter a page number' : ''">
				<button
					(click)="onGotoPage()"
					[disabled]="!page"
					class="paginator-btn paginator-btn-outline-primary"
					[ngClass]="!page ? 'disabled-btn' : ''">
					GO
				</button>
			</span>
			<div *ngIf="pageNumberErrorMessage" class="page-number-error-message">
				<span class="text-danger">{{ pageNumberErrorMessage }}</span>
			</div>
		</div>
		<select
			(change)="onChangeRecordsPerPage($event)"
			class="records-per-page-container"
			aria-label="records per page">
			<option [selected]="pageSize === page" *ngFor="let page of recordsPerPage">{{ page }}</option>
		</select>
		<ng-container>
			<mat-paginator
				appStylePaginator
				(changePage)="onPageChanged($event)"
				[length]="totalElements"
				[pageIndex]="pageIndex"
				[showTotalPages]="5"
				[pageSize]="pageSize"
				[pageSizeOptions]="[2, 5, 10, 20]"
				showFirstLastButtons
				aria-label="Select page of elements"></mat-paginator>
		</ng-container>
	</div>
</div>

<div class="data-table" *ngIf="isShowOverlayCard">
	<div class="device-offline-overlay">
		<div class="card overlay-text align-items-center">
			<mat-icon class="warning-icon">report_problem</mat-icon>
			<span class="offline-text">
				Device configuration cannot be done as it is {{ tableModel.deviceStatusMessage }}.
			</span>
			<button class="btn btn-primary custom-btn align-self-end" (click)="isShowOverlayCard = false">Okay</button>
		</div>
	</div>
</div>

<div #dataTable class="table-responsive" [hidden]="!isDataLoading">
	<table
		id="custom-table"
		[dataSource]="[]"
		matSort
		(matSortChange)="onSort($event)"
		class="table custom-table-stripped"
		aria-label="Data table"
		mat-table>
		<ng-container *ngFor="let column of columns; let index = index; trackBy: trackByFn">
			<ng-container [matColumnDef]="column['field']">
				<th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="true">
					{{ column["label"] }}
					<span class="ms-2 sort-icon" *ngIf="column['sort']">
						<i
							[ngClass]="
								sortFields.field === column['field'] && sortFields.direction
									? sortFields.direction
									: 'fa-sort'
							"
							class="fa"
							aria-hidden="true"></i>
					</span>
				</th>
				<tr class="mat-row" *matNoDataRow>
					<!-- <td class="mat-cell" colspan="6">
						<ngx-skeleton-loader [count]="pageSize" appearance="line" animation="progress" [theme]="{
							'background-color': '#cacaca7a','border-radius': '5px'}">
						</ngx-skeleton-loader>
					</td> -->

					<td *ngFor="let column of columns">
						<ngx-skeleton-loader
							[count]="pageSize"
							appearance="line"
							animation="progress"
							[theme]="{ 'background-color': '#cacaca7a', 'border-radius': '5px' }"></ngx-skeleton-loader>
					</td>
				</tr>
			</ng-container>
		</ng-container>
		<tr class="header-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr class="mat-row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
</div>
