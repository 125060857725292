import {
	AfterViewInit,
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnDestroy,
	Renderer2,
	SimpleChanges
} from "@angular/core";
declare var bootstrap: any;

@Directive({
	selector: "[appTooltip]"
})
export class BootstrapTooltipInitDirective implements AfterViewInit, OnDestroy {
	bootstrapTooltipElem: any;

	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@Input() placement = "top";
	@Input() appTooltip: string | any;
	@Input() customClass!: string;

	@HostListener("click", ["$event"]) onClick($event: any) {
		// this.bootstrapTooltipElem?.hide();
	}

	ngAfterViewInit(): void {
		this.parseTooltipDetails();
	}

	ngOnChanges(changes: SimpleChanges) {
		if ("appTooltip" in changes) {
			this.parseTooltipDetails();
		}
	}

	parseTooltipDetails() {
		let isHTMLTooltip = false;
		this.renderer.setAttribute(this.el.nativeElement, "data-bs-toggle", "tooltip");
		this.renderer.setAttribute(this.el.nativeElement, "data-bs-placement", this.placement);
		if (this.appTooltip && typeof this.appTooltip === "string") {
			this.renderer.setAttribute(this.el.nativeElement, "title", this.appTooltip);
		} else if (!this.appTooltip && !this.el.nativeElement.getAttribute("title")) {
			this.renderer.removeAttribute(this.el.nativeElement, "data-bs-toggle", "tooltip");
			this.renderer.removeAttribute(this.el.nativeElement, "title");
			this.bootstrapTooltipElem?.hide();
			this.bootstrapTooltipElem?.disable();
		} else {
			this.renderer.setAttribute(this.el.nativeElement, "title", this.appTooltip?.innerHTML);
			isHTMLTooltip = true;
		}

		if (this.appTooltip) {
			this.bootstrapTooltipElem = new bootstrap.Tooltip(this.el.nativeElement, {
				trigger: "hover",
				html: isHTMLTooltip,
				customClass: this.customClass || "custom-tooltip-class"
			});
		}
	}

	

	ngOnDestroy(): void {
		this.bootstrapTooltipElem?.hide();
	}
}
