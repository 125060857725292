import {
	Directive,
	Input,
	Renderer2,
	ElementRef,
	AfterViewInit,
	OnInit,
	TemplateRef,
	ViewContainerRef
} from "@angular/core";
import { AuthenticationService } from "../services";

@Directive({
	selector: "[appHasPrivilege]"
})
export class HasPrivilegeDirective implements OnInit {
	@Input("appHasPrivilege") privilege!: string;

	constructor(
		private authService: AuthenticationService,
		private el: ElementRef,
		private renderer: Renderer2,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {}

	ngOnInit(): void {
		this.checkPrivilege();
	}

	private checkPrivilege(): void {
		const hasPrivilege = this.authService.isPrivilegeAvailable(this.privilege);
		if (hasPrivilege) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
