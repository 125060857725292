import { Component } from '@angular/core';

@Component({
  selector: 'app-hour-glass-loader',
  templateUrl: './hour-glass-loader.component.html',
  styleUrls: ['./hour-glass-loader.component.scss']
})
export class HourGlassLoaderComponent {

}
