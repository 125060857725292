import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Data } from "@angular/router";
import { DataSharedService } from "../shared/services/data-shared.service";

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
	constructor(		
		private dataSharedService: DataSharedService,		
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		//adding the service type to the request
		if(request.url.includes('/adaptor/')) {
			request = request.clone({
				setParams: { serviceType: this.dataSharedService.getCustomerServiceType()?.id.toString() }
			});
		}

		if(request.urlWithParams.includes('/SCDX/')){
			const secretKey = this.dataSharedService.getCustomerSecretKey();
			let credentials = secretKey?.split(":");
			let token = "";
			if (credentials?.length > 1) {
				token = `Basic ${window.btoa(secretKey)}`;
			} else {
				token = `Basic ${secretKey}`;
			}
			request = request.clone({
				setHeaders: { 
					//Authorization: `Basic ${window.btoa('admin:devadmin')}`						
					//Authorization: `Basic ${window.btoa(request.urlWithParams.includes('/Test-6/') ? 'admin:devadmin' : 'dharmarajan.arumugam@tatacommunications.com:Spaed@123')}`
					Authorization: token
					//Authorization: `Basic ${window.btoa(request.urlWithParams.includes('/Test-1/') ? 'admin:devadmin' : 'admin:devadmin')}`
				}
			});
		}
		const userProfile  = localStorage.getItem("userProfile");
		if (userProfile) {
			const userProfileObj = JSON.parse(userProfile);
			if(userProfileObj.timezoneName) {
				const updatedReq = request.clone({ 
					headers: request.headers.set('timezone', userProfileObj.timezoneName),
				  });
				
				return this.constructHandle(updatedReq, next);
			}
		}		
		
		return this.constructHandle(request, next);
	}

	constructHandle(request: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>>{
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				let errorMessage: string;
				if (error.error instanceof ErrorEvent) {
					// client-side error
					errorMessage = error.error.message;
				} else {
					// server-side error
					if (error.status === 403) {
						//Handle forbidden
					} else if(error.status === 404){
						errorMessage = "We couldn't  process your request right now. Please try again later or contact support team if the issue persists";
						if(error.error.message){
							errorMessage = error.error.message;
						}else if(error?.message){
							errorMessage = error?.message;
						}
					}else {
						errorMessage =
							"We couldn't  process your request right now. Please try again later or contact support team if the issue persists";
					}
				}
				return throwError(() => errorMessage);
			})
		);
	}
}
