<div class="row mt-3 pb-3 site-list">
	<div class="col-12 col-md-12 col-sm-12">
		<div class="card p-3">
			<div class="col-md-12 mb-0">
				<div class="col-md-12 d-flex justify-content-end">
					<div class="col-md-4">
						<h2 class="page-title">Sites ({{ totalSize }})</h2>
					</div>
					<div class="col-md-8 d-flex justify-content-end">
						<div class="col-4 mt-2">
							<app-customer-select-list
								[isShowAll]="true"
								(afterInitEvent)="getSelectCustomerList($event)"
								(selectValue)="onSelectCustomer($event)"></app-customer-select-list>
						</div>
						<div class="col-md-3 px-2 mt-2">
							<app-search-input
								[searchTerm]="searchTerm"
								(setSearchValue)="search($event)"
								[dataParams]="dataParams"></app-search-input>
						</div>
						<ng-container>
							<div class="d-flex align-items-center">
								<button
									*ngIf="flags.isEnableCreate && canShowAddBtn"
									appTooltip="Add new site"
									placement="left"
									aria-label="icon that displays tooltip for add site"
									class="custom-btn btn-config btn btn-outline-primary me-2"
									[routerLink]="
										flags.isSaseCustomer
											? ['/site-management', 'action', 'create-site']
											: ['/site-management', 'site-action', 'create-site']
									"
									color="primary">
									<span>Add</span>
									<img src="./assets/icons/plus.svg" class="add-button-img" />
								</button>

								<button
									*ngIf="flags.isSaseCustomer"
									[disabled]="!siteModel?.selectedCustomerId"
									[ngClass]="{ 'disable-network-summary-btn': !siteModel?.selectedCustomerId }"
									appTooltip="{{
										siteModel?.selectedCustomerId
											? 'Click here to view network summary'
											: 'Select customer to view network summary'
									}}"
									placement="left"
									aria-label="icon that displays tooltip to view network summary"
									class="custom-btn btn-config btn btn-outline-primary my-2 network-btn p-0"
									(click)="onClickNetworkSummary()"
									color="primary">
									<span>Network Summary</span>
								</button>
							</div>
						</ng-container>
						<ng-container *ngIf="false">
							<div
								class="d-flex align-items-center px-1"
								[matTooltipPosition]="'above'"
								[matTooltip]="
									flags?.isAllSelected
										? 'Change Selection to Unique Customer'
										: 'Download Config Report'
								">
								<button
									*ngIf="downloadProgress"
									appTooltip="Download Config Report"
									placement="left"
									aria-label="icon that displays tooltip for download config report"
									class="download-btn-config btn btn-outline-primary btn_text"
									color="primary"
									[disabled]="downloadProgress">
									<i class="fa" [ngClass]="{ 'fa-spin fa-spinner': downloadProgress }"></i>
									<span>Downloading...</span>
								</button>

								<button
									*ngIf="!downloadProgress && dataSource.onlineDeviceList?.length > 0"
									[disabled]="flags?.isAllSelected"
									placement="left"
									aria-label="icon that displays tooltip for download config report"
									class="download-btn-config btn btn-outline-primary btn_text"
									color="primary"
									(click)="onClickDownloadConfigReport()"
									[ngClass]="flags?.isAllSelected ? 'button-disabled' : ''">
									<span>Download Config</span>
									<span class="p-r-4"><i class="fa fa-download text-end color-primary"></i></span>
								</button>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="col-md-12 mt-1" >				
				<app-custom-data-table [settings]="{
						data: data,
						columnConfig: dataSource.columnConfig,
						length: totalSize,							
						loaderFlags: {
							isLoading: showProgress,
							isErrorFetchingData: flags.isErrorFetchingData
						},
						paginator: paginator,
						resetSelectedData: flags.resetSelectedData,
						enableCheckbox:false,
						pageSize:pageSize						
					}" (onPageChange)="onPageChanged($event)"></app-custom-data-table>
			</div>
		</div>
	</div>
	<div class="device-unavailable-overlay" *ngIf="isShowOverlayCard">
		<div class="card overlay-text align-items-center">
			<mat-icon class="warning-icon">report_problem</mat-icon>
			<span class="unavailable-text">{{ MONITOR_SITE_WO_DEVICE_MAPPED }}</span>
			<button class="btn btn-primary custom-btn align-self-end" (click)="isShowOverlayCard = false">Okay</button>
		</div>
	</div>
</div>

<ng-template #confirmDeletionTemplate>
	<div class="site-delete-confirm-dialog">
		<div class="dialog-header">
			<div class="header-text">Confirm Action</div>
		</div>
		<div mat-dialog-content class="body">
			<ng-container *ngIf="flags?.isDeviceMappedWithSite; else confirmDeleteTemplate">
				<p>
					This site is associated with
					<span *ngFor="let item of dataSource?.mappedDevices; last as isLast">
						<a class="cursor-pointer" (click)="onClickDeviceName(item)">{{ item.ciName }}</a>
						<span class="ps-1 pe-1" *ngIf="!isLast">,</span>
					</span>
					. Please remove the device and try again.
				</p>
			</ng-container>
			<ng-template #confirmDeleteTemplate>
				<ng-container>
					<p>
						<span>Are you sure you want to delete the site?</span>
					</p>
				</ng-container>
			</ng-template>
		</div>
		<div mat-dialog-actions class="d-flex justify-content-end gap-2">
			<div *ngIf="flags?.isDeviceMappedWithSite; else deletionConfirmationTemplate">
				<button
					class="btn btn-primary custom-btn btn-config"
					color="primary"
					type="button"
					(click)="this.confirmDeletionTemplateRef.close()">
					Okay
				</button>
			</div>
			<ng-template #deletionConfirmationTemplate>
				<button
					class="btn btn-primary custom-btn btn-config"
					color="primary"
					type="button"
					(click)="this.confirmDeletionTemplateRef.close()">
					No
				</button>
				<button
					class="btn btn-primary custom-btn btn-config"
					color="primary"
					type="button"
					(click)="onClickDeleteSite()">
					Yes
				</button>
			</ng-template>
		</div>
	</div>
</ng-template>
<ng-template #attributesTemplate>
	<div class="attributes-dialog">
		<div class="dialog-header bg-header">
			<div class="col-md-12 d-flex justify-content-between align-items-center">
				<div class="col-md-11 px-2 fs-13">Download Config</div>
				<div class="col-md-1 text-end">
					<button mat-icon-button class="close-button" [mat-dialog-close]="false">
						<mat-icon class="text-end attributes-cls-btn">cancel</mat-icon>
					</button>
				</div>
			</div>
		</div>

		<div id="attr-form" class="fs-13 attributes-form overflow-y-hide" mat-dialog-content>
			<div class="row h_100">
				<div class="col-6 px-2 fs-13">Select the device(s) to download:</div>
				<div class="col-6">
					<app-multi-select
						[selection]="'multiple'"
						[data]="dataSource?.onlineDeviceList"
						[selected]="selectedDevice"
						(onDropdownClose)="onDeviceDropdownClosed($event)"
						id="dashboard-sites"
						class="dashboard-sites custom-multi-select sm-height multiselect--dropdown"
						placeholder="Select Device"></app-multi-select>
					<div *ngIf="selectedDevice.length === 0" class="position-relative">
						<span class="text-danger">Select atleast one Device</span>
					</div>
				</div>
			</div>
		</div>
		<hr />
		<div mat-dialog-actions class="d-flex justify-content-end gap-2 mb-2">
			<button
				mat-dialog-close
				type="button"
				class="btn btn-primary custom-btn btn-config apply-btn action-buttons"
				(click)="downloadConfigReport()"
				[mat-dialog-close]="true"
				[disabled]="!isOnlineDeviceAvailable || selectedDevice.length === 0">
				Download
			</button>
		</div>
	</div>
</ng-template>
<ng-template #fsKeyTemplate>
	<div class="attributes-dialog">
		<div class="dialog-header bg-header">
			<div class="col-md-12 d-flex justify-content-between align-items-center">
				<div class="col-md-11 p-all-12 fs-13">Device credentials</div>
				<div class="col-md-1 text-end">
					<button mat-icon-button class="close-button" [mat-dialog-close]="true">
						<mat-icon class="text-end attributes-cls-btn">cancel</mat-icon>
					</button>
				</div>
			</div>
		</div>
		<div class="fs-13 attributes-form" mat-dialog-content>
			<div class="row text-center">
				<div class="row-flex">
					<span class="attribute-key">Username:</span>
					<p class="ms-2 attribute-value text-start">{{ webNativeTokenData?.deviceUsername }}</p>
				</div>
				<div class="row-flex">
					<span class="attribute-key">Password:</span>
					<p class="break-word attribute-value text-start">
						{{ maskedKey || "N/A" }}
						<i
							*ngIf="!webNativeTokenData?.devicePassword"
							appTooltip="Please contact your administrator for password."
							class="fa fa-info-circle ms-2"
							aria-hidden="true"></i>
						<button
							*ngIf="webNativeTokenData?.devicePassword"
							id="copied-button"
							class="btn copy-button rounded-circle">
							<span class="ms-2 cursor-pointe copy-icon" (click)="onCopy()">
								<i class="fa fa-files-o" aria-hidden="true"></i>
							</span>
						</button>
					</p>
				</div>
				<div class="row-flex">
					<span class="attribute-key col-4">Web Console Session Time :</span>
					<p class="ms-2 attribute-value text-start">expire at - {{ webConsoleExpiryTme }}</p>
				</div>
				<div class="row-flex" *ngIf="launchWebConsoleURL">
					<span class="attribute-key">Web Console URL:</span>
					<div class="table-link d-flex justify-content-end gap-2">
						<span (click)="goToLink(launchWebConsoleURL)">Copy password and launch console in new tab</span>
					</div>
				</div>
			</div>
		</div>
		<hr />
		<div mat-dialog-actions class="d-flex justify-content-end gap-2">
			<button mat-dialog-close type="button" class="btn btn-primary custom-btn btn-config">Close</button>
		</div>
	</div>
</ng-template>
<ng-template #tunnelConfigurationFormTemplate>
	<div class="attributes-dialog">
		<div class="dialog-header bg-header">
			<div class="col-md-12 d-flex justify-content-between align-items-center">
				<div class="col-md-11 p-all-12 fs-13">Tunnel Configuration</div>
				<div class="col-md-1 text-end">
					<button mat-icon-button class="close-button" [mat-dialog-close]="true">
						<mat-icon class="text-end attributes-cls-btn">cancel</mat-icon>
					</button>
				</div>
			</div>
		</div>
		<div mat-dialog-content class="col-12 col-md-12 col-sm-12 p-t-4">
			<div class="col-md-12">
				<form [formGroup]="tunnelConfigurationForm" (ngSubmit)="onSubmitTunnelConfigForm()">
					<!-- Private config start -->
					<div class="row mb-4 ml-1">
						<div class="col-md-6 col-sm-12">
							<div class="form-group readonly-textbox">
								<label class="form-label required" for="ip">Name</label>
								<input
									autocomplete="off"
									type="text"
									id="tunnelName"
									class="form-control form-height"
									formControlName="tunnelName"
									aria-describedby="tunnelName"
									placeholder="Enter Name" />
								<ng-container
									*ngIf="
										tunnelConfigurationForm.get('tunnelName')?.touched &&
										tunnelConfigurationForm.get('tunnelName')?.invalid
									">
									<div
										*ngIf="tunnelConfigurationForm.get('tunnelName')?.errors?.['required']"
										class="text-danger pt-1">
										IP Address is required.
									</div>
									<div
										*ngIf="tunnelConfigurationForm.get('tunnelName')?.errors?.['maxlength']"
										class="text-danger pt-1">
										IP Address cannot exceed 100 characters.
									</div>
								</ng-container>
							</div>
						</div>
						<div class="col-md-6 col-sm-12">
							<div class="form-group readonly-textbox">
								<label class="form-label required" for="ip">IP Address</label>
								<input
									autocomplete="off"
									type="text"
									id="privateIp"
									class="form-control form-height"
									formControlName="privateIp"
									aria-describedby="privateIp"
									placeholder="Enter IP Address" />
								<ng-container
									*ngIf="
										tunnelConfigurationForm.get('privateIp')?.touched &&
										tunnelConfigurationForm.get('privateIp')?.invalid
									">
									<div
										*ngIf="tunnelConfigurationForm.get('privateIp')?.errors?.['required']"
										class="text-danger pt-1">
										IP Address is required.
									</div>
									<div
										*ngIf="tunnelConfigurationForm.get('privateIp')?.errors?.['maxlength']"
										class="text-danger pt-1">
										IP Address cannot exceed 100 characters.
									</div>
									<div
										*ngIf="tunnelConfigurationForm.get('privateIp')?.errors?.['pattern']"
										class="text-danger pt-1">
										Enter a valid IP Address.
									</div>
								</ng-container>
							</div>
						</div>
					</div>
					<div class="row mb-4 ml-1">
						<div class="col-md-6 col-sm-12">
							<div class="form-group readonly-textbox">
								<label class="form-label required" for="port">Port</label>
								<input
									autocomplete="off"
									type="text"
									id="privatePort"
									class="form-control form-height"
									formControlName="privatePort"
									aria-describedby="Port"
									placeholder="Enter Port" />
								<ng-container
									*ngIf="
										tunnelConfigurationForm.get('privatePort')?.touched &&
										tunnelConfigurationForm.get('privatePort')?.invalid
									">
									<div
										*ngIf="tunnelConfigurationForm.get('privatePort')?.errors?.['required']"
										class="text-danger pt-1">
										Port is required.
									</div>
									<div
										*ngIf="tunnelConfigurationForm.get('privatePort')?.errors?.['maxlength']"
										class="text-danger pt-1">
										Port cannot exceed 100 characters.
									</div>
									<div
										*ngIf="tunnelConfigurationForm.get('privatePort')?.errors?.['pattern']"
										class="text-danger pt-1">
										Enter a valid Port Number.
									</div>
								</ng-container>
							</div>
						</div>
						<div class="col-md-6 col-sm-12">
							<div class="form-group readonly-textbox">
								<label class="form-label required" for="firstName">Public Key</label>
								<input
									autocomplete="off"
									type="text"
									id="privatePublicKey"
									class="form-control form-height"
									formControlName="privatePublicKey"
									aria-describedby="privatePublicKey"
									placeholder="Enter Public Key" />
								<ng-container
									*ngIf="
										tunnelConfigurationForm.get('privatePublicKey')?.touched &&
										tunnelConfigurationForm.get('privatePublicKey')?.invalid
									">
									<div
										*ngIf="tunnelConfigurationForm.get('privatePublicKey')?.errors?.['required']"
										class="text-danger pt-1">
										Public Key is required.
									</div>
								</ng-container>
							</div>
						</div>
					</div>
					<!-- Private config end -->

					<!-- Public config start -->
					<div class="row mb-4 ml-1" *ngIf="false">
						<div class="col-md-12 col-sm-12">
							<div class="form-group readonly-textbox">
								<label class="form-label" for="tunnel">Public Tunnel</label>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group readonly-textbox">
								<label class="form-label required" for="ip">IP Address</label>
								<input
									autocomplete="off"
									type="text"
									id="publicIp"
									class="form-control form-height"
									formControlName="publicIp"
									aria-describedby="publicIp"
									placeholder="Enter IP Address" />
								<ng-container
									*ngIf="
										tunnelConfigurationForm.get('publicIp')?.touched &&
										tunnelConfigurationForm.get('publicIp')?.invalid
									">
									<div
										*ngIf="tunnelConfigurationForm.get('publicIp')?.errors?.['required']"
										class="text-danger pt-1">
										IP Address is required.
									</div>
									<div
										*ngIf="tunnelConfigurationForm.get('publicIp')?.errors?.['maxlength']"
										class="text-danger pt-1">
										IP Address cannot exceed 100 characters.
									</div>
									<div
										*ngIf="tunnelConfigurationForm.get('publicIp')?.errors?.['pattern']"
										class="text-danger pt-1">
										Enter a valid IP Address.
									</div>
								</ng-container>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group readonly-textbox">
								<label class="form-label required" for="port">Port</label>
								<input
									autocomplete="off"
									type="text"
									id="publicPort"
									class="form-control form-height"
									formControlName="publicPort"
									aria-describedby="Port"
									placeholder="Enter Port" />
								<ng-container
									*ngIf="
										tunnelConfigurationForm.get('publicPort')?.touched &&
										tunnelConfigurationForm.get('publicPort')?.invalid
									">
									<div
										*ngIf="tunnelConfigurationForm.get('publicPort')?.errors?.['required']"
										class="text-danger pt-1">
										Port is required.
									</div>
									<div
										*ngIf="tunnelConfigurationForm.get('publicPort')?.errors?.['maxlength']"
										class="text-danger pt-1">
										Port cannot exceed 100 characters.
									</div>
									<div
										*ngIf="tunnelConfigurationForm.get('publicPort')?.errors?.['pattern']"
										class="text-danger pt-1">
										Enter a valid Port Number.
									</div>
								</ng-container>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group readonly-textbox">
								<label class="form-label required" for="firstName">Overlay IP</label>
								<input
									autocomplete="off"
									type="text"
									id="publicTunnel"
									class="form-control form-height"
									formControlName="publicTunnel"
									aria-describedby="publicTunnel"
									placeholder="Enter Overlay IP Range" />
								<ng-container
									*ngIf="
										tunnelConfigurationForm.get('publicTunnel')?.touched &&
										tunnelConfigurationForm.get('publicTunnel')?.invalid
									">
									<div
										*ngIf="tunnelConfigurationForm.get('publicTunnel')?.errors?.['required']"
										class="text-danger pt-1">
										Overlay IP is required.
									</div>
									<div
										*ngIf="tunnelConfigurationForm.get('publicTunnel')?.errors?.['pattern']"
										class="text-danger pt-1">
										Enter a valid Overlay IP.
									</div>
								</ng-container>
							</div>
						</div>
					</div>
					<div class="row mb-4 ml-1" *ngIf="false">
						<div class="col-md-4 col-sm-12">
							<div class="form-group readonly-textbox">
								<label class="form-label required" for="firstName">Public Key</label>
								<input
									autocomplete="off"
									type="text"
									id="publicPublicKey"
									class="form-control form-height"
									formControlName="publicPublicKey"
									aria-describedby="publicPublicKey"
									placeholder="Enter Public Key" />
								<ng-container
									*ngIf="
										tunnelConfigurationForm.get('publicPublicKey')?.touched &&
										tunnelConfigurationForm.get('publicPublicKey')?.invalid
									">
									<div
										*ngIf="tunnelConfigurationForm.get('publicPublicKey')?.errors?.['required']"
										class="text-danger pt-1">
										Public Key is required.
									</div>
								</ng-container>
							</div>
						</div>
						<!-- <div class="col-md-4 col-sm-12">
							<div class="form-group readonly-textbox">
								<label class="form-label required" for="firstName">
									public Key
								</label>
								<input
									autocomplete="off"
									type="text"
									id="publicKey"
									class="form-control"
									formControlName="publicKey"
									aria-describedby="public Key"
									placeholder="Enter public Key" />
								<ng-container
									*ngIf="
										tunnelConfigurationForm.get('publicKey')?.touched &&
										tunnelConfigurationForm.get('publicKey')?.invalid
									">
									<div
										*ngIf="tunnelConfigurationForm.get('publicKey')?.errors?.['required']"
										class="text-danger pt-1">
										public Key is equired.
									</div>
								</ng-container>
							</div>
						</div> -->
						<div class="col-md-4 col-sm-12">
							<div class="form-group readonly-textbox">
								<label class="form-label" for="firstName">MTU</label>
								<input
									autocomplete="off"
									type="text"
									id="publicMtu"
									class="form-control form-height"
									formControlName="publicMtu"
									aria-describedby="publicMtu"
									placeholder="Enter MTU" />
								<ng-container
									*ngIf="
										tunnelConfigurationForm.get('publicMtu')?.touched &&
										tunnelConfigurationForm.get('publicMtu')?.invalid
									">
									<div
										*ngIf="tunnelConfigurationForm.get('publicMtu')?.errors?.['pattern']"
										class="text-danger pt-1">
										MTU Number can contain only numeric values.
									</div>
								</ng-container>
							</div>
						</div>
					</div>
					<!-- Public config end -->
				</form>
			</div>
		</div>
		<hr class="m-0" />
		<div mat-dialog-actions class="d-flex justify-content-end gap-2">
			<div class="py-2 gap-2 d-flex justify-content-end">
				<button
					mat-dialog-close
					class="btn btn-primary custom-btn btn-config"
					type="button"
					color="primary"
					(click)="onCancelClicked()">
					Cancel
				</button>
				<button
					class="btn btn-primary custom-btn btn-config"
					type="button"
					color="primary"
					(click)="onResetForm()">
					Reset
				</button>
				<button
					class="btn btn-primary custom-btn btn-config"
					color="primary"
					type="submit"
					(click)="onSubmitTunnelConfigForm()"
					[disabled]="!tunnelConfigurationForm.valid">
					Submit
				</button>
			</div>
		</div>
	</div>
</ng-template>
<ng-template let-element="element" let-column="column" #nameFieldTemplate>
	<ng-container *ngIf="column?.isLink; else deviceNameTemplate">
		<span [appTooltip]="element?.name" (click)="onLinkClicked(element)" class="name-field-con ellipsis">{{ element?.name }}</span>
	</ng-container>
	<ng-template #deviceNameTemplate>
		<span [appTooltip]="element?.name" class="name-field-con ellipsis">{{ element?.name }}</span>
	</ng-template>
</ng-template>
<ng-template let-element="element" let-column="column" #statusFieldTemplate>
	<span [appTooltip]="element?.deviceStatus">
		<i class="fa fa-circle" [ngClass]="{
				'online-green': element?.deviceStatus === deviceStatus.online,
				offline: element?.deviceStatus === deviceStatus.offline,
				'partially-online': element?.deviceStatus === deviceStatus.partiallyOnline
			}" aria-hidden="true"></i>
		<span>&nbsp;{{ element?.deviceStatus }}</span>
		<!-- <span>&nbsp;{{deviceStatus.online}}</span> -->
	</span>
</ng-template>
<ng-template let-element="element" let-column="column" #deviceNameTemplate>
	<ng-container *ngIf="element?.devices?.length; else emptyDeviceTemplate">
		<div *ngFor="let device of element.devices;">
			<span [appTooltip]="device?.ciDisplayName" class="ellipsis">
				{{ device?.ciDisplayName + (element?.devices?.length  > 1 ? ",": "") }}
			</span>
		</div>
	</ng-container>
	<ng-template #emptyDeviceTemplate>
		<div>
			<span class="sml-1r">{{ "-" }}</span>
		</div>
	</ng-template>
</ng-template>
<ng-template let-element="element" let-column="column" #actionMenuTemplate>
	<div class="url-category-action-menu">
		<div class="button-container">
			<button (click)="onViewSite(element)" type="button" class="text-start custom-button">
				<div class="icon-container">
					<i class="fa fa-eye" aria-hidden="true"></i>
					<span class="gotham-book-regular icon-lable">View</span>
				</div>
			</button>
		</div>
		<div class="button-container" *ngIf="flags?.isEnableEdit">
			<button (click)="onEditSite(element)" type="button" class="text-start custom-button">
				<div class="icon-container">
					<i class="fa fa-pencil" aria-hidden="true"></i>
					<span class="gotham-book-regular icon-lable">Edit</span>
				</div>
			</button>
		</div>
		<div class="button-container" *ngIf="flags?.isEnableDelete">
			<button (click)="onDeleteSite(element)" type="button" class="text-start custom-button">
				<div class="icon-container">
					<i class="fa fa-trash" aria-hidden="true"></i>
					<span class="gotham-book-regular icon-lable">Delete</span>
				</div>
			</button>
		</div>		
	</div>
</ng-template>
