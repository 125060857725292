import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from "@angular/forms";
import * as CryptoJS from "crypto-js";
import { IpConfig, IpVersion, PortConfig } from "../models/utils";
import { GlobalConstants } from "./global-constants";
import { Observable, map, of } from "rxjs";
import { CommonService } from "../services/common.service";
const v4Pattern = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;
const v4CidrPattern = /^(3[0-2]|[12]?[0-9])$/;

const v6Pattern =
	/^((?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(:[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(:[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(:[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(:[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,6}|:)|(?::((?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(%[0-9a-zA-Z]{1,})?$/;
const v6CidrPattern = /^(12[0-8]|^[0-9]$|^[1-9][0-9]$|^[1][01][0-9]$)$/;

const netMaskPattern = /^(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$/;
const portPattern = GlobalConstants.PORT_PATTERN;

export function aesencrypt(key: any, value: any) {
	key = CryptoJS.enc.Utf8.parse(key);
	let ciphertext = CryptoJS.AES.encrypt(value, key, { iv: key }).toString();
	return ciphertext;
}

export function aesdecrypt(key: any, value: any) {
	key = CryptoJS.enc.Utf8.parse(key);
	let decryptedData = CryptoJS.AES.decrypt(value, key, {
		iv: key
	});
	return decryptedData.toString(CryptoJS.enc.Utf8);
}
function checkIpV4(ip: string, config?: IpConfig) {
	let isValid = v4Pattern.test(ip);
	let isValidSegment = false;
	if (isValid) {
		const splitDots = ip.split(".");
		for (const ipNum of splitDots) {
			isValid = Number(ipNum) <= 255;
			if (!isValidSegment) {
				isValidSegment = Number(ipNum) > 0; //check whether atleast one value in the segment is greater than 0
			}
			if (!isValid) break;
		}
	}
	if (config && "nonZeroSegmentRequired" in config) {
		return config?.nonZeroSegmentRequired ? isValid && isValidSegment : isValid;
	} else {
		return isValid && isValidSegment;
	}
}

function checkIpV6(ip: string) {
	return v6Pattern.test(ip);
}

function isValidIPV6(config: IpConfig, control: AbstractControl) {
	let isValid = true;
	if (config.multi) {
		const ipList = (control.value as string).split(",");
		for (const ip of ipList) {
			isValid = checkIpV6(ip);
			if (!isValid) {
				break;
			}
		}
	} else {
		const values = config.isCidr ? (control.value as string).split("/") : [control.value];
		isValid = checkIpV6(values[0]);
		if (config.isCidr && isValid) {
			isValid = values.length === 2 ? v6CidrPattern.test(values[1]) : false;
		}
	}
	return isValid;
}

function isValidIPV4(config: IpConfig, control: AbstractControl) {
	let isValid = true;
	if (config.multi) {
		const ipList = (control.value as string).split(",");
		for (const ip of ipList) {
			isValid = checkIpV4(ip, config);
			if (!isValid) {
				break;
			}
		}
	} else {
		if (config.supportWithOrWithoutCidr) {
			let values = [control.value];
			const containsCidr = (control.value as string).includes("/");
			if (containsCidr) {
				values = (control.value as string).split("/");
			}
			isValid = checkIpV4(values[0], config);

			if (containsCidr && isValid) {
				isValid = values.length === 2 && Number(values[1]) > 0 ? v4CidrPattern.test(values[1]) : false;
			}
		} else {
			const values = config.isCidr ? (control.value as string).split("/") : [control.value];
			isValid = checkIpV4(values[0], config);
			if (config.isCidr && isValid) {
				// check if subnet mask exists & mask number greater than zero
				isValid = values.length === 2 && Number(values[1]) > 0 ? v4CidrPattern.test(values[1]) : false;
			}
		}
	}
	return isValid;
}

function comboIP(control: AbstractControl, config?: IpConfig) {
	const ipList = (control.value as string).split(",");
	let isValid = false;
	for (let ip of ipList) {
		ip = ip.trim(); // Removes white spaces if any
		isValid = isRangeIP(ip, config);
		if (!isValid) {
			isValid = isValidIPV4Mask(ip, config);
		}
		if (!isValid) {
			isValid = isValidIPV6Mask(ip, config);
		}
		if (!isValid) {
			isValid = checkIpV4(ip, config);
		}
		if (!isValid) {
			isValid = checkIpV6(ip);
		}
		if (!isValid) {
			break;
		}
	}
	return isValid;
}

export function IPValidate(config: IpConfig): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		if (control.value) {
			let isValid = true;
			switch (config.version) {
				case IpVersion.V4:
					isValid = isValidIPV4(config, control);
					break;
				case IpVersion.V6:
					isValid = isValidIPV6(config, control);
					break;
				default:
					if (typeof config.combo !== "undefined") {
						if (config.combo) {
							isValid = comboIP(control, config);
						}
					} else {
						isValid = isValidIPV4(config, control);
						if (!isValid) {
							isValid = isValidIPV6(config, control);
						}
					}
					break;
			}
			if (!isValid) {
				return { inValidIp: true };
			}
		}
		return null;
	};
}

export function isValidUrls(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		const isValidUrls = (input: string) =>
			input
				.split(",")
				.every((value) =>
					/^((((.*):\/\/)|(mailto:|news:))(%[0-9A-Fa-f]{2}|[-()_.!~*';/?:@&=+$,A-Za-z0-9])+)([).!';/?:,][[:blank:]])?$/i.test(
						value
					)
				);
		if (!value) {
			return null;
		}
		if (isValidUrls(value)) {
			return null;
		} else {
			return { inValidUrl: true };
		}
	};
}
export function isNotEmptySpaceStr(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;		
		if (!value) {
			return null;
		}
		if (value?.trim() !== '') {
			return null;
		} else {
			return { required: true };
		}
	};
}

/**
 *
 * @param elem
 *  elem - sortBy (desc, asc). data (array of values), fromKey(key name) , toKey(key name)
 *  from and to key optional. default value ID.
 *  sortBy optional. default value asc
 */
export function sorList(elem: any) {
	let result = [];
	elem.fromKey = elem.fromKey ? elem.fromKey : "id";
	elem.toKey = elem.toKey ? elem.toKey : "id";
	if (elem.sortBy === "asc") {
		result = elem?.data?.sort(
			(a: { [x: string]: number }, b: { [x: string]: number }) => a[elem.fromKey] - b[elem.toKey]
		);
	} else {
		//desc
		result = elem?.data?.sort(
			(a: { [x: string]: number }, b: { [x: string]: number }) => b[elem.toKey] - a[elem.fromKey]
		);
	}
	return result;
}


export function validatePort(config: PortConfig) {
	return (control: AbstractControl): { [key: string]: any } | null => {
		if (control.value) {
			let isValid = true;
			if (config.combo) {
				isValid = validateComboPort(control, config);
			} else {
				isValid = validatePortPattern(control, config);
			}
			if (!isValid) {
				return { inValidPort: true };
			}
		}
		return null;
	};
}

function validateComboPort(control: AbstractControl, config?: PortConfig) {
	const ports = (control.value as string).split(",");
	let isValid = false;
	for (let port of ports) {
		isValid = isRangePort(port, config);
		if (!isValid) {
			isValid = checkPort(port, config);
		}
		if (!isValid) {
			return isValid;
		}
	}
	return isValid;
}


function isRangePort(controlValue: string, config?: PortConfig) {
	let isValid = false;
	if (controlValue && controlValue.indexOf("-") !== -1) {
		const portRange = controlValue.split("-");
		isValid =
			portRange.length == 2 &&
			portPattern.test(portRange[0]) &&
			portPattern.test(portRange[1]) &&
			Number(portRange[0]) < Number(portRange[1]);
	}
	return isValid;
}

function checkPort(port: string, config?: PortConfig): boolean {
	let isValid = false;
	if (config?.isRange) {
		isValid = isRangePort(port, config);
	} else {
		isValid = portPattern.test(port);
	}
	return isValid;
}

function validatePortPattern(control: AbstractControl, config?: PortConfig): boolean {
	let isValid = false;
	if (config?.multi) {
		const ports = (control.value as string).split(",");
		for (let port of ports) {
			isValid = checkPort(port, config);
			if (!isValid) {
				return isValid;
			}
		}
	} else {
		const port = control.value as string;
		isValid = checkPort(port, config);
	}
	return isValid;
}

function isRangeIP(controlValue: string, config?: IpConfig) {
	let isValid = false;
	if (controlValue && controlValue.indexOf("-") !== -1) {
		let ipList = controlValue.split("-");
		if (ipList.length != 2) {
			return isValid;
		}
		for (const ip of ipList) {
			isValid = checkIpV4(ip, config);
			if (!isValid) {
				isValid = checkIpV6(ip);
			}
			if (!isValid) {
				return isValid;
			}
		}
	}
	return isValid;
}

function isValidIPV4Mask(controlValue: string, config?: IpConfig) {
	let isValid = false;
	if (controlValue && controlValue.indexOf("/") !== -1) {
		let ipMaskList = controlValue.split("/");
		if (ipMaskList.length != 2) {
			return isValid;
		}
		isValid = checkIpV4(ipMaskList[0], config);
		if (isValid) {
			// check if subnet mask exists & mask number greater than zero
			isValid = ipMaskList.length === 2 && Number(ipMaskList[1]) > 0 && v4CidrPattern.test(ipMaskList[1]);
		}
	}
	return isValid;
}

function isValidIPV6Mask(controlValue: string, config?: IpConfig) {
	let isValid = false;
	if (controlValue && controlValue.indexOf("/") !== -1) {
		let ipMaskList = controlValue.split("/");
		if (ipMaskList.length != 2) {
			return isValid;
		}
		isValid = checkIpV4(ipMaskList[0], config);
		if (isValid) {
			// check if subnet mask exists & mask number greater than zero
			isValid = ipMaskList.length === 2 && Number(ipMaskList[1]) > 0 && v6CidrPattern.test(ipMaskList[1]);
		}
	}
	return isValid;
}

export function validateAppLegendName(name: any) {
	return name == "Unknown" ? "ukn" : name;
}

export function ipWithSubnetMaskValidator(commonService: CommonService): AsyncValidatorFn {
	return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
		if (control.value && control.touched) {
			const payload = {
				ipAddresses: control.value
			};
			return commonService.ipWithSubnetMaskValidation(payload).pipe(
				map((response: any) => {
					if (response?.responseCode === 0) {
						return response?.data?.valid ? null : { inValidIp: true };
					} else {
						return null;
					}
				})
			);
		}
		return of(null);
	};
}

export function encodeString(value: any) {
	return btoa(value);
}

export function decodeString(encodedValue: any) {
	return atob(encodedValue);
}

export function generateChecksum(file: File): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		const chunkSize = 2 * 1024 * 1024; // 2MB	chunks
		const accumulatedHash = CryptoJS.algo.MD5.create();
		let processedChunks = 0;
		const totalChunks = Math.ceil(file.size / chunkSize); // Calculate total chunks

		reader.onload = (event: ProgressEvent<FileReader>) => {
			if (event.target && event.target.result) {
				const arrayBuffer: any = event.target.result as ArrayBuffer;
				const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
				accumulatedHash.update(wordArray);
				processedChunks++;

				if (processedChunks < totalChunks) {
					const nextChunkStart = processedChunks * chunkSize;
					const nextChunkBlob = file.slice(nextChunkStart, nextChunkStart + chunkSize); // Slice the file for next chunk
					reader.readAsArrayBuffer(nextChunkBlob);
				} else {
					const finalHash = accumulatedHash.finalize().toString(CryptoJS.enc.Hex);
					resolve(finalHash);
				}
			} else {
				reject(false);
			}
		};

		reader.onerror = (error) => {
			reject(false);
		};

		reader.readAsArrayBuffer(file.slice(0, chunkSize)); // Read the first chunk
	});
}

export function convertUknToUnknown(name: any) {
    return name.toLowerCase() === "ukn" ? "Unknown" : name;
}

export function scrollToTop() {
	window.scrollTo({ top: 0, behavior: 'smooth' });
}
