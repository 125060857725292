import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class DataSharedService {
	selectedCustomer: Object = {};
	loggedInUserInfo: any;
	dataSource: any = {
		privileges: [],
		setPrivilegesByLoggedinUserRole: [],
		customersData: [],
		devicePagination: {
			pageIndex: 0,
			pageSize: 5,
			searchTerm: null
		}
	};
	public isUserDetailsFetched = false;
	public isUserPrivilegesFetched = false;
	public isCustomersDataFetchComplete = false;
	public isCustomersDataFetchError = false;

	private trackDeviceStatusSubject = new BehaviorSubject<string>("");
	trackDeviceStatus$ = this.trackDeviceStatusSubject.asObservable();
	private serviceType!: { id: number; serviceName: string };

	//temp code for STC dependancy
	public customerContextPath = "";
	public customerSecretKey = "";
	spaedContextPath = "";
	spaedBasePath = "";
	private customerdata$ = new BehaviorSubject<any>(null);
	private openPopupSubject = new Subject<void>();
	openPopup$ = this.openPopupSubject.asObservable();

	constructor() {}

	setSelectedCustomer(customer: any) {
		let getCustomer = this.dataSource.customersData.find(
			(val: any) => val?.id === customer?.value || val.id === customer?.id
		);
		if(getCustomer) {
			this.selectedCustomer = getCustomer;
			this.setContextpathOnCustomerselect();
			this.setCustomerInfo(customer?.id || customer?.value);
		}
		else if( customer.value === "All") {
			this.selectedCustomer = customer;
		}
	}

	getSelectedCustomer() {
		return this.selectedCustomer;
	}

	// should move to util
	/**
	 *
	 * @param previous
	 * @param current
	 */
	comparePreviousAndCurrentValue(previous: {}, current: {}) {
		let selectedValue: any;
		if (previous && Object.keys(previous).length) {
			selectedValue = previous;
		} else {
			selectedValue = current;
		}
		return selectedValue;
	}

	setLoggedInUserInfo(userInfo: any) {
		this.loggedInUserInfo = userInfo;
		//In case loggedinuserdetails API is delayed widget values not getting set so below code is added.
		if(Object.hasOwn(this.selectedCustomer, 'id')) {
            const cust:any = this.selectedCustomer;
            this.setCustomerInfo(cust?.id);
        }
	}

	getLoggedInyUserInfo() {
		return this.loggedInUserInfo;
	}

	setPrivileges(privileges: any) {
		this.dataSource.privileges = privileges;
	}

	getPrivileges() {
		return this.dataSource.privileges;
	}

	setPrivilegesByLoggedinUserRole(privileges: any) {
		this.dataSource.setPrivilegesByLoggedinUserRole = privileges;
	}

	getPrivilegesByLoggedinUserRole() {
		return this.dataSource.setPrivilegesByLoggedinUserRole;
	}

	setCustomersData(customers: any) {
		this.dataSource.customersData = customers;
		this.setSelectedCustomer(customers[0]);
	}

	getCustomersData(): any {
		return [...this.dataSource.customersData];
	}

	setPageIndex(pageIndex: any) {
		this.dataSource.devicePagination.pageIndex = pageIndex;
	}

	getPageIndex(): any {
		return this.dataSource.devicePagination.pageIndex;
	}

	setPageSize(pageSize: any) {
		this.dataSource.devicePagination.pageSize = pageSize;
	}

	getPageSize(): any {
		return this.dataSource.devicePagination.pageSize;
	}

	setSearchTerm(searchTerm: any) {
		this.dataSource.devicePagination.searchTerm = searchTerm;
	}

	getSearchTerm(): any {
		return this.dataSource.devicePagination.searchTerm;
	}

	setTrackDeviceStatus(status: string) {
		this.trackDeviceStatusSubject.next(status);
	}

	// Temp code for STC dependancy

	setCustomerContextPath(customerContextPath: any) {
		this.customerContextPath = customerContextPath;
	}

	setCustomerSecretKey(customerSecretKey: any) {
		this.customerSecretKey = customerSecretKey;
	}

	setSpaedBasePath(contextPath: any) {
		let basePathTemp = environment.conspaedBasePath;
		//this.spaedBasePath = basePathTemp.replace(this.getSpaedContextPath(), contextPath);
		this.setSpaedContextPath(contextPath);
		this.spaedBasePath = basePathTemp.replace("host-name/context-path", contextPath);
		environment.spaedBasePath = this.spaedBasePath;
	}

	setSpaedContextPath(contextPath: any) {
		this.spaedContextPath = contextPath;
	}

	getSpaedBasePath() {
		return this.spaedBasePath;
	}

	getCustomerContextPath() {
		return this.customerContextPath;
	}

	getSpaedContextPath() {
		return this.spaedContextPath;
	}

	setContextpathOnCustomerselect() {
		if (this.selectedCustomer) {
			const customerVal: any = this.selectedCustomer.valueOf();
			const selCust = this.dataSource.customersData;
			const index = selCust.findIndex((customer: any) => customer.value === customerVal?.value);
			if (index === -1) return;
			const selectedCust = selCust[index];
			this.setSpaedBasePath(selectedCust?.customerContextPath);
			this.setCustomerContextPath(selectedCust?.customerContextPath);
			this.setCustomerSecretKey(selectedCust?.customerSecretkey);
		}
	}

	getCustomerSecretKey() {
		return this.customerSecretKey;
	}

	getCustomerInfo() {
		return this.customerdata$.asObservable();
	}

	setCustomerInfo(customerId: string) {
		let customer = this.loggedInUserInfo?.services.filter((customer: any) => customer.customerId === customerId)[0];
		if(customer) {
			this.customerdata$.next(customer);
			this.setCustomerServiceType(customer.service);
		}
	}

	setCustomerServiceType(serviceType: any) {
		this.serviceType = serviceType;
	}

	getCustomerServiceType() {
		return this.serviceType;
	}

	openPopup() {
		this.openPopupSubject.next();
	}
}
