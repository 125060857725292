import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material/material.module";
import { DataTableComponent } from "./component/data-table/data-table.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ConfirmDialogComponent } from "../common/confirm-dialog/confirm-dialog.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { StylePaginatorDirective } from "./directives/style-paginator.directive";
import { LoaderComponent } from "./component/loader/loader/loader.component";
import { MultiSelectComponent } from "./component/multi-select/multi-select.component";
import { RouterModule } from "@angular/router";
import { BootstrapTooltipInitDirective } from "./directives/bootstrap-tooltip-init.directive";
import { SearchInputComponent } from "./component/search-input/search-input.component";
import { CustomerSelectListComponent } from "./component/customer-select-list/customer-select-list.component";
import { ProgressBarComponent } from "./component/progress-bar/progress-bar.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NgSelectModule } from "@ng-select/ng-select";
import { KeysPipe } from "./component/pipes/keys.pipe";
import { NullablePipe } from "./component/pipes/nullable.pipe";
import { ShowAllWidgetDataComponent } from "./component/show-all-widget-data/show-all-widget-data.component";
import { CheckUserTypeDirective } from "./directives/check-user-type.directive";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { CarouselTableComponent } from "./component/carousel-table/carousel-table.component";
import { TimeParserUtil } from "../utility/time-parser-utility";
import { FilterTablePipe } from "./component/pipes/table-filter.pipe";
import { SiteListComponent } from "./component/site-list/site-list.component";
import { DeviceListComponent } from "./component/device-list/device-list.component";
import { ServiceEdgeListComponent } from "./component/service-edge-list/service-edge-list.component";
import { MultiSelectModule } from "primeng/multiselect";
import { DropdownModule } from "primeng/dropdown";
import { CheckboxModule } from "primeng/checkbox";
import { InputTextModule } from "primeng/inputtext";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ListToCommaSeperatedPipe } from "./component/pipes/list-to-comma-seperated.pipe";
import { FileUploadComponent } from "../common/file-upload/file-upload.component";
import { CommonMultiselectComponent } from "./component/common-multiselect/common-multiselect.component";
import { CustomDataTableComponent } from "./component/custom-data-table/custom-data-table.component";
import { DateRangeComponent } from "../common/date-range/date-range.component";
import { CalendarModule } from "primeng/calendar";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { AutoCompleteModule } from "primeng/autocomplete";
import { SelectButtonModule } from "primeng/selectbutton";
import { TagModule } from "primeng/tag";
import { AccordionModule } from "primeng/accordion";
import { TableModule } from "primeng/table";
import { PaginatorModule } from "primeng/paginator";
import { ToggleUpDownComponent } from "./component/toggle-up-down/toggle-up-down.component";
import { FieldsetModule } from "primeng/fieldset";
import { ChipModule } from "primeng/chip";
import { NetworkRangeListComponent } from "./component/network-range-list/network-range-list.component";
import { FilterNetworkRangePipe } from "./component/pipes/filter-network-range.pipe";
import { CapitalizePipe } from "./component/pipes/capitalize.pipe";
import { CustomValidators } from "./asyncValidators/customValidators";
import { TrimSpacesPipe } from "./component/pipes/trim-space.pipe";
import { DragDropModule } from "primeng/dragdrop";
import { ScrollerModule } from "primeng/scroller";




import { ToastModule } from "primeng/toast";
import { MenuModule } from "primeng/menu";
import { ContextMenuModule } from 'primeng/contextmenu';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { PrimeTableComponent } from "./component/prime-table/prime-table.component";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ResetActionValidator } from "./utils/reset-form-util";
import { DateTimeUtil } from "./utils/chart-utils/date-time-utils";
import { SliderModule } from "primeng/slider";
import { XrangeChartUtils } from "../common/charts/xrange-chart/xrange-chart-utils";
import { EventReportsComponent } from "./component/event-reports/event-reports.component";
import { SplitButtonModule } from "primeng/splitbutton";
import { DialogModule } from "primeng/dialog";
import { ProfileListPopupComponent } from "./component/profile-list-popup/profile-list-popup.component";
import { HasPrivilegeDirective } from "./directives/has-privilege.directive";
import { DialogService, DynamicDialogModule } from "primeng/dynamicdialog";
import { CardModule } from "primeng/card";
import { DotBouncerLoaderComponent } from "./component/loader/dot-bouncer-loader/dot-bouncer-loader.component";
import { HourGlassLoaderComponent } from "./component/loader/hour-glass-loader/hour-glass-loader.component";
import { ToggleContentComponent } from "./component/toggle-content/toggle-content.component";
import { FileUploadModule } from "primeng/fileupload";
import { ButtonModule } from "primeng/button";
import { FilterService } from "primeng/api";
import { TunnelConnectivityFlowComponent } from "./component/tunnel-connectivity-flow/tunnel-connectivity-flow.component";
@NgModule({
	declarations: [
		DataTableComponent,
		ConfirmDialogComponent,
		StylePaginatorDirective,
		LoaderComponent,
		MultiSelectComponent,
		BootstrapTooltipInitDirective,
		SearchInputComponent,
		CustomerSelectListComponent,
		ProgressBarComponent,
		KeysPipe,
		NullablePipe,
		ShowAllWidgetDataComponent,
		CheckUserTypeDirective,
		CarouselTableComponent,
		FilterTablePipe,
		SiteListComponent,
		DeviceListComponent,
		ServiceEdgeListComponent,
		CommonMultiselectComponent,
		ListToCommaSeperatedPipe,
		FileUploadComponent,
		CustomDataTableComponent,
		DateRangeComponent,
		ToggleUpDownComponent,
		NetworkRangeListComponent,
		FilterNetworkRangePipe,
		CapitalizePipe,
		TrimSpacesPipe,
		PrimeTableComponent,
		EventReportsComponent,
		ProfileListPopupComponent,
		HasPrivilegeDirective,
		DotBouncerLoaderComponent,
		HourGlassLoaderComponent,
		ToggleContentComponent,
		TunnelConnectivityFlowComponent
	],
	imports: [
		MaterialModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgMultiSelectDropDownModule,
		RouterModule,
		NgxSkeletonLoaderModule,
		NgSelectModule,
		SlickCarouselModule,
		MultiSelectModule,
		DropdownModule,
		CheckboxModule,
		InputTextModule,
		ScrollPanelModule,
		CalendarModule,
		SelectButtonModule,
		TagModule,
		AccordionModule,
		TableModule,
		PaginatorModule,
		FieldsetModule,
		ChipModule,
		DragDropModule,	
		OverlayPanelModule,
		SliderModule ,
		SplitButtonModule,
		DialogModule,
		DynamicDialogModule,
		CardModule,
		FileUploadModule,
		ButtonModule,
		DropdownModule,
		ToastModule,
		MenuModule,
		ContextMenuModule,
		SkeletonModule,
		TooltipModule,
		ScrollerModule
	],
	exports: [
		CommonModule,
		MaterialModule,
		DataTableComponent,
		FormsModule,
		ReactiveFormsModule,
		NgMultiSelectDropDownModule,
		StylePaginatorDirective,
		MultiSelectComponent,
		LoaderComponent,
		RouterModule,
		BootstrapTooltipInitDirective,
		SearchInputComponent,
		CustomerSelectListComponent,
		CarouselTableComponent,
		ProgressBarComponent,
		NgSelectModule,
		KeysPipe,
		NgxSkeletonLoaderModule,
		NullablePipe,
		ShowAllWidgetDataComponent,
		CheckUserTypeDirective,
		SlickCarouselModule,
		FilterTablePipe,
		SiteListComponent,
		DeviceListComponent,
		ServiceEdgeListComponent,
		CommonMultiselectComponent,
		MultiSelectModule,
		TableModule,
		DropdownModule,
		TagModule,
		ToastModule,
		MenuModule,
		ButtonModule,
		ContextMenuModule,
		SkeletonModule,
		ScrollPanelModule,
		ListToCommaSeperatedPipe,
		FileUploadComponent,
		TooltipModule,
		CustomDataTableComponent,
		DateRangeComponent,
		AutoCompleteModule,
		SelectButtonModule,
		TagModule,
		AccordionModule,
		PaginatorModule,
		ToggleUpDownComponent,
		FieldsetModule,
		ChipModule,
		NetworkRangeListComponent,
		FilterNetworkRangePipe,
		CapitalizePipe,
		TrimSpacesPipe,
		DragDropModule,
		CalendarModule,
		CustomDataTableComponent,
		DropdownModule,
		OverlayPanelModule,
		SliderModule,
		EventReportsComponent,
		DialogModule,
		ProfileListPopupComponent,
		HasPrivilegeDirective,
		DynamicDialogModule,
		DotBouncerLoaderComponent,
		HourGlassLoaderComponent,
		ToggleContentComponent,
		CardModule,
		FileUploadModule,
		ButtonModule,
		PrimeTableComponent,
		ScrollerModule
	],
	providers: [TimeParserUtil, CustomValidators, ResetActionValidator, DialogService, FilterService,DateTimeUtil, XrangeChartUtils]
})
export class SharedModule {}
