import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class AgHttpService {
	constructor(private http: HttpClient) {}

	public makeGetRequest<T>(url: string, mockFile?: string): Observable<T | null> {
		return this.http.get<T>(url, { observe: "response" }).pipe(
			map((res: HttpResponse<T>) => {
				return res.body;
			})
		);
	}

	public getHttp(url: any) {
		return this.http.get(url);
	}

	public postHttp(url: any, postData: any) {
		return this.http.post(url, postData);
	}

	public makePostRequest<T>(url: string, body?: any): Observable<T | null> {
		return this.http.post<T>(url, body, { observe: "response" }).pipe(
			map((res: HttpResponse<T>) => {
				return res.body;
			})
		);
	}

	public makePutRequest<T>(url: string, body?: any): Observable<T | null> {
		return this.http.put<T>(url, body, { observe: "response" }).pipe(
			map((res: HttpResponse<T>) => {
				return res.body;
			})
		);
	}

	public makePatchRequest<T>(url: string, body?: any): Observable<T | null> {
		return this.http.patch<T>(url, body, { observe: "response" }).pipe(
			map((res: HttpResponse<T>) => {
				return res.body;
			})
		);
	}

	public makeDeleteRequest<T>(url: string, body?: any): Observable<T | null> {
		return this.http
			.request<T>("delete", url, {
				body,
				observe: "response"
			})
			.pipe(
				map((res: HttpResponse<T>) => {
					return res.body;
				})
			);
	}

	public makeDownloadFileRequest<T>(url: string, header: string): Observable<Blob> {
		const headers = new HttpHeaders({ "Content-Type": header });
		return this.http.get(url, { headers, responseType: "blob" });
	}

	downLoadFile(url:any, postData: any) {
        return this.http.post(url, postData, {
            'responseType': 'blob',
            'observe': 'response'
        });
    }
	public makeDownloadFileRequestWithoutHeaders<T>(url: string): Observable<Blob> {		
		return this.http.get(url, { responseType: "blob" });
	}
	public makeDownloadFilePostRequest<T>(url: string, body: any, header: string): Observable<Blob> {
		const headers = new HttpHeaders({ "Content-Type": header });
		return this.http.post(url, body, { headers, responseType: "blob" });
	}

	public makeDownloadFileRequestWithProgress<T>(url: string, header: string): Observable<any> {
		const headers = new HttpHeaders({ "Content-Type": header });
		return this.http.get(url, { headers, responseType: "blob", reportProgress: true, observe: "events" });
	}

	public makeGetTextRequest(url: string): Observable<any> {
		return this.http.get(url, { responseType: "text" as const });
	}

	public makeDownloadFilePostRequestWithProgress<T>(url: string, header: string,body:any): Observable<any> {
		const headers = new HttpHeaders({ "Content-Type": header });
		return this.http.post(url, body, { headers, responseType: "blob", reportProgress: true, observe: "events" });
	}
	
	public makePostRequestWithProgress<T>(url: string, body?: any): Observable<T | null> {
		return this.http.post<T>(url, body, { reportProgress: true, observe: "events" }).pipe(
			map((res: HttpEvent<T>) => {
				return res as any;
			})
		);
	}
}
