import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/app.service";
import { User } from "src/app/shared/models/user";

@Component({
	selector: "app-main",
	templateUrl: "./main.component.html",
	styleUrls: [ "./main.component.scss" ]
})
export class MainComponent implements OnInit {
	footerModel: any = {};
	public loggedInUser: User | undefined;
	constructor(
		private appService: AppService
	) { }

	ngOnInit(): void {
		this.appService.loggedInUserDetailsObservable.subscribe({
			next: (user: User | null) => {
				if (user) {
					this.footerModel.userProfile = {
						isPartnerUser: (user?.userOwner?.name?.toLowerCase() === 'tata tele') // stopgap to unblock ttsl requirement
					};
				}
			}
		});
	}
}
