import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription, finalize, take } from "rxjs";
import { ConfirmDialogComponent, ConfirmDialogModel } from "src/app/common/confirm-dialog/confirm-dialog.component";
import { DataParams, DeviceStatus } from "./../../models/utils";
import { CommonService } from "../../services/common.service";
import { Element } from "../../models/data-table";
import { GlobalConstants, GLOBAL_NAMES, INFO_MESSAGES } from "../../utils/global-constants";
import { pageOptions, TableColumns, TableLazyLoadOptions } from "../../models/table";
import { SitesManagementService } from "src/app/sites-management/sites-management.service";
import { User } from "src/app/shared/models/user";
import { AppService } from "src/app/app.service";
import { ToasterService } from "src/app/shared/toaster.service";
import { AuthenticationService } from "src/app/shared/services";
import { Role } from "src/app/shared/models/role";
import { DataSharedService } from "src/app/shared/services/data-shared.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Clipboard } from "@angular/cdk/clipboard";
import { TimeParserUtil } from "src/app/utility/time-parser-utility";
import { HttpEventType } from "@angular/common/http";
import { PreviousRouteService } from "../../services/route-service";
import { encodeString } from "../../utils/commonutils";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
	selector: "app-shared-sites-list",
	templateUrl: "./site-list.component.html",
	styleUrls: ["./site-list.component.scss"]
})
export class SiteListComponent implements OnInit, OnDestroy {
	siteModel: any = {};
	tunnelConfigModel: any = {};
	_columns: any[] = [];
	@Input() set columns(value: Array<any>) {
		this._columns = value;
		this.parseTableData(value);
	}
	get columns(): Array<any> {
		return this._columns;
	}
	@Input() canShowAddBtn!: boolean;
	confirmDeletionTemplateRef: any;
	@ViewChild("confirmDeletionTemplate") confirmDeletionTemplate!: TemplateRef<any>;
	MONITOR_SITE_WO_DEVICE_MAPPED = INFO_MESSAGES.MONITOR_SITE_WO_DEVICE_MAPPED_MSG;
	//MONITOR_SITE_WO_DEVICE_MAPPED = INFO_MESSAGES.DEVICE_OFFLINE_ERR;
	DEVICE_WEB_CONSOLE_LAUNCH_TEXT = INFO_MESSAGES.DEVICE_CONFIG_OFFLINE_ERR;
	DEVICE_CONFIG_NOTAVAILABLE = INFO_MESSAGES.DEVICE_CONFIG_NOTAVAILABLE_ERR;

	data: Element[] = [];

	showProgress = true;
	downloadProgress = false;
	totalSize!: number;
	pageIndex = 0;
	searchTerm = "";
	pageSize = GlobalConstants.Default_PageSize;
	dataParams: DataParams = { showSearchInput: true, searchDivClass: "site-search" };
	customerSelectList: any[] = [];
	customerValueSelected: any[] = [];
	showCustomerSelect = false;
	selectedCustomerId: any;
	flags: any = {
		isDeviceMappedWithSite: false,
		isSaseCustomer: false
	};
	webNativeTokenData: any = {};
	sortQuery = "";
	refreshProgress = false;
	isShowOverlayCard = false;
	dataSource: any = {
		mappedDevices: []
	};
	isShowDeviceWebConsoleCard = false;
	isShowDeviceNotAvailCard = false;
	isOnlineDeviceAvailable = false;
	selectedDevice: any[] = [];
	maskedKey!: string;
	launchWebConsoleURL!: string;
	webConsoleExpiryTme!: string;
	agentService = false;

	//public loggedInUser: User | undefined;
	@ViewChild("attributesTemplate") attributesTemplate!: TemplateRef<any>;
	@ViewChild("fsKeyTemplate") fsKeyTemplate!: TemplateRef<any>;

	//custom data table
	deviceStatus = DeviceStatus;
	paginator : any = {} 
	@ViewChild("actionMenuTemplate", { static: true }) actionMenuTemplate!: TemplateRef<any>;
	@ViewChild("statusFieldTemplate", { static: true }) statusFieldTemplate!: TemplateRef<any>;
	@ViewChild("nameFieldTemplate", { static: true }) nameFieldTemplate!: TemplateRef<any>;
	@ViewChild("deviceNameTemplate", { static: true }) deviceNameTemplate!: TemplateRef<any>;


	public loggedInUser: any = {};
	customerChange$!: Subscription;
	//patch for Agent
	@ViewChild("tunnelConfigurationFormTemplate") tunnelConfigurationFormTemplate!: TemplateRef<any>;
	public tunnelConfigurationForm!: FormGroup;

	constructor(
		private dialog: MatDialog,
		private toasterService: ToasterService,
		private authService: AuthenticationService,
		private router: Router,
		private siteManagementService: SitesManagementService,
		private appService: AppService,
		private dataSharedService: DataSharedService,
		private previousRouteService: PreviousRouteService,
		private snackBar: MatSnackBar,
		private clipboard: Clipboard,
		private commonService: CommonService,
		private timeParser: TimeParserUtil,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.customerChange$ = this.dataSharedService.getCustomerInfo().subscribe((customerInfo: any) => {
			if (customerInfo) {
				this.flags.isCustomerChanged = false;
				this.flags.isSaseCustomer = customerInfo?.service?.serviceName === GlobalConstants.serviceTypeSase;
			}
		});
		this.appService.loggedInUserDetailsObservable.subscribe({
			next: (user: User | null) => {
				if (user) {
					this.loggedInUser = user;
				}
			}
		});
		this.flags.isEnableEdit = this.authService.isPrivilegeAvailable(Role.canEditSite);
		this.showProgress = true;
		this.flags.isEnableDelete = true;
		this.flags.isEnableCreate = this.authService.isPrivilegeAvailable(Role.canCreateSite);
		//this.getSelectCustomerList();
		this.flags.isEnableDelete = this.authService.isPrivilegeAvailable(Role.canDeleteSite);
		this.flags.isEnableConfigurations = false; //this.authService.isPrivilegeAvailable(Role.canCreateConfigurationItem);
		// this.flags.isEnableConfigurations = true;
		this.flags.ConfigurationsLabel = GLOBAL_NAMES.LAUNCH_DEVICE_WEB_CONSOLE;
		this.flags.ConfigurationsMenuTwoLabel = GLOBAL_NAMES.DOWNLOAD_CONFIG;
		this.flags.isEnableConfigurationsTwo = false;
		this.flags.ConfigurationsMenuTwoFontIcon = "download";
		this.flags.isEnableConfigurationsThree = false;
		this.flags.ConfigurationsIcon = "terminal";
		this.flags.isAllSelected = false;
		this.flags.hasOnlineDevices = false;
		this.flags.showAllSiteListFetchError = false;
		this.dataSource.unsortedSites = [];
		this.flags.isEnableTunnelConfiguration = false;
	}

	parseTableData(columns:Array<any>) {
		this.dataSource.columnConfig = {
			columnDef: columns,
			displayColumns: columns.map((column: any) => column.header)
		};
		columns.forEach((element: any) => {
			if (element.isTemplateRequired) {
				const columnType = this.filterFieldElement(element.header);
				switch (columnType.header) {
					case "actions": {
						element[ "cellTemplate" ] = this.actionMenuTemplate;
						break;
					}
					case "online": {
						element[ "cellTemplate" ] = this.statusFieldTemplate;
						break;
					}
					case "name": {
						element[ "cellTemplate" ] = this.nameFieldTemplate;
						break;
					}
					case "devices": {
						element["cellTemplate"] = this.deviceNameTemplate;
						break;
					}
				}
			}
		});
	}

	filterFieldElement(columName: string) {
		const column = this.columns?.find((fieldElement: any) => fieldElement.header === columName);
		return column;
	}

	public launchWebPopup(deviceId: any) {
		this.showProgress = true;
		this.commonService.getWebNativeUIToken(deviceId).subscribe({
			next: (response) => {
				if (response) {
					this.showProgress = false;
					//this.isShowDeviceWebConsoleCard = true;
					this.webNativeTokenData = response;
					const devicePassword = response?.devicePassword;
					const httpProxyUser = response?.httpProxyUser;
					const httpProxyPassword = response?.httpProxyPassword;
					const hostname = response?.hostname;
					this.maskedKey = "*".repeat(devicePassword.length);
					//https://{httpProxyUser}:{httpProxyPassword}@hostname
					this.webConsoleExpiryTme = this.timeParser.toDateTime(response?.expires);
					//const launchWebConsoleURL = `https://${httpProxyUser}:${httpProxyPassword}@${hostname}`;
					const launchWebConsoleURL = `https://${hostname}`;
					this.launchWebConsoleURL = launchWebConsoleURL;
					this.dialog.open(this.fsKeyTemplate, {
						width: "800px",
						disableClose: true,
						autoFocus: false
					});
				} else {
					this.toasterService.showErrorMessage("Unable to proceed with the action , please try again later");
				}
				this.showProgress = false;
				this.refreshProgress = false;
			},
			error: (err: any) => {
				this.showProgress = false;
				this.refreshProgress = false;
				this.toasterService.showErrorMessage("Unable to proceed with the action , please try again later");
			}
		});
	}
	onCopy(): void {
		this.clipboard.copy(this.webNativeTokenData?.devicePassword || "");
		this.snackBar.open("Copied to clipboard!!!", "Dismiss", { duration: 3000 });
	}
	goToLink(url: string) {
		this.onCopy();
		window.open(url, "_blank");
	}

	getSelectCustomerList(event: any) {
		const existingCustomerSelection: any = this.dataSharedService.getSelectedCustomer();
		if (existingCustomerSelection?.id && existingCustomerSelection?.name) {
			this.siteModel.selectedCustomer = [existingCustomerSelection];
			this.siteManagementService.selectedCustomerId = this.siteModel.selectedCustomer[0].id;
			this.siteModel.selectedCustomerId = existingCustomerSelection?.id;
		} else {
			this.siteManagementService.selectedCustomerId = event.length && event[0].id ? event[0].id : null;
			this.siteModel.selectedCustomerId = this.siteManagementService.selectedCustomerId;
		}
		const previousUrl: any = this.previousRouteService.getPreviousUrl();
		if (
			(previousUrl?.includes("/monitoring/site-monitoring/") || previousUrl?.includes("/site-management/")) &&
			(!previousUrl.includes("/site-management/sites") || previousUrl.includes("/network-management/sites"))
		) {
			this.searchTerm = this.dataSharedService.getSearchTerm();
			if (!this.searchTerm) {
				this.pageIndex = this.dataSharedService.getPageIndex();
				this.pageSize = this.dataSharedService.getPageSize();
			}
		} else {
			this.dataSharedService.setPageIndex(0);
			this.dataSharedService.setPageSize(GlobalConstants.Default_PageSize);
			this.dataSharedService.setSearchTerm("");
		}
		this.flags.isCustomerChanged = true;
		this.getSites({ pageIndex: this.pageIndex || 0, pageSize: this.pageSize || GlobalConstants.Default_PageSize });
		this.getAllOnlineDevice();
	}

	onSelectCustomer(event: any) {
		this.flags.isCustomerChanged = true;
		this.flags.isAllSelected = event?.label.toUpperCase() == "ALL" ? true : false;
		this.siteManagementService.selectedCustomerId = event.id ? event.id : null;
		this.siteModel.selectedCustomerId = this.siteManagementService.selectedCustomerId;
		this.showProgress = true;
		// this.dataSharedService.setSelectedCustomer(event);
		// this.dataSharedService.setContextpathOnCustomerselect();
		this.getSites();
		this.getAllOnlineDevice();
	}

	onEditSite(element: Element): void {
		let id = encodeString(element["id"]);
		if (this.flags.isSaseCustomer) {
			this.router.navigateByUrl(`/site-management/action/edit-site/${id}`);
		} else {
			this.router.navigateByUrl(`/site-management/site-action/edit-site/${id}`);
		}
	}

	onDeleteSite(selectedSite: any): void {
		this.dataSource.selectedSite = selectedSite;
		this.dataSource.mappedDevices = selectedSite["devices"] ? selectedSite["devices"] : [];
		this.flags.isDeviceMappedWithSite = this.dataSource.mappedDevices.length;
		this.confirmDeletionTemplateRef = this.dialog.open(this.confirmDeletionTemplate, {
			width: "450px",
			disableClose: true
		});
	}

	onClickDeleteSite() {
		this.refreshProgress = true;
		this.doDeleteSite(this.dataSource.selectedSite);
	}

	onClickDeviceName(selectedDeviceDetails: any) {
		this.confirmDeletionTemplateRef.close();
		let id = encodeString(selectedDeviceDetails?.ciId);
		let url;
		 if (this.flags.isSaseCustomer) { 
		 	url = `/device-management/details/${id}`;
		 } else {
			url = `/device-management/spaed/details/${id}`;
		 }
		this.router.navigate([url]);
	}

	doDeleteSite(selectedSite: any) {
		this.siteManagementService.deleteSite(selectedSite.id).subscribe({
			next: (response) => {
				if (response.responseCode === 0) {
					this.toasterService.showSuccessMessage(INFO_MESSAGES.DELETE_SITE_SUCCESS);
					this.confirmDeletionTemplateRef.close();
					this.getSites(this.dataSource.pageOptions);
				} else {
					this.toasterService.showErrorMessage("Unable to proceed with the action , please try again later");
					this.confirmDeletionTemplateRef.close();
				}
				this.refreshProgress = false;
			},
			error: () => {
				this.refreshProgress = false;
				this.toasterService.showErrorMessage("Unable to proceed with the action , please try again later");
				this.confirmDeletionTemplateRef.close();
			}
		});
	}

	onViewSite(element: Element): void {
		let id = encodeString(element["id"]);
		if (this.flags.isSaseCustomer) {
			this.router.navigateByUrl(`/site-management/details/${id}`);
		} else {
			this.router.navigateByUrl(`/site-management/details/spaed/${id}`);
		}
	}

	onPageChanged(event: TableLazyLoadOptions) {
		this.showProgress = true;
		this.dataSource.pageOptions = event;
		this.dataSharedService.setPageIndex(event?.pageIndex);
		this.dataSharedService.setPageSize(event?.pageSize);
		this.setSortParam(event?.sortField, event?.direction);
		this.getSites(event);
		this.getAllOnlineDevice();
	}

	private getSites(
		page: pageOptions = { pageIndex: 0, pageSize: GlobalConstants.Default_PageSize },
		sort?: string
	): void {
		this.data = [];
		this.dataSource.sites = [];
		this.dataSource.unsortedSites = [];
		this.siteManagementService
			.getAllSites(page.pageIndex, page.pageSize, this.searchTerm, this.sortQuery)
			.subscribe({
				next: (response: any) => {
					if (response.responseCode === 0) {
						this.data = response.data.content.map((data: any) => {
							//temporary solution needs to be removed after unified screen
							if (this.flags.isSaseCustomer) {
								return {
									...data,
									...data.address,
									aliases: data?.aliases?.map(
										(aliasData: { id: number; name: string }) => aliasData.name
									)
								};
							} else {
								let latLongLocation = data?.latitude + ", " + data?.longitude;
								let isOnline =
									data?.devices?.length > 0
										? data?.devices[0]?.transientDetails?.controlPlaneConnected
										: false;
								let serialNum =
									data?.devices?.length > 0 ? data?.devices[0]?.manufacturerSerialNumber : "-";
								let macAddress = data?.devices?.length > 0 ? data?.devices[0]?.identifyingMac : "-";
								return {
									...data,
									...data.address,
									location: latLongLocation,
									deviceStatus: isOnline ? this.deviceStatus.online : this.deviceStatus.offline,
									aliases: data?.aliases?.map(
										(aliasData: { id: number; name: string }) => aliasData.name
									),
									manufacturerSerialNumber: serialNum,
									identifyingMac: macAddress
								};
							}
						});
						// this.data =  data;
						this.dataSource.unsortedSites = JSON.parse(JSON.stringify(this.dataSource.sites));
						this.pageIndex = response.data.number;
						this.pageSize = page.pageSize;
						this.paginator = response.data.pageable;
						this.totalSize = response.data.totalElements;
					}
					this.showProgress = false;
					this.refreshProgress = false;
				},
				error: () => {
					this.showProgress = false;
					this.refreshProgress = false;
				}
			});
	}

	onLinkClicked(event: any) {
		const siteListUrl = window.location.href;
		
			this.dataSharedService.setSelectedCustomer({
				label: event?.customerDisplayName,
				value: event?.customerId
			});
			this.commonService.setSiteCustomerId(event?.customerId);
			if (siteListUrl?.includes("network-management/sites")) {
				let id = encodeString(event?.id);
				this.flags.isSaseCustomer
					? this.router.navigateByUrl(`/site-management/action/edit-site/${id}`)
					: this.router.navigateByUrl(`/site-management/site-action/edit-site/${id}`);
			} 
			else if (event?.devices && event?.devices?.length > 0) {
				let id = encodeString(event?.id);
				let singleTenantSiteId = encodeString(event?.singleTenantSiteId);
				// this.router.navigate(["monitoring", "site-monitoring", singleTenantSiteId, id]);
				this.router.navigate(["monitoring", "site-monitoring", id]);
			} else {
				this.isShowOverlayCard = true;
			}
		
	}

	private setSortParam(active: string, direction: string): void {
		if (active && direction) {
			let sortParam = active;
			if (sortParam === "countryText") {
				sortParam = "address.country.name";
			} else if (sortParam === "cityText") {
				sortParam = "address.city.name";
			}
			this.sortQuery = `sort=${sortParam},${direction}`;
		} else {
			this.sortQuery = "";
		}
	}

	public search(value: string) {
		this.searchTerm = value;
		this.dataSharedService.setSearchTerm(value);
		this.showProgress = true;
		this.getSites();
	}

	onConfigurationWanAndLan(element: { [key: string]: any }): void {
		this.setContextPathAndKey(element);
		let isOnline =
			element?.["devices"]?.length > 0 ? element?.["devices"][0]?.transientDetails?.controlPlaneConnected : false;
		if (isOnline && element?.["devices"]?.length > 0) {
			let deviceId = element?.["devices"][0]?.singleTenantDeviceId;
			this.launchWebPopup(deviceId);
		} else {
			this.isShowOverlayCard = true;
		}
	}
	getAllOnlineDevice() {
		if (!this.flags.isAllSelected) {
			try {
				//this.commonService.getAllDevice().subscribe({
				//let	page: pageOptions = { pageIndex: 0, pageSize: 5 };
				this.siteManagementService.getAllMtcDevice().subscribe({
					next: (response: any) => {
						if (response?.data?.length > 0) {
							const devices = response?.data;
							this.flags.hasOnlineDevices = true;
							this.dataSource.onlineDeviceList = devices
								.filter(
									(data: any) =>
										!(
											data?.model?.name == null ||
											data?.manufacturerSerialNumber == null ||
											data?.singleTenantDeviceId == null ||
											data?.singleTenantDeviceId == undefined
										)
								)
								.map((data: any) => ({
									value: data,
									label: data?.model?.name + " " + data?.manufacturerSerialNumber
								}));
						} else {
							this.dataSource.onlineDeviceList = [];
							this.flags.hasOnlineDevices = false;
						}
						this.isOnlineDeviceAvailable = this.dataSource.onlineDeviceList?.length > 0 ? true : false;
						//this.showProgress = false;
					},
					error: (err: any) => {
						//this.showProgress = false;
						this.isOnlineDeviceAvailable = false;
					}
				});
			} catch (error) {
				this.isOnlineDeviceAvailable = false;
			}
		}
	}

	onClickDownloadConfigReport(): void {
		this.selectedDevice = [];
		if (this.dataSource?.onlineDeviceList?.length > 0) {
			this.dialog
				.open(this.attributesTemplate, {
					width: "750px",
					data: {},
					autoFocus: false,
					disableClose: true
				})
				.afterClosed()
				.subscribe((isAttributesSelected: boolean) => {
					if (isAttributesSelected) {
						this.onApplyFilters();
					}
				});
		} else {
			this.isShowOverlayCard = true;
		}
	}

	onApplyFilters(): void {
		//   do nothing
	}

	downloadConfigReport() {
		let deviceIds = this.selectedDevice;
		if (deviceIds.length == 0) {
			return;
		} else {
			const message = INFO_MESSAGES.DOWNLOAD_CONFIG_CONFIRMATION;
			const data = new ConfirmDialogModel("Confirm Action", message);
			const dialogReference = this.dialog.open(ConfirmDialogComponent, {
				maxWidth: "450px",
				data,
				disableClose: true
			});
			dialogReference.afterClosed().subscribe((agree: any) => {
				if (agree) {
					const devicePayload = {
						deviceId: deviceIds
							.filter(
								(device) =>
									device.value?.singleTenantDeviceId != null &&
									device.value?.singleTenantDeviceId != undefined
							)
							.map((devicesIds) => devicesIds.value?.singleTenantDeviceId)
					};
					this.downloadTemplate(devicePayload);
				}
			});
		}
	}
	onDownloadConfig(element: { [key: string]: any }): void {
		this.setContextPathAndKey(element);
		if (element?.["devices"]?.length > 0) {
			//let deviceId = element?.["devices"][0]?.id;
			let deviceId = element?.["devices"][0]?.singleTenantDeviceId;
			const devicePayload = {
				deviceId: [deviceId]
			};
			const message = INFO_MESSAGES.DOWNLOAD_CONFIG_CONFIRMATION;
			const data = new ConfirmDialogModel("Confirm Action", message);
			const dialogReference = this.dialog.open(ConfirmDialogComponent, {
				maxWidth: "450px",
				data,
				disableClose: true
			});
			dialogReference.afterClosed().subscribe((agree: any) => {
				if (agree) {
					this.downloadTemplate(devicePayload);
				}
			});
		} else {
			this.isShowOverlayCard = true;
		}
		//this.downloadTemplate(devicePayload);
	}

	downloadTemplate(payload: any): void {
		this.downloadProgress = true;
		this.siteManagementService.getDownloadConfigReport(payload).subscribe({
			next: (event: any) => {
				if (event.type === HttpEventType.DownloadProgress) {
				}
				if (event.type === HttpEventType.Response) {
					const a = document.createElement("a");
					const objectUrl = URL.createObjectURL(event.body);
					a.href = objectUrl;
					/*
					if(payload?.deviceId?.length > 1){
						a.download = 'device_config.zip';
					} else {						
						a.download = 'device_config.xlsx';
					}
					*/
					var contentDisposition = event.headers.get("Content-Disposition");
					if (contentDisposition != null && contentDisposition != undefined) {
						var filename = contentDisposition.split(";")[1].split("filename")[1].split("=")[1].trim();
						a.download = new Date().toISOString() + "_" + filename;
					} else {
						if (payload?.deviceId?.length > 1) {
							a.download = `device_config_${new Date().toISOString()}.zip`;
						} else {
							a.download = `device_config_${new Date().toISOString()}.xlsx`;
						}
					}

					a.click();
					URL.revokeObjectURL(objectUrl);
					this.downloadProgress = false;
				}
			},
			error: (err: any) => {
				this.downloadProgress = false;
				if (err != null && err != undefined && err.indexOf("404")) {
					this.toasterService.showErrorMessage(INFO_MESSAGES.API_ERR_MESSGE);
				} else {
					this.toasterService.showErrorMessage(err);
				}
			}
		});
	}

	onDeviceDropdownClosed(event: any) {
		this.selectedDevice = event.value;
	}

	onConfigurationsClicked(element: { [key: string]: any }): void {
		this.setContextPathAndKey(element);
		//if (element?.["currentRouterUnits"]?.length > 0) {
		if (element["online"]) {
			//let deviceId = element?.["currentRouterUnits"][0]?.id;
			let deviceId = encodeString(element?.["devices"][0]?.singleTenantDeviceId);
			this.router.navigateByUrl(`/configuration-management/configure-device/${deviceId}`);
		} else {
			this.isShowOverlayCard = true;
		}
	}

	sortSites(event: any) {
		let sites = this.dataSource.sites;
		//sorting
		if (event.data.direction == "asc") {
			sites = sites.sort((a: any, b: any) => {
				return Number(b.online) - Number(a.online);
			});
		} else if (event.data.direction == "desc") {
			sites = sites.sort((a: any, b: any) => {
				return Number(a.online) - Number(b.online);
			});
		} else {
			this.dataSource.sites = JSON.parse(JSON.stringify(this.dataSource.unsortedSites));
			setTimeout(() => {
				this.showProgress = false;
			}, 1000);
			return;
		}
		this.dataSource.sites = JSON.parse(JSON.stringify(sites));
		setTimeout(() => {
			this.showProgress = false;
		}, 1000);
	}

	setContextPathAndKey(element: any) {
		if (element?.customer?.contextPath && element?.customer?.secretKey) {
			this.dataSharedService.setSpaedBasePath(element.customer.contextPath);
			this.dataSharedService.setCustomerContextPath(element.customer.contextPath);
			this.dataSharedService.setCustomerSecretKey(element.customer.secretKey);
		}
	}

	onClickNetworkSummary() {
		if (this.siteModel.selectedCustomerId) {
			let id = encodeString(this.siteModel.selectedCustomerId);
			this.router.navigateByUrl(`/customer-management/network-summary/${id}`);
		}
	}

	//Patch fix for agent tunnel  Configuration implementation START
	onConfigureTunnel(element: any) {
		this.createTunnelConfigurationForm();
		this.dialog.open(this.tunnelConfigurationFormTemplate, {
			width: "650px",
			data: {},
			autoFocus: true,
			disableClose: true
		});
		// .afterClosed()
		// .subscribe((isAttributesSelected: boolean) => {
		// });
		this.siteModel.siteId = element.id;
		this.siteModel.customerId = element.customerId;
		this.getTunnelConfigurationFormValues();
	}

	createTunnelConfigurationForm() {
		this.tunnelConfigurationForm = this.fb.group({
			privateIp: this.fb.control<string | null>("", {
				validators: [Validators.required, Validators.pattern(GlobalConstants.IP_WITH_OPTIONAL_SUBNET_PATTERN)]
			}),
			privatePort: ["", Validators.required],
			privatePublicKey: ["", Validators.required],
			privateMtu: this.fb.control<string | null>("", {
				validators: [Validators.pattern(GlobalConstants.NUMER_PATTERN)]
			}),
			privateTunnel: ["", [Validators.pattern(GlobalConstants.IP_WITH_OPTIONAL_SUBNET_PATTERN)]],
			tunnelName: ["", Validators.required],
			publicIp: this.fb.control<string | null>("", {
				validators: [Validators.pattern(GlobalConstants.IP_PATTERN)]
			}),
			publicPort: [""],
			publicPublicKey: [""],
			publicMtu: this.fb.control<string | null>("", {
				validators: [Validators.pattern(GlobalConstants.NUMER_PATTERN)]
			}),
			publicTunnel: ["", Validators.pattern(GlobalConstants.IP_PATTERN)]
		});
	}

	onResetForm() {
		let message;
		message = INFO_MESSAGES.RESET_CHANGES;
		const data = new ConfirmDialogModel("Confirm Action", message);
		const dialogReference = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: "450px",
			data,
			disableClose: true
		});
		dialogReference.afterClosed().subscribe((agree: any) => {
			if (agree) {
				this.tunnelConfigurationForm.reset();
			}
		});
	}

	onCancelClicked() {}

	onSubmitTunnelConfigForm() {
		if (this.tunnelConfigurationForm.valid) {
			// copilot coverage
			// :search string in copilot. group private and public into two different objects
			// Perform form submission logic here
			let privateConfig = {
				// need to change the form object to remove the below unwanted code
				tunnelName: this.tunnelConfigurationForm.get("tunnelName")?.value,
				ip: this.tunnelConfigurationForm.get("privateIp")?.value,
				port: this.tunnelConfigurationForm.get("privatePort")?.value,
				publicKey: this.tunnelConfigurationForm.get("privatePublicKey")?.value,
				overlayIp: this.tunnelConfigurationForm.get("privateTunnel")?.value,
				mtu: this.tunnelConfigurationForm.get("privateMtu")?.value
			};

			let publicConfig = {
				ip: this.tunnelConfigurationForm.get("publicIp")?.value,
				port: this.tunnelConfigurationForm.get("publicPort")?.value,
				publicKey: this.tunnelConfigurationForm.get("publicPublicKey")?.value,
				overlayIp: this.tunnelConfigurationForm.get("publicTunnel")?.value,
				mtu: this.tunnelConfigurationForm.get("publicMtu")?.value
			};

			let payload = {
				siteId: this.siteModel?.siteId,
				privateTunnel: privateConfig
				// publicTunnel: publicConfig
			};
			this.doSaveOrUpdateTunnelConfiguration(payload);
			// copilot coverage
		} else {
			// Handle form validation errors
		}
	}

	doSaveOrUpdateTunnelConfiguration(payload: any) {
		// :search string in copilot. geneate callback for post payload
		this.siteManagementService.saveorupdateTunnelConfig(payload).subscribe({
			next: (response: any) => {
				// Handle the response from the API
				if (response.responseCode === 0) {
					this.toasterService.showSuccessMessage(INFO_MESSAGES.TUNNEL_CONFIG_SUCCESS);
					this.dialog.closeAll();
				}
			},
			error: (error: any) => {
				this.toasterService.showErrorMessage(INFO_MESSAGES.TUNNEL_CONFIG_ERR);
				// Handle the error from the API
			}
		});
	}

	getTunnelConfigurationFormValues() {
		this.siteManagementService.getTunnelConfigById(this.siteModel?.siteId, this.siteModel?.customerId).subscribe({
			next: (response: any) => {
				// Handle the response from the API
				if (response.responseCode === 0) {
					this.tunnelConfigModel = response.data;
					this.tunnelConfigurationForm.patchValue({
						privateIp: this.tunnelConfigModel?.privateTunnel?.ip,
						privatePort: this.tunnelConfigModel?.privateTunnel?.port,
						privatePublicKey: this.tunnelConfigModel?.privateTunnel?.publicKey,
						privateTunnel: this.tunnelConfigModel?.privateTunnel?.overlayIp,
						privateMtu: this.tunnelConfigModel?.privateTunnel?.mtu,
						tunnelName: this.tunnelConfigModel?.privateTunnel?.tunnelName
						// publicIp: this.tunnelConfigModel?.publicTunnel?.ip,
						// publicPort: this.tunnelConfigModel?.publicTunnel?.port,
						// publicPublicKey: this.tunnelConfigModel?.publicTunnel?.publicKey,
						// publicTunnel: this.tunnelConfigModel?.publicTunnel?.overlayIp,
						// publicMtu: this.tunnelConfigModel?.publicTunnel?.mtu
					});
				}
			},
			error: () => {
				// Handle the error from the API
			}
		});
	}

	ngOnDestroy(): void {
		this.customerChange$?.unsubscribe();
	}
}
