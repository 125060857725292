<div class="toolbar header-bg gotham-book-regular container-fluid zcss">
	<div id="menu-lists" class="col-md-10">
		<ul>
			<li class="left-list">
				<div class="partner-logo logo" *ngIf="headerModel?.userProfile?.isPartnerUser">
					<img [src]="headerModel?.userProfile?.logo" height="35px" margin-top="-6px" />
				</div>
				<div class="logo" *ngIf="!headerModel?.userProfile?.isPartnerUser">
					<img [src]="headerModel?.userProfile?.logo" />
				</div>
			</li>
			<li class="left-list">
				<div class="border-right"></div>
			</li>
			<!-- {{dataSource.menus | json}} -->
			<li *ngFor="let menu of dataSource.menus" class="left-list nav-item">
				<div
					class="pointer parent-menu-label {{ menu?.id === 49 ? 'user_menu' : '' }}"
					routerLinkActive="active-menu gotham-bold-regular"
					[routerLinkActiveOptions]="{ exact: true }"
					(click)="goto(menu.url)">
					{{ menu?.label }}
					<span class="car_img" *ngIf="menu.subMenu?.length > 0">
						<img src="./assets/images/header/carret.svg" />
					</span>
				</div>
				<ul class="submenu dropdown-menu" *ngIf="menu.subMenu?.length > 0">
					<li *ngFor="let submenu of menu.subMenu">
						<div class="submenu-container">
							<a class="dropdown-item" [routerLinkActive]="'cus_active'" (click)="goto(submenu.url)">
								{{ submenu?.label }}
							</a>
						</div>
					</li>
				</ul>
			</li>
		</ul>
	</div>
	<div id="icon-list" class="col-md-2">
		<ul>
			<!-- <li class="right-list">
				<div class="icon-bell">
					<img class="header-device-icon" src="./assets/images/header/Header_Notification Bell Icon.svg" />
				</div>
			</li>

			<li class="right-list">
				<div class="icon-device cursor-pointer" [matMenuTriggerFor]="deviceCount">
					<img class="header-device-icon" src="./assets/images/header/Header_Device Icn.svg" />
				</div>
			</li>

			<li class="right-list">
				<div class="icon-mail">
					<img class="header-device-icon" src="./assets/images/header/Header_Mail icon.svg" />
				</div>
			</li>-->

			<li class="right-account-list">
				<div class="icon-mail header-account" (click)="openMenu(profileAccountMenu)">
					<span>{{ headerModel.userProfile?.loginName }}</span>
				</div>
				<i
					class="fa cursor-pointer"
					aria-hidden="true"
					[matMenuTriggerFor]="accountSetting"
					#profileAccountMenu="matMenuTrigger"
					[ngClass]="{
						'fa-angle-down': !profileAccountMenu.menuOpen,
						'fa-angle-up': profileAccountMenu.menuOpen
					}"></i>
			</li>
		</ul>

		<mat-menu #accountSetting="matMenu" [overlapTrigger]="false" class="account-menu">
			<mat-list>
				<mat-list-item>
					<div
						mat-line
						[appTooltip]="headerModel?.userProfile?.displayName"
						class="text-center profile-label">
						<strong>{{ headerModel?.userProfile?.displayName }}</strong>
					</div>
					<div
						mat-line
						[appTooltip]="headerModel?.userProfile?.emailId"
						class="text-center text-overflow profile-label">
						{{ headerModel?.userProfile?.emailId }}
					</div>
				</mat-list-item>
				<mat-divider></mat-divider>
				<mat-list-item>
					<button mat-menu-item>
						<button [routerLink]="['account-management/profile']" mat-menu-item>
							<mat-icon>person</mat-icon>
							<span routerLinkActive="active" class="label fs-14">View Profile</span>
						</button>
					</button>
				</mat-list-item>
				<mat-divider></mat-divider>
				<mat-list-item class="logout">
					<button
						(click)="onLogout()"
						mat-line
						appTooltip="Logout"
						class="mt-2 logout-btn"
						mat-flat-button
						color="primary">
						<mat-icon>logout</mat-icon>
						<span routerLinkActive="active" class="label">Logout</span>
					</button>
				</mat-list-item>
			</mat-list>
		</mat-menu>
		<mat-menu #deviceCount="matMenu" [overlapTrigger]="false" class="device-menu" *ngIf="false">
			<mat-list>
				<ng-container *ngFor="let deviceStatus of dataSource.deviceCount">
					<mat-list-item>
						<div class="profile-label" (click)="onNaviagteToDeviceList(deviceStatus.status)">
							<strong>{{ deviceStatus.status }}</strong>
							: {{ deviceStatus.count }}
						</div>
					</mat-list-item>
					<mat-divider></mat-divider>
				</ng-container>
			</mat-list>
		</mat-menu>
	</div>
</div>
