<div class="show-all-widget-data">
	<div class="header-text row d-flex align-items-center m-0">
		<div class="col-10 pt-3">
			<h6>{{ title }}</h6>
		</div>
		<div class="col-2 text-end">
			<button mat-icon-button [mat-dialog-close]="true" [disableRipple]="true">
				<mat-icon class="text-end attributes-cls-btn">cancel</mat-icon>
			</button>
		</div>
	</div>

	<div mat-dialog-content class="fs-14">
		<div class="d-flex justify-content-end offset-7 col-5">
			<app-search-input
				*ngIf="!isDataLoading"
				(setSearchValue)="search($event)"
				[dataParams]="dataParams"></app-search-input>
		</div>
		<div>
			<app-custom-data-table
				[settings]="{
					data: tableDataSource,
					columnConfig: dataSource.columnConfig,
					length: tableDataSource.length,
					isClientSidePagination: true,
					loaderFlags: {
						isLoading: isDataLoading,
						isErrorFetchingData: false
					},
					hidePagination: false,
					recordsPerPage: recordsPerPage,
					pageSize: pageSize,
				}"></app-custom-data-table>
		</div>
		<!-- <app-data-table
			[showPaginator]="!isDataLoading"
			[isClientSidePagination]="true"
			[recordsPerPage]="recordsPerPage"
			[pageSize]="pageSize"
			[totalSize]="totalSize"
			[columns]="columns"
			[data]="tableDataSource"
			[isDataLoading]="isDataLoading"></app-data-table> -->
	</div>
</div>
