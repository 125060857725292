<div *ngIf="data.length !==0 ; else noDataTemplate">

	<div class="row p-3">
		<div class="col-md-3">
			<div class="row bold-heading">&nbsp;</div>
			<div class="row row-headers carousel-element" *ngFor="let item of rowHeaders">
				{{item}}
			</div>
		</div>
		<div class="col-md-9 pl-0 pr">

			<div *ngIf="data.length <= 4">
				<div class="row">
					<div *ngFor="let x of data" class="content-item plr-0"
						[ngClass]="{ 'col-md-12': (data.length === 1), 'col-md-6': (data.length === 2), 'col-md-3': (data.length === 4), 'col-md-4': (data.length === 3)  }">
						<div *ngFor="let item of x | keyvalue; let first = first; let last = last"
							[ngClass]="{'bold-heading': first, 'content-item-border carousel-element': !first }">
							{{x[item]}}
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="data.length > 4">
				<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
					<div ngxSlickItem *ngFor="let x of data; let i = index" class="slide content-item">
						<div>
							<div *ngFor="let item of x | keyvalue; let first = first; let last = last"
								[ngClass]="{'bold-heading': first, 'content-item-border carousel-element': !first }">
								{{x[item]}}
							</div>
						</div>
					</div>
				</ngx-slick-carousel>
			</div>

			<div class="next-btn" *ngIf="data.length > 4">
				<button class="circle-button" (click)="next()">
					<i class="fa fa-angle-right"></i>
				</button>
			</div>

		</div>
	</div>

</div>

<ng-template #noDataTemplate>
	<div class="row no-data-content align-items-center">
		<div class="col-12 text-center">{{NO_DATA_FOUND}}</div>
	</div>
</ng-template>