import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class BreadcrumbsService {
	private menuClickEvent: any = new BehaviorSubject<any>(null);
	menuClickEventObservable: any = this.menuClickEvent.asObservable();
	constructor() {}

	onMenuClickEvent(event: any) {
		this.menuClickEvent.next(event);
	}
}
