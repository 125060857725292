<div
	*ngIf="flags?.showScrollToBottomBtn"
	class="scroll-con"
	(click)="onClickScrollDown()"
	(mouseover)="onHoverIcon()"
	(mouseleave)="onRemoveHover()"
	appTooltip="Go to bottom">
	<i class="fa fa-angle-double-down scroll-icon"></i>
</div>
<div
	*ngIf="flags?.showScrollToTopBtn"
	class="scroll-con"
	(click)="onClickScrollUp()"
	(mouseover)="onHoverIcon()"
	(mouseleave)="onRemoveHover()"
	appTooltip="Go to top">
	<i class="fa fa-angle-double-up scroll-icon double-up-icon"></i>
</div>
