import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { CommonService } from "../services/common.service";
import { Injectable } from "@angular/core";

@Injectable()
export class CustomValidators {
	constructor(private commonService: CommonService) {}

	ipWithSubnetMaskValidator(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
			if (control.value && (control.dirty || control.touched)) {
				const payload = {
					ipAddresses: control.value
				};
				return this.commonService.ipWithSubnetMaskValidation(payload).pipe(
					map((response: any) => {
						if (response?.responseCode === 0) {
							return response?.data?.valid ? null : { inValidIp: true };
						} else {
							return null;
						}
					})
				);
			}
			return of(null);
		};
	}
}
