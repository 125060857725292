export class GlobalConstants {
	public static readonly USER_NAME_PATTERN = /^[a-zA-Z][a-zA-Z0-9._-]*(@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})?$/;
	public static readonly ALPHANUMERIC_DOT_UNDERSCORE_AT_COMMA_PATTERN = /^[\w.,& @-]*$/;
	public static readonly ALPHANUMERIC_DOT_UNDERSCORE_AT_PATTERN = /^[\w.& @-]*$/;
	public static readonly ALPHANUMERIC_DOT_UNDERSCORE_PATTERN = /^[\w.& -]*$/;
	public static readonly DISPLAY_NAME_PATTERN = /^[\w.& @-]*$/;
	public static readonly ALPHANUMERIC_PATTERN = /^[a-zA-Z0-9]*$/;
	public static readonly ALPHANUMERIC_HYPHEN_PATTERN = /^[a-zA-Z0-9-]*$/;
	public static readonly NUMER_HYPHEN_PATTERN = /^[0-9-]+$/;
	public static readonly NUMER_PATTERN = /^[0-9]+$/;
	public static readonly PORT_PATTERN =
		/^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;
	public static readonly ALPHANUMERIC_UNDERSCORE_AT_PATTERN = /^[\w@-]*$/;
	public static readonly ALPHANUMERIC_UNDERSCORE_WITHSPACE_AT_PATTERN = /^[A-Za-z0-9- ]+$/;
	public static readonly ALPHANUMERIC_WITHOUTANDPATTERN = /^[\w.@-]*$/;
	public static readonly CONTACTNUMBER_PATTERN = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
	public static readonly IPV6_PATTERN =
		/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$|^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;
	public static readonly IPV4_PATTERN =
		/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
	//public static readonly MAC_ADDRESS_PATTERN = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}.[0-9a-fA-F]{4}.[0-9a-fA-F]{4})$/;
	public static readonly MAC_ADDRESS_PATTERN = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
	public static readonly IP_PATTERN =
		/((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
	public static readonly SECRET_KEY = "&2GhR)l1k<?9Xf8D";
	public static readonly FLOAT_PATTERN = /^[+-]?\d+(\.\d+)?$/;
	public static readonly SITE_DEFAULT = { label: "All", value: 0 };
	public static readonly COUNTRY_DEFAULT = { label: "All", value: "" };
	public static readonly RESPONSE_SUCCESS_CODE = 0;
	public static readonly CUSTOMER_DEFAULT = { label: "All", value: 0 };
	public static readonly ALIAS_PATTERN = /^[a-zA-Z]+[a-zA-Z0-9_]{1,49}$/;
	public static readonly bps = { label: "bps", value: "bps" };
	public static readonly Kbps = { label: "Kbps", value: "Kbps" };
	public static readonly Mbps = { label: "Mbps", value: "Mbps" };
	public static readonly Gbps = { label: "Gbps", value: "Gbps" };
	public static readonly Tbps = { label: "Tbps", value: "Tbps" };
	public static readonly bytes = { label: "Bytes", value: "Bytes" };
	public static readonly KB = { label: "KB", value: "KB" };
	public static readonly MB = { label: "MB", value: "MB" };
	public static readonly GB = { label: "GB", value: "GB" };
	public static readonly TB = { label: "TB", value: "TB" };
	public static readonly unitsInBytes = [
		GlobalConstants.bytes,
		GlobalConstants.KB,
		GlobalConstants.MB,
		GlobalConstants.GB,
		GlobalConstants.TB
	];
	public static readonly unitsInBits = [
		GlobalConstants.bps,
		GlobalConstants.Kbps,
		GlobalConstants.Mbps,
		GlobalConstants.Gbps,
		GlobalConstants.Tbps
	];
	public static readonly netMaskPattern =
		/^(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$/;
	public static readonly portRangePattern =
		/^(6553[0-5]|655[0-2][0-9]|65[0-4][0-9]{2}|6[0-4][0-9]{3}|[1-5][0-9]{4}|[0-9]{1,4})$|^(6553[0-5]|655[0-2][0-9]|65[0-4][0-9]{2}|6[0-4][0-9]{3}|[1-5][0-9]{4}|[0-9]{1,4})-(6553[0-5]|655[0-2][0-9]|65[0-4][0-9]{2}|6[0-4][0-9]{3}|[1-5][0-9]{4}|[0-9]{1,4})$/;
	public static readonly ipPattern = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
	public static readonly gatewayPattern = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
	public static readonly serviceTypeSase = "SASE";
	public static readonly serviceTypeSpaed = "SPAED";
	public static readonly STC_ORIGIN = "STC";
	public static readonly PROM_ORIGIN = "PROM";
	public static readonly ISO_VERSION_PATTERN = /^v[0-9]*(?:\.[0-9]{0,4})*(?:\.[0-9]{0,4})?$/;
	public static readonly ALPHA_NUMER_HYPHEN_PATTERN = /^[a-zA-Z0-9-]+$/;
	public static readonly DOMAIN_PATTERN =
		/^(?:(?:\*\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,},)*(?:[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|(?:\*\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
	public static readonly Default_PageSize = 50;
	public static readonly EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	public static readonly NON_ZERO_INTERGERPATTERN = /^[1-9]\d*$/;
	public static readonly INTERGERPATTERN = /^[0-9]\d*$/;
	public static readonly DESCRIPTION_MAX_LENGTH = 800;
	public static readonly VERSION_PATTERN = /(v\d+\.\d+\.\d+(-[^\s.]+)?)$/i;
	public static readonly AGENT_NETWORK_ADDRESS_PATTERN =
		"^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/([0-9]|[1-2][0-9]|3[0-2])$";
	public static readonly IP_WITH_OPTIONAL_SUBNET_PATTERN = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(\/\d{1,2})?$/; // Copilot
	public static readonly breakOutFilterOptions = [
		{ label: "Local Breakout", value: "0" },
		{ label: "Edge Breakout", value: "1" },
		{ label: "Private Traffic", value: "2" }
	];
}

export const INFO_MESSAGES = {
	API_ERR_MESSGE: "Unable to proceed with the action , please try again later or contact your system Administrator",
	ERR_LOADING_WIDGET: "We are having trouble loading this widget right now. Please try again later.",
	ERR_LOADING_DASHBOARD: "We are having trouble loading the dashboard right now. Please try again later.",
	FORM_ERR: "There are few errors in the form, please correct the error and try submitting again",
	LAN_FORM_SAVE_SUCCESS: "LAN configurations are saved successfully",
	LAN_FORM_APPLY_SUCCESS: "LAN configurations are applied successfully",
	SAVE_ERROR: "We couldn't process your request at this moment. Please try again later",
	FORM_SAVE_CONFIRMATION: "Are you sure you want to apply changes?",
	LAN_FORM_SAVE_CONFIRMATION: "Are you sure you want to save changes?",
	LAN_FORM_APPLY_CONFIRMATION: "Are you sure you want to apply changes?",
	LAN_FETCH_ERROR: "We are having trouble in fetching LAN configuration. Please try again later.",
	WAN_FETCH_ERROR: "We are having trouble in fetching WAN/rules configuration. Please try again later.",
	SEN_WAN_FETCH_ERROR: "We are having trouble in fetching service edge configuration. Please try again later.",
	WAN_FORM_SAVE_SUCCESS: "WAN configurations are applied successfully",
	WAN_RULRES_FORM_SAVE_SUCCESS: "WAN configurations are applied successfully",
	CONFIGURATIONS_FORM_SAVE_SUCCESS: "configurations are applied successfully",
	CONFIGURATIONS_FORM_DELETE_SUCCESS: "configurations are deleted successfully",
	CONFIGURATIONS_TEMPLATE_FORM_SAVE_SUCCESS: "configurations template has been created successfully",
	WAN_RULES_FORM_SAVE_ERR: "We are having trouble in saving WAN rules configuration. Please try again later.",
	WAN_OFFLINE_CONFIGURATION_RULES_FORM_SUCCESS:
		"Configurations are saved successfully. It will apply when device comes to online.",
	LAN_OFFLINE_CONFIGURATION_RULES_FORM_SUCCESS:
		"Configurations are saved successfully. It will apply when device comes to online.",
	SAVE_FORM_CONFIRMATION: "Are you sure you want to save changes?",
	APPLY_FORM_CONFIRMATION: "Are you sure you want to apply changes?",

	CREATE_CUSTOMER_ACCOUNT_SUCCESS: "Customer has been created successfully",
	UPDATE_CUSTOMER_ACCOUNT_SUCCESS: "Customer has been updated successfully",
	CREATE_CUSTOMER_ACCOUNT_CONFIRMATION: "Are you sure you want to create the customer?",
	UPDATE_CUSTOMER_ACCOUNT_CONFIRMATION: "Are you sure you want to update the customer?",
	CREATE_EDIT_CUSTOMER_ACCOUNT_ERROR: "Unable to save the customer at this moment. Try again later",

	DELETE_SITE_CONFIRMATION: "Are you sure you want to delete the site?",
	DELETE_SITE_SUCCESS: "Site is deleted successfully.",
	SITE_SAVE_SUCCESS: "Site saved successfully",
	SITE_SAVE_ERROR: "Unable to save site at this moment, please try again later",
	CREATE_SITE_CONFIRMATION: "Are you sure you want to create the site?",
	UPDATE_SITE_CONFIRMATION: "Are you sure you want to update the site?",
	MONITOR_SITE_WO_DEVICE_MAPPED_MSG: "Monitoring details is not available as no device is mapped.",
	DEVICE_CONFIG_OFFLINE_ERR: "Device configuration cannot be done as it is offline",
	DEVICE_OFFLINE_ERR: "Device is currenlty offline! cannot proceed...",
	LAUNCH_WEB_CONSOLE_POPUP_TEXT: "Launch Device web console",
	DEVICE_CONFIG_NOTAVAILABLE_ERR: "Device is not available !",
	DEVICE_CONFIG_DEVICE_NOT_AVAILABLE_ERR: "Device configuration cannot be done as device(s) are not available.",

	DEVICE_TRACK_DEVICE_CONFIGURE_CONFIRMATION: "Are you sure you want to configure the device?",
	DEVICE_TRACK_DEVICE_SHIP_CONFIRMATION: "Are you sure you want to ship the device?",
	DEVICE_TRACK_DEVICE_RCVD_CONFIRMATION: "Are you sure you have received the device?",
	DEVICE_TRACK_DEVICE_APPROVE_CONFIRMATION: "Are you sure you want to activate the device?",
	DEVICE_CREATE_SUCCESS: "Device created successfully.",
	DEVICE_CREATE_EDIT_ERROR: "Failed to save device at this moment, please try again later",
	DEVICE_CREATE_CONFIRMATION: "Are you sure you want to create the device?",
	DEVICE_UPDATE_CONFIRMATION: "Are you sure you want to update the device?",
	UNAUTHENTICATED_DEVICE_MONITORING_MSG: "Device configuration cannot be done as it is unauthenticated.",
	DELETE_HOST_CONFIRMATION: "Are you sure you want to deactivate the host?",
	HOST_CREATE_CONFIRMATION: "Are you sure you want to create the host?",
	HOST_UPDATE_CONFIRMATION: "Are you sure you want to update the host?",
	HOST_CREATE_SUCCESS: "Host is added successfully",
	HOST_CREATE_EDIT_ERROR: "Failed to save host at this moment, please try again later",
	DELETE_HOST_SUCCESS: "Host is deleted successfully.",
	DELETE_CONTROLLER_CONFIRMATION: "Are you sure you want to delete the controller instance?",
	CONTROLLER_CREATE_CONFIRMATION: "Are you sure you want to create the controller instance?",
	CONTROLLER_UPDATE_CONFIRMATION: "Are you sure you want to update the controller instance?",
	CONTROLLER_CREATE_SUCCESS: "Controller instance is added successfully",
	CONTROLLER_CREATE_EDIT_ERROR: "Failed to save controller instance at this moment, please try again later",
	DELETE_CONTROLLER_SUCCESS: "Controller instance is deleted successfully.",

	SEN_OFFLINE_ERR_MSG: "Device is Offline!. Unable to load Wan Configurations Details",
	SERVICE_EDGE_OFFLINE_MONITORING_MSG: "Service Edge configuration cannot be done as it is Offline.",
	SERVICE_EDGE_CREATE_SUCCESS: "Service edge created successfully.",
	SERVICE_EDGE_UPDATE_SUCCESS: "Service edge updated successfully.",
	SERVICE_EDGE_ERROR: "Failed to save service edge details at this moment, please try again later",
	SERVICE_EDGE_CREATE_CONFIRMATION_MESSAGE: "Are you sure you want to create the service edge?",
	SERVICE_EDGE_UPDATE_CONFIRMATION_MESSAGE: "Are you sure you want to update the service edge?",

	POLICY_SAVE_CONFIRMATION: "Are you sure you want to save the policie(s)?",
	POLICY_APPLY_CONFIRMATION: "Are you sure you want to apply the policie(s)?",
	POLICY_SAVE_SUCCESS: "Policy saved successfully",
	POLICY_APPLY_SUCCESS: "Policy applied successfully",
	POLICY_APPLY_ERROR: "Failed to make this action at this moment, please try again later",
	POLICY_DELETE_CONFIRMATION: "Are you sure you want to delete the policy?",
	POLICY_CANCEL_EDIT: "Are you sure you want to discard the changes?",
	POLICY_RESET_CONFIRMATION: "Are you sure you want to discard the unsaved changes?",
	TEMPLATE_DELETE_CONFIRMATION: "Are you sure you want to delete the template configuration?",
	TEMPLATE_AND_DEVICE_DELETE_CONFIRMATION: "Are you sure you want to delete the template and device configuration?",

	TUNNEL_DELETE_WARNING_MESSAGE: "Are you sure you want to delete datapath of ",
	TUNNEL_DELETE_SUCCESS: "Datapath deleted successfully of ",
	TUNNEL_DELETE_FAILED: "Unable to delete the data path at this moment. Please try again later.",

	POD_DELETE_WITH_TUNNEL: "Tunnels are attached with the POD. Are you sure you want to delete the POD ?",
	POD_DELETE_WITHOUT_TUNNEL: "Are you sure you want to delete the POD ?",
	POD_DELETE_SUCCESS: "POD deleted successfully",
	POD_DELETE_FAILED: "Unable to delete the POD at this moment. Please try again later.",

	USER_CREATE_NO_CUSTOMER_SELECTED_WARNING:
		"You haven't selected any customer and therefore by default all the customers will be assigned to this user.",
	USER_CREATE_CONFIRMATION: "Are you sure you want to create a user?",
	USER_UPDATE_CONFIRMATION: "Are you sure you want to update a user?",
	USER_CREATE_SUCCESS: "User has been created successfully",
	USER_UPDATE_SUCCESS: "User details has been updated successfully",
	USER_CREATE_UPDATE_ERROR: "Unable to save the user details at this moment. Try again later.",
	USER_RESET_PWD_CONFIRMATION: "Are you sure you want to reset password for the user?",
	USER_ENABLE_CONFIRMATION: "Are you sure you want to enable user?",
	USER_DISABLE_CONFIRMATION: "Are you sure you want to disable user?",
	USER_DEACTIVATED_SUCCESS: "User has been deactivated successfully",
	USER_UPDATE_STATUS_ERROR: "Unable to update user status, please try again later.",

	NO_DATA_FOUND: "No data found!",
	LOADING_BASE_MSG: "Loading in progress...",
	LOADING_MSG_1: "Please wait, we are trying to fetch records...",
	LOADING_MSG_2: "It is taking more time than expected! Please hold on...",
	PAGINATION_INCORRECT_PAGE_NO: "Please provide correct page number",
	LEAVE_PAGE: "Are you sure you want to leave this page?",
	DEVICE_NAME_ALREADY_EXISTS: "Device name already exists",
	DEVICE_ALREADY_EXISTS: "Device details already exist",
	DEVICE_BULK_EXISTS: "The following records already exists : ",
	RESET_CHANGES: "Are you sure you want to discard the unsaved changes?",

	NO_DEVICE_CONFIGURED: "No device(s) configured.",
	STABLE_SYSTEM: "System is stable.",
	STABLE_CONNECTION: "Connection is stable.",
	NO_NETWORK_TRAFFIC: "No traffic identified  on the network.",
	NO_HOST_NETWORK_TRAFFIC: "No traffic identified on host network.",
	NO_TENANT_NETWORK_TRAFFIC: "No traffic identified on any of the tenant(s) network.",

	DEACTIVATE_CUSTOMER_ACCOUNT_CONFIRMATION: "Are you sure you want to deactivate the customer?",
	DEACTIVATE_CUSTOMER_ACCOUNT_SUCCESS: "Customer has been deactivated successfully.",
	DEACTIVATE_CUSTOMER_ACCOUNT_ERROR: "Unable to deactivate the customer at this moment. Please try again later",
	ACTIVATE_CUSTOMER_ACCOUNT_CONFIRMATION: "Are you sure you want to activate the customer?",
	ACTIVATE_CUSTOMER_ACCOUNT_SUCCESS: "Customer has been activated successfully.",
	ACTIVATE_CUSTOMER_ACCOUNT_ERROR: "Unable to activate the customer at this moment. Please try again later",
	DOWNLOAD_CONFIG_CONFIRMATION: "Are you sure want to download the configuration?",
	SOURCE_IP_FILTER_TOOLTIP:
		"Enter Source IP or Source Port or both seperated by ' : ' followed by a ' , ' to seperate multiple values. Eg: '192.168.1.0,1234,192.168.1.1:1232 '",
	DESTINATION_IP_FILTER_TOOLTIP:
		"Enter Destination IP or Destination Port or both seperated by ' : ' followed by a ' , ' to seperate multiple values. Eg: '192.168.1.0,1234,192.168.1.1:1232 '",
	CREATE_PRIVATE_APPLICATION_CONFIRMATION: "Are you sure you want to create the custom application?",
	UPDATE_PRIVATE_APPLICATION_CONFIRMATION: "Are you sure you want to update the custom application?",
	PRIVATE_APPLICATION_SAVE_SUCCESS: "Custom application saved successfully",
	PRIVATE_APPLICATION_SAVE_ERROR: "Unable to save custom application at this moment, please try again later",
	PRIVATE_APPLICATION_UPDATE_SUCCESS: "Custom application updated successfully",
	PRIVATE_APPLICATION_UPDATE_ERROR: "Unable to update application at this moment, please try again later",

	POLICY_DELETE_SUCCESS: "Policy deleted successfully",

	PRIVATE_APPLICATION_DELETE_SUCCESS: "Application deleted successfully",
	RULES_FETCH_ERROR_MESSAGE:
		"Unable to fetch the rules for the selected application at this moment, please try again later",
	DELETE_ACTION_FAILURE_MESSAGE: "Unable to proceed with the action at this moment, please try again later",

	WEB_SOCKET_CONNECTIVITY_IDLE_TIME: 30,
	WEB_SOCKET_CONNECTION_TIME_OUT: 30000,
	WEB_SOCKET_CONNECTION_CLOSE_COUNTDOWN_TIME: 1000,
	CREATE_WIDELY_USED_APP_CONFIRMATION: "Are you sure you want to create widely used application?",
	UPDATE_WIDELY_USED_APP_CONFIRMATION: "Are you sure you want to update the widely used application?",

	WIDELY_USED_APP_SAVE_SUCCESS: "Widely used SaaS application saved successfully",
	WIDELY_USED_APP_UPDATE_SUCCESS: "Widely used SaaS application updated successfully",
	WIDELY_USED_APP_DELETE_SUCCESS: "Widely used SaaS application deleted successfully",
	WIDELY_USED_APP_SAVE_FAILURE: "Unable to save widely used SaaS application at this moment, please try again later",
	WIDELY_USED_APP_UPDATE_FAILURE:
		"Unable to update widely used SaaS application at this moment, please try again later",
	WIDELY_USED_APP_DELETE_FAILURE:
		"Unable to delete widely used SaaS application at this moment, please try again later",
	WIDELY_USED_APP_LIST_FETCH_ERROR:
		"Unable to fetch the widely used SaaS application at this moment, please try again later",

	CATEGORY_FETCH_ERROR_MESSAGE: "Unable to fetch the details at this moment, please try again later",
	WIDELY_USED_APPLICATION_DELETE_CONFIRMATION_MESSAGE: "Are you sure you want to delete the widely used application?",
	CREATE_URL_CATEGORY_CONFIRMATION: "Are you sure you want to create the URL category?",
	URL_CATEGORY_FAILURE_MESSAGE: "Unable to proceed with the action at this moment, please try again later",
	BACK_CONFIRMATION_MESSAGE: "Are you sure you want to leave this page?",
	URL_CATEGORY_CREATION_SUCCESS_MESSAGE: "URL category saved successfully",
	URL_CATEGORY_UPDATION_SUCCESS_MESSAGE: "URL category updated successfully",
	UPDATE_URL_CATEGORY_CONFIRMATION_MESSAGE: "Are you sure you want to update the URL category?",
	URL_CATEGORY_DELETE_CONFIRMATION_MESSAGE: "Are you sure you want to delete the URL category?",
	URL_CATEGORY_DELETE_SUCCESS_MESSAGE: "URL category deleted successfully",
	URL_CATEGORY_POLICY_CONFIGURED_DELETE_INFO_MESSAGE:
		"Selected category cannot be deleted because it is associated with policy.",

	WAN_LAN_CONFIG_LOADING_TIME_OUT: 3000,

	DEVICE_UPDATE_CUSTOMER_TOOLTIP_MESSAGE: "Customer name cannot be modified as the device is activated",
	SITE_UPDATE_TYPE_TOOLTIP_MESSAGE: "Site type cannot be modified as the device is already configured",
	ENTER_PAGE_NUMBER_MESSAGE: "Please enter the page number",

	NAME_PATTERN_TOOLTIP_MESSAGE:
		"Allowed characters are A-Z, a-z, 0-9, hyphens(-), ampersand(&), underscore(_), periods(.), and spaces.",
	IP_PATTERN_TOOLTIP_MESSAGE: "Enter list of IP address or IP address with subnet separated by commas.",
	DOMAIN_PATTERN_TOOLTIP_MESSAGE: "Enter list of domain separated by commas.",
	PORT_PATTERN_TOOLTIP_MESSAGE: "Enter list of port or port range separated by commas. Allowed port range (0-65535).",
	USER_NAME_PATTERN_TOOLTIP_MESSAGE:
		"Name should start and end with the alphabet. Can contain alphabets and spaces only.",
	RESET_CHANGE_CHECK_MESSAGE: "No changes have been made to discard.",
	SEN_INTERFACE_SUCCESS_MESSAGE: "Interface details saved successfully",

	LOCATION_TYPE_CHANGE_WARNING: "Location change will take effect only on manual restart of the device. ",

	SSE_CONFIG_FETCH_FAILURE_MESSASE: "Unable to fetch the details at this moment, please try again later.",
	SSE_CONFIG_SAVE_FAILURE_MESSAGE: "Unable to save the details at this moment, please try again later",
	SSE_BASIC_CONFIG_SAVE_SUCCESS_MESSAGE: "Tenant and token details saved successfully",
	SSE_CONFIG_SAVE_SUCCESS_MESSAGE: "Configurations saved successfully",
	TUNNEL_CONFIG_CONFIRMATION_TEMPLATE_MESSAGE: "Are you sure you want to configure the tenant?",
	TUNNEL_CONFIG_STATUS_UPDATE_FAILURE_MESSAGE:
		"Unable to change the configuration status at this moment, please try again later",
	TUNNEL_CONFIG_DELETE_FAILURE_MESSAGE: "Unable to delete the details at this moment, please try again later",
	TUNNEL_CONFIG_DELETE_SUCCESS_MESSAGE: "Configuration deleted successfully",
	TUNNEL_CONFIG_STATUS_UPDATE_SUCCESS_MESSAGE: "Configuration status updated successfully",
	TUNNEL_CONFIG_DELETE_CONFIRMATION_MESSAGE: "Are you sure you want to delete the configuration?",
	TUNNEL_BASIC_CONFIG_CONFIRMATION_MESSAGE: "Are you sure you want to configure the tenant profile?",

	POLICY_DELETE_HINT:
		"Note: To ensure the deletion of any removed policies that are currently applied on the device, please click the ‘Apply’ button at the bottom of the page.",

	FORM_CANCEL_CONFIRMATION_MESSAGE: "Are you sure you want to leave this page?",
	RESET_CHANGES_AND_BACK_CONFIRMATION_MESSAGE:
		"Are you sure you want to discard the unsaved changes and leave this page?",

	QOS_PROFILE_SAVE_SUCCESS: "QoS profile saved successfully",
	QOS_PROFILE_UPDATE_SUCCESS: "QoS profile updated successfully",
	QOS_PROFILE_DELETE_SUCCESS: "QoS profile deleted successfully",
	QOS_PROFILE_SAVE_FAILURE: "Unable to save QoS profile at this moment, please try again later",
	QOS_PROFILE_UPDATE_FAILURE: "Unable to update QoS profile at this moment, please try again later",
	QOS_PROFILE_DELETE_FAILURE: "Unable to delete QoS profile at this moment, please try again later",
	QOS_PROFILE_LIST_FETCH_ERROR: "Unable to fetch the QoS profile at this moment, please try again later",
	PROFILE_ASSOCIATED_DEVICE_FETCH_ERRMSG:
		"Unable to fetch the associated device(s) at this moment, please try again later",
	PROFILE_DELETE_CONFIRMATION: "Are you sure you want to delete the profile?",
	WAN_CONFIG_FORM_SAVE_ERR: "We are having trouble in saving WAN configuration. Please try again later.",
	CREATE_QOS_PROFILE_CONFIRMATION: "Are you sure you want to create a QoS profile?",
	UPDATE_QOS_PROFILE_CONFIRMATION: "Are you sure you want to update a QoS profile?",
	QOS_PROFILE_HEADER: "Change QoS Profile",
	NO_NEW_POLICY_TO_APPLY: "No rules have been added or modified to apply changes",
	DELETE_SOFTWARE: "Are you sure you want to delete the software?",
	DELETE_SOFTWARE_SUCCESS: "Software deleted successfully",
	DELETE_CUSTOMER_SOFTWARE_CONFIRMATION: "Are you sure you want to remove the software mapping?",
	DELETE_CUSTOMER_SOFTWARE_SUCCESS: "Software mapping removed successfully",
	DELETE_CUSTOMER_SOFTWARE_ERROR:
		"Mapping cannot be deleted because it's currently being used by a customer's device(s)",
	DELETE_SOFTWARE_ERROR: "Firmware cannot be deleted because version is currently associated with a customer",
	DOWNGRADE_NOT_APPLICABLE: "Downgrade is not applicable for the selected device.",

	REPORT_TYPE_DETAILS_FETCH_ERROR_MESSAGE:
		"Unable to fetch the report type details at this moment, please try again later",
	SITE_DETAILS_FETCH_ERROR_MESSAGE: "Unable to fetch the report type details at this moment, please try again later",
	SCHEDULED_REPORTS_DETAILS_FETCH_ERROR_MESSAGE:
		"Unable to fetch the scheduled report details at this moment, please try again later",
	SCHEDULED_REPORT_DELETE_CONFIRMATION_MESSAGE: "Are you sure you want to delete the scheduled report?",
	SCHEDULED_REPORT_PAUSE_CONFIRMATION_MESSAGE: "Are you sure you want to pause the scheduled report?",
	SCHEDULED_REPORT_DELETE_FAILURE_ERROR_MESSAGE:
		"Unable to delete the scheduled report details at this moment, please try again later",
	SCHEDULED_REPORT_MAIL_FETCH_ERROR_MESSAGE:
		"Unable to fetch the list of mail id's at this moment, please try again later",
	DOWNLOAD_REPORT_CONFIRMATION_MESSAGE: "Are you sure you want to download the report?",
	DOWNLOAD_SUCCESS_MESSAGE: "Report downloaded successfully",
	DOWNLOAD_FAILURE_ERROR_MESSAGE: "Unable to download the report at this moment, please try again later",
	SCHEDULE_REPORT_CONFIRMATION_MESSAGE: "Are you sure you want to create this schedule?",
	SCHEDULE_REPORT_FAILURE_MESSAGE: "Unable to schedule the period at this moment, please try again later",
	SCHEDULE_REPORT_BUSINESS_TIMING_FETCH_ERROR_MESSAGE:
		"Unable to fetch the business timing details at this moment, please try again later",
	SCHEDULE_REPORT_UPDATE_STATUS_FAILURE_MESSAGE:
		"Unable to update the status details at this moment, please try again later",
	SCHEDULED_REPORT_FETCH_ERROR_MESSAGE:
		"Unable to fetch the scheduled report details at this moment, please try again later",
	POLICY_SAVE_SUCCESS_MESSAGE: "Policies saved successfully",
	SOFTWARE_RELEASE_SUCCESS: "Software image released to customer(s) successfully",
	SOFTWARE_RELEASE_ERROR: "Unable to release software image to customer(s) at this moment, please try again later",
	IMAGE_UPLOAD_RELEASE_SUCCESS: "Image uploaded and it is released to customer(s) successfully",
	IMAGE_UPLOAD_RELEASE_ERROR: "Unable to upload image at this moment, please try again later",
	IMAGE_UPLOAD_SUCCESS: "Image uploaded successfully",
	CUSTOMER_CREATE_BANDWIDTH_CREATION_ERROR:
		"Customer has been created but unable to save bandwidth at this moment, please try again later",
	CUSTOMER_UPDATE_BANDWIDTH_UPDATE_ERROR:
		"Customer has been updated but unable to save bandwidth at this moment, please try again later",
	PACKET_CAPTURE_DURATION_MAX_VALUE_MESSAGE: "Enter a duration in the range of 5 and 300 seconds",
	PACKET_CAPTURE_FILTER_TOOLTIP_MESSAGE: "Enter valid tcpdump filter",
	INTERFACE_DETAILS_FETCH_ERROR_MESSAGE:
		"Unable to fetch the interface details at this moment, please try again later",
	DEVICE_FETCH_ERROR_MESSAGE: "Unable to fetch the device details at this moment, please try again later",
	DEVICE_FETCH_LOADER_MESSAGE: "Fetching device details...",
	INTERFACE_FETCH_LOADER_MESSAGE: "Fetching interface details...",
	PCAP_FILE_DOWNLOAD_SUCCESS_MESSAGE: "File downloaded successfully",
	PCAP_FILE_DOWNLOAD_FAILURE_MESSAGE: "Unable to download the file at this moment, please try again later",
	INPROGRESS_FETCH_ERROR_MESSAGE: "Unable to fetch the in progress details at this moment, please try again later",
	INPROGRESS_FETCH_TOOLTIP_MESSAGE: "Please wait, we are fetching the details...",
	DOWNLOAD_FILE_FETCH_TOOLTIP_MESSAGE: "Please wait, we are downloading the file...",
	PCAP_FILE_DOWNLOAD_EMPTY_MESSAGE: "File has no content so it can't be downloaded",
	PACKET_CAPTURE_SAVE_FAILURE_MESSAGE: "Unable to save the details at this moment, please try again later",
	IN_PROGRESS: "in progress",

	//Agent
	ENABLE_STRICT_MODE_CONFIRMATION: "Are you sure want to enable the strict mode for the customer?",
	DISABLE_STRICT_MODE_CONFIRMATION: "Are you sure want to disable the strict mode for the customer?",
	ENABLE_STRICT_MODE_SUCCESS: "Strict mode  has been enabled successfully.",
	ENABLE_STRICT_MODE_ERROR: "Unable to enable the strict mode at this moment. Please try again later.",
	DISABLE_STRICT_MODE_SUCCESS: "Strict mode  has been disabled successfully.",
	DISABLE_STRICT_MODE_ERROR: "Unable to disable the strict mode at this moment. Please try again later.",
	ENABLE_STRICT_MODE_USER_CONFIRMATION: "Are you sure want to enable the strict mode for the user?",
	DISABLE_STRICT_MODE_USER_CONFIRMATION: "Are you sure want to disable the strict mode for the user?",

	CREATE_AGENT_USER_APPLICATION_CONFIRMATION: "Are you sure you want to create the user?",
	UPDATE_AGENT_USER_APPLICATION_CONFIRMATION: "Are you sure you want to update the user?",
	DELETE_AGENT_USER_APPLICATION_CONFIRMATION: "Are you sure you want to delete the user?",
	AGENT_USER_CREATE_SUCCESS: "User created successfully.",
	AGENT_BULK_UPLOAD_SUCCESS: "File uploaded successfully.",
	AGENT_USER_APPLICATION_SAVE_ERROR: "Unable to save user at this moment, please try again later.",
	AGENT_USER_APPLICATION_BULK_EMPTY_FILE_SAVE_ERROR: "The file is empty, please upload a valid proper file.",
	AGENT_BULK_UPLOAD_SIZE_EXCEED_ERROR: "File size exceeds the maximum limit of 10 MB.",
	AGENT_BULK_UPLOAD_INVALID_FORMAT_ERROR: "Invalid file extension.",
	AGENT_USER_APPLICATION_UPDATE_SUCCESS: "User updated successfully.",
	AGENT_EXCEL_TEMPLATE_SUCCESS: "Template downloaded successfully.",
	AGENT_EXCEL_TEMPLATE_FAIL: "Unable to download a template.",
	AGENT_BULK_UPLOAD_FAIL: "Unable to process the uploaded file.",
	AGENT_BULK_EXPORT_SUCCESS: "user details exported successfully.",
	AGENT_BULK_EXPORT_FAIL: "Unable to download a user details.",
	AGENT_USER_APPLICATION_UPDATE_ERROR: "Unable to update user at this moment, please try again later.",
	AGENT_USER_LIST_FETCH_ERROR: "Unable to fetch the sase agent user list at this moment, please try again later.",
	SASE_AGENT_USER_LIST_FETCH_ERROR:
		"Unable to fetch the sase agent user list at this moment, please try again later.",
	AGENT_USER_FETCH_ERROR: "Unable to fetch the sase agent user details at this moment, please try again later.",
	AGENT_INVITATION_SENT_SUCCESS: "Invitation sent successfully.",
	AGENT_INVITATION_SENT_ERROR: "Unable to send invitation at this moment, please try again later.",
	EMAIL_PATTERN_TOOLTIP_MESSAGE: "Enter a valid email address.",
	UPN_PATTERN_TOOLTIP_MESSAGE:
		"Username should start with alphabet.Only alphanumeric, dot, hyphen, underscore, @ are allowed.",
	MAC_PATTERN_TOOLTIP_MESSAGE: "Enter a valid MAC address. Example: 00-B0-D0-63-C2-26.",
	SUBMIT_TOOLTIP_MESSAGE: "Please fill all the mandatory fields.",
	N0_CHANGES_FORM_RESET: "No changes made to reset.",

	TUNNEL_CONFIG_SUCCESS: "Tunnel configuration saved successfully.",
	TUNNEL_CONFIG_ERR: "Failed to save tunnel configuration at this moment, please try again later.",

	VLAN_CONFIGURATION_SUCESS: "VLAN configuration saved successfully.",
	VLAN_CONFIG_SAVE_CONFIRM: "Are you sure you want to save the VLAN configuration?",
	VLAN_CONFIG_SAVE_ERR: "Failed to save VLAN configuration at this moment, please try again later.",
	VLAN_CONFIG_DETAILS_GET_ERR: "Failed to fetch VLAN configuration at this moment, please try again later.",
	VLAN_DETAIL_DELETE_CONFIRM: "Are you sure u want to delete the vlan configuration?",
	AGENT_DISABLE_WARNING: "Are you sure you want to proceed?",
	AGENT_DISABLE_HINT: "Note: Disabling the agent will uninstall all the user agents.",

	USER_DELETED_SUCCESS: "User has been deleted successfully",
	USER_DELETED_ERROR: "Unable to delete user at this moment, please try again later"
};

export const GLOBAL_NAMES = {
	CPE_DEVICE_DETAILS: "cpe details",
	SEN_DEVICE_DETAILS: "sen details",
	WAN_CONFIG: "wan config",
	CONFIRMATION_MODAL_TITLE: "Confirm Action",
	ONLINE: "online",
	OFFLINE: "offline",
	REMOVE: "removed",
	JITTER: "jitter",
	LATENCY: "latency",
	PACKETLOSS: "packetloss",
	LAUNCH_DEVICE_WEB_CONSOLE: "Launch device web console",
	APPLY_TEMPLATE_DEVICE_CONFIG: "Apply to device(s)",
	DOWNLOAD_CONFIG: "Download Config",
	SERVICE_TYPE: "Sase",
	SEN: "SEN",
	CPE: "CPE",
	DEVICE_NOT_ACTIVATED: "Device not registered",
	DEVICE_ONLINE: "Online",
	DEVICE_OFFLINE: "Offline",
	DATA_CENTER: "Data Center"
};
export const PH1CIPHER = [
	{ id: "des", name: "DES" },
	{ id: "3des", name: "3DES" },
	{ id: "aes128", name: "AES128" },
	{ id: "aes192", name: "AES192" },
	{ id: "aes256", name: "AES256" }
];
export const PH1AUTH = [
	{ id: "md5", name: "MD5" },
	{ id: "sha1", name: "SHA1" },
	{ id: "sha256", name: "SHA256" },
	{ id: "sha384", name: "SHA384" },
	{ id: "sha512", name: "SHA512" }
];
export const PH1DHGROUP = [
	{ id: "modp768", name: "MODP768" },
	{ id: "modp1024", name: "MODP1024" },
	{ id: "modp1536", name: "MODP1536" },
	{ id: "modp2048", name: "MODP2048" },
	{ id: "modp3072", name: "MODP3072" },
	{ id: "modp4096", name: "MODP4096" }
];
export const PH2CIPHER = [
	{ id: "des", name: "DES" },
	{ id: "3des", name: "3DES" },
	{ id: "aes128", name: "AES128" },
	{ id: "aes192", name: "AES192" },
	{ id: "aes256", name: "AES256" }
];
export const PH2HASH = [
	{ id: "md5", name: "MD5" },
	{ id: "sha1", name: "SHA1" },
	{ id: "sha256", name: "SHA256" },
	{ id: "sha384", name: "SHA384" },
	{ id: "sha512", name: "SHA512" }
];
export const PH2PFSGROUP = [
	{ id: "modp768", name: "MODP768" },
	{ id: "modp1024", name: "MODP1024" },
	{ id: "modp1536", name: "MODP1536" },
	{ id: "modp2048", name: "MODP2048" },
	{ id: "modp3072", name: "MODP3072" },
	{ id: "modp4096", name: "MODP4096" }
];
export const MENU = {
	responseCode: 0,
	message: "",
	traceId: "c2198ea6-0d29-4b0d-ae70-1ae00443066f",
	data: {
		menu: [
			{
				id: 1,
				label: "Dashboard",
				icon: "Dashboard",
				url: "/dashboard",
				subMenu: []
			},
			{
				id: 2,
				label: "Customers",
				icon: "vpn_lock",
				url: "/customer-management/customers",
				privilegeId: 99,
				subMenu: []
			},
			{
				id: 3,
				label: "Monitoring",
				icon: "fingerprint",
				url: "/site-management/sites",
				subMenu: [
					{
						id: 6,
						label: "Service Edges",
						icon: "assessment",
						url: "/service-edge-management/service-edge-list",
						privilegeId: 188,
						subMenu: []
					},
					{
						id: 7,
						label: "Sites",
						icon: "fingerprint",
						url: "/site-management/sites",
						subMenu: []
					},
					{
						id: 8,
						label: "Devices",
						icon: "assessment",
						url: "/device-management/device-list",
						privilegeId: 111,
						subMenu: []
					}
				]
			},
			{
				id: 4,
				label: "Configuration",
				icon: "settings",
				url: "/network-management/configure-network",
				privilegeId: 156,
				subMenu: [
					{
						id: 9,
						label: "Service Edges",
						icon: "assessment",
						url: "/network-management/service-edge-list",
						privilegeId: 200,
						subMenu: []
					},
					{
						id: 10,
						label: "Sites",
						icon: "fingerprint",
						url: "/network-management/sites",
						subMenu: []
					},
					{
						id: 11,
						label: "Devices",
						icon: "assessment",
						url: "/network-management/device-list",
						subMenu: []
					},
					{
						id: 12,
						label: "Policies",
						icon: "settings",
						url: "/network-management/configure-network",
						privilegeId: 156,
						subMenu: []
					},
					{
						id: 13,
						label: "Objects",
						icon: "settings",
						url: "/profiles",
						privilegeId: 156,
						subMenu: []
					},
					{
						id: 14,
						label: "Template Based Configuration",
						icon: "settings",
						url: "/configuration-management/templates",
						privilegeId: 93,
						subMenu: []
					},
					{
						id: 15,
						label: "Controller Instance Config",
						icon: "settings",
						url: "/device-management/device-controller-list",
						privilegeId: 167,
						subMenu: []
					}
				]
			},
			{
				id: 5,
				label: "User Management",
				icon: "vpn_lock",
				url: "/user-management/users",
				privilegeId: 4,
				subMenu: []
			},
			{
				id: 6,
				label: "Operations",
				icon: "settings",
				url: "/device-management/device-image-upload",
				privilegeId: 168,
				subMenu: [
					{
						id: 14,
						label: "Firmware Management",
						icon: "settings",
						url: "/device-management/device-image-upload",
						privilegeId: 170,
						subMenu: []
					},
					{
						id: 15,
						label: "Software Management",
						icon: "settings",
						url: "/device-management/device-version-upgrade",
						privilegeId: 168,
						subMenu: []
					}
				]
			}
		]
	}
};
export const TABLENAMETYPE = [
	{ label: "main", value: "main" },
	{ label: "wan", value: "wan" },
	{ label: "wan2", value: "wan2" },
	{ label: "wan3", value: "wan3" },
	{ label: "wan4", value: "wan4" }
];
export const ROUTETYPE = [
	{ label: "unicast", value: "unicast" },
	{ label: "local", value: "local" },
	{ label: "broadcast", value: "broadcast" },
	{ label: "multicast", value: "multicast" },
	{ label: "unreachable", value: "unreachable" },
	{ label: "prohibit", value: "prohibit" },
	{ label: "blackhole", value: "blackhole" },
	{ label: "anycast", value: "anycast" }
];
export const ACTIONTYPE = [
	{ label: "drop", value: "drop" },
	{ label: "accept", value: "accept" },
	{ label: "reject", value: "reject" }
];
export const PROTOCOLTYPE = [
	{ label: "TCP UDP", value: "TCP UDP" },
	{ label: "TCP", value: "TCP" },
	{ label: "UDP", value: "UDP" },
	{ label: "ICMP", value: "ICMP" }
];
export const MODETYPE = [
	{ label: "blacklist", value: "blacklist" },
	{ label: "whitelist", value: "whitelist" }
];
export const MACFILTERTYPE = [
	{ label: "disable", value: "disable" },
	{ label: "Allow listed only", value: "Allow listed only" },
	{ label: "Allow all except listed", value: "Allow all except listed" }
];
export const SOURCEZONETYPE = [
	{ label: "Device(output)", value: "Device(output)" },
	{ label: "Any zone(forward)", value: "Any zone(forward)" },
	{ label: "lan", value: "lan" },
	{ label: "wan", value: "wan" },
	{ label: "scdx", value: "scdx" },
	{ label: "wireguard", value: "wireguard" }
];
export const DESTINATIONZONEUNTYPE = [
	{ label: "Unspecified", value: "Unspecified" },
	{ label: "Any zone(forward)", value: "Any zone(forward)" },
	{ label: "lan", value: "lan" },
	{ label: "wan", value: "wan" },
	{ label: "scdx", value: "scdx" },
	{ label: "wireguard", value: "wireguard" }
];
export const DESTINATIONZONEDEVINTYPE = [
	{ label: "Device(input)", value: "Device(input)" },
	{ label: "Any zone", value: "Any zone" },
	{ label: "lan", value: "lan" },
	{ label: "wan", value: "wan" },
	{ label: "scdx", value: "scdx" },
	{ label: "wireguard", value: "wireguard" }
];
