<div class="col-md-12">
    <p-table [lazy]="settings.islazyLoading" [columns]="columns" [value]="data" 
        [scrollable]="settings.virtualScroller ? true: false" 
        [scrollHeight]="settings.virtualScroller ? '250px': ''" 
        [virtualScroll]="settings.virtualScroller ? true : false"
        [virtualScrollItemSize]="46"
        (onRowSelect)="rowSelect($event)" (onRowUnselect)="rowUnSelect($event)"
        (onHeaderCheckboxToggle)="headerCheckboxToggle($event)" (sortFunction)="customSort($event)" [customSort]="false"
        (onLazyLoad)="onLazyLoad($event)" [(selection)]="selectedRows" [paginator]="settings.noPagination ? false : true " [rows]="5"
        [selectionPageOnly]="true" 
        [selectionMode]="settings.selectionMode ? settings.selectionMode : ''" 
        [metaKeySelection] = "false"
        [showJumpToPageInput]="true"
        [totalRecords]="settings.islazyLoading ? settings.totalRecords : 0" [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
        [lazyLoadOnInit]="false"
        [first]="settings.showFirstRow ? 1 : 0"
        >
        <ng-template pTemplate="header" let-columns>
            <tr>
                <ng-container *ngIf="settings?.enableRowSelection">
                    <th *ngIf="settings?.hideHeaderCheckbox; else showCheckbox">
                        {{" "}}
                    </th>
                    <ng-template #showCheckbox>
                        <th #showCheckbox>
                            <p-tableHeaderCheckbox ></p-tableHeaderCheckbox>
                        </th>
                    </ng-template>
                </ng-container>
                <ng-container *ngFor="let col of columns">

                    <!-- <th *ngIf="col.headerType == 'checkbox'" [ngStyle]="{'width':col.width ? col.width : ''}">
                        <span>
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </span>
                    </th> -->
                    <th *ngIf="col.headerType == 'sort'" [pSortableColumn]="col.field"
                        [ngStyle]="{'width':col.width ? col.width : ''}">
                        <span class="mr-1">{{ col.label }}</span>&nbsp;
                        <p-sortIcon class="mx-2" [field]="col.field"></p-sortIcon>
                    </th>
                    <th *ngIf="col.headerType == 'filter'" [ngStyle]="{'width':col.width ? col.width : ''}">
                        <div>
                            <div class="flex align-items-center">
                                {{ col.label }}
                                <p-columnFilter [field]="col.field" matchMode="startsWith" display="menu"
                                    [showMenu]="true" [showMatchModes]="false" [showOperator]="false"
                                    [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" [options]="col.filterOptions"
                                            (onChange)="filter($event.value)" placeholder="Any" [showClear]="true">
                                            <ng-template let-option pTemplate="item">
                                                {{option.value}}
                                                <!-- <p-tag [value]="option.value"
                                                [severity]="getSeverity(option.label)"></p-tag> -->
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </div>
                    </th>
                    <th *ngIf="col.headerType == 'multiFilter'" [ngStyle]="{'width':col.width ? col.width : ''}" >
                        <div>
                            <div class="flex align-items-center">
                                {{ col.label }}
                                <p-columnFilter [field]="col.field" matchMode="in" display="menu" [showMenu]="true"
                                    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [showHeader]='false'
                                            [options]="col.filterOptions" placeholder="Select Severities"
                                            (onChange)="filter($event.value); filter_func($event)" optionLabel="name"
                                            display="chip">
                                            <ng-template let-option pTemplate="item">
                                                <div class="inline-block vertical-align-middle">
                                                    <span class="ml-1 mt-1">{{ option.name }}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </div>
                    </th>
                    <th *ngIf="!col.headerType || col.headerType == 'text' || col.headerType == undefined"
                        [ngStyle]="{'width':col.width ? col.width : ''}">
                        <div>
                            <span>{{ col.label }}</span>
                        </div>
                    </th>
                    <th *ngIf="col.headerType == 'custom'" [ngStyle]="{'width':col.width ? col.width : ''}">
                        <!-- <ng-template [ngTemplateOutlet]="col.cellCustomTemplate">

                        </ng-template> -->
                        <ng-content [select]="[col.cellCustomTemplate]">
                        </ng-content>
                    </th>
                </ng-container>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <ng-container *ngIf="settings?.enableRowSelection">
                    <td>
                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of columns">
                    <ng-container *ngIf="!column?.cellCustomTemplate; else displayTemplate">
                        <td [ngClass]="column?.cellClass" [ngStyle]="column?.cellStyle">
                            {{ rowData[column.field] }}
                        </td>
                    </ng-container>

                    <ng-template #displayTemplate>
                        <td [ngClass]="column?.cellClass" [ngStyle]="column?.cellStyle">
                            <ng-template [ngTemplateOutletContext]="{ rowData: rowData, column: column }"
                                [ngTemplateOutlet]="column.cellCustomTemplate"> </ng-template>
                        </td>
                    </ng-template>

                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="settings.enableRowSelection ? columns.length + 1 : columns.length">
                    <div class="empty-template-wrapper d-flex justify-content-center p-2 my-4">
                        <div class="empty-template">
                            <img [ngStyle]="{height:'100px'}" src="./assets/icons/no_data.svg" alt="No data available">
                            <span>Oops! No results found.</span>
                            <span>Try adjusting filters or search.</span>
                        </div>
                    </div>
                </td>
            </tr>

        </ng-template>
    </p-table>
</div>