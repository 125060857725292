import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TableColumns } from "../../models/table";
import { DataParams } from "../../models/utils";

@Component({
	selector: "app-show-all-widget-data",
	templateUrl: "./show-all-widget-data.component.html",
	styleUrls: ["./show-all-widget-data.component.scss"]
})
export class ShowAllWidgetDataComponent implements OnInit {
	isDataLoading = false;
	isDataFetchError = false;
	totalSize = 0;
	recordsPerPage = [10, 50, 100];
	pageSize = 10;
	columns: TableColumns[] = [];
	title!: string;
	tableDataSource: any[] = [];
	dataParams: DataParams = { showSearchInput: true, searchDivClass: "customer-search" };
	cloneDataSource = [];
	dataSource: any = { columnConfig: { columnDef: [], displayColumns: [] } };

	constructor(
		public dialogRef: MatDialogRef<ShowAllWidgetDataComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			title: string;
			dataSource?: any;
			isDataLoading: boolean;
			isDataFetchError: boolean;
			columns: any;
		}
	) {
		this.title = data.title;
		this.parseTableDetails();
		this.updateData(data);
	}

	ngOnInit() {}

	parseTableDetails() {
		const columnDef = this.data?.columns;
		columnDef?.forEach((column: any) => {
			column.cellClass = "drill-down-cell-details";
		});
		this.dataSource.columnConfig = {
			columnDef: columnDef,
			displayColumns: columnDef.map((column: any) => column.header)
		};
	}

	updateData(data: any) {
		this.isDataLoading = data?.isDataLoading;
		this.columns = data?.columns;
		if (!data?.isDataLoading) {
			this.tableDataSource = data?.dataSource;
			this.cloneDataSource = structuredClone(data?.dataSource);
			this.isDataFetchError = data?.isDataFetchError;
			this.totalSize = this.tableDataSource?.length;
		}
	}

	search(searchText: string) {
		const searchTextLowerCase = searchText.toLocaleLowerCase();
		let filteredData: any[] = [];
		this.cloneDataSource.forEach((element: any) => {
			for (let key in element) {
				if (element[key] !== null) {
					if (typeof element[key] !== "object") {
						if (element[key].toString().toLowerCase().includes(searchTextLowerCase)) {
							filteredData.push(element);
							break;
						}
					} else {
						if (JSON.stringify(element[key]).toLowerCase().includes(searchTextLowerCase)) {
							filteredData.push(element);
							break;
						}
					}
				}
			}
		});
		this.tableDataSource = filteredData;
		this.totalSize = this.tableDataSource?.length;
	}
}
