import { environment } from "../../../environments/environment";

export class APIUrlConstants {
	public static readonly BASE_PATH = "BASE_PATH";
	public static readonly FS_BASE_PATH = "FS_BASE_PATH";

	public static readonly DEVICE_MANAGEMENT_BASEPATH = "DEVICE_MANAGEMENT_BASEPATH";
	public static readonly CUSTOMER_MANAGEMENT_BASEPATH = "CUSTOMER_MANAGEMENT_BASEPATH";
	public static readonly MONITORING_SERVICE_BASEPATH = "MONITORING_SERVICE_BASEPATH";
	public static readonly NETWORK_MANAGEMENT_BASEPATH = "NETWORK_MANAGEMENT_BASEPATH";
	public static readonly SECURITY_MANAGEMENT_BASEPATH = "SECURITY_MANAGEMENT_BASEPATH";
	public static readonly USER_MANAGEMENT_BASEPATH = "USER_MANAGEMENT_BASEPATH";
	public static readonly REMOTE_UPGRADE_BASEPATH = "REMOTE_UPGRADE_BASEPATH";

	public static readonly DEVICE_MANAGEMENT_URL = "DEVICE_MANAGEMENT_URL";
	public static readonly CUSTOMER_MANAGEMENT_URL = "CUSTOMER_MANAGEMENT_URL";
	public static readonly MONITORING_SERVICE_URL = "MONITORING_SERVICE_URL";
	public static readonly NETWORK_MANAGEMENT_URL = "NETWORK_MANAGEMENT_URL";
	public static readonly SECURITY_MANAGEMENT_URL = "SECURITY_MANAGEMENT_URL";
	public static readonly USER_MANAGEMENT_URL = "USER_MANAGEMENT_URL";
	public static readonly REMOTE_UPGRADE_URL = "REMOTE_UPGRADE_URL";
	public static readonly AGENT_SERVICE_BASEPATH = "AGENT_SERVICE_BASEPATH";
}
