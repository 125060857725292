import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DateTime } from "luxon";
import { Subscription, fromEvent } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { TimeParserUtil } from 'src/app/utility/time-parser-utility';

@Component({
	selector: 'app-date-range',
	templateUrl: './date-range.component.html',
	styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit {

	@Input() date: any;
	@ViewChild("calendar") calendar: any;
	@Output() dateChanged = new EventEmitter<any>();

	flags: any = {
		isRangeSelected: false,
		isDateRangeSelected: false,
		isTimeDifference: false,
		isMaxDayRangeError: false,
		isYesterdaySelected : false
	};
	dashboardModel: any = {};
	dataSource: any = {
		selectedStartDate: "",
		selectedEndDate: ""
	}
	selectedCustomRange: any = {
		startDate: new Date(),
		endDate: new Date(),
		rangeDate: []
	};
	showDropdown: boolean = false;
	maxDate = new Date();
	selectedDate = 1;
	dateFilters: any = [
		{ value: 1, label: "Today", position: "left", dateTimeStamp: false },
		{ value: 2, label: "Last 1 hour", position: "right", dateTimeStamp: true },
		{ value: 3, label: "Yesterday", position: "left", dateTimeStamp: false },
		{ value: 4, label: "Last 24 hours", position: "right", dateTimeStamp: true },
		{ value: 5, label: "This Week", position: "left", dateTimeStamp: false },
		{ value: 6, label: "Last 7 days", position: "right", dateTimeStamp: false },
		{ value: 7, label: "Last 15 minutes", position: "left", dateTimeStamp: true },
		{ value: 8, label: "Last 30 days", position: "right", dateTimeStamp: false },
		{ value: 9, label: "Last 30 minutes", position: "left", dateTimeStamp: true },
		// { value: 10, label: "This Month", position: "right" },
		{ value: 11, label: "Custom", position: "left", dateTimeStamp: true }

	];
	clickSubscription!: Subscription;

	constructor(private timeParser: TimeParserUtil,
		private commonService: CommonService) {

	}

	ngOnInit(): void {
		this.clickSubscription = fromEvent(document, "click").subscribe((event: any) => {
			const element = event.target.closest("div#filter-date");
			if (!element && !event.target.closest("#btn-container")) {
				this.showDropdown = false;
			} else if (event.target.closest("#btn-container")) {
				this.showDropdown = !this.showDropdown;
			}
		});
		this.selectedDate = this.date?.selectedDateFilter || 1;
	}

	onCloseDateRangePicker() {
		this.resetFlagsForDateTime();
		if (this.selectedCustomRange.rangeDate && this.selectedCustomRange.rangeDate[0] && this.selectedCustomRange.rangeDate[1] && !this.flags.isSameDateRange) {
			this.customDateRangeValidation();
			this.flags.isDateRangeSelected = true;
			this.selectedCustomRange.startDate = this.selectedCustomRange.rangeDate[0];
			this.selectedCustomRange.endDate = this.selectedCustomRange.rangeDate[1];
			const timeDifferenceInMinutes = Math.abs((this.selectedCustomRange.startDate.getTime() - this.selectedCustomRange.endDate.getTime()) / (1000 * 60));
			const dateDifference = Math.floor(
				(Date.UTC(
					this.selectedCustomRange.endDate.getFullYear(),
					this.selectedCustomRange.endDate.getMonth(),
					this.selectedCustomRange.endDate.getDate()
				) -
					Date.UTC(
						this.selectedCustomRange.startDate.getFullYear(),
						this.selectedCustomRange.startDate.getMonth(),
						this.selectedCustomRange.startDate.getDate()
					)) /
				(1000 * 60 * 60 * 24)
			);
			this.setSelectedDateTime("custom", this.selectedCustomRange.rangeDate[0], this.selectedCustomRange.rangeDate[1]);
			this.flags.isDateInvalid = this.selectedCustomRange.startDate.valueOf() > this.selectedCustomRange.endDate.valueOf();
			this.flags.isMaxDayRangeError = dateDifference >= 30;
			this.flags.isTimeDifference = timeDifferenceInMinutes < 5;
			if (!this.flags.isDateInvalid) {
				if (dateDifference < 30 && !this.flags.isTimeDifference && !this.flags.isSameDateRange) {
					this.dateChanged.emit(this.date);
				}
			}
			this.dataSource.selectedStartDate = this.selectedCustomRange.rangeDate[0];
			this.dataSource.selectedEndDate = this.selectedCustomRange.rangeDate[1];
		}
		this.flags.isStartAndEndDateSelected = this.selectedCustomRange.rangeDate && (!this.selectedCustomRange.rangeDate[0] || !this.selectedCustomRange.rangeDate[1]);
	}

	resetFlagsForDateTime() {
		this.flags.isSameDateRange = false;
		this.flags.isDateInvalid = false;
		this.flags.isMaxDayRangeError = false;
		this.flags.isTimeDifference = false;
		this.flags.isDateRangeSelected = false;
		this.flags.isStartAndEndDateSelected = false;
	}

	onDateSelected(dateFilter: any): void {
		this.selectedDate = dateFilter.value;
		const currentDate = new Date();
		let pastDate = new Date();
		this.flags.isMaxDayRangeError = false;
		this.flags.isRangeSelected = false;
		this.flags.isTimeDifference = false;
		this.flags.isDateTimeStamp = false;
		this.flags.isStartAndEndDateSelected = false;
		this.flags.isDateInvalid = false;
		let parsedDate: any;
		switch (dateFilter?.label?.toLowerCase()) {
			case "today":
				this.getFormattedDate(currentDate);
				break;
			case "yesterday":
				pastDate.setDate(pastDate.getDate() - 1);
				break;
			case "last 15 minutes":
				parsedDate = this.parseDateTimeSubtract(15);
				pastDate = parsedDate.startDate;
				break;
			case "last 30 minutes":
				parsedDate = this.parseDateTimeSubtract(30);
				pastDate = parsedDate.startDate;
				break;
			case "last 1 hour":
				parsedDate = this.parseDateTimeSubtract(60);
				pastDate = parsedDate.startDate;
				break;
			case "last 24 hours":
				parsedDate = this.parseDateTimeSubtract(1440);
				pastDate = parsedDate.startDate;
				break;
			case "last 7 days":
				pastDate.setDate(pastDate.getDate() - 6);
				break;
			case "this week":
				pastDate.setDate(pastDate.getDate() - pastDate.getDay());
				break;
			case "last 30 days":
				pastDate.setDate(pastDate.getDate() - 29);
				break;
			case "this month":
				pastDate.setDate(1);
				break;
			case "custom":
				this.flags.isRangeSelected = true;
				this.onOpenRangePicker();
				break;
			default:
				break;
		}
		if (dateFilter?.dateTimeStamp) {
			this.setSelectedDateTime(dateFilter.label, pastDate, currentDate);
		}
		else if(dateFilter?.label?.toLowerCase() == "yesterday"){
			let yestEndDateTime = new Date();
			yestEndDateTime.setDate(currentDate.getDate() - 1);
			yestEndDateTime.setHours(23, 59, 59);
			pastDate.setHours(0,0,0)	 
			this.setSelectedDateTime(dateFilter.label, pastDate, yestEndDateTime);
		}
		else{		
			this.setSelectedDate(dateFilter.label, pastDate, currentDate);
		}
		this.showDropdown = false;
		this.dateChanged.emit(this.date);
	}

	onOpenRangePicker() {
		this.calendar.showOverlay();
		this.calendar.inputfieldViewChild.nativeElement.dispatchEvent(new Event('click'));
		this.maxDate = new Date();
		this.selectedCustomRange.rangeDate[0] = new Date();
	}

	setSelectedDate(label: string, startDate: any, endDate: any) {
		this.flags.isYesterdaySelected = false;	
		const fromDate = this.getFormattedDate(startDate);
		const toDate = this.getFormattedDate(endDate);
		this.date.fromDate = `${fromDate["day"]} ${fromDate["month"]} ${fromDate["year"]}`;
		if (label.toLowerCase() === "today" || label.toLowerCase() === "yesterday") {
			this.date.toDate = ``;
		} else {
			this.date.toDate = `${toDate["day"]} ${toDate["month"]} ${toDate["year"]}`;
		}
	}

	setSelectedDateTime(label: string, startDate: any, endDate: any) {
		this.flags.isYesterdaySelected = false;	
		let fromDate = startDate;
		let toDate = endDate;
		fromDate = this.getFormattedDateWithTime(startDate);
		toDate = this.getFormattedDateWithTime(endDate);
		this.date.fromDate = `${fromDate["day"]} ${fromDate["month"]} ${fromDate["year"]} ${fromDate["hours"]} ${fromDate["minutes"]}`;
		this.date.toDate = `${toDate["day"]} ${toDate["month"]} ${toDate["year"]} ${toDate["hours"]} ${toDate["minutes"]}`;
		if (label?.toLowerCase() === "yesterday"){
			this.flags.isYesterdaySelected = true;
			this.flags.isDateTimeStamp = false;
			this.date.fromDate = `${fromDate["day"]} ${fromDate["month"]} ${fromDate["year"]}`;	
			this.date.toDate = `${toDate["day"]} ${toDate["month"]} ${toDate["year"]} ${toDate["hours"]} ${toDate["minutes"]}`; 
		}
		else if (label?.toLowerCase() !== "custom") {			
			this.flags.isDateTimeStamp = true;
			this.flags.isYesterdaySelected = false;
			this.dashboardModel.startDateTime = this.dashboardModel.dateTimeRange.startDate.toFormat("dd MMM yyyy HH:mm");
			this.dashboardModel.endDateTime = this.dashboardModel.dateTimeRange.endDate.toFormat("dd MMM yyyy HH:mm");
		}
	}

	getFormattedDateWithTime(date: Date): { [key: string]: any } {
		const month = date.toLocaleString("en-US", { month: "short" });
		const year = date.toLocaleString("en-US", { year: "numeric" });
		const day = date.getDate();
		const hours = date.getHours();
		const minutes = date.getMinutes();
		return {
			month, year, day, hours, minutes
		};
	}

	getFormattedDate(date: Date): { [key: string]: any } {
		const month = date.toLocaleString("en-US", { month: "short" });
		const year = date.toLocaleString("en-US", { year: "numeric" });
		const day = date.getDate().toString().padStart(2, '0');
		return {
			month,
			year,
			day
		};
	}

	parseDateTimeSubtract(minutes: number) {
		let filterDateRange: any = {
			startDate: "",
			endDate: ""
		};
		filterDateRange.endDate = DateTime.local();
		filterDateRange.startDate = new Date(filterDateRange.endDate.minus({ minutes: minutes }));
		this.dashboardModel.dateTimeRange = {
			startDate: filterDateRange.endDate.minus({ minutes: minutes }),
			endDate: filterDateRange.endDate
		};
		return filterDateRange;
	}

	customDateRangeValidation() {
		if (this.dataSource.selectedStartDate && this.dataSource.selectedEndDate) {
			let inputParam = {
				startDate: this.selectedCustomRange.rangeDate[0].valueOf(),
				endDate: this.selectedCustomRange.rangeDate[1].valueOf(),
				selectedStartDate: this.dataSource.selectedStartDate.valueOf(),
				selectedEndDate: this.dataSource.selectedEndDate.valueOf()
			}
			this.flags.isSameDateRange = this.commonService.customRangeValidation(inputParam);
		}
	}


}
