import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../../app.service";
import { User } from "../../shared/models/user";
import { MatMenuTrigger } from "@angular/material/menu";
import { BreadcrumbsService } from "../breadcrumbs/breadcrumbs.service";
import { DataSharedService } from "src/app/shared/services/data-shared.service";
import { DeviceManagementService } from "src/app/shared/services/device-management.service";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
	dataSource: any = {};
	headerModel: any = {};
	loggedInUserDetails: any = {};
	filterItems: any = {
		SPAED: [
			{ label: "Service Edges", url: "/network-management/service-edge-list" },
			{ label: "Objects", url: "/profiles" },
			{ label: "Service Edges", url: "/service-edge-management/service-edge-list" },
			// { label: "Reports", url: "/report-management/availability/report" },
			{ label: "Upgrade", url: "/operations/upgrade" },
			{ label: "Software Management", url: "/operations/software-management" },
			{ label: "Reports", url: "/dashboard/reports" }
		],
		SASE: [
			{ label: "Firmware Management", url: "/device-management/device-image-upload" },
			{ label: "Upgrades", url: "/device-management/device-version-upgrade" },
			{ label: "Template Based Configuration", url: "/configuration-management/templates" },
			{ label: "Controller Instance Config", url: "/device-management/device-controller-list" },
			{ label: "Logs", url: "/monitoring/logs" }
		]
	};

	constructor(
		private router: Router,
		private appService: AppService,
		private deviceMgmtService: DeviceManagementService,
		private breadcrumbService: BreadcrumbsService,
		private dataSharedService: DataSharedService // private httpClient: HttpClient
	) {}

	ngOnInit(): void {
		// this.httpClient.get('./assets/data/menu/menu.json').subscribe(data => {
		// 	this.filterItems = data;
		// 	console.log("items",this.filterItems);
		// });

		this.appService.getMenus().subscribe((response) => {
			// Menu gets rendered only after customer is set
			this.dataSource.unFilteredMenus = response.data?.menu?.length ? response.data.menu : [];
			this.dataSharedService.getCustomerInfo().subscribe((customerInfo: any) => {
				if (customerInfo?.service?.serviceName) {
					this.dataSource.menus = this.getFilteredMenu(
						customerInfo?.service?.serviceName,
						this.dataSource.unFilteredMenus
					);
				} else {
					customerInfo = this.dataSharedService.getLoggedInyUserInfo()?.services[0];
					this.dataSource.menus = this.getFilteredMenu(
						customerInfo?.service?.serviceName,
						this.dataSource.unFilteredMenus
					);
				}
			});
		});

		this.appService.loggedInUserDetailsObservable.subscribe({
			next: (user: User | null) => {
				if (user) {
					this.loggedInUserDetails = user;
					this.findLogo(user);
				}
			}
		});

		this.deviceMgmtService.deviceCount().subscribe({
			next: (response: any) => {
				if (response.responseCode === 0) {
					this.dataSource.deviceCount = response.data;
				}
			},
			error: (err: any) => {}
		});
	}

	findLogo(user: any) {
		const userOwnerName = this.loggedInUserDetails?.userOwner?.name?.toLowerCase();
		let logo = "";
		let isPartnerUser = false;
		if (userOwnerName === "tata tele") {
			logo = "ttbs_logo_full_white.svg";
			isPartnerUser = true;
		} else if (userOwnerName === "tata play") {
			logo = "tata_play.svg";
			isPartnerUser = true;
		} else {
			logo = "tata_logo.svg";
		}
		logo = `./assets/images/header/${logo}`;

		this.headerModel.userProfile = {
			displayName: user.displayName,
			emailId: user.emailId,
			loginName: user.firstName?.charAt(0)!.toUpperCase() + user.lastName!.charAt(0)?.toUpperCase(),
			isPartnerUser: isPartnerUser, // stopgap to unblock ttsl / tata play requirement
			logo: logo
		};
	}

	goto(route: string) {
		this.breadcrumbService.onMenuClickEvent(route);
		this.router.navigate([route]);
	}

	onNaviagteToDeviceList(status: string): void {
		this.dataSharedService.setSelectedCustomer({ id: 0, name: "All" });
		this.dataSharedService.setTrackDeviceStatus(status);
		if (this.router.url !== "/network-management/device-list") {
			this.router.navigate(["network-management", "device-list"]);
		}
	}

	onLogout() {
		this.router.navigate(["/logout"]);
	}

	openMenu(menu: MatMenuTrigger) {
		menu.openMenu();
	}

	getFilteredMenu(customerType: string, menu: any) {
		// deep copy of menu
		let mainMenu = JSON.parse(JSON.stringify(menu));
		return mainMenu.filter((menuItem: any) => {
			let tempObj = { label: menuItem.label, url: menuItem.url };
			if (menuItem.subMenu.length) {
				menuItem.subMenu = this.getFilteredMenu(customerType, menuItem.subMenu);
				return menuItem;
			} else if (!this.isObjectInArray(tempObj, this.filterItems[customerType])) {
				return menuItem;
			}
		});
	}

	//Function to check if an object is present in an array of objects
	isObjectInArray(obj: any, arr: any) {
		return arr.some((item: any) => JSON.stringify(item) === JSON.stringify(obj));
	}
}
