import { OnInit, Component } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { BreadcrumbsService } from "./breadcrumbs.service";

@Component({
	selector: "app-breadcrumbs",
	templateUrl: "./breadcrumbs.component.html",
	styleUrls: ["./breadcrumbs.component.scss"]
})
export class BreadCrumbsComponent implements OnInit {
	breadcrumbs: any[] = [];
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private breadcrumbsService: BreadcrumbsService
	) {}

	ngOnInit(): void {
		// this.breadcrumbsService.menuClickEventObservable.subscribe((url: any) => {
		// 	const currentLocation =
		// 		location.hash.length && location.hash.split("#").length && location.hash.split("#")[1].length
		// 			? location.hash.split("#")[1]
		// 			: "";
		// 	if (currentLocation !== url) {
		// 		// change only if another menu item is clicked (not current screen)
		// 		this.breadcrumbs = [{ title: "Dashboard", url: "/dashboard", isHomeUrl: true }];
		// 	}
		// });
		let breadcrumbs = sessionStorage.getItem("breadcrumbs");
		if (breadcrumbs && breadcrumbs.length) {
			this.breadcrumbs = JSON.parse(breadcrumbs);
		} else {
			this.breadcrumbs = [{ title: "Dashboard", url: "/dashboard", isHomeUrl: true }];
		}
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const details = this.getBreadcrumbDetails(this.activatedRoute.snapshot);
				if (details && details.name) {
					var index = this.breadcrumbs.map((element) => element.title).indexOf(details.name);
					if (index > -1) {
						this.breadcrumbs.splice(index);
					}
					this.breadcrumbs.push({
						title: details.name,
						url: event.url,
						isHomeUrl: details.isHomeUrl
					});
					if(this.breadcrumbs.length > 4) {
						// display first index and last 3 index in breadcrumbs
						this.breadcrumbs = [
							this.breadcrumbs[0],							
							...this.breadcrumbs.slice(-3)
						];
					}
					sessionStorage.setItem("breadcrumbs", JSON.stringify(this.breadcrumbs));
				}
			}
		});
	}

	getBreadcrumbDetails(snapshot: any): any {
		if (snapshot && snapshot.data && snapshot.data.title) {
			return { name: snapshot.data.title, isHomeUrl: snapshot.data.isHomeUrl ? snapshot.data.isHomeUrl : false };
		} else if (snapshot && snapshot.children && snapshot.children.length) {
			return this.getBreadcrumbDetails(snapshot.children[0]);
		}
		return null;
	}

	onClickBreadcrumb(event: any) {
		this.router.navigateByUrl(event.url);
	}
}
