import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { User } from "./shared/models/user";
import { AgHttpService } from "./shared/services/ag-http-service";
import { ApiUrlService } from "./shared/services/api-url.service";
import { API_URLS } from "./shared/models/utils";

@Injectable({
	providedIn: "root"
})
export class AppService {
	private loggedInUserDetailsSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

	public loggedInUserDetailsObservable: Observable<User | null> = this
		.loggedInUserDetailsSubject as Observable<User | null>;

	public apiUrls!: API_URLS;
	public basePath!: string;
	public customerMgmtUrl!: string;

	constructor(private agHttpService: AgHttpService, private apiUrlService: ApiUrlService) {
		this.apiUrls = this.apiUrlService.getAPIUrls();
		this.basePath = this.apiUrls.BASE_PATH;
		this.customerMgmtUrl = this.apiUrls.CUSTOMER_MANAGEMENT_URL;
	}

	getLoggedInUserDetails(): Observable<any> {
		const url = `${this.apiUrls.USER_MANAGEMENT_URL}/user/loggedinuserdetails`;
		return this.agHttpService.makeGetRequest(url);
	}

	setUserDetails(user: User): void {
		this.loggedInUserDetailsSubject.next(user);
	}

	getMenus(): Observable<any> {
		const url = `${this.customerMgmtUrl}/menu`;
		return this.agHttpService.makeGetRequest(url);
	}
}
