<div class="form-group">
	<input
		type="text"
		id="search"
		[value]="searchTerm"
		class="form-control form-control-search"
		autocomplete="off"
		#listSearchInput
		(keyup)="updateSearch(listSearchInput.value)"
		aria-describedby="search"
		[placeholder]="placeholder" />
</div>
