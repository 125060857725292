import { Injectable } from "@angular/core";
import { DataSharedService } from "./data-shared.service";
@Injectable({ providedIn: "root" })
export class AuthenticationService {
	loggedinUserPrivileges: any = [];
	constructor(private dataSharedService: DataSharedService) {}

	public get userValue() {
		this.loggedinUserPrivileges = this.dataSharedService.getPrivilegesByLoggedinUserRole();
		let roles: any = [];
		let privileges: any = [];
		let user: any = {};
		if (this.loggedinUserPrivileges?.length) {
			privileges = this.loggedinUserPrivileges;
		} else {
			user = this.dataSharedService.getLoggedInyUserInfo();
			if (user) {
				user?.roles.forEach((rolesElement: any) => {
					rolesElement?.roles.forEach((role: any) => {
						roles.push(role?.name?.toLowerCase());
					});
				});
			}
			privileges = this.getRolesMasterInfo(roles);
			this.dataSharedService.setPrivilegesByLoggedinUserRole(privileges);
		}
		return privileges;
	}

	getRolesMasterInfo(_loggedinUserroles: any) {
		let roleMasterData = this.dataSharedService.getPrivileges();
		let previleages: any = [];
		_loggedinUserroles.forEach((lur: any) => {
			roleMasterData.forEach((rmd: any) => {
				if (rmd.role?.toLowerCase() === lur?.toLowerCase()) {
					previleages = [...previleages, ...rmd.privileges];
				}
			});
		});
		return previleages;
	}

	// this funtion used to validate the buttons and other actions icons based on logged in user privileges
	isPrivilegeAvailable(privilege: any) {
		let privileges = [];
		if (this.loggedinUserPrivileges && this.loggedinUserPrivileges.length) {
			privileges = this.loggedinUserPrivileges;
		} else {
			privileges = this.userValue;
		}
		return privileges.indexOf(privilege) > -1;
	}
}
