import { Injectable } from "@angular/core";
import { DateTime } from "luxon";

@Injectable()
export class TimeParserUtil {
	constructor() {}

	/**
	 *
	 * @param param containes two keys.
	 * 1) isDefault - If it is true then it will return the default name otherwise it will append with GMT
	 * 2) isObject - object or string. Default Values are array of strings
	 * return offsetTimeZone
	 */
	getUnixTime(param: any) {
		let data: any = {
			date: "",
			status: ""
		};
		if (param?.date) {
			data.date = param.date.toUnixInteger();
		} else {
			let now = DateTime.local();
			data.date = now.toUnixInteger();
		}
		return data;
	}

	getCalendarStartDate() {}

	/**
	 * Parses time zone & format
	 * @param param - containes the below attributes
	 * "date", "timeZone", "format"
	 * Example Format
	 * DD-MM-YYYY, HH:mm:ss zz --> Date time with timezone
	 * DD-MM-YYYY, HH:mm:ss --> Date Time 24 hrs
	 * DD-MM-YYYY, HH:mm:ss a--> Date Time 12 hrs AM/PM
	 * DD-MM-YYYY --> Date
	 */
	parseTimeZone(_param: any) {
		// TODO
	}

	/**
	 * format date
	 * @param param
	 * @returns
	 * function will parse a moment with the offset fixed to the provided offset
	 * fixed offset & it is NOT a time zone
	 */
	formatDate(_param: any) {
		// TODO
	}

	/**
	 * Parses calendar end date utc
	 * @param param
	 */
	parseCalendarEndDateUtc(_param: any) {
		// TODO
	}

	getUserLocalTimeZone() {
		// TODO
	}

	formatDateRange(_param: any) {
		// TODO
	}

	// Get current local time and convert to UTC
	getCurrentLocalTimeToGMT() {
		// Get the current local time
		const localTime = DateTime.local();

		// Convert local time to GMT
		const gmtTime = Math.floor(localTime.toUTC().toSeconds());

		return gmtTime;
	}

	convertDateTimeToGMT(dateObject: {
		year: number;
		month: number;
		day: number;
		hour: number;
		minute: number;
		second: number;
	}) {
		const opts = {
			zone: "local"
		};
		const localDateTime = DateTime.fromObject(
			{
				year: dateObject.year,
				month: dateObject.month,
				day: dateObject.day,
				hour: dateObject.hour,
				minute: dateObject.minute,
				second: dateObject.second
			},
			opts
		);

		const gmtDateTime = localDateTime.toUTC();

		const gmtTimeInSeconds = Math.floor(gmtDateTime.toSeconds());

		return gmtTimeInSeconds;
	}

	getCurrentLocalTime() {
		// Get the current local time
		const localTime = DateTime.local().toFormat("HH:mm:ss");

		return localTime;
	}

	getDaysDifferenceFromCurrentDate(dateObject: { year: number; month: number; day: number }) {
		// Get the current date
		const currentDate = DateTime.local().startOf("day");

		// Create a Luxon DateTime object for a specific day
		const specificDate = DateTime.fromObject({
			year: dateObject.year,
			month: dateObject.month,
			day: dateObject.day
		}).startOf("day");

		// Calculate the difference between the current day and the specific day
		const diffInDays = specificDate.diff(currentDate, "days").days;

		return diffInDays;
	}

	// Get the current date's start time in IST (current date with start time 12:00)
	getISTCurrentDateStartTime() {
		const currentDateStartIST = DateTime.local()
			.setZone("Asia/Kolkata")
			.startOf("day")
			.toSeconds();
		return Math.floor(currentDateStartIST);
	}

	// Get the current date with till current time in IST
	getISTCurrentDateEndTime() {
		const currentDateTimeIST = DateTime.local()
			.setZone("Asia/Kolkata")
			.toSeconds();
		return Math.floor(currentDateTimeIST);
	}

	toDateTime(secs: any) {
		var tzOffsetMilliSeconds = -(new Date().getTimezoneOffset() * 60 * 1000);
		var tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;
		return new Date(secs * 1000 + tzOffsetMilliSeconds).toISOString().substring(0, 19) + " " + tzName;
	}
	utctoIstTime(dateUTC: any) {
		if (dateUTC) {
			dateUTC = dateUTC.replace("Z[UTC]", "");
		}
		let date = new Date(dateUTC);
		//date shifting for IST timezone (+5 hours and 30 minutes)
		date.setHours(date.getHours() + 5);
		date.setMinutes(date.getMinutes() + 30);
		date = date;
		let dates = date.toString();
		dates = dates
			.split(" ")
			.slice(0, 5)
			.join(" ");
		var tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;
		return dates + " " + tzName;
	}
	utctoIstTimeWithoutZone(dateUTC: any) {
		if (dateUTC) {
			dateUTC = dateUTC.replace("Z[UTC]", "");
		}
		let date = new Date(dateUTC);
		//date shifting for IST timezone (+5 hours and 30 minutes)
		date.setHours(date.getHours() + 5);
		date.setMinutes(date.getMinutes() + 30);
		date = date;
		let dates = date.toString();
		dates = dates
			.split(" ")
			.slice(0, 5)
			.join(" ");
		//var tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;
		return dates;
	}
	
	epochToHumanReadable(epochTime: any, timeProps : string) {
		let date = new Date(epochTime * 1000);
		// Months in JavaScript are 0-indexed, so we add 1 to get the correct month
		let month = date.getMonth() + 1;

		// Pad single digits with leading zero
		let day = String(date.getDate()).padStart(2, '0');
		let monthStr = String(month).padStart(2, '0');

		// Get year, hours, minutes, and seconds
		let year = date.getFullYear();
		let hours = date.getHours().toString().padStart(2,'0');
		let minutes = date.getMinutes().toString().padStart(2,'0');

		// Construct the human-readable date string
		let dateString = ''
		if (timeProps == "date") {
			dateString = `${day}/${monthStr}/${year}`
		}
		else {
			dateString = `${hours}:${minutes}`
		}
		return dateString;
	}

	getTimeToReadableISTFormat(secs: any) {
		let ISTReadableFormat = DateTime.fromSeconds(secs).toFormat('dd LLL, yyyy hh:mm a') ; //=>15 Mar,2024 09:51 AM
		return ISTReadableFormat;
	} 

	ISOToReadableFormat(date: any) {
		return DateTime.fromISO(date).toFormat("dd LLL yyyy hh:mm a");
	}
}
