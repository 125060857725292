import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "listToCommaSeperated"
})
export class ListToCommaSeperatedPipe implements PipeTransform {
	transform(values: any, ...args: any[]): any {
		// arg[0] - indicates key to fetch
		if (Array.isArray(values) && values.length) {
			if (typeof values[0] === "object") {
				const key: string = args?.length && args[0];
				return values.map((value: any) => value[key])?.join(", ");
			} else if (typeof values[0] === "string") {
				return values.map((value: any) => value)?.join(", ");
			}
		} else if (typeof values === "string" && values?.length) {
			return [values].join(", ");
		}
	}
}
