import { Component, EventEmitter, Input, Output } from "@angular/core";
import { debounceTime, Subject } from "rxjs";
import { DataParams } from "../../models/utils";
import { DataSharedService } from "../../services/data-shared.service";

@Component({
	selector: "app-search-input",
	templateUrl: "./search-input.component.html",
	styleUrls: ["./search-input.component.scss"]
})
export class SearchInputComponent {
	@Input() dataParams: DataParams = {};
	@Input() searchTerm = "";	
	@Input() placeholder: string = 'Search';
	@Output() setSearchValue: EventEmitter<string> = new EventEmitter<string>();

	_searchInput: Subject<string> = new Subject();

	constructor(private dataSharedService: DataSharedService) {
		this._setSearchSubscription();
	}

	_setSearchSubscription() {
		this._searchInput.pipe(debounceTime(500)).subscribe((searchValue: string) => {
			this.setSearchValue.emit(searchValue);
		});
	}

	public updateSearch(value: string) {
		this._searchInput.next(value);
	}

	ngOnDestroy() {
		this._searchInput.unsubscribe();
	}

	ngOnInit() {
		this.searchTerm = this.dataSharedService.getSearchTerm();
	}
}
