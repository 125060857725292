import { Injectable } from "@angular/core";
import { map, Observable, of ,BehaviorSubject} from "rxjs";
import { environment } from "src/environments/environment";
import { AgHttpService } from "src/app/shared/services/ag-http-service";
import { ApiUrlService } from "src/app/shared/services/api-url.service";
import { API_URLS } from "src/app/shared/models/utils";

@Injectable({
	providedIn: "root"
})
export class CommonService {
	apiUrls!: API_URLS;
	public basePath = "";
	selectedCustomer: Object = {};
	public isUserDetailsFetched = false;
	public isUserPrivilegesFetched = false;
	public isCustomersDataFetchComplete = false;
	public isCustomersDataFetchError = false;
	spaedBasePath = "";
	spaedSecretKey = "";
	spaedContextPath = "";
	customerSecretKey = "";
	customerContextPath = "";
	loggedInUserInfo: any;
	dataSource: any = {
		privileges: [],
		setPrivilegesByLoggedinUserRole: [],
		customersData: []
	};
	selectedSiteCustId: any;

	constructor(private agHttpService: AgHttpService, private apiurlService: ApiUrlService) {
		this.basePath = environment?.basePath ? environment.basePath : "";
		this.apiUrls = this.apiurlService.getAPIUrls();
	}

	// ngOnInit() {
	// 	this.basePath = environment?.basePath ? environment.basePath : "";
	// 	this.apiUrls = this.apiurlService.getAPIUrls();
	// }

	private userServiceType = new BehaviorSubject<string>('SPAED'); // Initialize with default value

	setUserServiceType(condition: string) {
		this.userServiceType.next(condition);
 	}

	getUserServiceType() {
	return this.userServiceType.asObservable();
	}

	setCustomersData(customers: any) {
		this.dataSource.customersData = customers;
	}

	setContextpathOnCustomerselect() {
		const customerVal: any = this.selectedCustomer.valueOf();
		const selCust = this.dataSource.customersData;
		const index = selCust.findIndex((customer: any) => customer.value === customerVal?.value);
		if(index === -1) return;
		const selectedCust = selCust[index];
		this.setSpaedBasePath(selectedCust?.customerContextPath);
		this.setCustomerContextPath(selectedCust?.customerContextPath);
		this.setCustomerSecretKey(selectedCust?.customerSecretkey);
	}
	// changeDateFormate(matDate: any): any {
	//     const date = new Date(matDate);
	//     return (new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1,
	//         date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())).toISOString();
	// }

	// getCustomerByServiceId(id: number): Observable<any> {
	//     const url = `${this.basePath}/iti-portal-core/customer/customers/by/serviceId?serviceId=${id}`;
	//     return this.agHttpService.makeGetRequest(url);
	// }

	// getCustomersByUserServiceId(serviceId: number): Observable<any> {
	//     const userId = this.dataSharedSvc.getLoggedinUserInfo()?.id;
	//     const url = `${this.basePath}/iti-visualization/api/customer?serviceId=${serviceId}&userId=${userId}`;
	//     return this.agHttpService.makeGetRequest(url);
	// }

	// getAllCustomers(): Observable<any> {
	//     const userId = this.dataSharedSvc.getLoggedinUserInfo()?.id;
	//     const url = `${this.basePath}/iti-portal-core/customer/customers/by/userid?userId=${userId}`;
	//     return this.agHttpService.makeGetRequest(url).pipe(map((res: any) => {
	//        return {data: res.data.map((data: any) =>  ({customerId: data.id, displayName: data.displayName}))}
	//     }));
	// }

	getCities(state: number): Observable<any> {
		// const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/auxiliary-data/cities/${state}`;
		const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/common/cities/list/${state}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getCountries(): Observable<any> {
		// const url = environment.spaedBasePath + "/auxiliary-data/countries";
		const url = this.apiUrls.CUSTOMER_MANAGEMENT_URL + "/customer/list/customer/auxiliary-data";
		return this.agHttpService.makeGetRequest(url).pipe(map((res: any) => res));
	}

	public getStates(countryCode: string): Observable<any> {
		// const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/auxiliary-data/states/${countryCode}`;
		const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/common/states/auxiliary-data/${countryCode}`;
		return this.agHttpService.makeGetRequest(url).pipe(map((res: any) => res));
	}

	getAllAlias(customerId: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/alias/customer/${customerId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getAliasForSites(customerId: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/alias/customer/${customerId}`;
		//return this.agHttpService.makeGetRequest(url);
		return of({});
	}

	getCustomers(): Observable<any> {
		const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/customer/customers/user`;
		return this.agHttpService.makeGetRequest(url);
	}

	setSelectedCustomer(customer: any) {
		this.selectedCustomer = customer;
	}

	getSelectedCustomer() {
		return this.selectedCustomer;
	}

	// should move to util
	/**
	 *
	 * @param previous
	 * @param current
	 */
	comparePreviousAndCurrentValue(previous: {}, current: {}) {
		let selectedValue: any;
		if (previous && Object.keys(previous).length) {
			selectedValue = previous;
		} else {
			selectedValue = current;
		}
		return selectedValue;
	}
	setSpaedBasePath(contextPath: any) {
		let basePathTemp = environment.conspaedBasePath;
		//this.spaedBasePath = basePathTemp.replace(this.getSpaedContextPath(), contextPath);
		this.setSpaedContextPath(contextPath);
		this.spaedBasePath = basePathTemp.replace("host-name/context-path", contextPath);
		environment.spaedBasePath = this.spaedBasePath;
	}

	getSpaedBasePath() {
		return this.spaedBasePath;
	}

	setSpaedContextPath(contextPath: any) {
		this.spaedContextPath = contextPath;
	}

	getSpaedContextPath() {
		return this.spaedContextPath;
	}

	setSpaedSecretKey(spaedSecretKey: any) {
		this.spaedSecretKey = spaedSecretKey;
	}

	getSpaedSecretKey() {
		return this.spaedSecretKey;
	}
	setCustomerContextPath(customerContextPath: any) {
		this.customerContextPath = customerContextPath;
	}

	getCustomerContextPath() {
		return this.customerContextPath;
	}

	setCustomerSecretKey(customerSecretKey: any) {
		this.customerSecretKey = customerSecretKey;
	}

	getCustomerSecretKey() {
		return this.customerSecretKey;
	}
	setLoggedInUserInfo(userInfo: any) {
		this.loggedInUserInfo = userInfo;
		this.setUserServiceType(userInfo?.serviceType || "SPAED")
	}

	getLoggedInyUserInfo() {
		return this.loggedInUserInfo;
	}

	setPrivileges(privileges: any) {
		this.dataSource.privileges = privileges;
	}

	getPrivileges() {
		return this.dataSource.privileges;
	}

	setPrivilegesByLoggedinUserRole(privileges: any) {
		this.dataSource.setPrivilegesByLoggedinUserRole = privileges;
	}

	getPrivilegesByLoggedinUserRole() {
		return this.dataSource.setPrivilegesByLoggedinUserRole;
	}

	getCustomersData(): any {
		return [...this.dataSource.customersData];
	}

	getWebNativeUIToken(deviceId:any): Observable<any> {
		const response = { 

			"hostname": "device1116372979_controllerTestSQLInjection.NotSet.secureserviceedge.tatacommunications.com", 
		
			"expires": 1693580057, 
		
			"deviceUsername": "admin", 
		
			"previousExpiry": 1693579948, 
		
			"httpProxyUser": "41i+jsVYuybK0pQuGpAgVnKILm1M0Zc=", 
		
			"httpProxyPassword": "68v28872D0FsBkv7wf4GCLRCKRmJiMA=", 
		
			"devicePassword": "Tata@123" 
		
		} 

		const url = `${environment.spaedBasePath}/devices/${deviceId}/get-native-webui-token`;
		return this.agHttpService.makeGetRequest(url);
		//return of(response);
	}
	getAllDevice(): Observable<any>{
		let url = `${environment.spaedBasePath}/devices`;
			return (this.agHttpService.makeGetRequest(url));
	}

	public getAuxiliaryData(): Observable<any> {
		const url = this.basePath + "/customer/list/customer/auxiliary-data";
		return this.agHttpService.makeGetRequest(url).pipe(map((res: any) => res.data));
	}

	parseLanVlanNetworkRangeDetails(sites: any) {
		let ipRangeDetails: any = [];
		let name: any = "";
		sites.forEach((site: any) => {
			let lanDefaultIpDetails: any = [];
			let lanStaticIpDetails: any = [];
			let vlanOnLinkIpDetails: any = [];
			let vlanStaticIpDetails: any = [];
			name = site.name;
			if (site?.devices?.length) {
				const lanDevices = site?.devices?.filter((device: any) => device.lan);
				const vlanDevices = site?.devices?.filter((device: any) => device.vlan);
				lanDefaultIpDetails = lanDevices?.map((device: any) => ({
					ipDetails: [device.lan.ip]
				}));
				lanDevices?.forEach((device: any) => {
					device?.lan?.staticRoutes?.forEach((route: any) => {
						lanStaticIpDetails.push(route.subnet);
					});
				});
				vlanDevices?.forEach((device: any) => {
					device?.vlan?.forEach((vlan: any) => {
						vlanOnLinkIpDetails.push(vlan.ip);
						vlan.staticRoutes?.forEach((route: any) => {
							vlanStaticIpDetails.push(route.subnet);
						});
					});
				});
				let parsedDetails = {
					name: name,
					lanDefaultIpDetails,
					lanStaticIpDetails,
					vlanOnLinkIpDetails,
					vlanStaticIpDetails
				};
				ipRangeDetails.push(parsedDetails);
			}
		});
		return ipRangeDetails;
	}
	
	public ipWithSubnetMaskValidation(payload: any): Observable<any> {
		const url = `${this.apiUrls.SECURITY_MANAGEMENT_URL}/network/config/ip/validation`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	searchCloudLocationData(searchTerm: string): Observable<any> {
		const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/common/cities/name?name=${searchTerm}`;
		return this.agHttpService.makeGetRequest(url);
	}

	public getCommonCountries(): Observable<any> {
		const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/customer/list/customer/auxiliary-data`;
		//const url = environment.custMgmtBasePath + "/customer/list/customer/auxiliary-data";
		return this.agHttpService.makeGetRequest(url).pipe(map((res: any) => res.data.countries));
	}

	public getCommonStates(countryCode: number): Observable<any> {
		const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/common/states/auxiliary-data/${countryCode}`;
		return this.agHttpService.makeGetRequest(url).pipe(map((res: any) => res.data.states));
	}

	public getCommonCities(state: number): Observable<any> {
		const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/common/cities/list/${state}`;
		return this.agHttpService.makeGetRequest(url).pipe(map((res: any) => res));		
	}
	
	customRangeValidation(inputParam: any) {
		let isSameRange = false;
		if (
			inputParam?.startDate === inputParam.selectedStartDate &&
			inputParam.endDate === inputParam.selectedEndDate
		) {
			isSameRange = true;
		}
		return isSameRange;
	}

	getFormattedDate(date: Date): { [key: string]: any } {
		const month = date.toLocaleString("en-US", { month: "short" });
		const year = date.toLocaleString("en-US", { year: "numeric" });
		const day = date.getDate();
		return {
			month,
			year,
			day
		};
	}


	setSiteCustomerId(siteCusId:any) {		
		this.selectedSiteCustId = siteCusId;
	}

	getSiteCustomerId() {
		return this.selectedSiteCustId;		
	}
 
}
