<div class="confirm-dialog">
	<div class="dialog-header">
		<div class="d-flex justify-content-between align-items-center">
			<p class="dialog-title py-2 px-3 mb-0">Upload File</p>
			<button type="button" mat-dialog-close class="btn-close me-3" aria-label="Close"></button>
		</div>
	</div>

	<div class="px-3 py-2">
		<div>
			<div class="mb-1">
				<!-- <span class="upload-info" hidden="true">Max file size is 500KB.</span> -->
				<span
					class="upload-info"
					*ngIf="fileUploadModel.allowedExtentions.length <= 1; else multipleTypesMessage">
					Supported file type is {{ fileUploadModel.allowedExtentions }}
				</span>
				<ng-template #multipleTypesMessage>
					<span class="upload-info">Supported file types are {{ fileUploadModel.allowedExtentions }}</span>
				</ng-template>
			</div>
			<span
				class="cursor-pointer file-selection-con"
				(click)="fileUpload.click()"
				*ngIf="!flags?.isFileSelected; else selectedFileTemplate">
				<div class="upload-box">
					<span><i class="fa fa-upload"></i></span>
					<span>Select File</span>
				</div>
				<input
					#fileUpload
					class="d-none"
					id="file-upload"
					type="file"
					[accept]="fileUploadModel.allowedExtentions"
					title="Select file"
					[multiple]="fileUploadModel.multiFile"
					(change)="onFileSelect($event)" />
			</span>
			<ng-template #selectedFileTemplate>
				<span class="file-selection-con">
					<span
						class="file-badge"
						[ngClass]="fileUploadModel.selectedFileNames!.length > 1 ? 'badge-space' : ''"
						*ngFor="let fileName of fileUploadModel.selectedFileNames; let i = index">
						<span class="m-r-5">{{ fileName }}</span>
						<button type="button" class="remove-btn" (click)="onRemoveFile(i)">
							<i class="fa fa-times-circle-o cursor-pointer" aria-hidden="true"></i>
						</button>
					</span>
				</span>
			</ng-template>
		</div>
		<div *ngIf="!flags?.isValidFormat" class="text-danger pt-1">
			<span *ngIf="fileUploadModel.warningMessage; else defaultErrorTemplate">
				<span>{{ fileUploadModel.warningMessage }}</span>
			</span>
		</div>
		<ng-template #defaultErrorTemplate>
			<span>Please upload file with valid extension.</span>
		</ng-template>
		<div class="text-danger pt-1" *ngIf="flags?.isFileEmpty">
			<span>File is empty. Please upload a file containing valid data.</span>
		</div>
	</div>
	<div class="confirm-btn">
		<div class="d-flex justify-content-end">
			<span [ngClass]="!flags.isFileSelected ? 'not-allowed' : ''">
				<button
					class="btn btn-primary custom-btn btn-config"
					color="primary"
					type="button"
					[disabled]="!flags.isFileSelected || flags?.isFileEmpty"
					(click)="onUploadClick()">
					<span *ngIf="fileUploadModel.uploadButtonName; else uploadText">
						{{ fileUploadModel.uploadButtonName }}
					</span>
					<ng-template #uploadText>
						<span>Upload</span>
					</ng-template>
				</button>
			</span>
		</div>
	</div>
</div>
