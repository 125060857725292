import { Component, ElementRef, HostListener, Input, SimpleChanges } from "@angular/core";
import { of } from "rxjs";
@Component({
	selector: "app-toggle-up-down",
	templateUrl: "./toggle-up-down.component.html",
	styleUrls: ["./toggle-up-down.component.scss"]
})
export class ToggleUpDownComponent {
	@Input() isWindowHeightChanged!: boolean;
	flags: any = {
		showScrollToBottomBtn: false,
		showScrollToTopBtn: false
	};
	scrollTimeOut: any;
	scrollListener: any;
	scrolledToTop: boolean = false;

	ngOnInit() {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes["isWindowHeightChanged"] && changes["isWindowHeightChanged"].currentValue) {
		  this.onClickScrollDown();
		}
	  }

	@HostListener("window:scroll", [])
	onWindowScroll() {
		this.flags.showScrollToBottomBtn = window.scrollY > 30;
		if (this.flags.showScrollToBottomBtn) {
			this.flags.showScrollToTopBtn =
				Math.ceil(window.scrollY) >= document.documentElement.scrollHeight - window.innerHeight;
			Math.ceil(window.scrollY) >= document.documentElement.scrollHeight - window.innerHeight
				? (this.flags.showScrollToBottomBtn = false)
				: "";
		}
		this.enableIconHideTimeOut();
	}

	enableIconHideTimeOut() {
		clearTimeout(this.scrollTimeOut);
		this.scrollTimeOut = setTimeout(() => {
			this.flags.showScrollToBottomBtn = false;
		}, 3000);
	}

	onClickScrollDown() {
		window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
		this.flags.showScrollToBottomBtn = false;
		this.flags.showScrollToTopBtn = true;
		this.enableIconHideTimeOut();
	}

	onClickScrollUp() {
		window.scrollTo({ top: 0, behavior: "smooth" });
		this.flags.showScrollToTopBtn = false;
		this.enableIconHideTimeOut();
	}

	resetFlags() {
		this.flags.showScrollToBottomBtn = false;
		this.flags.showScrollToTopBtn = false;
	}

	onHoverIcon() {
		clearTimeout(this.scrollTimeOut);
	}

	onRemoveHover() {
		this.enableIconHideTimeOut();
	}

	ngOnDestroy() {
		window.removeEventListener("scroll", this.scrollListener);
		clearTimeout(this.scrollTimeOut);
		this.resetFlags();
	}
}
