<div class="container_cus" *ngIf="showCustomerDropDown">
		<div class="row">
			<div class="col-md-12 col-sm-12">
				<div class="form-group">
					<!-- <label class="form-label" for="customer">Customer </label> -->
					<ng-multiselect-dropdown
						[settings]="dropdownSettings"
						class="customer-custom-multi-select custom-multi-select cust-global-select hide-checkbox multiselect--dropdown w100"
						[data]="customers"
						[(ngModel)]="valueSelected"
						[ngModelOptions]="{ standalone: true }"
						(onSelect)="onSelectCustomer($event)"
						(onDeSelect)="onDeselectCustomer($event)"
						placeholder="Select Customer"></ng-multiselect-dropdown>
					<div class="text-danger">
						<!-- {{formErrors.customerId}} -->
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- <div class="row p-3">
		<div class="col-md-4 col-sm-6">
			<div class="form-group">
				<label class="form-label" for="customer">Customer</label>
				<ng-multiselect-dropdown [settings]="dropdownSettings"
					class="custom-multi-select  hide-checkbox multiselect--dropdown w100" [data]="customers"
					[(ngModel)]="selectedCustomer" [ngModelOptions]="{'standalone': true}"
					(onSelect)="onSelectCustomer($event)" (onDeSelect)="onDeselectCustomer($event)"
					placeholder="Select Customer">
				</ng-multiselect-dropdown>
				<div class="text-danger">                
				</div>
			</div>
		</div>
	</div> -->
	