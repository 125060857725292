import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "../services";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private authenticationService: AuthenticationService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const user = this.authenticationService.userValue;
		if (user) {
			// check if route is restricted by role
			let routeData = route?.data["roles"];
			let previleages = routeData.filter((o: any) => user.indexOf(o) > -1);
			if (!previleages?.length) {
				// role not authorized so redirect to home page
				this.router.navigate(["/forbidden"]);
				return false;
			}
			return true;
		} else {
			// To be handled the refresh scenario
		}
		// This is not applicable. Need to remove.
		return true;
	}
}
