<div class="row mt-4 pb-3 site-list">
	<div class="col-12 col-md-12 col-sm-12">
		<div class="card p-3">
			<div class="col-md-12 mb-0">
				<div class="col-md-12 d-flex align-items-center">
					<div>
						<h2 class="page-title">Reports</h2>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-5">
					<div class="custom-container">
						<div class="d-flex" appTooltip="{{!dataSource?.clonedUrlReportDetails?.length && !dataSource?.clonedSecurityEventDetails?.length ? 'No events found for search /  export' : ''}}">
							<span class="col-6"
								[ngClass]="{'disabled-search-input' : (!(dataSource?.urlReportDetails?.length ||  dataSource?.reportDetails?.length) && !searchTerm.length) && flags?.isShowTableDetails}">
								<app-search-input (setSearchValue)="search($event)" [searchTerm]="searchTerm"
									[dataParams]="dataParams"
									[placeholder]="'Search in URLs / Security Events'"></app-search-input>
							</span>
							<span *ngIf="!flags?.isDownloadInprogress; else downloadLoaderTemplate">
								<button class="btn btn-primary custom-btn btn-config export-search-btn export-btn"
									[ngClass]="{'disabled-btn': !dataSource?.clonedUrlReportDetails?.length && !dataSource?.clonedSecurityEventDetails?.length}"
									type="button" (click)="onClickExport()">
									<i class="fa fa-file-excel-o" aria-hidden="true"></i>
									<span class="p-l-4">Export</span>
								</button>
							</span>
							<ng-template #downloadLoaderTemplate>
								<button
									class="btn btn-primary custom-btn btn-config export-search-btn export-btn disabled-btn"
									type="button" appTooltip="Download in progress">
									<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
									<span class="p-l-4">Export</span>
								</button>
							</ng-template>
						</div>
					</div>
				</div>
				<div class="col-7">
					<div class="flex-wrap d-flex justify-content-end gap-2">
						<div class="col-3 customer-select-drop-down">
							<app-customer-select-list [isShowAll]="false"
								(afterInitEvent)="getSelectCustomerList($event)"
								(selectValue)="onSelectCustomer($event)"></app-customer-select-list>
						</div>
						<div class="col-3">
							<app-multi-select [selection]="'multiple'" [data]="dataSource?.siteList"
								[selected]="selectedSite" (onDropdownClose)="onSiteDropdownClosed($event)"
								class="dashboard-sites custom-multi-select sm-height multiselect--dropdown"
								placeholder="Select Site"></app-multi-select>
							<div *ngIf="selectedSite.length === 0" class="position-relative">
								<span class="text-danger">Select atleast one site</span>
							</div>
						</div>
						<div>
							<app-date-range [date]="date" (dateChanged)="onDateChanged($event)"></app-date-range>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="flags?.isShowTableDetails" class="row mt-3">
				<div class="row mx0 mb-4 col-md-12 col-sm-12">
					<mat-accordion class="p-all-0 report-accordion">
						<mat-expansion-panel [expanded]="true">
							<mat-expansion-panel-header class="panel-header-bg">
								<mat-panel-title class="panel-title text-orange">URLs</mat-panel-title>
							</mat-expansion-panel-header>
							<div class="col-md-12 col-sm-12">
								<app-custom-data-table [settings]="{
								data: dataSource?.urlReportDetails,
								columnConfig: urlTableColumns,
								length: dataSource?.urlReportDetails?.length,
								isClientSidePagination: true,
								loaderFlags: {
									isLoading: flags?.isUrlfDataLoading,
									isErrorFetchingData: false
								},
								noRecordsErrorMessage: 'No events found!',	
								hidePagination: !dataSource?.urlReportDetails?.length ? true : false,
								recordsPerPage: recordsPerPage,
								pageSize: pageSize,
							}"></app-custom-data-table>
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</div>

				<div class="row mx0 mb-4 col-md-12 col-sm-12">
					<mat-accordion class="p-all-0 report-accordion">
						<mat-expansion-panel
							[expanded]="!dataSource?.reportDetails?.length || !dataSource?.urlReportDetails?.length ? true : false">
							<mat-expansion-panel-header class="panel-header-bg">
								<mat-panel-title class="panel-title text-orange">Security Events</mat-panel-title>
							</mat-expansion-panel-header>
							<div class="col-md-12 col-sm-12">
								<app-custom-data-table [settings]="{
								data: dataSource?.reportDetails,
								columnConfig: this.reportTableColumns.columnConfig,
								length: dataSource?.reportDetails?.length,
								isClientSidePagination: true,
								loaderFlags: {
									isLoading: flags.isSeDataLoading,
									isErrorFetchingData: false
								},
								noRecordsErrorMessage: 'No events found!',	
								hidePagination: !dataSource?.reportDetails?.length ? true : false,
								recordsPerPage: recordsPerPage,
								pageSize: pageSize,
							}"></app-custom-data-table>
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-container *ngIf="flags?.isShowProgress">
	<app-loader></app-loader>
</ng-container>