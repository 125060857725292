export const environment = {
    production: false,
    keyCloakConfigFile: 'assets/config/keycloak/dev-keycloak.json',
    basePath: 'http://localhost:7070/spaed-v1',
    deviceBasePath: 'https://stg.opstic.tatacommunications.com/spaed-cm',
    //deviceBasePath: 'http://localhost:8091/spaed-dm',
    keycloakURL: 'https://stgkeycloak.opstic.tatacommunications.com',
    keycloakContextPath: 'https://stg.opstic.tatacommunications.com/spaed-controller',
    userMgmtBasePath: 'https://stg.opstic.tatacommunications.com/spaed-v1/user-management',
    //userMgmtBasePath: 'http://localhost:8092/spaed-v1/user-management',
    custMgmtBasePath: 'https://stg.opstic.tatacommunications.com/spaed-v1/customer-mgmt',
    //custMgmtBasePath: 'http://localhost:8091/spaed-v1/customer-mgmt',
    customerMgmtBasePath: 'customer-mgmt',
    monitoringServiceBasePath: 'monitoring-service',
    networkMgmtBasePath: 'network-mgmt',
    securityMgmtBasePath: 'http://localhost:7070/security-mgmt',
    //spaedBasePath: 'https://pandora.secureserviceedge.tatacommunications.com/Test-6/SCDX/api/v1'
    //spaedBasePath: 'https://pandora.secureserviceedge.tatacommunications.com/ui-test-1/SCDX/api/v1'
    //spaedBasePath: 'https://athena.baselinux.net/Test-1/SCDX/api/v1'
    //spaedBasePath: 'https://pandora.secureserviceedge.tatacommunications.com/Test-3/SCDX/api/v1'
    spaedBasePath: 'https://host-name/context-path/SCDX/api/v1',
    conspaedBasePath: 'https://host-name/context-path/SCDX/api/v1',
    remoteUpgradeBasePath:'https://stg.opstic.tatacommunications.com/spaed-ru',
}
