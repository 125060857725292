import { IDropdownSettings } from "ng-multiselect-dropdown";

export function singleSelectionDropdownSettings(
	settings = { idFieldValue: "value", textFieldValue: "label" }
): IDropdownSettings {
	return {
		singleSelection: true,
		idField: settings.idFieldValue,
		textField: settings.textFieldValue,
		selectAllText: "Select All",
		unSelectAllText: "UnSelect All",
		itemsShowLimit: 1,
		closeDropDownOnSelection: true,
		allowSearchFilter: true
	};
}

export function multiSelectionDropdownSettings(
	settings = { idFieldValue: "value", textFieldValue: "label" }
): IDropdownSettings {
	return {
		singleSelection: false,
		idField: settings.idFieldValue,
		enableCheckAll: true,
		textField: settings.textFieldValue,
		selectAllText: "Select All",
		unSelectAllText: "UnSelect All",
		itemsShowLimit: 1,
		allowSearchFilter: true
	};
}
