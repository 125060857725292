import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "./app.service";
import { PreviousRouteService } from "./shared/services/route-service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, AfterViewInit {
	constructor(
		private router: Router,
		private appService: AppService,
		private previousRouteService: PreviousRouteService
	) {}
	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.appService.getLoggedInUserDetails().subscribe({
			next: (response: any) => {
				if (response?.responseCode === 0) {
					this.appService.setUserDetails(response.data);
				}
			}
		});
	}
}
