import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";

interface TunnelData {
	sourceDevice: Interfaces[];
	destinationDevice: Interfaces[];
}

interface Interfaces {
	x: number;
	y: number;
	width: number;
	height: number;
	name: string;
	status: boolean;
}
@Component({
	selector: "app-tunnel-connectivity-flow",
	templateUrl: "./tunnel-connectivity-flow.component.html",
	styleUrls: ["./tunnel-connectivity-flow.component.scss"]
})
export class TunnelConnectivityFlowComponent implements OnChanges {
	@Input() tunnelData!: TunnelData;
	@ViewChild("canvas", { static: true }) canvas!: ElementRef<HTMLCanvasElement>;

	private ctx!: any;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes["tunnelData"].currentValue) {
			this.drawDiagram();
		}
	}

	ngAfterViewInit() {
		if (this.canvas && this.canvas.nativeElement) {
			this.ctx = this.canvas.nativeElement.getContext("2d");
			if (this.ctx) {
				this.drawDiagram();
			}
		}
	}

	drawDiagram() {
		if (!this.ctx) {
			return;
		}
		// Rest of the method remains unchanged

		// Clear the canvas
		this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);

		const maxInterfaces = Math.max(this.tunnelData.sourceDevice.length, this.tunnelData.destinationDevice.length);

		const circleYPos = maxInterfaces > 2 ? 105 : 75;

		const serviceEdge = { x: 250, y: circleYPos, radius: 30, label: "POP" };

		this.canvas.nativeElement.height = 45 * (maxInterfaces >= 3 ? maxInterfaces : 3) + 10;

		// Draw left interfaces
		this.tunnelData.sourceDevice.forEach((iface) => this.drawInterface(iface));

		// Draw right interfaces
		this.tunnelData.destinationDevice.forEach((iface) => this.drawInterface(iface));

		// Draw service edge (circle)
		this.drawServiceEdge(serviceEdge);

		// Draw connections from left interfaces to service edge
		if (this.tunnelData.sourceDevice.length > 2 || this.tunnelData.destinationDevice.length > 2) {
			this.tunnelData.sourceDevice.forEach((iface, index) =>
				this.drawCurvedConnection(iface, serviceEdge, index, this.tunnelData.sourceDevice.length, true)
			);
			this.tunnelData.destinationDevice.forEach((iface, index) =>
				this.drawCurvedConnection(serviceEdge, iface, index, this.tunnelData.destinationDevice.length, false)
			);
		} else {
			this.tunnelData.sourceDevice.forEach((iface, index) =>
				this.drawConnection(iface, serviceEdge, index, this.tunnelData.sourceDevice.length, true)
			);

			this.tunnelData.destinationDevice.forEach((iface, index) =>
				this.drawConnection(serviceEdge, iface, index, this.tunnelData.destinationDevice.length, false)
			);
		}
	}

	drawInterface(iface: any) {
		// Draw the rectangle
		this.ctx.fillStyle = iface?.status ? "#20bf2038" : "#ff00003d";
		this.ctx.fillRect(iface.x, iface.y, iface.width, iface.height);

		// Draw the small circle
		const circleRadius = 4;
		const circleX = iface.x + 10; // Circle's x position inside the rectangle
		const circleY = iface.y + iface.height / 2; // Center the circle vertically
		this.ctx.fillStyle = iface.status ? "green" : "#c00606"; // Circle color
		this.ctx.beginPath();
		this.ctx.arc(circleX, circleY, circleRadius, 0, 2 * Math.PI);
		this.ctx.fill();

		// Draw the label text
		this.ctx.fillStyle = "black";
		this.ctx.font = "12px Gotham-Medium";
		const textX = circleX + circleRadius + 5; // Adjust the text position to the right of the circle
		const textY = iface.y + (iface.height + 10) / 2; // Center the text vertically
		this.ctx.fillText(iface.label, textX, textY);
	}

	drawServiceEdge(edge: any) {
		this.ctx.fillStyle = "white";
		this.ctx.beginPath();
		this.ctx.arc(edge.x, edge.y, edge.radius, 0, 2 * Math.PI);
		this.ctx.fill();

		this.ctx.fillStyle = "black";
		this.ctx.font = "12px Gotham-Medium";
		this.ctx.fillText(edge.label, edge.x - 15, edge.y - 10);

		// Load the image
		const image = new Image();
		image.src = "./assets/icons/datacenter.svg";
		image.onload = () => {
			// Calculate the dimensions of the image to fit inside the circle
			const imgWidth = edge.radius * 1;
			const imgHeight = edge.radius * 1;
			const imgX = edge.x - imgWidth / 2;
			const imgY = edge.y - imgHeight / 2 + 10; // Adjust the position to be below the label

			// Clip to the circle and draw the image
			this.ctx.save();
			this.ctx.beginPath();
			this.ctx.arc(edge.x, edge.y, edge.radius, 0, 2 * Math.PI);
			this.ctx.closePath();
			this.ctx.clip();

			this.ctx.drawImage(image, imgX, imgY, imgWidth, imgHeight);
			this.ctx.restore();
		};
	}

	drawCurvedConnection(from: any, to: any, index: number, total: number, isLeft: boolean) {
		if (isLeft) {
			this.ctx.strokeStyle = from.status ? "green" : "#c00606";
		} else {
			this.ctx.strokeStyle = to.status ? "green" : "#c00606";
		}
		this.ctx.beginPath();
		let fromX, fromY, toX, toY, cp1X, cp1Y, cp2X, cp2Y;

		if (from.radius) {
			// from service edge (circle) to interface (rectangle)
			fromX = from.x;
			fromY = from.y;
			toX = to.x;
			toY = to.y + to.height / 2;

			const angle = (Math.PI * (index + 1)) / (total + 1);
			fromX = from.x + from.radius * Math.cos(angle - Math.PI / 2);
			fromY = from.y + from.radius * Math.sin(angle - Math.PI / 2);

			cp1X = from.x + from.radius * 1.5 * Math.cos(angle - Math.PI / 2);
			cp1Y = from.y + from.radius * 1.5 * Math.sin(angle - Math.PI / 2);
			cp2X = to.x - 50;
			cp2Y = toY;
		} else if (to.radius) {
			// from interface (rectangle) to service edge (circle)
			fromX = from.x + from.width;
			fromY = from.y + from.height / 2;
			toX = to.x;
			toY = to.y;

			const angle = (Math.PI * (index + 1)) / (total + 1);
			toX = to.x - to.radius * Math.cos(angle - Math.PI / 2);
			toY = to.y + to.radius * Math.sin(angle - Math.PI / 2);

			cp1X = fromX + 50;
			cp1Y = fromY;
			cp2X = to.x - to.radius * 1.5 * Math.cos(angle - Math.PI / 2);
			cp2Y = to.y + to.radius * 1.5 * Math.sin(angle - Math.PI / 2);
		}

		this.ctx.moveTo(fromX, fromY);
		this.ctx.bezierCurveTo(cp1X, cp1Y, cp2X, cp2Y, toX, toY);
		this.ctx.stroke();
	}

	drawConnection(from: any, to: any, index: number, total: number, isLeft: boolean) {
		this.ctx.strokeStyle = "red";
		this.ctx.beginPath();
		let fromX, fromY, toX, toY;

		if (from.radius) {
			// from service edge (circle) to interface (rectangle)
			fromX = from.x;
			fromY = from.y;
			toX = to.x;
			toY = to.y + to.height / 2;

			const angle = (Math.PI / (total + 1)) * (index + 1);
			fromX = from.x + from.radius * Math.cos(angle - Math.PI / 2);
			fromY = from.y + from.radius * Math.sin(angle - Math.PI / 2);

			this.ctx.moveTo(fromX, fromY);
			this.ctx.lineTo(toX, toY);
		} else if (to.radius) {
			// from interface (rectangle) to service edge (circle)
			fromX = from.x + from.width;
			fromY = from.y + from.height / 2;
			toX = to.x;
			toY = to.y;

			const angle = (Math.PI / (total + 1)) * (index + 1);
			toX = to.x - to.radius * Math.cos(angle - Math.PI / 2);
			toY = to.y + to.radius * Math.sin(angle - Math.PI / 2);

			this.ctx.moveTo(fromX, fromY);
			this.ctx.lineTo(toX, toY);
		}

		this.ctx.stroke();
	}
}
