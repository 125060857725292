import { NgModule } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatRadioModule } from "@angular/material/radio";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatListModule } from "@angular/material/list";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatChipsModule } from "@angular/material/chips";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTabsModule } from "@angular/material/tabs";
import { MatStepperModule } from "@angular/material/stepper";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { LayoutModule } from "@angular/cdk/layout";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { OverlayModule } from "@angular/cdk/overlay";

@NgModule({
	imports: [
		MatButtonModule,
		MatIconModule,
		MatMenuModule,
		MatToolbarModule,
		MatRadioModule,
		MatCardModule,
		MatGridListModule,
		MatTooltipModule,
		MatSlideToggleModule,
		MatSelectModule,
		MatDialogModule,
		MatFormFieldModule,
		MatInputModule,
		MatListModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatChipsModule,
		MatCheckboxModule,
		MatTabsModule,
		MatStepperModule,
		DragDropModule,
		MatExpansionModule,
		LayoutModule,
		ClipboardModule,
		MatSnackBarModule,
		MatAutocompleteModule,
		OverlayModule
	],
	exports: [
		MatButtonModule,
		MatIconModule,
		MatMenuModule,
		MatToolbarModule,
		MatTooltipModule,
		MatRadioModule,
		MatCardModule,
		MatGridListModule,
		MatSlideToggleModule,
		MatDialogModule,
		MatSelectModule,
		MatFormFieldModule,
		MatInputModule,
		MatListModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatChipsModule,
		MatCheckboxModule,
		MatTabsModule,
		MatStepperModule,
		DragDropModule,
		MatExpansionModule,
		LayoutModule,
		ClipboardModule,
		MatSnackBarModule,
		MatAutocompleteModule,
		OverlayModule
	],
	providers: [
		{ provide: MatDialogRef, useValue: {} },
		{ provide: MAT_DIALOG_DATA, useValue: {} }
	]
})
export class MaterialModule {}
