import { KeycloakService } from "keycloak-angular";
import { concatMap,from, switchMap } from "rxjs";
import { ConfigInitService } from "./config-init.service";
import { InitService } from "./init-service";

export function initializeKeycloak(
	keycloak: KeycloakService,
	configService: ConfigInitService,
	initService: InitService
) {
	//export function getBaseLocation() {
		//let paths: string[] = location.pathname.split('/').splice(1, 1);
		let paths: string[] = location.pathname.split('/').splice(2, 2);
		let basePath: string = (paths && paths[0]) || 'ui-test-1'; // Default: context-path to identify context is not setted
		//initService.initSpaedBasePath(basePath);

	return () => {
		return configService.initializeEnvUrls().pipe(
			concatMap(() => {
				return configService
					.getConfig()
					.pipe(
						switchMap<any, any>((config) =>
							from(
								keycloak.init({
									config: {
										url: config["KEYCLOAK_URL"] + "",
										realm: config["KEYCLOAK_REALM"],
										clientId: config["KEYCLOAK_CLIENT_ID"]
									},
									initOptions: {
										onLoad: "login-required",
										checkLoginIframe: false,
										useNonce: false
									}
									// shouldAddToken: (request) => {
									//   return true;
									// }
								})
							)
						)
					)
					.toPromise()
					.then(() =>
						Promise.all([
							initService.loadUserDetails(),
							initService.getPrivileges(),
							initService.getCustomers()
						])
					)
					.then(() => {})
					.catch(() => {});
			})
		);
	};
}
