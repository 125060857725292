import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
	selector: "app-confirm-dialog",
	templateUrl: "./confirm-dialog.component.html",
	styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent {
	title: string;
	message: string;
	hint: string;
	eventDetails: any;
	customTemplateMessage: any;
	flags = {
		isEnableEvent: false,
		isEnableOkayButton: false
	};
	constructor(
		private router: Router,
		public dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { title: string; message?: string; hint?: string; modalProps?: any }
	) {
		this.title = data.title;
		this.message = data.message ? data.message : "All unsaved changes will be lost. Do you want to proceed?";
		this.hint = data.hint ?? "";
		this.flags.isEnableOkayButton = data.modalProps && data.modalProps?.action === "Okay" ? true : false;
		this.eventDetails = data?.modalProps; // need to delete
		this.flags.isEnableEvent = this.eventDetails?.allowRoute; // it should be enhance based on functional requirement
		this.customTemplateMessage = this.eventDetails?.messages;
	}

	onConfirm(): void {
		this.dialogRef.close(true);
	}

	onCancel(): void {
		this.dialogRef.close(false);
	}

	onEventAction() {
		if (this.eventDetails?.allowRoute) {
			this.dialogRef.close(false);
			this.router.navigateByUrl(this.eventDetails.url);
		}
	}
}

export class ConfirmDialogModel {
	constructor(public title: string, public message?: string, public modalProps?: any, public hint?: string) {}
}
