import { AfterViewChecked, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { User } from '../models/user';

@Directive({
	selector: '[appCheckUserType]'
})
export class CheckUserTypeDirective {
	@Input() set appCheckUserType(userType: string) {
		this.userType = userType;
		this.validateUserType()
	}
	userType!: string;
	loggedInUser!: User;

	constructor(private vcRef: ViewContainerRef, private tmpRef: TemplateRef<any>, private appService: AppService) {
		this.appService.loggedInUserDetailsObservable.subscribe({
			next: (user: User | null) => {
				if (user) {
					this.loggedInUser = user;
					this.validateUserType();
				}
			}
		});
	}

	validateUserType() {
		this.vcRef.clear();
		if (this.loggedInUser?.userType?.toLowerCase() === this.userType?.toLowerCase()) {
			this.vcRef.createEmbeddedView(this.tmpRef);
		}
	}

}
