import {
	AbstractControl,
	FormArray,
	FormControl,
	FormGroup,
	Validators,
	FormGroupDirective,
	NgForm,
	ValidationErrors,
	ValidatorFn
} from "@angular/forms";
import { inject, Renderer2 } from "@angular/core";
import { ErrorStateMatcher } from "@angular/material/core";

export function validateAllFormFields(control: AbstractControl): void {
	if (control instanceof FormGroup) {
		Object.keys(control.controls).forEach((field) => {
			validateAllFormFields(control.get(field) as AbstractControl);
		});
	} else if (control instanceof FormArray) {
		for (const c of control.controls) {
			validateAllFormFields(c);
		}
	}
	// control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
	control.markAsDirty();
	control.markAsTouched();
}

export function userErrorFormMessages(): any {
	return {
		firstName: {
			required: "First Name is required",
			minlength: "First Name should be atleast 2 characters",
			maxlength: "First Name should not exceed 100 characters.",
			pattern: "First name should start and end with alphabet and can contain alphabets and space only."
		},
		lastName: {
			required: "Last Name is required",
			minlength: "Last name should be atleast 2 characters",
			maxlength: "Last Name should not exceed 100 characters.",
			pattern: "Last name should start and end with alphabet and can contain alphabets and space only."
		},
		displayName: {
			required: "Display Name is required.",
			maxlength: "Display Name should not exceed 100 characters.",
			pattern: "Display name should start and end with alphabet and can contain alphabets and space only."
		},
		loginName: {
			required: "Login Name is required.",
			maxlength: "Login Name should not exceed 20 characters.",
			pattern: "Login name can contain alphanumeric characters only.",
			duplicateLoginName: "Login name already exists."
		},
		contactNumber: {
			minlength: "Contact Number should be minimum of 1 digits.",
			maxlength: "Contact Number should not exceed 100 digits.",
			pattern: "Each contact number should be 10 digit only without country code"
		},
		emailId: {
			required: "Email is required.",
			pattern: "Invalid email address",
			duplicateEmailId: "Email address already exists.",
			domainNotAllowed: "Email domain is not allowed"
		},
		userType: {
			required: "User type is required."
		},
		services: {
			required: "Service is required."
		},
		userOwnerId: {
			required: "User Owner is required."
		},
	};
}

export function teamFormErrorMessage(): any {
	return {
		name: {
			required: "Name is required",
			minlength: "Name should be atleast 1 characters",
			maxlength: "Name should not exceed 50 characters."
		},
		description: {
			required: "Description is required",
			minlength: "Description should be atleast 1 characters",
			maxlength: "Description should not exceed 290 characters."
		},
		code: {
			required: "Code is required",
			minlength: "Code should be atleast 1 characters",
			maxlength: "Code should not exceed 50 characters."
		},
		dl: {
			required: "DL is required",
			minlength: "DL should be atleast 1 characters",
			maxlength: "DL should not exceed 50 characters."
		},
		departmentId: {
			required: "Department is required"
		},
		active: {
			required: "Active Status is required."
		}
	};
}

export function DepartmentFormErrorMessage(): any {
	return {
		name: {
			required: "Name is required",
			minlength: "Name should be atleast 1 characters",
			maxlength: "Name should not exceed 50 characters."
		},
		description: {
			required: "Description is required",
			minlength: "Description should be atleast 1 characters",
			maxlength: "Description should not exceed 290 characters."
		},
		code: {
			required: "Code is required",
			minlength: "Code should be atleast 1 characters",
			maxlength: "Code should not exceed 50 characters."
		},
		category: {
			required: "Category is required"
		},
		leadUSerId: {
			required: "Lead USer is required"
		},
		active: {
			required: "Active Status is required."
		}
	};
}

export function privilegeFormErrorMessage(): any {
	return {
		privilegeName: {
			required: "Name is required",
			minlength: "Name should be atleast 1 characters",
			maxlength: "Name should not exceed 50 characters."
		},
		privilegeCode: {
			required: "Code is required",
			minlength: "Code should be atleast 1 characters",
			maxlength: "Code should not exceed 50 characters."
		},
		active: {
			required: "Active Status is required."
		}
	};
}

export function customerErrorMessage(): any {
	return {
		name: {
			required: "Customer name is required",
			maxlength: "Customer name should not exceed 100 characters.",
			minlength: "Customer name should be minimum 3 characters.",
			pattern: "Customer name can contain alphanumeric, dot, underscore, hyphen, & and @ only.",
			duplicateCustomerName: "Customer name already exists"
		},
		displayName: {
			required: "Display name is required",
			maxlength: "Display name should not exceed 100 characters.",
			pattern: "Display name can contain alphanumeric, dot, underscore, hyphen, & and @ only."
		},
		description: {
			required: "Description is required",
			maxlength: "Description should not exceed 800 characters.",
			pattern: "Description can contain alphanumeric, dot, underscore, hyphen, & and @ only."
		},
		websiteUrl: {
			required: "Website url is required.",
			maxlength: "Website url should not exceed 400 characters.",
			inValidUrl: "Website url must be a valid url."
		},
		accountTypeId: {
			required: "Account Type is required."
		},
		customerIndustry: {
			required: "Customer Industry is required field."
		},
		countryId: {
			required: "Country is required."
		},
		stateId: {
			required: "State is required."
		},
		cityId: {
			required: "City name is required.",
			maxlength: "City name should not exceed 100 characters.",
			pattern: "City name can contain alphanumeric only."
		},
		zipCode: {
			required: "zip code is required.",
			maxlength: "zip code should not exceed 10 characters.",
			pattern: "zip code can contain numbers and hyphen only."
		},
		address: {
			required: "Address is required.",
			maxlength: "Address should not exceed 800 characters.",
			pattern: "Address can contain alphanumeric, dot, underscore, hyphen and @ only."
		},
		contactNumber: {
			minlength: "Contact Number should be minimum of 5 digits.",
			maxlength: "Contact Number should not exceed 100 digits.",
			pattern: "Each contact number should be 10 digit only without country code"
		},
		taxIdentificationNumber: {
			minlength: "Tax Identification Number should be minimum of 1 digits.",
			maxlength: "Tax Identification Number should not exceed 20 digits.",
			pattern: "Tax Identification Number can alphanumeric only."
		},
		gst: {
			minlength: "GST Number should be of 1 digits.",
			maxlength: "GST Number should be of 20 digits.",
			required: "GST Number is required.",
			pattern: "GST Number can alphanumeric only."
		},
		timeZoneId: {
			required: "Timezone is required."
		},
		emailDomain: {
			minlength: "Email Domain should be of 1 digits.",
			maxlength: "Email Domain should be of 100 digits.",
			email: "Invalid email domain."
		},
		parentId:{
			required: "Customer owner is required."
		},
		serviceTypeId:{
			required: "Service Type is required."
		},
		agentIp: {
            minlength: "Network address should be of 1 digits.",
            required: "Network address is required.",
            pattern:" Enter valid Network address with subnet mask(xx.xx.xx.xx/yz)."
        }
	};
}

export function SiteErrorMessage(): any {
	return {
		name: {
			required: "Site name is required",
			maxlength: "Site name should not exceed 100 characters.",
			pattern: "Site name can contain alphanumeric, dot, underscore, hyphen, & and @ only.",
			duplicateSiteName: "Site name already exists"
		},
		lanIp: {			
			inValidIp: "LAN IP address should be valid IP with CIDR format."
		},
		subnetMask: {
			min: "Subnet mask should be minimum 1.",
			max: "Subnet mask should not exceed 31."			
		},
		latitude: {
			required: "latitude is required."
		},
		longitude: {
			required: "longitude is required."
		},
		unitIds: {
			required: "WiFi router unit is required."
		},
		countryId: {
			required: "Country is required."
		},
		stateId: {
			required: "State is required."
		},
		cityId: {
			required: "City name is required.",
			maxlength: "City name should not exceed 100 characters.",
			pattern: "City name can contain alphanumeric only."
		},
		zipCode: {
			required: "Pin code is required.",
			maxlength: "Pin code should not exceed 10 characters.",
			pattern: "Pin code can contain numbers and hyphen only."
		},
		address: {
			required: "Address is required.",
			maxlength: "Address should not exceed 800 characters.",
			pattern: "Address can contain alphanumeric, dot, underscore, hyphen and @ only."
		},
		phoneNumber: {
			required: "Phone number is required.",
			maxlength: "Phone Number cannot be more than 10 digits"
		},
		email:{
			email: "Provide valid mail address.",
			required: "Mail Address is required. "
		},
		locationTypeId:{
			required: "Location is required."
		}

	};
}

export function imageUploadErrorMessage(): any {
	return {
		deviceModel: {
			required: "Please select a device model."
		},
		version: {
			required: "Please provide a valid version.",
			alreadyMapped: "Version already exists for model."
		},
		description: {},
		fileName: {
			required: "Please upload a valid file."
		}
	};
}

export function firmwareMappingErrorMessage(): any {
	return {
		firmwareId: {
			required: "Enter firmware Id"
		},
		customersList: {
			required: "Select atleast one customer"
		}
	};
}

export function requiredValidator(formControlValueFn: () => any): any {
	return (formControl: FormControl) => {
		if (!formControl.parent) {
			return null;
		}
		if (formControlValueFn()) {
			return Validators.required(formControl);
		}
		return null;
	};
}

export function focusOnInvalidControl(form: FormGroup, renderer: Renderer2, scrollToTop = false): void {
	const invalidControl = Object.keys(form.controls).find((formControl: any) => form.controls[formControl].invalid);
	let control;
	try {
		if (invalidControl === "customerSpocEmail") {
			control = renderer.selectRootElement(`[name="${invalidControl}"]`, true);
		} else {
			control = renderer.selectRootElement(`[formcontrolname="${invalidControl}"]`, true);
		}
		if (control?.type === "textarea") {
			control = renderer.selectRootElement(`[name="${invalidControl}"]`, true);
		}
		control.focus();
	} catch (e: any) {
		try {
			control = renderer.selectRootElement(`[name="${invalidControl}"]`, true);
			control.focus();
		} catch (e: any) {
			if (scrollToTop) {
				window.scrollTo(0, 0);
			}
		}
	}
}

export function trackByFn(index: number, item: any): number {
	return index;
}

export class ConfirmPasswordMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const invalidControl = control && control.errors && control.errors["required"] && control.touched;
		const invalidParentControl =
			control && control.dirty && control.parent?.errors && control?.parent?.errors["notIdentical"];
		return invalidControl || invalidParentControl;
	}
}

export function confirmPasswordValidator(form: AbstractControl): ValidationErrors | null {
	const password = form.get("password")?.value;
	const confirmPassword = form.get("confirmPassword")?.value;
	if ((!password && confirmPassword) || (!confirmPassword && password) || (!password && !confirmPassword)) {
		return null;
	}
	return password === confirmPassword ? null : { notIdentical: true };
}

export function slaApplyValidator(): any {
	return (form: FormGroup): ValidationErrors | null => {
		const latency = form.get("latency")?.value;
		const jitter = form.get("jitter")?.value;
		const packetLoss = form.get("packetLoss")?.value;
		if (!latency && !jitter && !packetLoss) {
			return {
				isApplyMissing: true
			};
		}

		return null;
	};
}

export function slaToAccessValidator(): any {
	return (form: FormGroup): ValidationErrors | null => {
		const application = form.get("application")?.value;
		const srcIp = form.get("srcIp")?.value;
		const srcPort = form.get("srcPort")?.value;
		const dstIP = form.get("dstIP")?.value;
		const dstPort = form.get("dstPort")?.value;

		if (!application && !srcIp && !srcPort && !dstIP && !dstPort) {
			return {
				isToAccessMissing: true
			};
		}

		return null;
	};
}

export function portValidator(range: { min: number; max: number }): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}
		const inputValue = Number(control.value);
		if (!isNaN(inputValue) && inputValue >= range.min && inputValue <= range.max) {
			return null;
		}
		return { invalidPortRange: true };
	};
}
export function essidManageErrorMessage(): any {
	return {
		essidName: {
			required: "ESSID is required",			
		},
		wifiPassword: {
			required: "Password is required",			
		}
	}
}
export function securedRoutingErrorMessage(): any {
	return {
		lendingHostname: {
			required: "Lending hostname is required.",						
			inValidIp: "Lending hostname should be valid IP."
				
		},
		lendingUdpPort: {
			required: "Lending UDP port is required.",	
			min: "Lending UDP port should be minimum 1.",
			max: "Lending UDP port should not exceed 65535."			
		},
		lendingPublicKey: {
			required: "Lending host public key is required.",			
		},
		borrowingInterfaceAddress: {
			required: "Borrowed IP address is required.",
			inValidIp: "Borrowed IP address should be valid IP with CIDR format."
		},
		listeningUdpPort: {
			required: "Borrowed UDP port is required",
			min: "Borrowed UDP port should be minimum 1.",
			max: "Borrowed UDP port should not exceed 65535."		
		},
		directListeningAddress: {
			required: "DNS name / IP address is required.",
			inValidIp: "DNS Name / IP address should be valid IP."		
		},
		directListeningPort: {
			required: "Listening port is required.",
			min: "Listening port should be minimum 1.",
			max: "Listening port should not exceed 65535."
		},
		gSigsHostname: {
			required: "GSIGS hostname is required.",
			inValidIp: "GSIGS hostname should be valid IP."			
		},
		gSigsSecretKey: {
			required: "GSIGS secret key is required",			
		}
	}
}

export function templateConfigErrorMessage(): any {
	return {
		templateName: {
			required: "Name is required",			
		},
		description:{
			required: "Description is required",
		},
		version: {
			required: "version is required",			
		},
		essidName: {
			required: "ESSID is required",			
		},
		password: {
			required: "Password is required",			
		},
		interfaceName:{
			required: "Interface is required",
		},
		subnet: {
			required: "Target is required",	
			inValidIp: "Target should be Valid IP"	
		},
		gateway: {
			required: "Gateway is required",
			pattern: "Gateway should be Valid IP"				
		},
		routeType: {
			required: "Route Type is required",			
		},
		netMask:{
			required: "NetMask is required",
			pattern: "NetMask should be Valid"	
		},
		name: {
			required: "Name is required",			
		},
		protocol: {
			required: "ESSID is required",			
		},
		sourceZone: {
			required: "Source Zone is required",			
		},
		destinationZone: {
			required: "Destination Zone is required",			
		},
		sourceMacAddress: {
			required: "Source Mac Address is required",
			invalid:"Source Mac Address should be valid"		
		},
		sourceIpAddress: {
			required: "Source IP Address is required",
			invalid:"Source IP Address should be valid"			
		},
		sourcePort:{
			required: "Source Port is required",
			invalid:"Source Port should be valid"
		},
		destinationIpAddress: {
			required: "Destination IP Address is required",	
			invalid:"Destination IP should be valid"		
		},
		destinationPort: {
			required: "Destination Port is required",
			invalid:"Destination Port should be valid"		
		},
	}
}

export function wanInterfaceErrorMessage(): any {
	return {
		ispName: {
			required: "ISP Name is required",			
		},
		interfaceDescription: {
			required: "Interface Description is required",			
		}
	}
}

export function PrivateApplicationMessage(): any {
	return {
		applicationName: {
			required: "Name is required.",
			minlength: "Name should not be less than 2 characters.",
			maxlength: "Name should not exceed 100 characters.",
			pattern: "Enter valid name.",
			duplicateName:  "Name already exists for customer."
		},
		ip: {
			required: "IP/IP Subnet name is required.",
			inValidIp: "Enter valid IP address."
		},
		port: {
			required: "Port is required.",
			invalidPortRange: "Enter valid port."
		},
		protocolId: {
			required: "Protocol is required."
		}
	};
}