<!-- <div class="footer-section d-flex justify-content-between align-items-center px-3">
    <div>

    </div>
    <div>
        @2022 Tatacommunications Ltd. All rights reserved.
    </div>
</div> -->

<div class="container-fluid">
	<div class="footer-section row p-all-8 gotham-book-regular footer-typo">
		<div class="col-md-6 col-sm-12 text-position-menu">
			<div class="col-md-12">About Us | Contact Us | Policies | Legal | Sitemap</div>
		</div>
		<div class="col-md-6 text-end col-sm-12 text-position-static-content">
			@{{year}} Tatacommunications Ltd. All rights reserved.
		</div>
	</div>
</div>
