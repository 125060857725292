import { Component } from '@angular/core';

@Component({
  selector: 'app-dot-bouncer-loader',
  templateUrl: './dot-bouncer-loader.component.html',
  styleUrls: ['./dot-bouncer-loader.component.scss']
})
export class DotBouncerLoaderComponent {

}
