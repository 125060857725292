<ng-template #ipRangeDetailsTemplate>
	<div class="ip-details-confirm-dialog">
		<div class="dialog-header">
			<div class="dialog-header">
				<h6 class="header-text">Network Range Details</h6>
			</div>
		</div>
		<div mat-dialog-content class="body ip-dialog-con">
			<div class="col-12 mb-3 float-end" *ngIf="ipRangeDetails?.length">
				<div class="form-group float-end">
					<input
						[(ngModel)]="searchText"
						placeholder="Search"
						class="form-control height-40 paginator-text-input"
						type="text" />
				</div>
			</div>
			<table class="table table-stripped cus-tb ip-range-table-con">
				<tr>
					<th class="table-header-name">Site</th>
					<th class="table-header-name">LAN</th>
					<th class="table-header-name">VLAN</th>
				</tr>
				<tbody>
					<ng-container *ngIf="(ipRangeDetails | filterNetworkRange : searchText).length > 0; else noResults">
						<tr *ngFor="let ipDetails of ipRangeDetails | filterNetworkRange : searchText">
							<td class="table-sub-header">{{ ipDetails.name }}</td>
							<td>
								<span *ngIf="ipDetails?.lanDefaultIpDetails[0]?.ipDetails?.length">
									<span class="table-sub-header">Default:</span>
									<span
										*ngFor="
											let rangeDetails of ipDetails.lanDefaultIpDetails[0]?.ipDetails;
											let last = last
										">
										<span>{{ rangeDetails }}</span>
									</span>
									<br />
								</span>
								<span *ngIf="ipDetails?.lanStaticIpDetails?.length">
									<span class="table-sub-header">Static:</span>
									<span *ngFor="let rangeDetails of ipDetails?.lanStaticIpDetails; let last = last">
										<span>{{ rangeDetails }}</span>
										<span *ngIf="!last">,</span>
									</span>
								</span>
							</td>
							<td>
								<span
									*ngIf="
										ipDetails?.vlanOnLinkIpDetails?.length || ipDetails?.vlanStaticIpDetails?.length; else noIpDetailsTemplate
									">
									<span *ngIf="ipDetails?.vlanOnLinkIpDetails?.length">
										<span class="table-sub-header">On-link:</span>
										<span
											*ngFor="
												let rangeDetails of ipDetails?.vlanOnLinkIpDetails;
												let last = last
											">
											<span>{{ rangeDetails }}</span>
											<span *ngIf="!last">,</span>
										</span>
										<br />
									</span>
									<span *ngIf="ipDetails?.vlanStaticIpDetails?.length">
										<span class="table-sub-header">Static:</span>
										<span
											*ngFor="
												let rangeDetails of ipDetails?.vlanStaticIpDetails;
												let last = last
											">
											<span>{{ rangeDetails }}</span>
											<span *ngIf="!last">,</span>
										</span>
									</span>
								</span>
								<ng-template #noIpDetailsTemplate>
									<span>-</span>
								</ng-template>
							</td>
						</tr>
					</ng-container>
					<ng-template #noResults>
						<tr>
							<td colspan="3" class="text-center">No details found.</td>
						</tr>
					</ng-template>
				</tbody>
			</table>
		</div>
	</div>
	<div class="d-flex md48 justify-content-end gap-2 align-items-center py-2 px-2">
		<button
			(click)="onClickClose()"
			class="btn btn-primary custom-btn btn-config m-r-5"
			color="primary"
			type="button">
			Close
		</button>
	</div>
</ng-template>
