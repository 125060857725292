<div>
	<div class="header">
		<app-header></app-header>
	</div>
	<div class="breadcrumbs col-12 p-all-0">
		<app-breadcrumbs></app-breadcrumbs>
	</div>
	<div class="content flex-wrapper">
		<div class="container-fluid">
			<router-outlet></router-outlet>
		</div>
		<!-- <div class="footer">
			<app-footer></app-footer>
		</div> -->
	</div>
</div>