import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import * as _ from "lodash";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { ProfileService } from "src/app/profile/profile.service";
import { TableColumns } from "src/app/shared/models/table";
import { DataParams, IResponse } from "src/app/shared/models/utils";
import { TimeParserUtil } from "src/app/utility/time-parser-utility";
import { GlobalConstants, INFO_MESSAGES } from "../../utils/global-constants";
import { Subscription } from "rxjs";

@Component({
	selector: "app-event-reports",
	templateUrl: "./event-reports.component.html",
	styleUrls: ["./event-reports.component.scss"]
})
export class EventReportsComponent {
	@Input() inputTableDataSource!: any;

	dataSource: any = {
		clonedUrlReportDetails: [],
		clonedSecurityEventDetails: []
	};
	tableDataSource: any = {};
	flags: any = {
		isExportInProgress: false,
		isShowTableDetails: false,
		isUrlfDataLoading: false,
		isSeDataLoading: false
	};
	model: any = {};
	selectedSite: any[] = [];
	date = {
		fromDate: "",
		toDate: ""
	};
	dashboardLoadedTime = new Date();

	urlTableColumns = {
		columnDef: [
			{ header: "userName", displayName: "Username" },
			{ header: "category", displayName: "Category" },
			{ header: "url", displayName: "URL" },
			{ header: "uploadFormatted", displayName: "Upload" },
			{ header: "downloadFormatted", displayName: "Download" },
			{ header: "dateTime", displayName: "Date & Time" }
		]
	};

	reportTableColumns: any = {
		columnConfig: {
			columnDef: [
				{ header: "url", displayName: "URL" },
				{ header: "dstIp", displayName: "Dest IP" },
				{ header: "dstPort", displayName: "Dest Port" },
				{ header: "category", displayName: "Category" },
				{ header: "noOfHits", displayName: "No. of Hits" },
				{ header: "uploadFormatted", displayName: "Uplink" },
				{ header: "downloadFormatted", displayName: "Downlink" },
				{ header: "permission", displayName: "Permission" },
				{ header: "policyName", displayName: "Policy Name" }
			],
			displayColumns: []
		}
	};

	totalSize: any;
	recordsPerPage = [5, 10, 50, 100];
	pageIndex = 0;
	pageSize = 10;
	searchTerm = "";
	showProgress = false;
	dataParams: DataParams = { showSearchInput: true, searchDivClass: "site-search" };
	
	private urlReportSubscription: Subscription | null = null;
	private securityEventSubscription: Subscription | null = null;

	constructor(public dashboardService: DashboardService, private timeParser: TimeParserUtil) {}

	getFormattedDate(date: Date): { [key: string]: any } {
		const month = date.toLocaleString("en-US", { month: "short" });
		const year = date.toLocaleString("en-US", { year: "numeric" });
		const day = date.getDate();
		return {
			month,
			year,
			day
		};
	}

	getSelectCustomerList(event: any) {
		this.flags.isShowProgress = true;
		this.model.customerId = event.length && event[0].id ? event[0].id : null;
		this.getAllSites(true);
	}

	onSelectCustomer(event: any) {
		this.model.customerId = event.id ? event.id : null;
		this.flags.isShowProgress = true;
		this.resetSerach();
		this.dataSource.siteList = [];
		this.getAllSites();
	}

	onDateChanged(event: any) {
		this.date.fromDate = event.fromDate;
		this.date.toDate = event.toDate;
		this.fetchReportAndEventDetails();
		this.resetSerach();
	}

	resetSerach() {
		this.searchTerm = "";
	}

	ngOnInit() {
		const fromDate = this.getFormattedDate(new Date());
		this.date.fromDate = `${fromDate["day"]} ${fromDate["month"]} ${fromDate["year"]}`;
		this.tableDataSource = this.inputTableDataSource;
	}

	fetchReportAndEventDetails() {
		this.getReportDetails();
		this.getSecurityEventsReportDetails();
	}

	// onClickSearch() {
	// 	this.dashboardLoadedTime = new Date();
	// 	this.flags.isShowTableDetails = true;
	// 	this.getReportDetails();
	// 	this.getSecurityEventsReportDetails();
	// }

	getReportDetails() {
		let payload = this.getCommonPayload();
		this.flags.isShowTableDetails = true;
		this.flags.isUrlfDataLoading = true;
		if (this.urlReportSubscription) {
			this.urlReportSubscription.unsubscribe();
		}
		this.urlReportSubscription = this.dashboardService.getUserTrafficreports(payload).subscribe({
			next: (response: any) => {
				if (response.data?.length) {
					this.dataSource.urlReportDetails = response.data;
					this.dataSource.clonedUrlReportDetails = structuredClone(response.data);
				} else {
					this.dataSource.urlReportDetails = [];
					this.dataSource.clonedUrlReportDetails = [];
				}
				this.flags.isUrlfDataLoading = false;
			},
			error: () => {
				this.dataSource.urlReportDetails = [];
				this.flags.isUrlfDataLoading = false;
			}
		});
	}

	private getCommonPayload() {
		return {
			widgetName: "Top blocked category",
			customerId: [this.model.customerId],
			startTime: this.getUTCDateFromString(this.date.fromDate, true),
			endTime: this.date.toDate
				? this.getUTCDateFromString(this.date.toDate, false)
				: this.getUTCDateFromString(this.date.fromDate, false),
			siteId: this.selectedSite.map((site: any) => {
				return site.value;
			}),
			widgetPageable: {
				page: 0,
				size: 0,
				sort: {
					name: "0",
					type: "desc"
				}
			},
			filters: [{ name: "", value: "0" }]
		};
	}

	getUTCDateFromString(date: string, isStartDate: boolean) {
		const splitDate = date.split(" ");
		const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		const monthIndex = month.findIndex((element) => element === splitDate[1]);
		let utcInMs = 0;
		if (isStartDate) {
			// utcInMs = new Date(Date.UTC(+splitDate[2], monthIndex, +splitDate[0], 0, 0, 0)).getTime();
			const dateObject = {
				year: +splitDate[2],
				month: monthIndex + 1,
				day: +splitDate[0],
				hour: splitDate[3] ? +splitDate[3] : 0,
				minute: splitDate[4] ? +splitDate[4] : 0,
				second: 0
			};
			utcInMs = this.timeParser.convertDateTimeToGMT(dateObject);
		} else {
			//check the difference b/w current date and selected date. If selected date in past set time as 23:59:59 else set dashboard loaded time
			const selectedDate = { year: +splitDate[2], month: monthIndex + 1, day: +splitDate[0] };
			const currentTime =
				this.timeParser.getDaysDifferenceFromCurrentDate(selectedDate) >= 0
					? this.getDashboardLoadedTime().split(":")
					: "23:59:59".split(":");
			const dateObject = {
				...selectedDate,
				hour: splitDate[3] ? +splitDate[3] : +currentTime[0],
				minute: splitDate[4] ? +splitDate[4] : +currentTime[1],
				second: 0
			};
			utcInMs = this.timeParser.convertDateTimeToGMT(dateObject);
			// utcInMs = new Date(Date.UTC(+splitDate[2], monthIndex, +splitDate[0], 23, 59, 59)).getTime();
		}
		// return utcInMs / 1000; // divide by 1000 to convert ms to sec
		return utcInMs;
	}

	getDashboardLoadedTime() {
		return `${this.dashboardLoadedTime.getHours()}:${this.dashboardLoadedTime.getMinutes()}:${this.dashboardLoadedTime.getSeconds()}`;
	}

	async getAllSites(isInitialLoad?: boolean) {
		let isSiteAvailable = false;
		try {
			const payload = {
				customerId: [this.model.customerId]
			};
			const siteResponse: IResponse = await this.dashboardService.getAllSites(payload);
			if (siteResponse?.responseCode === GlobalConstants.RESPONSE_SUCCESS_CODE) {
				this.dataSource.siteList = siteResponse?.data?.map((site: any) => {
					return {
						label: site.displayName,
						value: site.id,
						...site
					};
				});
				isSiteAvailable = true;
				this.selectedSite = siteResponse?.data?.map((site: any) => {
					return {
						label: site.displayName,
						value: site.id
					};
				});
				if (!this.dataSource.siteList?.length) {
					isSiteAvailable = false;
					this.resetTable();
				}
				this.flags.isShowProgress = false;
				this.fetchReportAndEventDetails();
			} else {
				this.resetTable();
				this.dataSource.siteList = [];
				isSiteAvailable = false;
				this.flags.isShowProgress = false;
				this.unsubscribeData();
				this.resetTableDetailsAndFlags();
			}
		} catch (error) {
			isSiteAvailable = false;
			this.flags.isShowProgress = false;
			this.unsubscribeData();
			this.resetTableDetailsAndFlags();
		}
		return isSiteAvailable;
	}

	unsubscribeData() {
		if (this.urlReportSubscription) {
			this.urlReportSubscription.unsubscribe();
			this.flags.isUrlfDataLoading = false;
		}
		if (this.securityEventSubscription) {
			this.securityEventSubscription.unsubscribe();
			this.flags.isSeDataLoading = false;
		}
	}

	resetTableDetailsAndFlags() {
		this.flags.isShowTableDetails = true;
		this.dataSource.reportDetails = [];
		this.dataSource.urlReportDetails = [];
		this.dataSource.clonedUrlReportDetails = [];
		this.dataSource.clonedSecurityEventDetails = [];
	}

	resetTable() {
		throw new Error("Method not implemented.");
	}

	onSiteDropdownClosed(event: any) {
		if (!_.isEqual(this.selectedSite, event.value)) {
			this.selectedSite = event.value;
		}
		this.resetSerach();
	}

	getSecurityEventsReportDetails() {
		let payload = this.getCommonPayload();
		this.flags.isShowTableDetails = true;
		this.flags.isSeDataLoading = true;
		if (this.securityEventSubscription) {
			this.securityEventSubscription.unsubscribe();
		}
		this.securityEventSubscription = this.dashboardService.getSecurityEventseports(payload).subscribe({
			next: (response: any) => {
				if (response.data?.length) {
					this.dataSource.reportDetails = response.data;
					this.dataSource.clonedSecurityEventDetails = structuredClone(response.data);
				} else {
					this.dataSource.reportDetails = [];
					this.dataSource.clonedSecurityEventDetails = [];
				}
				this.flags.isSeDataLoading = false;
			},
			error: () => {
				this.dataSource.reportDetails = [];
				this.flags.isSeDataLoading = false;
			}
		});
	}

	onClickExport() {
		this.flags.isDownloadInprogress = true;
		let payload = this.getCommonPayload();
		this.dashboardService.downloadUserTrafficreportsCsv(payload).subscribe({
			next: (response: any) => {
				const blob = new Blob([response], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				});
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = "events_report.xlsx";
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				this.flags.isDownloadInprogress = false;
			},
			error: (err: any) => {
				this.flags.isDownloadInprogress = false;
				console.log("error", err);
			}
		});
	}

	search(searchString: any) {
		this.searchTerm = searchString;
		this.showProgress = true;
		this.flags.resetSelectedData = searchString !== this.searchTerm;
		this.dataSource.urlReportDetails = searchString
			? this.dataSource.clonedUrlReportDetails?.filter((item: any) =>
					JSON.stringify(item).toLowerCase().includes(searchString.toLowerCase())
			  )
			: structuredClone(this.dataSource.clonedUrlReportDetails);
		this.dataSource.reportDetails = searchString
			? this.dataSource.clonedSecurityEventDetails?.filter((item: any) =>
					JSON.stringify(item).toLowerCase().includes(searchString.toLowerCase())
			  )
			: structuredClone(this.dataSource.clonedSecurityEventDetails);
		this.showProgress = false;
	}

	ngOnDestroy(): void {
		this.unsubscribeData();
	}
}
