import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { API_URLS } from "../models/utils";
import { AgHttpService } from "./ag-http-service";
import { ApiUrlService } from "./api-url.service";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
	providedIn: "root"
})
export class ServiceEdgesManagementService {
	public selectedCustomerId: any;
	apiUrls!: API_URLS;

	constructor(private agHttpService: AgHttpService, private apiUrlService: ApiUrlService) {
		this.apiUrls = this.apiUrlService.getAPIUrls();
	}

	getDevice(deviceId: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/${deviceId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getCustomers(): Observable<any> {
		const url = `${this.apiUrls.CUSTOMER_MANAGEMENT_URL}/customer/customers`;
		return this.agHttpService.makeGetRequest(url);
	}

	getSites(): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site/sites/customer/type/service-owner`;
		return this.agHttpService.makeGetRequest(url);
	}

	getdeviceType(): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/types`;
		return this.agHttpService.makeGetRequest(url);
	}

	getAllDevices(pageIndex: number, pageSize: number, searchTerm?: string, sort?: string): Observable<any> {
		let queryString: string = `page=${pageIndex}&size=${pageSize}`;
		if (sort) {
			queryString += `&${sort}`;
		} else {
			queryString += `&sort=id,desc`;
		}
		if (searchTerm) {
			queryString = queryString + `&searchText=${searchTerm}`;
		}
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/sen?${queryString}`;
		return this.agHttpService.makeGetRequest(url);
	}

	addDevice(body: any): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/create/sen`;
		return this.agHttpService.makePostRequest(url, body);
	}

	deviceInitConfiguration(deviceId: string, body: any): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/applications/deploy/initial/config/${deviceId}`;
		return this.agHttpService.makePostRequest(url, body);
	}
	getDeviceKey(deviceId: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/device/key/${deviceId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	updateCIWorkfowStatus(ciId: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/status/${ciId}`;
		return this.agHttpService.makePutRequest(url);
	}

	applyCustomerToSenMappingConfig(mappingPayload: any): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/sen/ports/customer`;
		return this.agHttpService.makePostRequest(url, mappingPayload);
	}

	getCustomerToSenMappingConfig(customerId: string): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/sen/ports/customer/${customerId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getAllCpeDevices(pageIndex: number, pageSize: number, customerId: any, searchTerm?: string): Observable<any> {
		let queryString = `page=${pageIndex}&size=${pageSize}&sort=id,desc`;
		if (searchTerm) {
			queryString = queryString + `&term=${searchTerm}`;
		}
		queryString = customerId ? queryString + `&customerId=${customerId}` : queryString;
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/cis?${queryString}`;
		return this.agHttpService.makeGetRequest(url);
	}

	configureServiceEdge(payload: any) {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/tunnel`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getConfiguredSEDetails(customerId: any, deviceId?: number) {
		let url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/tunnel/${customerId}`;
		if (deviceId) {
			url = `${url}?deviceId=${deviceId}`;
		}
		return this.agHttpService.makeGetRequest(url);
	}

	getAllSENDevices(customerId:number) {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/sen/all/${customerId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getAllCEDevices(customerId: number) {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/cis/${customerId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	checkDeviceNameAvailability(deviceName: string): Observable<any> {
		// Typeid is 2 for SEN
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/name/availability/${deviceName}/2`;
		return this.agHttpService.makeGetRequest(url);
	}

	getCPESENMappingStatusLog(payload: {
		cuId: number;
		cpeId?: number;
		podId?: number;
		tunnelId?: number;
	}): Observable<any> {
		let url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/tunnel/status/${payload.cuId}`;
		if (payload?.podId) {
			url = `${url}?podId=${payload.podId}`;
		}
		if (payload?.cpeId) {
			url = `${url}?cpeId=${payload.cpeId}`;
		}
		if (payload?.tunnelId) {
			url = `${url}?tunnelId=${payload.tunnelId}`;
		}
		return this.agHttpService.makeGetRequest(url);
	}

	cpeSenSoftMapping(payload: any): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/sen-customer`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getSfcConfiguredDetails(customerId: number) {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/sfc/attributes/${customerId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getEdgeNodeDetails(customerId: number) {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/sfc/sen/customer/${customerId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getSfcConfigPopDetails(customerId: number) {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/sfc/pops/${customerId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	saveBasicConfigurationDetails(payload: any) {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/sfc/tenant/save`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	saveTunnelConfigurationDetails(payload: any) {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/sfc`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	updateTunnelStatus(payload: any) {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/sfc/ability`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getTunnelStatusLogDetails(payload: any) {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/sfc/status/${payload.customerId}/${payload.attributeId}`;
		return this.agHttpService.makePostRequest(url, payload );
	}

	getConfiguredTunnelDetails(payload: any) {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/sfc/edit`;
		return this.agHttpService.makePostRequest(url, payload );
	}

	deleteTunnelDetails(payload: any) {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/sfc/delete/${payload.customerId}/${payload.attributeId}`;
		return this.agHttpService.makeDeleteRequest(url);
	}

	downloadSfcConfigurationSampleTemplate() {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/sfc/download/report`;
		return this.agHttpService.makeDownloadFileRequest(url, "application/pdf");
	}

	getStatusDetails(tunnelId: number): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/tunnel/tunnel/details/${tunnelId}`;
		return this.agHttpService.makeGetRequest(url);
	  }

}
