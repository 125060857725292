import { Component, EventEmitter, Input, Output, Inject } from "@angular/core";
import { FileUploadParams } from "./file-upload-params";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: "app-file-upload",
	templateUrl: "./file-upload.component.html",
	styleUrls: ["./file-upload.component.scss"]
})
export class FileUploadComponent {
	fileUploadModel!: FileUploadParams;
	flags: any = {
		isFileSelected: false,
		isValidFormat: false,
		isFileEmpty: false
	};

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<FileUploadComponent>) {}

	ngOnInit() {
		if (this.data?.fileUploadModel) {
			this.fileUploadModel = this.data.fileUploadModel;
			this.fileUploadModel.selectedFileNames = [];
			this.fileUploadModel.selectedFileData = [];
		}
		this.flags.isValidFormat = true;
	}

	onRemoveFile(index: number) {
		this.flags.isFileEmpty = false;
		this.fileUploadModel.selectedFileNames?.splice(index, 1);
		this.fileUploadModel.selectedFileData?.splice(index, 1);
		this.flags.isFileSelected = this.fileUploadModel.selectedFileNames!.length >= 1;
	}

	onFileSelect(event: any) {
		this.flags.isFileEmpty = false;
		let files = Array.from(event.target.files);
		this.validateFileInput(event.target.files[0]);
		if (this.validateExtentions(files)) {
			files.forEach((file: any, i) => {
				this.fileUploadModel.selectedFileNames?.push(file.name);
				this.fileUploadModel.selectedFileData?.push(event.target.files[i]);
			});
			this.flags.isValidFormat = true;
			this.flags.isFileSelected = true;
		} else this.flags.isValidFormat = false;
	}

	validateFileInput(fileContent: File) {
		const reader = new FileReader();
		reader.onload = (fileElement: any) => {
			const fileContent: string = fileElement?.target?.result;
			if (fileContent?.trim().length === 0) {
				this.flags.isFileEmpty = true;
			}
		};
		reader.readAsText(fileContent);
	}

	onUploadClick() {
		this.dialogRef.close(this.fileUploadModel);
	}

	validateExtentions(files: Array<any>): Boolean {
		let isInvalidflag = false;
		for (let file of files) {
			let extention = this.getExtention(file?.name);
			if (!this.fileUploadModel.allowedExtentions.includes(extention)) {
				isInvalidflag = true;
				break;
			}
		}
		return !isInvalidflag;
	}

	getExtention(fileName: string) {
		return fileName.substring(fileName.lastIndexOf(".", fileName.length));
	}
}
