<div class="common-pmultiselect">
	<p-multiSelect
		#multiselect
		[options]="_settings.options"
		[(ngModel)]="selectedValues"
		[optionLabel]="_settings?.optionLabel"
		[optionValue]="_settings?.optionValue"
		class="multiselect-custom"
		[dataKey]="_settings?.dataKey"
		[group]="_settings?.group"
		(onChange)="onChange()"
		[ngClass]="_settings?.class"
		[defaultLabel]="'Select'"
		[filter]="true"
		[showToggleAll]="false"
		[virtualScroll]="_settings?.isEnableVirtualScroll"
		[virtualScrollItemSize]="_settings?.virtualScrollItemSize"
		(onBlur)="onBlur()"
		(onFilter)="onFilter($event)"
		[readonly]="_settings?.readonly"
		[appendTo]="_settings?.appendTo"
		[disabled]="_settings?.isDisabled">
		<ng-template pTemplate="header" *ngIf="enableSelectAll">
			<p-checkbox
				class="select-all-checkbox"
				[(ngModel)]="selectAll"
				[binary]="true"
				inputId="binary"
				(onChange)="onSelectAll($event)"></p-checkbox>
		</ng-template>
		<ng-template let-group pTemplate="group">
			<div>
				<i class="fa map-marker-icon {{ group?.value }}"></i>
				<!-- <i class="fa fa-sitemap map-marker-icon"></i> -->
				<span class="text-bold">{{ group.label }}</span>
			</div>
		</ng-template>
		<ng-template let-value let-item pTemplate="selectedItems">
			<ng-container *ngIf="value?.length; else noValueTemplate">
				<div class="d-flex fs-12 justify-content-between">
					<div class="lan-text-overflow-ellipsis site-value-wrap">
						{{value[0]?.[_settings?.optionLabel]}}
					</div>
					<div *ngIf="value?.length > 1">+{{ value?.length - 1 }}</div>
				</div>
			</ng-container>
			<ng-template #noValueTemplate>
				<div class="prime-dropdown-placeholder">
					{{ _settings?.placeholder || "Select" }}
				</div>
			</ng-template>
		</ng-template>
	</p-multiSelect>
</div>
