<div class="overlay" *ngIf="!showFontAwesone">
	<div class="center">
		<div class="flippers-alternate">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
		<div class="loading-text">{{ loadingMessage }}</div>
	</div>
</div>
<div class="overlay" *ngIf="showFontAwesone">
	<div class="center">
		<i class="fa fa-spinner fa-spin spinner-color" style="font-size: 60px"></i>
	</div>
</div>
