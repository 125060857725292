import { Injectable } from "@angular/core";
import { BehaviorSubject, firstValueFrom, map, Observable, of, Subject } from "rxjs";
import { AgHttpService } from "../shared/services/ag-http-service";
import { API_URLS } from "../shared/models/utils";
import { ApiUrlService } from "../shared/services/api-url.service";
import { valueFormatterByBits } from "../shared/utils/chart-utils/unit-formatter";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class DashboardService {
	networkStatusCountryChange = new Subject();
	networkStatusCountryChange$ = this.networkStatusCountryChange.asObservable();
	apiUrls!: API_URLS;
	loadWidgets = new BehaviorSubject<any>(null);
	loadWidgets$ = this.loadWidgets.asObservable();
	resetDashboard = new BehaviorSubject<any>(null);
	resetDashboard$ = this.resetDashboard.asObservable();
	loadReportDetails = new BehaviorSubject<any>(null);
	loadReportDetails$ = this.loadReportDetails.asObservable();

	constructor(private agHttpService: AgHttpService, private apiUrlService: ApiUrlService, private http: HttpClient) {
		this.apiUrls = this.apiUrlService.getAPIUrls();
	}

	public getApplicationTraffic(): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/network/config/access/policy/flows`;
		return this.agHttpService.makeGetRequest(url).pipe(map((res: any) => res));
	}

	getWorldMap(countryCode: String): Observable<any> {
		return this.agHttpService.getHttp(`./assets/data/country/${countryCode.toLowerCase()}.json`);
	}

	getTopAppsData(payload: any): Observable<any> {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/sen/dashboard/topapps/utilization`;
		return this.agHttpService.makePostRequest(url, payload);
	}
	
	getDeviceEventsSummarySTC(payload: any, pagination: any): Observable<any> {
		let url = `${environment.spaedBasePath}/device/event?page=${pagination?.page}&size=${pagination?.size}`;
		return this.agHttpService.makePostRequest(url , payload);		
	}

	getDeviceEventsSummary(payload: any): Observable<any> {
		let url = `${this.apiUrls.MONITORING_SERVICE_URL}/cpe/dashboard/events`;
		return this.agHttpService.postHttp(url, payload);
		// return of(this.getDeviceSummaryMockData(payload));
	}

	getSaseDeviceEventsSummary(payload: any): Observable<any> {
		let url = `${this.apiUrls.MONITORING_SERVICE_URL}/cpe/dashboard/event/details`;
		return this.agHttpService.postHttp(url, payload);
		// return of(this.getDeviceSummaryMockData(payload));
	}

	getServiceEdgeEventsSummary(payload: any): Observable<any> {
		let url = `${this.apiUrls.MONITORING_SERVICE_URL}/cpe/dashboard/events`;
		return this.agHttpService.postHttp(url, payload);
	}

	getAllSites(payload: any): Promise<any> {
		let url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site/sites/all/customer`;
		return firstValueFrom(this.agHttpService.postHttp(url, payload));
	}

	getCountryByCustomerIdSiteId(payload: any): Promise<any> {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/cpe/dashboard/country`;
		return firstValueFrom(this.agHttpService.postHttp(url, payload));
	}

	getNetworkStatusWidgetData(payload: any): Observable<any> {
		let url = `${this.apiUrls.MONITORING_SERVICE_URL}/cpe/dashboard/network/status`;
		return this.agHttpService.postHttp(url, payload);
	}

	getSiteUtilizationData(payload: any): Observable<any> {
		let url = `${this.apiUrls.MONITORING_SERVICE_URL}/cpe/dashboard/site/utilization`;
		return this.agHttpService.postHttp(url, payload);
	}

	getNetworkHealthData(payload: any): Observable<any> {
		let url = `${this.apiUrls.MONITORING_SERVICE_URL}/cpe/dashboard/network/health`;
		return this.agHttpService.postHttp(url, payload);
	}

	getEdgeUtilisation(payload: any): Observable<any> {
		let url = `${this.apiUrls.MONITORING_SERVICE_URL}/sen/dashboard/edge/utilization`;
		return this.agHttpService.postHttp(url, payload);
	}

	getTopUserChartData(payload: any): Observable<any> {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/sen/dashboard/top/users`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getCPEWanDetails(payload: any): Observable<any> {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/cpe/dashboard/wan/details`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getTopBlockedCategoryDetails(payload: any) {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/sen/dashboard/categories`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getTopBlockedCategoryDrillDownDetails(category: string, payload: any): Observable<any> {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/sen/dashboard/categories/${category}`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getTopWebUserDetails(payload: any) {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/sen/dashboard/webusers`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getTopWebUserDrillDownDetails(selectedValue: any, payload: any): Observable<any> {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/sen/dashboard/webusers/${selectedValue}`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getUserTrafficDetailsByCategory(category: string, user: string, payload: any): Observable<any> {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/sen/dashboard/user/traffic?category=${category}&user=${user}`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getUserTrafficreports(payload: any): Observable<any> {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/sen/dashboard/user/traffic/utilization`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getSecurityEventseports(payload: any): Observable<any> {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/sen/dashboard/user/traffic/security/events`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	downloadUserTrafficreportsCsv(payload: any): Observable<Blob> {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/report/user/traffic/utilization/report/excel`;
		return this.http.post(url, payload, { responseType: "blob" });
	}

	getTopApplicationsDetails(payload: any): Observable<any> {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/cpe/dashboard/top/apps/byusers`;
		return this.agHttpService.makePostRequest(url, payload);
	}
	
	getTopAppsByUsersLegendAppChartDetails(payload: any): Observable<any> {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/cpe/dashboard/users/app/utilization`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getTopAppsByUsersLegendAppChartProps(options: { isDrilldownEnabled: boolean; yAxisLabelUnit: any }): any {
		const props: any = {
			type: "spline",
			chart: {
				backgroundColor: "#F2F2F2"
			},
			xAxis: {
				type: options.isDrilldownEnabled ? "category" : "datetime",
				reversed: false,
				title: {
					text: options.isDrilldownEnabled ? "Date (MM/DD/YYYY)" : "Time (HH:MM)",
					enabled: true,
					style: {
						color: "#2E2E2E",
						fontSize: "12px",
						fontFamily: "Gotham-medium, Regular, sans-serif",
						fontWeight: "bold"
					}
				},
				labels: {
					formatter: (point: any) => {
						let formattedDateTime = "";
						if (options?.isDrilldownEnabled) {
							formattedDateTime = point?.value;
						} else {
							const date = new Date(point?.value);
							const hours: any = date.getHours();
							const minutes: any = date.getMinutes();
							formattedDateTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
						}
						return formattedDateTime;
					},
					style: {
						color: "#2E2E2E",
						fontSize: "10px",
						fontFamily: "Gotham-Book, Regular, sans-serif"
					}
				},
				gridLineWidth: 1,
				gridLineDashStyle: "Dash",
				gridLineColor: "#dbdbdb",
				lineColor: "#181818",
				lineWidth: 0.5,
				tickmarkPlacement: "on",
				crosshair: {
					color: "#c2c0c0",
					width: 1
				}
			},
			yAxis: {
				title: {
					text: `Bandwidth (in ${options?.yAxisLabelUnit?.label})`,
					style: {
						color: "#2E2E2E",
						fontSize: "12px",
						fontFamily: "Gotham-medium, Regular, sans-serif",
						fontWeight: "bold"
					}
				},
				className: "",
				labels: {
					// format: "{value}",
					formatter: (point: any) => {
						const data: any = point;
						return valueFormatterByBits(options?.yAxisLabelUnit?.value, data?.value) || data?.value;
					},
					style: {
						color: "#2E2E2E",
						fontSize: "10px",
						fontFamily: "Gotham-Book, Regular, sans-serif"
					}
				},
				gridLineWidth: 1,
				gridLineDashStyle: "Dash",
				gridLineColor: "#dbdbdb",
				lineColor: "#181818",
				lineWidth: 0.5,
				showFirstLabel: false,
				showLastLabel: false
			},
			legend: {
				enabled: true,
				align: "left",
				symbolHeight: 10,
				symbolWidth: 10,
				symbolRadius: 3,
				itemStyle: {
					fontSize: "12px",
					color: "#181818",
					fontFamily: "'Gotham-Book', Regular, sans-serif"
				}
			},
			tooltip: {
				headerFormat: "",
				pointFormat: "{point.displayValue}",
				backgroundColor: "rgb(0, 0, 0, 0.05)",
				borderRadius: 20,
				// borderColor: "#0F0F0F",
				style: {
					color: "#0f0f0f",
					fontSize: "10px",
					fontFamily: "'Gotham-medium', sans-serif"
				}
			},
			plotOptions: {
				series: {
					label: {
						enabled: false
					}
				}
			},
			drilldownProps: {
				xAxis: {
					type: "datetime",
					tickPositioner: function () {
						const data: any = this;
						// To set the start tick position  remove the first index tick position and replace with min value
						if (data.tickPositions?.length > 3) {
							data.tickPositions.splice(0, 2);
							// set the min value in first tick poisition
							return [data.dataMin, ...data.tickPositions];
						} else {
							return data.tickPositions;
						}
					},
					labels: {
						formatter: (point: any) => {
							const date = new Date(point?.value);
							const hours: any = date.getHours();
							const minutes: any = date.getMinutes();
							return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
						}
					},
					units: [
						["minute", [1, 5, 15]],
						["hour", [1, 2]]
					]
				}
			}
		};
		return props;
	}

	getTopAppsByUsersDataCpe(payload: any): Observable<any> {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/cpe/dashboard/top/apps/byusers`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getTopBlockedCategoriesWidgetDetails() {
		let response = {
			data: [
				{
					name: "Critical",
					y: 70
				},
				{
					name: "Medium",
					y: 20
				},
				{
					name: "Low",
					y: 10
				}
			]
		};
		return response;
	}

	getTopUrlsWidgetDetails() {
		let response = {
			data: [
				{
					name: "Critical",
					y: 30
				},
				{
					name: "Medium",
					y: 30
				},
				{
					name: "Low",
					y: 40
				}
			]
		};
		return response;
	}

	getTopUsersWidgetDetails() {
		let response = {
			data: [
				{
					name: "Critical",
					y: 45
				},
				{
					name: "Medium",
					y: 35
				},
				{
					name: "Low",
					y: 20
				}
			]
		};
		return response;
	}

	getTopBlockedCategoriesTableDetails() {
		let response = {
			headerElements: [
				{
					id: "category",
					name: "Category",
					isClickable: true
				},
				{
					id: "noOfUsers",
					name: "Number of Users"
				}
			],
			rowElements: [
				{
					category: "Gaming",
					noOfUsers: "10"
				},
				{
					category: "Streaming",
					noOfUsers: "20"
				},
				{
					category: "Streaming",
					noOfUsers: "20"
				},
				{
					category: "Streaming",
					noOfUsers: "20"
				},
				{
					category: "Streaming",
					noOfUsers: "20"
				}
			]
		};
		return response;
	}

	getTopUrlsTableDetails() {
		let response = {
			headerElements: [
				{
					id: "urls",
					name: "URLs",
					isClickable: true
				},
				{
					id: "reputationScore",
					name: "Reputation Score"
				},
				{
					id: "numberOfUsers",
					name: "Number of Users"
				}
			],
			rowElements: [
				{
					urls: "youtube.com",
					reputationScore: "40",
					numberOfUsers: "50"
				},
				{
					urls: "facebook.com",
					reputationScore: "40",
					numberOfUsers: "50"
				},
				{
					urls: "instagram.com",
					reputationScore: "40",
					numberOfUsers: "50"
				},
				{
					urls: "github.com",
					reputationScore: "40",
					numberOfUsers: "50"
				},
				{
					urls: "torrentz.com",
					reputationScore: "40",
					numberOfUsers: "50"
				}
			]
		};
		return response;
	}

	getTopUsersTableDetails() {
		let response = {
			headerElements: [
				{
					id: "users",
					name: "Users",
					isClickable: true
				},
				{
					id: "noOfCategories",
					name: "Number of Categories"
				}
			],
			rowElements: [
				{
					users: "1.1.1.1",
					noOfCategories: "10"
				},
				{
					users: "1.1.1.1",
					noOfCategories: "10"
				},
				{
					users: "1.1.1.1",
					noOfCategories: "10"
				},
				{
					users: "1.1.1.1",
					noOfCategories: "10"
				},
				{
					users: "1.1.1.1",
					noOfCategories: "10"
				}
			]
		};
		return response;
	}

	getTopBlockedCategoriesDrillDownTableDetails() {
		let response = {
			headerElements: [
				{
					id: "userName",
					name: "Username"
				},
				{
					id: "sourceAddress",
					name: "Src Address"
				},
				{
					id: "destinationAddress",
					name: "Dst Address"
				},
				{
					id: "eventType",
					name: "Type"
				},
				{
					id: "alertDateTime",
					name: "Date"
				},
				{
					id: "Details",
					name: "Details"
				}
			],
			rowElements: [
				{
					userName: "youtube.com",
					sourceAddress: "1.1.1.1",
					destinationAddress: "2.2.2.2",
					eventType: "Reputation",
					alertDateTime: "04.08.2023 10.45",
					Details: "youtube.com"
				},
				{
					userName: "youtube.com",
					sourceAddress: "1.1.1.1",
					destinationAddress: "2.2.2.2",
					eventType: "Reputation",
					alertDateTime: "04.08.2023 10.45",
					Details: "youtube.com"
				},
				{
					userName: "youtube.com",
					sourceAddress: "1.1.1.1",
					destinationAddress: "2.2.2.2",
					eventType: "Reputation",
					alertDateTime: "04.08.2023 10.45",
					Details: "youtube.com"
				}
			]
		};
		return response;
	}

	getReportUrlTableDetails() {
		let response = {
			responseCode: 0,
			message: "",
			traceId: "8b2cd483-d14a-4422-953f-c8fa0691ee8f",
			data: {
				content: [
					{
						username: "10.1.1.1",
						category: "OTT",
						urls: "hotstar.com",
						download: "10MB",
						upload: "10MB",
						dateTime: "07.08.2023 08.12"
					},
					{
						username: "20.1.1.1",
						category: "OTT",
						urls: "hotstar.com",
						download: "10MB",
						upload: "10MB",
						dateTime: "06.08.2023 08.12"
					},
					{
						username: "30.1.1.1",
						category: "OTT",
						urls: "hotstar.com",
						download: "10MB",
						upload: "10MB",
						dateTime: "08.08.2023 08.12"
					}
				],
				pageable: {
					sort: {
						empty: false,
						sorted: true,
						unsorted: false
					},
					offset: 0,
					pageNumber: 0,
					pageSize: 5,
					paged: true,
					unpaged: false
				},
				totalPages: 1,
				totalElements: 1,
				last: true,
				size: 5,
				number: 0,
				sort: {
					empty: false,
					sorted: true,
					unsorted: false
				},
				numberOfElements: 1,
				first: true,
				empty: false
			}
		};
		return response;
	}

	getReportTableDetails() {
		let response = {
			responseCode: 0,
			message: "",
			traceId: "8b2cd483-d14a-4422-953f-c8fa0691ee8f",
			data: {
				content: [
					{
						url: "goole.com",
						destinationIp: "10.20.1.2",
						sourcePort: "223",
						destinationPort: "443",
						category: "Gaming",
						numberOfUsers: "5",
						uplink: "google.com",
						downlink: "google.com",
						permission: "Allow",
						policyName: "Test policy"
					},
					{
						url: "goole.com",
						destinationIp: "10.20.1.2",
						sourcePort: "223",
						destinationPort: "443",
						category: "Gaming",
						numberOfUsers: "5",
						uplink: "google.com",
						downlink: "google.com",
						permission: "Allow",
						policyName: "Test policy"
					},
					{
						url: "goole.com",
						destinationIp: "10.20.1.2",
						sourcePort: "223",
						destinationPort: "443",
						category: "Gaming",
						numberOfUsers: "5",
						uplink: "google.com",
						downlink: "google.com",
						permission: "Allow",
						policyName: "Test policy"
					},
					{
						url: "goole.com",
						destinationIp: "10.20.1.2",
						sourcePort: "223",
						destinationPort: "443",
						category: "Gaming",
						numberOfUsers: "5",
						uplink: "google.com",
						downlink: "google.com",
						permission: "Allow",
						policyName: "Test policy"
					}
				],
				pageable: {
					sort: {
						empty: false,
						sorted: true,
						unsorted: false
					},
					offset: 0,
					pageNumber: 0,
					pageSize: 5,
					paged: true,
					unpaged: false
				},
				totalPages: 1,
				totalElements: 1,
				last: true,
				size: 5,
				number: 0,
				sort: {
					empty: false,
					sorted: true,
					unsorted: false
				},
				numberOfElements: 1,
				first: true,
				empty: false
			}
		};
		return response;
	}

	getTopUrlsByReputationChartDetails() {
		let response = {
			data: [
				{
					name: "Critical",
					y: 25,
					range: "0-25"
				},
				{
					name: "Medium",
					y: 35,
					range: "25-60"
				},
				{
					name: "Low",
					y: 40,
					range: "60-100"
				}
			]
		};
		return response;
	}

	topUrlDrillDownDetails() {
		let response = {
			headerElements: [
				{
					id: "userName",
					name: "Users"
				},
				{
					id: "sites",
					name: "Sites"
				},
				{
					id: "numberOfHits",
					name: "Number Of Hits"
				}
			],
			rowElements: [
				{
					userName: "Test User",
					numberOfHits: "10",
					sites: "CHN_JTP"
				},
				{
					userName: "Test User",
					numberOfHits: "10",
					sites: "CHN_JTP"
				},
				{
					userName: "Test User",
					numberOfHits: "10",
					sites: "CHN_JTP"
				}
			]
		};
		return response;
	}

	getSessionFlow(payload: any): Observable<any> {
		const url = `${this.apiUrls.MONITORING_SERVICE_URL}/sen/dashboard/sessionflow`;
		return this.agHttpService.makePostRequest(url, payload);
	}
}
