import { Component, OnInit, Input, OnDestroy, SimpleChanges } from "@angular/core";
import { interval, Subscription } from "rxjs";
import { INFO_MESSAGES } from 'src/app/shared/utils/global-constants';

@Component({
	selector: "app-loader",
	templateUrl: "./loader.component.html",
	styleUrls: ["./loader.component.css"]
})
export class LoaderComponent implements OnInit, OnDestroy {
	@Input() showFontAwesone = false;
	@Input() inputLoaderMessage: string = '';

	timerSubscription: Subscription;

	loadingMessage = this.inputLoaderMessage || INFO_MESSAGES.LOADING_BASE_MSG;
 
	constructor() {
		this.timerSubscription = interval(1000).subscribe((number: number) => {
			if (number == 5) {
				this.loadingMessage = INFO_MESSAGES.LOADING_MSG_1;
			} else if (number === 15) {
				this.loadingMessage = INFO_MESSAGES.LOADING_MSG_2;
			}
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.timerSubscription.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['inputLoaderMessage']) {
			this.updateLoadingMessage();
		}
	}

	updateLoadingMessage() {
		this.loadingMessage = this.inputLoaderMessage || INFO_MESSAGES.LOADING_BASE_MSG;
	}
}
