import { Component, ViewChild, TemplateRef, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Clipboard } from "@angular/cdk/clipboard";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ToasterService } from "src/app/shared/toaster.service";
import { aesdecrypt, encodeString } from "src/app/shared/utils/commonutils";
import { GlobalConstants, GLOBAL_NAMES, INFO_MESSAGES } from "src/app/shared/utils/global-constants";
import { DataParams } from "src/app/shared/models/utils";
import { CommonService } from "src/app/shared/services/common.service";
import { Device } from "src/app/shared/models/device";
import { ConfirmDialogModel, ConfirmDialogComponent } from "src/app/common/confirm-dialog/confirm-dialog.component";
import { take } from "rxjs/operators";
import { User } from "src/app/shared/models/user";
import { AppService } from "src/app/app.service";
import { Role } from "src/app/shared/models/role";
import { AuthenticationService } from "src/app/shared/services";
import { DataSharedService } from "src/app/shared/services/data-shared.service";
import { TableColumns, pageOptions } from "../../models/table";
import { ServiceEdgesManagementService } from "../../services/service-edges-management.service";
import { PreviousRouteService } from "../../services/route-service";

@Component({
	selector: "app-shared-service-edge-list",
	templateUrl: "./service-edge-list.component.html",
	styleUrls: ["./service-edge-list.component.scss"]
})
export class ServiceEdgeListComponent {
	@Input() columns!: TableColumns[];
	@Input() canShowAddBtn!: boolean;
	@Input() canShowRegistrationKey!: boolean;
	SERVICE_EDGE_OFFLINE_MONITORING_MSG = INFO_MESSAGES.SERVICE_EDGE_OFFLINE_MONITORING_MSG;
	data: { [key: string]: any }[] = [];
	totalSize: any;
	showProgress = true;
	maskedKey!: string;
	pageIndex = 0;
	searchTerm = "";
	pageSize = GlobalConstants.Default_PageSize;
	dataParams: DataParams = { showSearchInput: true, searchDivClass: "site-search" };
	flags: any = {
		isEnableRegistrationKey: false,
		isDisableDelete: true
	};
	customerSelectList: any[] = [];
	customerValueSelected: any[] = [];
	showCustomerSelect = false;
	selectedCustomerId: any;
	isShowOverlayCard: boolean = false;
	@ViewChild("fsKeyTemplate") fsKeyTemplate!: TemplateRef<any>;
	refreshProgress = false;
	public loggedInUser: User | undefined;
	// @ViewChild('copiedTemplate') copiedTemplate!: TemplateRef<any>;
	senTransitionMessage: any = {
		shipping: "Are you sure you want to ship the service edge?",
		configure: "Are you sure you want to configure the service edge?",
		received: "Are you sure you have received the service edge?",
		approved: "Are you sure you want to approve the service edge?"
	};

	fsDetails: { fsId: number; fsKey: string } | null = null;
	dataSource: any = {};
	sortQuery = "";
	senSortOnlineFlag: boolean = true;
	@ViewChild("tenentCellTemplate", { static: true }) tenentCellTemplate!: TemplateRef<any>;

	constructor(
		private commonService: CommonService,
		private router: Router,
		private toasterService: ToasterService,
		private snackBar: MatSnackBar,
		private clipboard: Clipboard,
		private dialog: MatDialog,
		private serviceEdgesManagementService: ServiceEdgesManagementService,
		private appService: AppService,
		private authService: AuthenticationService,
		private dataSharedService: DataSharedService,
		private previousRouteService: PreviousRouteService
	) {}

	ngOnInit(): void {
		this.flags.isEnableConfigurations = this.authService.isPrivilegeAvailable(Role.canCreateConfigurationItem);
		this.appService.loggedInUserDetailsObservable.subscribe({
			next: (user: User | null) => {
				if (user) {
					this.loggedInUser = user;
				}
			}
		});
		this.flags.isEnableEdit = this.authService.isPrivilegeAvailable(Role.canEditserviceEdge);
		this.flags.isEnableAdd = this.authService.isPrivilegeAvailable(Role.canCreateServiceEdge);
		const previousUrl: any = this.previousRouteService.getPreviousUrl();
		if (
			(previousUrl?.includes("/service-edge-management/") ||
				previousUrl?.includes("/service-edge-configuration-management/") ||
				previousUrl?.includes("/monitoring/sen-monitoring/")) &&
			(!previousUrl.includes("/service-edge-management/service-edge-list") ||
				previousUrl.includes("/network-management/service-edge-list"))
		) {
			this.searchTerm = this.dataSharedService.getSearchTerm();
			if (!this.searchTerm) {
				this.pageIndex = this.dataSharedService.getPageIndex();
				this.pageSize = this.dataSharedService.getPageSize();
			}
		} else {
			this.dataSharedService.setPageIndex(0);
			this.dataSharedService.setPageSize(GlobalConstants.Default_PageSize);
			this.dataSharedService.setSearchTerm("");
		}
		this.getData(this.pageIndex || 0, this.pageSize || GlobalConstants.Default_PageSize);
		this.showProgress = true;
		this.dataSource.pageOptions = { pageIndex: 0, pageSize: GlobalConstants.Default_PageSize };
		const index = this.columns.findIndex((element) => element.field === "noOfCustomers");
		this.columns[index].cellCustomTemplate = this.tenentCellTemplate;
		this.flags.isEnableRegistrationKey = this.canShowRegistrationKey;
	}

	onSelectCustomer(event: any) {
		this.serviceEdgesManagementService.selectedCustomerId = event?.id ? event.id : null;
		this.showProgress = true;
		this.dataSharedService.setSelectedCustomer(event);
		this.getData();
	}

	addCustomer() {
		this.router.navigateByUrl("/service-edge-management/action/create-service-edges");
	}

	onDetailsClicked(element: { [key: string]: any }): void {
		let id = encodeString(element["id"]);
		this.router.navigateByUrl(`/service-edge-management/details/${id}`);
	}

	onConfigurationsClicked(element: { [key: string]: any }): void {
		if (element["online"]) {
			let id = encodeString(element["id"]);
			if (this.flags.isEnableConfigurations) {
				this.router.navigateByUrl(`/service-edge-configuration-management/configure-service-edge/${id}`);
			} else {
				this.router.navigateByUrl(`/service-edge-configuration-management/view-service-edge-configuration/${id}`);
			}
		} else {
			this.isShowOverlayCard = true;
		}
	}

	onEditClicked(element: Element): void {
		let id = encodeString(element["id"]);
		this.router.navigateByUrl(`/service-edge-management/action/edit-service-edges/${id}`);
	}

	onLinkClicked(event: { data: any; type: string }) {
		// refactor to switch statement - TODO
		if (event.type === "fsKey") {
			this.refreshProgress = true;
			this.serviceEdgesManagementService.getDeviceKey(event.data.id).subscribe({
				next: (response: any) => {
					if (response.responseCode === 0) {
						this.refreshProgress = true;
						const decrytpedFskey = aesdecrypt(GlobalConstants.SECRET_KEY, response.data.fsKey);
						this.fsDetails = { fsId: response.data.fsId, fsKey: decrytpedFskey };
						this.maskedKey = "*".repeat(this.fsDetails.fsKey.length);
						this.dialog.open(this.fsKeyTemplate, {
							width: "800px",
							disableClose: true,
							autoFocus: false
						});
					} else {
						this.toasterService.showErrorMessage(
							"Unable to proceed with the action , please try again later"
						);
					}
					this.refreshProgress = false;
				},
				error: (err: any) => {
					this.refreshProgress = false;
					this.toasterService.showErrorMessage("Unable to proceed with the action , please try again later");
				}
			});
		} else if (event.type === "refresh") {
			this.refreshProgress = true;
			this.serviceEdgesManagementService.getDevice(event.data.id).subscribe({
				next: (response: any) => {
					if (response.responseCode === 0) {
						this.refreshProgress = true;
						event.data.online = response.data.online;
						event.data.initialConfigStatus = response.data.initialConfigStatus;

						this.serviceEdgesConfiguration(this.data[event.data.index]);
						// this.getData({pageIndex: event.data.pageIndex, pageSize: event.data.pageSize});
					} else {
						this.toasterService.showErrorMessage(
							"Unable to proceed with the action , please try again later"
						);
					}
					this.refreshProgress = false;
				},
				error: (err: any) => {
					this.refreshProgress = false;
					this.toasterService.showErrorMessage("Unable to proceed with the action , please try again later");
				}
			});
		} else if (event.type === "SEN details") {
			const edgeListUrl = window.location.href;
			if (edgeListUrl?.includes("network-management/service-edge-list")) {
				this.navigateToWanConfiguration(event);
			} else {
				let id = encodeString(event.data.id);
				this.router.navigate(["monitoring", "sen-monitoring", id]);
			}
		} else if (event.type === "WAN config") {
			this.navigateToWanConfiguration(event);
		}
		//  else if(event.type === 'SEN Monitoring') {
		// 		this.router.navigate(['monitoring', 'sen-monitoring', event.data.id]);
		// }
		else if (event.type === "sort") {
			this.setSortParams(event.data.active, event.data.direction);
			this.refreshProgress = true;
			this.getData(event.data.pageIndex, event.data.pageSize);
		}
	}

	navigateToWanConfiguration(event: { data: any; type: string }) {
		if (event.data.online) {
			let id = encodeString(event.data.id);
			if (this.flags.isEnableConfigurations) {
				this.router.navigate(["service-edge-configuration-management", "configure-service-edge", id]);
			} else {
				this.router.navigate(["service-edge-configuration-management", "view-service-edge-configuration", id]);
			}
		} else {
			this.isShowOverlayCard = true;
		}
	}
	private setSortParams(active: string, direction: string): void {
		if (direction) {
			let sortParam = active;
			if (sortParam === "displayName") {
				sortParam = "display_name";
				this.sortQuery = `sort=${sortParam},${direction}`;
			} else if (sortParam === "online") {
				this.sortQuery = `online=${this.senSortOnlineFlag}`;
				this.senSortOnlineFlag = !this.senSortOnlineFlag;
			} else if (sortParam === "cityName") {
				this.sortQuery = `site=${direction === "asc" ? "DESC" : "ASC"}`;
			} else if (sortParam === "noOfCustomers") {
				this.sortQuery = `tenant=${direction === "asc" ? "ASC" : "DESC"}`;
			} else {
				this.sortQuery = `sort=${sortParam},${direction}`;
			}
		} else {
			this.sortQuery = "";
		}
	}

	private onHandleWorkFlowChange(event: Device): void {
		const message = this.senTransitionMessage[event!.assetStatus!.action?.toLowerCase()];
		const data = new ConfirmDialogModel(GLOBAL_NAMES.CONFIRMATION_MODAL_TITLE, message);
		const dialogReference = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: "450px",
			data,
			disableClose: true
		});

		dialogReference
			.afterClosed()
			.pipe(take(1))
			.subscribe((agree: any) => {
				if (!agree) {
					return;
				} else {
					this.refreshProgress = true;
					this.serviceEdgesManagementService.updateCIWorkfowStatus(event.id as number).subscribe({
						next: (response: any) => {
							if (response.responseCode === 0) {
								const device = this.data.find((data: Device) => data.id === event.id);
								if (device) {
									device["assetStatus"] = response.data;
								}
							} else {
								this.toasterService.showErrorMessage(
									"Unable to do this action at this moment, please try again later"
								);
							}
							this.refreshProgress = false;
						},
						error: (err: any) => {
							this.toasterService.showErrorMessage(
								"Unable to do this action at this moment, please try again later"
							);
							this.refreshProgress = false;
						}
					});
				}
			});
	}

	serviceEdgesConfiguration(device: any) {
		if (device && device.online && (!device.initialConfigStatus || device.nitialConfigStatus != "Configured")) {
			device.configuring = true;
			this.serviceEdgesManagementService.deviceInitConfiguration(device.id, {}).subscribe({
				next: (response: any) => {
					device.configuring = false;
					if (response && response.status && response.status.toLowerCase() == "success") {
						device.initialConfigStatus = response.data.initialConfigStatus;
						this.toasterService.showSuccessMessage("", device.name + " Configuration done Successfully");
					} else {
						this.toasterService.showErrorMessage(response.message);
					}
				},
				error: (err: any) => {
					device.configuring = false;
				}
			});
		}
	}
	onPageChanged(event: pageOptions) {
		this.showProgress = true;
		this.dataSource.pageOptions = event;
		this.dataSharedService.setPageIndex(event?.pageIndex);
		this.dataSharedService.setPageSize(event?.pageSize);
		this.getData(event.pageIndex, event.pageSize);
	}
	private getData(pageIndex: number = 0, pageSize: number = GlobalConstants.Default_PageSize): void {
		this.showProgress = true;
		this.serviceEdgesManagementService
			.getAllDevices(pageIndex, pageSize, this.searchTerm, this.sortQuery)
			.subscribe({
				next: (response: any) => {
					if (response.responseCode === 0) {
						this.data = response.data.content;
						// this.data[1]['utilization'].upload = "48"
						// this.data[1]['utilization'].download = "56"

						// this.data.push({ "id": 171, "name": "test-sen", "description": "test-desc", "typeId": 2, "type": "SEN", "customerId": 109, "customerName": "Customer Test", "siteId": 11, "siteName": "TEST SITE", "cityId": 131517, "cityName": "Bangalore", "online": true, "displayName": "Test SEN", "serialNumber": "0009-0008" })
						this.pageIndex = response.data.number;
						this.pageSize = pageSize;
						this.totalSize = response.data.totalElements;
					}
					this.showProgress = false;
					this.refreshProgress = false;
				},
				error: (err: any) => {
					this.showProgress = false;
					this.refreshProgress = false;
				}
			});
	}

	onCopy(): void {
		this.clipboard.copy(this.fsDetails ? this.fsDetails.fsKey : "");
		this.snackBar.open("Copied to clipboard!!!", "Dismiss", { duration: 3000 });
	}
	public search(value: string) {
		this.searchTerm = value;
		this.dataSharedService.setSearchTerm(value);
		this.showProgress = true;
		this.getData();
	}
}
