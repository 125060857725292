import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MultiselectDropdown } from "../../models/utils";
import { multiSelectionDropdownSettings, singleSelectionDropdownSettings } from "../../utils/ng-dropdown-settings";
import { cloneDeep } from "lodash";

@Component({
	selector: "app-multi-select",
	templateUrl: "./multi-select.component.html",
	styleUrls: ["./multi-select.component.scss"]
})
export class MultiSelectComponent {
	public dropdownSettings: any;

	private _selection = "";

	private _data!: any[];

	private _selected: any[] = [];

	public valueSelected: any;

	private isDropdownOpened = false;

	private clonedSelectedValue: any;

	@Input() set data(value: any[]) {
		this._data = value;
	}

	get data() {
		return this._data;
	}

	@Input() set selection(value: string) {
		if (value === "single") {
			this.dropdownSettings = singleSelectionDropdownSettings();
		} else {
			this.dropdownSettings = multiSelectionDropdownSettings();
		}
	}

	get selection() {
		return this._selection;
	}

	@Input() set selected(values: any) {
		if (values) {
			if (Array.isArray(values)) {
				this.valueSelected = values.map((value) => ({ label: value.label, value: value.value }));
			} else {
				let label = "";
				if (this.isNumber(values)) {
					label = this.data.find((value) => +value.value === +values).label;
				} else {
					let found = this.data.find((value) => value.value === values);
					if (found) {
						label = found.label;
					} else {
						label = values;
					}
				}
				this.valueSelected = [{ label: label, value: values }];
			}
			this.clonedSelectedValue = cloneDeep(this.valueSelected);
		} else {
			this.valueSelected = [];
			this.clonedSelectedValue = [];
		}
	}

	@Input() isDisabled = false;

	@Input() placeholder = "";

	@Output() selectValue: EventEmitter<MultiselectDropdown> = new EventEmitter<MultiselectDropdown>();

	@Output() deSelectValue: EventEmitter<MultiselectDropdown> = new EventEmitter<MultiselectDropdown>();

	@Output() deSelectAllValues: EventEmitter<MultiselectDropdown> = new EventEmitter<MultiselectDropdown>();

	@Output() selectAllValues: EventEmitter<MultiselectDropdown> = new EventEmitter<MultiselectDropdown>();

	@Output() onDropdownOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output() onDropdownClose: EventEmitter<any> = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}
	isNumber(str: string): boolean {
		if (typeof str !== "string") {
			return false;
		}

		if (str.trim() === "") {
			return false;
		}

		return !Number.isNaN(Number(str));
	}
	onSelectValue(event: any): void {
		this.selectValue.emit(event);
	}

	onDeselectValue(event: any): void {
		this.deSelectValue.emit(event);
	}

	onDropdownClicked(): void {
		this.isDropdownOpened = true;
		this.onDropdownOpen.emit(true);
	}

	onSelectAllValues(event: any): void {
		this.selectAllValues.emit(event);
	}

	onDeSelectAllValue(event: any) {
		this.deSelectAllValues.emit(event);
	}

	onDropdownClosed() {
		if (this.isDropdownOpened) {
			this.isDropdownOpened = false;
			// const isValueChanged = compareObject(this.clonedSelectedValue, this.valueSelected);
			this.onDropdownClose.emit({ value: this.valueSelected });
		}
	}
}
