<div class="profile-list-popup m-3">
	<div class="row mb-2 gap-5">
		<div class="col-7 current-selection-section">
			<span>
				<span class="attribute-key">Current Selection:</span>
				<span class="attribute-value">
					{{ selectedProfile?.name || defaultSelectedProfile?.name }}
				</span>
				<!-- <span id="selectedProfileTooltip" class="display-none">
					<div>Highest: {{ selectedProfile?.highest || defaultSelectedProfile?.highest }}%</div>
					<div>High: {{ selectedProfile?.high || defaultSelectedProfile?.high }}%</div>
					<div>Medium: {{ selectedProfile?.medium || defaultSelectedProfile?.medium }}%</div>
					<div>Low: {{ selectedProfile?.low || defaultSelectedProfile?.low }}%</div>
				</span> -->
			</span>
		</div>
		<div class="col-4">
			<app-search-input [searchTerm]="searchTerm" (setSearchValue)="search($event)"></app-search-input>
		</div>
	</div>

	<app-custom-data-table
		(rowSelect)="onRowSelected($event)"
		[settings]="{
			data: dataSource.profileList,
			columnConfig: dataSource.columnConfig,
			length: dataSource.profileList.length,
			isClientSidePagination: true,
			enableRadioBtn: true,
			loaderFlags: {
				isLoading: showProgress,
				isErrorFetchingData: false
			},
			hidePagination: false,
			defaultSelectedRows: defaultSelectedProfile
		}"></app-custom-data-table>

	<div class="d-flex justify-content-end gap-2 mt-4">
		<button
			(click)="onSave()"
			type="button"
			[disabled]="isSaveInProgress"
			class="custom-btn btn-config btn btn-primary">
			Save
		</button>
	</div>
</div>
