import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { of, Observable } from "rxjs";
import { catchError, mergeMap, tap } from "rxjs/operators";
import { AppComponentSvc } from "src/app/app.component-service";
// import { dataSharedService } from "src/app/shared/services/common.service";
import { GlobalConstants } from "src/app/shared/utils/global-constants";
import { environment } from "src/environments/environment";
import { ApiUrlService } from "src/app/shared/services/api-url.service";
import { APIUrlConstants } from "src/app/shared/utils/api-url-constants";
import { DataSharedService } from "src/app/shared/services/data-shared.service";

@Injectable({
	providedIn: "root"
})
export class InitService {
	constructor(
		private httpClient: HttpClient,
		private dataSharedService: DataSharedService, 
		private router: Router,
		private apiUrlService: ApiUrlService
	) {}

	public initSpaedBasePath(contextPath: any) {
		this.dataSharedService.setSpaedBasePath(contextPath);
		return this.dataSharedService.getSpaedBasePath();
	}

	public loadUserDetails() {
		//const url = environment.userMgmtBasePath + "/user/loggedinuserdetails";
		const userMgmtUrl = this.apiUrlService.getAPIUrlByKey(APIUrlConstants.USER_MANAGEMENT_URL);
		const url = `${userMgmtUrl}/user/loggedinuserdetails`;
		return this.httpClient
			.get(url)
			.pipe(
				tap((userDetails: any) => {
					const userProfile: any = userDetails.data ? JSON.stringify(userDetails.data) : "";
					// this.initSpaedBasePath(userDetails.data?.customerContextPath);// loading from db
					// this.dataSharedService.setCustomerContextPath(userDetails.data?.customerContextPath);
					// this.dataSharedService.setCustomerSecretKey(userDetails.data?.customerSecretkey);
					localStorage.setItem("userProfile", userProfile);
					this.dataSharedService.setLoggedInUserInfo(userDetails.data);
					if (userDetails.data?.userType.toLowerCase() != "internal") {
						if (
							this.dataSharedService.getCustomerContextPath() &&
							this.dataSharedService.getSpaedContextPath() !=
								this.dataSharedService.getCustomerContextPath()
						) {
							this.router.navigate(["/forbidden"]);
							return;
						}
					}

					return userDetails;
				})
			)
			.toPromise()
			.then(() => (this.dataSharedService.isUserDetailsFetched = true))
			.catch(() => (this.dataSharedService.isUserDetailsFetched = false));
	}

	public getPrivileges() {
		//const url = environment.userMgmtBasePath + "/role/user/roles/privileges";
		const userMgmtUrl = this.apiUrlService.getAPIUrlByKey(APIUrlConstants.USER_MANAGEMENT_URL);
		const url = `${userMgmtUrl}/role/user/roles/privileges`;
		return this.httpClient
			.get(url)
			.pipe(
				tap((privileges: any) => {
					this.dataSharedService.setPrivileges(privileges.data);
					return privileges;
				})
			)
			.toPromise()
			.then(() => (this.dataSharedService.isUserPrivilegesFetched = true))
			.catch(() => (this.dataSharedService.isUserPrivilegesFetched = false));
	}
	public getCustomers() {
		const customerMgmtUrl = this.apiUrlService.getAPIUrlByKey(APIUrlConstants.CUSTOMER_MANAGEMENT_URL);
		let customerData: any = [];
		const url = `${customerMgmtUrl}/customer/customers/user`;
		return this.httpClient
			.get(url)
			.pipe(
				tap((response: any) => {
					if (response.responseCode === GlobalConstants.RESPONSE_SUCCESS_CODE) {
						if (response.data && response.data.length) {
							customerData = response.data.map((customer: any) => {
								return {
									label: customer.displayName,
									value: customer.id,
									...customer
								};
							});
						}
					} else {
						this.dataSharedService.isCustomersDataFetchError = true;
					}
					this.dataSharedService.setCustomersData(customerData);
					this.initSpaedBasePath(customerData[0]?.customerContextPath); // loading from db
					this.dataSharedService.setCustomerContextPath(customerData[0]?.customerContextPath);
					this.dataSharedService.setCustomerSecretKey(customerData[0]?.customerSecretkey);
				})
			)
			.toPromise()
			.then(() => {
				this.dataSharedService.isCustomersDataFetchError = false;
			})
			.catch(() => {
				this.dataSharedService.isCustomersDataFetchError = true;
			});
	}
}