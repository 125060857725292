import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ListItem } from "ng-multiselect-dropdown/multiselect.model";
import { singleSelectionDropdownSettings } from "../../utils/ng-dropdown-settings";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { CommonService } from "../../services/common.service";
import { combineLatest } from "rxjs";
import { MultiselectDropdown } from "../../models/utils";
import { DataSharedService } from "../../services/data-shared.service";

type DropdownType = {
	id: number;
	name: string;
};

@Component({
	selector: "app-customer-select-list",
	templateUrl: "./customer-select-list.component.html",
	styleUrls: ["./customer-select-list.component.scss"]
})
export class CustomerSelectListComponent {
	private _data!: any[];
	private _selectedCustomer!: any[];
	public valueSelected: any;
	@Input() set data(value: any[]) {
		this._data = value;
		this.customers = value;
	}
	get data() {
		return this._data;
	}
	@Input() isShowAll = false;

	@Input() set selectedCustomer(values: any) {
		if (values) {
			if (Array.isArray(values)) {
				this.valueSelected = values.map((value) => ({ label: value.label, value: value.value }));
			} else {
				let label = "";
				if (this.isNumber(values)) {
					label = this.data?.find((value) => +value.value === +values).label;
				} else {
					let found = this.data?.find((value) => value.value === values);
					if (found) {
						label = found.label;
					} else {
						label = values;
					}
				}

				this.valueSelected = [{ label: label, value: values }];
			}
		} else {
			this.valueSelected = [];
		}
	}
	showProgress = false;
	dropdownSettings: IDropdownSettings = singleSelectionDropdownSettings({
		idFieldValue: "value",
		textFieldValue: "label"
	});
	customers: any[] = [];
	@Input() showCustomerDropDown = false;

	@Output() selectValue: EventEmitter<MultiselectDropdown> = new EventEmitter<MultiselectDropdown>();

	@Output() deSelectValue: EventEmitter<MultiselectDropdown> = new EventEmitter<MultiselectDropdown>();

	@Output() afterInitEvent: EventEmitter<any> = new EventEmitter<any>();

	constructor(private dataSharedService: DataSharedService) {}

	ngOnInit(): void {
		if (!this._data?.length) {
			if (!this.dataSharedService.isCustomersDataFetchError) {
				this.customers = this.dataSharedService.getCustomersData();
				this.showCustomerDropDown = this.customers.length > 1;
			}
		}
		if (this.isShowAll) {
			const isAllAlreadyExist =
				this.customers?.findIndex((customer) => customer.label === "All") === 0 ? true : false;
			if (!isAllAlreadyExist) {
				this.customers.unshift({ value: 0, label: "All", name: "All", id: 0 });
			}
		}
		let _selectedCustomer = this.dataSharedService.getSelectedCustomer();
		let valueSelected = this.dataSharedService.comparePreviousAndCurrentValue(_selectedCustomer, this.customers[0]);
		if (
			(valueSelected && Object.keys(valueSelected).length && valueSelected?.label && valueSelected?.value) ||
			(valueSelected?.id && valueSelected?.name)
		) {
			let selectedCustomerValue;
			if (valueSelected?.label && valueSelected?.value) {
				selectedCustomerValue = this.customers.find(
					(element) => element.label === valueSelected.label && element.value === valueSelected.value
				);
			} else {
				selectedCustomerValue = this.customers.find(
					(element) => element.id === valueSelected.id && element.name === valueSelected.name
				);
			}
			this.dataSharedService.setSelectedCustomer(
				selectedCustomerValue ? selectedCustomerValue : this.customers[0]
			);
			this.valueSelected = [selectedCustomerValue ? selectedCustomerValue : this.customers[0]];
		} else {
			this.valueSelected = [this.customers[0]];
			this.dataSharedService.setSelectedCustomer(valueSelected);
		}
		this.afterInitEvent.emit(this.valueSelected);
	}
	isNumber(str: string): boolean {
		if (typeof str !== "string") {
			return false;
		}

		if (str.trim() === "") {
			return false;
		}

		return !Number.isNaN(Number(str));
	}
	onSelectCustomer(event: any): void {
		let selectedCustomer = this.customers.find(
			(element) => element.value === event.value && element.label === event.label
		);
		this.dataSharedService.setSelectedCustomer(selectedCustomer ? selectedCustomer : event);
		this.selectValue.emit(selectedCustomer ? selectedCustomer : event);
	}

	onDeselectCustomer(event: any): void {
		this.deSelectValue.emit(event);
	}
}
