import {
	Component,
	Input,
	OnInit,
	forwardRef,
	ViewChild,
	AfterViewInit,
	OnChanges,
	SimpleChanges
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "app-common-multiselect",
	templateUrl: "./common-multiselect.component.html",
	styleUrls: [ "./common-multiselect.component.scss" ],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CommonMultiselectComponent),
			multi: true
		}
	]
})
export class CommonMultiselectComponent implements OnInit, ControlValueAccessor {
	@Input() set settings(value: any) {
		this._settings	= value;
		this.onSettingsChange();	
	}
	@ViewChild("multiselect", { static: false }) multiselect!: any;
	private _onChange = (_: any) => { };
	private _onTouched = () => { };
	public selectedValues: any[] = [];
	public selectAll = false;
	public filterText = "";
	public selectAllGroup = false;
	public filteredOptions: any[] = [];
	public selectedItemValue!: string;
	public _settings: any;
	enableSelectAll:boolean = true;

	ngOnInit(): void { }

	ngAfterViewInit() {
		this.setFirstSelectedItemLabel();
	}

	writeValue(value: any): void {
		this.selectedValues = value;
	}

	registerOnChange(fn: any): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void { }

	onBlur() {
		this._onTouched();
	}

	// Set selectedItemtemplate show label of first selected item
	setFirstSelectedItemLabel() {
		this.selectedItemValue = this.selectedValues?.length
			? this.multiselect.findLabelByValue(this.selectedValues[ 0 ])
			: "";
	}

	onChange() {
		// To set the select all check box as checked/uncheck
		if (this._settings.group) {
			this.selectAll = this._settings.options?.length
				? this.selectedValues?.length ===
				this._settings?.options?.map((option: any) => option.items)?.flat()?.length
				: false;
		} else {
			this.selectAll = this._settings.options?.length
				? this.selectedValues?.length === this._settings?.options?.length
				: false;
		}
		this.setFirstSelectedItemLabel();
		this._onChange(this.selectedValues);
		this._onTouched();
	}

	onFilter(event: any) {
		// Get the filtered item from event emitted
		this.filteredOptions = [];
		if (event.filter?.length) {
			this.filteredOptions = this._settings.group
				? this.multiselect._filteredOptions?.map((option: any) => option.items)?.flat()
				: this.multiselect._filteredOptions;
		}
	}

	onSelectAll(event: any) {
		// If select all check box is ticked
		if (event?.checked) {
			// Write custom logic for select all when there is an filtered item ( since the primeng doesnt handle by default. Instead of selecting filtered item it selects all items)
			if (this.filteredOptions?.length) {
				this.selectedValues = [ ...new Set([ ...this.filteredOptions, ...this.selectedValues ]) ];
				this.onChange();
			} else {
				// Use inbuilt primeng logic for select all
				this.multiselect.toggleAll(event?.originalEvent);
			}
		} else {
			// Write custom logic for unselect when there is an filtered item ( since the primeng doesnt handle by default. Instead of unchecking filtered item it unchecks all items)
			let filteredArray: any[] = [];
			if (this.filteredOptions?.length) {
				// Ignore the filtered item and include the items which doesnt present in filtered item
				this.selectedValues.forEach((item) => {
					if (!this.filteredOptions.includes(item)) {
						filteredArray.push(item);
					}
				});
				this.selectedValues = filteredArray;
				this.onChange();
			} else {
				this.multiselect.toggleAll(event?.originalEvent);
			}
		}
	}

	onSettingsChange() {
		if(this._settings?.options?.length) {
			this.enableSelectAll = this._settings?.options?.length;
		}
	}
}
