<ng-multiselect-dropdown
	class="w100"
	[settings]="dropdownSettings"
	[data]="data"
	[(ngModel)]="valueSelected"
	(click)="onDropdownClicked()"
	(onDeSelect)="onDeselectValue($event)"
	[disabled]="isDisabled"
	[placeholder]="placeholder"
	(onSelect)="onSelectValue($event)"
	(onSelectAll)="onSelectAllValues($event)"
	(onDeSelectAll)="onDeSelectAllValue($event)"
	(onDropDownClose)="onDropdownClosed()"></ng-multiselect-dropdown>
