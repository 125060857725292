import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { AgHttpService } from "../shared/services/ag-http-service";
import { API_URLS } from "../shared/models/utils";
import { ApiUrlService } from "../shared/services/api-url.service";
import { WebsocketService } from "./web-socket.service";

@Injectable({
	providedIn: "root"
})
export class ConfigurationManagementService {
	public selectedCustomerId: any;
	public deviceWANCount: BehaviorSubject<number | 0> = new BehaviorSubject<number | 0>(0);
	public deviceWANCount$: Observable<number | 0> = this.deviceWANCount as Observable<number>;

	public apiUrls: API_URLS;

	constructor(
		private agHttpService: AgHttpService,
		private apiUrlService: ApiUrlService,
		private websocketService: WebsocketService
	) {
		this.apiUrls = this.apiUrlService.getAPIUrls();
	}

	getWanDetailsByDeviceId(deviceId: number): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/wan/device/${deviceId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getLanDetailsByDeviceId(deviceId: number): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/lan/device/${deviceId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getWANRulesByDeviceId(deviceId: number): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/wan/rules/device/${deviceId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getWanCIConfig(ciId: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/config/wan/ci/${ciId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getLanCIConfig(ciId: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/config/lan/ci/${ciId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getDevice(ciId: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/${ciId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	saveWanConfig(wanConfigPayload: any): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/config/wan`;
		return this.agHttpService.makePostRequest(url, wanConfigPayload);
	}

	saveLanConfig(lanConfigPayload: any): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/config/lan`;
		return this.agHttpService.makePostRequest(url, lanConfigPayload);
	}

	applyConfig(configPayload: any, ciId: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/config/apply/${ciId}`;
		return this.agHttpService.makePostRequest(url, configPayload);
	}

	applyLanConfig(configPayload: any, deviceId: number): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/lan/apply/device/${deviceId}`;
		return this.agHttpService.makePostRequest(url, configPayload);
	}

	onSaveLanConfig(configPayload: any, deviceId: number): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/lan/save/device/${deviceId}`;
		return this.agHttpService.makePostRequest(url, configPayload);
	}

	applyWanConfig(configPayload: any, deviceId: number): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/wan/apply/device/${deviceId}`;
		return this.agHttpService.makePostRequest(url, configPayload);
	}

	applyRulesConfig(configPayload: any, deviceId: any): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/wan/rules/apply/device/${deviceId}`;
		return this.agHttpService.makePostRequest(url, configPayload);
	}

	getConfigDetailOptions(deviceId: number): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/lan/dhcp/options/device/${deviceId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	validateIp(payload: any): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/lan/ip/validation`;
		return this.agHttpService.makePostRequest(url, payload);
	}
	applyEssidConfig(configPayload: any, deviceId: number): Observable<any> {
		const url = `${environment.spaedBasePath}/device/changeEmittedESSIDpassword`;
		return this.agHttpService.makePostRequest(url, configPayload);
	}
	applySecuredRoutingConfig(configPayload: any, deviceId: number, payloadUrl: string): Observable<any> {
		const url = `${environment.spaedBasePath}/${payloadUrl}`;
		//return this.agHttpService.makePostRequest(url, configPayload);
		return of({});
	}

	getPodDetails(customerId: number): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/tunnel/pods/customer/${customerId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getDataPlaneNodeParameters(deviceId: number): Observable<any> {
		const response = [
			{
				id: 1,

				unit_id: "10",

				lendingHostIPAddress: null,

				lendingInterfaceAddress: null,

				lendingHostPubKey: null,

				lendingUdpPort: 0,

				listeningHost: "192.168.10.10",

				listeningPort: 7000,

				model: "Spaed-Dataplane",

				mac: "08:00:27:1d:d0:77",

				serial: "01223338888",

				gSigsEnable: true
			},

			{
				id: 1,

				unit_id: "10",

				gSigsHostName: "14.140.91.28",

				gSigsSecretKey: "qv80OJeAWNz$_JFL",

				gSigsPh1Algos: "aes256-sha512-modp4096",

				gSigsPh2Algos: "aes256-sha512-modp4096"
			}
		];
		const url = `${environment.spaedBasePath}/egressNode/${deviceId}`;
		return this.agHttpService.makeGetRequest(url);
		//return of(response);
	}
	getAllTemplates(page = 0, size = 10, searchTerm?: string, sort?: string): Observable<any> {
		const resonse = [
			{
				id: 1,
				templateName: "template 1",
				createdDate: "<TIMESTAMP>",
				lastUpdatedDate: "<LASTUPDATEDTIMESTAMP>",
				configuration: [
					{
						configType: "WIFI-SSID",
						userName: "",
						password: ""
					},
					{
						configType: "IPROUTING",
						subnet: "<SubNet in CIDR FORM>",
						gateway: "<IP ADDRESS>",
						interface: "<Interface of device>"
					},
					{
						configType: "URLWHITELISTING",
						hostName: ["<NAME OF THE URL TO BE WHITELISTED>"]
					},
					{
						configType: "URLBLACKISTING",
						hostName: ["<LIST OF THE URL TO BE WHITELISTED>"]
					},
					{
						configType: "PORTBLOCKING",
						port: [
							{
								portType: "<INGRESS/EGRESS>",
								minumunPort: "<INTEGER>",
								maximunPort: "<INTEGER>",
								protocol: "<TCP/UDP>"
							}
						]
					}
				]
			}
		];
		let queryString = `page=${page}&size=${size}`;
		if (sort) {
			queryString += `&${sort}`;
		} else {
			queryString += `&sort=id,desc`;
		}
		if (searchTerm) {
			queryString = queryString + `&term=${searchTerm}`;
		}
		let customerId = this.selectedCustomerId;
		queryString = this.selectedCustomerId ? queryString + `&customerId=${customerId}` : queryString;
		//let url = `${environment.basePath}/customer-mgmt/site?${queryString}`;

		const url = `${environment.spaedBasePath}/policytemplate `;
		return this.agHttpService.makeGetRequest(url);
		//return of(resonse);
	}
	deleteTemplate(deviceTemplateApplyPayload: any, deleteDeviceFalg: boolean): Observable<any> {
		const url = `${environment.spaedBasePath}/policytemplate?deviceDelete=${deleteDeviceFalg}`;
		return this.agHttpService.makeDeleteRequest(url, deviceTemplateApplyPayload);
		//return of({})
	}
	deleteDeviceTemplateCheck(deviceTemplateApplyPayload: any): Observable<any> {
		const url = `${environment.spaedBasePath}/device/policytemplate/delete/check`;
		return this.agHttpService.makePostRequest(url, deviceTemplateApplyPayload);
		//return of({})
	}
	getAllDevice(): Observable<any> {
		let url = `${environment.spaedBasePath}/devices`;
		return this.agHttpService.makeGetRequest(url);
	}
	saveTemplateConfig(configTemplatePayload: any): Observable<any> {
		const url = `${environment.spaedBasePath}/policytemplate`;
		return this.agHttpService.makePostRequest(url, configTemplatePayload);
	}
	applyDeviceTemplateMap(deviceTemplateApplyPayload: any): Observable<any> {
		const url = `${environment.spaedBasePath}/device/policytemplate/map`;
		return this.agHttpService.makePostRequest(url, deviceTemplateApplyPayload);
	}
	getTemplate(templateId: any, versionId: any): Observable<any> {
		let url = `${environment.spaedBasePath}/policytemplate/${templateId}/${versionId}`;
		return this.agHttpService.makeGetRequest(url);
	}
	updateTemplateConfig(configTemplatePayload: any): Observable<any> {
		const url = `${environment.spaedBasePath}/policytemplate`;
		return this.agHttpService.makePatchRequest(url, configTemplatePayload);
	}
	getDeviceTemplate(deviceId: any): Observable<any> {
		let url = `${environment.spaedBasePath}/device/policytemplate/${deviceId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getWanInterfaceConfiguration(deviceId: any): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/device/wanconfiguration/${deviceId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	saveWanInterfaceConfiguration(wanInterfacePayload: any): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/device/wanconfiguration`;
		return this.agHttpService.makePostRequest(url, wanInterfacePayload);
	}

	getAllMtcDevice(): Observable<any> {
		let customerId = this.selectedCustomerId;
		const payload = {
			customerId: customerId
		};
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/ci/devices/customer`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	lanIpUniquenessValidation(payload: any): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/lan/uniqueip/validation`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	getLANNetworks(customerId: any, deviceId: Number): Observable<any> {
		const url = `${this.apiUrls.SECURITY_MANAGEMENT_URL}/sen/policy/lan/networks/${customerId}?deviceId=${deviceId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getPrivateNetworks(customerId: any): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/private/application/customer/${customerId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getProtocolsList(type?: string): Observable<any> {
		let url = `${this.apiUrls.SECURITY_MANAGEMENT_URL}/sen/policy/protocol/list`;
		if (type) {
			url = `${url}?type=${type}`;
		}
		return this.agHttpService.makeGetRequest(url);
	}

	getPodCreationDetails(payload: any): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/remote/web/console/deploy/pod`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	removeActivePod(payload: any, auditId: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/remote/web/console/delete/pod?auditId=${auditId}`;
		return this.agHttpService.makePostRequest(url, payload);
	}

	ipLANNetworkRangeValidator(payload: any, customerId: number, deviceId?: number): Observable<any> {
		let url = `${this.apiUrls.SECURITY_MANAGEMENT_URL}/network/config/for/ip/valid/${customerId}`;
		if (deviceId) {
			url = `${url}?deviceId=${deviceId}`;
		}
		return this.agHttpService.makePostRequest(url, payload);
	}

	public getPrivateAndWidelyUsedApps(customerId: any): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/private/application/all/customer/${customerId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getWebConsoleUrl() {
		const url = `${this.apiUrls.FS_BASE_PATH}`;
		return url;
	}

	getIpLanNetworkRangeDetails(customerId: any): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/config/lan/networks/customer/${customerId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getQoSProfileByDevice(deviceId: number): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/qos/profile/device/${deviceId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	getQoSProfileByCustomer(customerId: number): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/qos/profile/customer/${customerId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	saveQoSProfileInDevice(deviceId: number, profileData: any): Observable<any> {
		const url = `${this.apiUrls.NETWORK_MANAGEMENT_URL}/qos/profile/device/${deviceId}`;
		return this.agHttpService.makePostRequest(url, profileData);
	}

	getSite(id: number): Observable<any> {
		const url = `${this.apiUrls.DEVICE_MANAGEMENT_URL}/site/${id}`;
		return this.agHttpService.makeGetRequest(url);
	}

	saveVlan(lanConfigPayload: any): Observable<any> {
		const url = `${this.apiUrls.AGENT_SERVICE_BASEPATH}/site/lan/save`;
		return this.agHttpService.makePostRequest(url, lanConfigPayload);
	}

	deleteVlanDetail(vlanId: any, siteId: any): Observable<any> {
		const url = `${this.apiUrls.AGENT_SERVICE_BASEPATH}/site/lan/delete/${vlanId}?siteId=${siteId}`;
		return this.agHttpService.makeDeleteRequest(url);
	}

	getVlanDetails(siteId: number): Observable<any> {
		const url = `${this.apiUrls.AGENT_SERVICE_BASEPATH}/site/vlandetail/${siteId}`;
		return this.agHttpService.makeGetRequest(url);
	}

	validateVlanIp(payload: any): Observable<any> {
		const url = `${this.apiUrls.AGENT_SERVICE_BASEPATH}/site/ip/validation`;
		return this.agHttpService.makePostRequest(url, payload);
	}
}
