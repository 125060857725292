import {
	AfterViewInit,
	Renderer2,
	Component,
	EventEmitter,
	Input,
	Output,
	OnInit,
	ViewChild,
	ElementRef
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { fromEvent, Subscription } from "rxjs";
import { TableColumns } from "../../models/table";
import { ToasterService } from "../../toaster.service";
import { trackByFn } from "../../utils/formutils";
import { Device } from "../../models/device-spaed";
import { AppService } from "../../../app.service";
import { User } from "../../models/user";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { GlobalConstants, GLOBAL_NAMES, INFO_MESSAGES } from "../../utils/global-constants";
import { AuthenticationService } from "src/app/shared/services";
import { Role } from "src/app/shared/models/role";

export interface Page {
	pageSize: number;
	pageIndex: number;
	sort?: any;
}

@Component({
	selector: "app-data-table",
	templateUrl: "./data-table.component.html",
	styleUrls: ["./data-table.component.scss"]
})
export class DataTableComponent implements OnInit, AfterViewInit {
	NO_DATA_FOUND = INFO_MESSAGES.NO_DATA_FOUND;
	@Input() pageIndex = 0;
	trackByFn = trackByFn;
	@Input() recordsPerPage = [5, 10, 50, 100];
	tableModel: any = {};

	@Input() set data(value: { [key: string]: any }[]) {
		if (value?.length > 0) {
			this.page = "";
			this.pageNumberErrorMessage = "";
			this.sourceData = value;
			if (this.isClientSidePagination) {
				this.filteredData = value.slice(0, this.pageSize);
				this.totalElements = this.sourceData.length;
				this.dataSource = new MatTableDataSource<any>(this.filteredData);
				this.dataSource.paginator = this.paginator;
			} else {
				this.totalElements = this.totalSize;
				this.dataSource = value;
				this.filteredData = value;
			}
		} else {
			this.totalElements = 0;
			this.dataSource = null;
			this.filteredData = [];
			this.showPaginator = false;
		}
	}

	@Input() displayedColumns: string[] = [];

	@Input() columns: TableColumns[] = [];

	@Input() isClientSidePagination = false;

	@Input() isExternalUser = false;

	@Input() showRoleAssign = false;

	@ViewChild(MatPaginator) paginator!: MatPaginator;

	@ViewChild(MatSort, { static: true }) sort!: MatSort;

	sortFields = {
		field: "",
		direction: ""
	};

	@ViewChild(MatTable) table!: MatTable<any>;

	@Input() totalSize!: number;

	@Input() isUserInternal!: boolean;

	@Input() flags: any | undefined;

	@Input() showPaginator: boolean = false;

	@Input() pageSize = 5;

	@Input() isDataLoading: boolean = false;

	@Output() toggleClicked: EventEmitter<Element> = new EventEmitter();

	@Output() detailsClicked: EventEmitter<Element> = new EventEmitter();

	@Output() editClicked: EventEmitter<Element> = new EventEmitter();

	@Output() deleteAction: EventEmitter<Element> = new EventEmitter();

	@Output() configurationsClicked: EventEmitter<Element> = new EventEmitter();

	@Output() configurationsTwoClicked: EventEmitter<Element> = new EventEmitter();

	@Output() configurationsThreeClicked: EventEmitter<Element> = new EventEmitter();

	@Output() pageChanged: EventEmitter<Page> = new EventEmitter<Page>();

	@Output() linkClicked: EventEmitter<any> = new EventEmitter<any>();

	@Output() assignClicked: EventEmitter<Element> = new EventEmitter();

	@Output() changeStatusAction: EventEmitter<Element> = new EventEmitter();

	@Output() launchWebConsoleClicked: EventEmitter<Element> = new EventEmitter();

	@ViewChild("dataTable", { static: false }) dataTable!: ElementRef;

	@Output() onConfigureTunnelClicked: EventEmitter<Element> = new EventEmitter();

	@Output() onConfigureStrictModeClicked: EventEmitter<Element> = new EventEmitter();

	sourceData: { [key: string]: any }[] = [];

	filteredData: { [key: string]: any }[] = [];

	page!: any;

	totalElements: number = 0;

	dataSource: any;

	eventSubscription!: Subscription;

	isShowOverlayCard!: boolean;

	pageNumberErrorMessage: string = "";

	role = Role;

	constructor(
		private toasterService: ToasterService,
		private renderer2: Renderer2,
		private appService: AppService,
		private authService: AuthenticationService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.appService.loggedInUserDetailsObservable.subscribe((user: User | null) => {
			this.isUserInternal = user?.userType?.toLowerCase() === "internal";
		});
		this.displayedColumns = this.columns.map((column: TableColumns) => column.field);
	}
	onToggleClicked(element: Element, e: any): void {
		const checkVal = { statusChecked: e.target.checked };
		const toggleData = { ...element, ...checkVal };
		this.toggleClicked.emit(toggleData);
	}
	onDetailsClicked(element: Element): void {
		this.detailsClicked.emit(element);
	}

	OnAssignClicked(element: Element): void {
		this.assignClicked.emit(element);
	}

	onEditClicked(element: Element): void {
		this.editClicked.emit(element);
	}

	onDelete(element: Element): void {
		this.deleteAction.emit(element);
	}

	onConfigurations(element: Element): void {
		this.configurationsClicked.emit(element);
		const elem = this.dataTable.nativeElement.querySelector("div.action-container.active");
		if (elem) {
			this.renderer2.removeClass(elem, "active");
		}
	}

	onChangeStatus(element: Element): void {
		this.changeStatusAction.emit(element);
	}

	onPageChanged(event: any): void {
		this.setPaginatorData();
	}

	private setPaginatorData(): void {
		if (this.isClientSidePagination) {
			this.getPaginatedData();
		} else {
			this.pageChanged.emit({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize });
		}
	}

	private getPaginatedData(): void {
		const startIndex = (this.paginator.pageIndex + 1 - 1) * this.paginator.pageSize;
		const endIndex = (this.paginator.pageIndex + 1) * this.paginator.pageSize;
		this.filteredData = this.sourceData.slice(startIndex, endIndex);
		this.dataSource = new MatTableDataSource<any>(this.filteredData);
		this.table.renderRows();
	}

	onGotoPage(): void {
		this.pageNumberErrorMessage = "";
		const lastPage = Math.ceil(this.totalElements / this.paginator.pageSize);
		let message = `Enter a page number less than or equal to ${lastPage}`;
		if (this.paginator.pageIndex + 1 === Number(this.page)) {
			this.pageNumberErrorMessage = `You are already on page ${this.page}`;
			return;
		}
		if (this.page > lastPage) {
			this.pageNumberErrorMessage = message;
			return;
		}
		if (this.showPaginator) {
			this.paginator.pageIndex = this.page - 1;
		}
		if (this.isClientSidePagination) {
			this.getPaginatedData();
			this.paginator.page.next({
				pageIndex: this.paginator.pageIndex,
				pageSize: this.paginator.pageSize,
				length: this.paginator.length
			});
		} else {
			this.pageChanged.emit({
				pageIndex: this.paginator && this.paginator.pageIndex ? this.paginator.pageIndex : 0,
				pageSize: this.paginator && this.paginator.pageSize ? this.paginator.pageSize : this.pageSize
			});
		}
		this.page = "";
	}

	onLinkClicked(column: TableColumns, element: any, keyType?: string): void {
		if (column.field === "wan") {
			this.linkClicked.emit({ data: element, type: "WAN config" });
		} else if (column.isLink) {
			if (element.type == "CPE") {
				this.linkClicked.emit({ data: element, type: "CPE details" });
			} else if (element.type == "SEN") {
				this.linkClicked.emit({ data: element, type: "SEN details" });
			} else {
				this.linkClicked.emit({ data: element, type: "Some link" });
			}
		}
	}

	onClickRegistrationKey(element: any, keyType?: string) {
		if (this.flags?.isEnableRegistrationKey) {
			if (keyType === "key") {
				this.linkClicked.emit({ data: element, type: "fsKey" });
			} else if (keyType === "refresh") {
				this.linkClicked.emit({
					data: { ...element, pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize },
					type: "refresh"
				});
			}
		}
	}

	ngOnDestroy(): void {
		this.eventSubscription.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.eventSubscription = fromEvent(document, "click").subscribe((event: any) => {
			if (!event.target.closest(".action-container.active") && event.target.id !== "more-button") {
				const elem = this.dataTable.nativeElement.querySelector("div.action-container.active");
				if (elem) {
					this.renderer2.removeClass(elem, "active");
				}
			}
		});
	}

	onButtonClicked(event: any, index: number): void {
		const elem = this.dataTable.nativeElement.querySelector("div.action-container.action-" + index);
		const elemActive = this.dataTable.nativeElement.querySelector("div.action-container.active");
		if (elemActive && elemActive !== elem) {
			this.renderer2.removeClass(elemActive, "active");
		}
		if (elem) {
			if (elem.classList.contains("active")) {
				this.renderer2.removeClass(elem, "active");
			} else {
				this.renderer2.addClass(elem, "active");
			}
		}
	}

	onworkFlowClicked(event: Device): void {
		this.linkClicked.emit({ data: event, type: "workflow" });
	}

	isTrackingActionVisible(action: string): boolean {
		let isEnableActionButton = false;
		if (!action) {
			return false;
		}
		isEnableActionButton =
			(this.isUserInternal && (action === "Shipping" || action === "Configure" || action === "Activated")) ||
			(!this.isUserInternal && (action === "Received" || action === "Approved" || action === "Activated"));
		return isEnableActionButton;
	}

	onSort(event: any): void {
		if (event.direction) {
			this.sortFields.field = event.active;
			if (event.direction === "asc") {
				this.sortFields.direction = "fa-sort-asc";
			} else {
				this.sortFields.direction = "fa-sort-desc";
			}
		} else {
			this.sortFields.field = "";
			this.sortFields.direction = "";
		}
		this.linkClicked.emit({
			data: { ...event, pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize },
			type: "sort"
		});
	}

	onClickDeviceConfigure(element: any, device: any) {
		if (device?.online) {
			this.router.navigate(["monitoring", "site-monitoring", element?.id]);
			// this.router.navigate(["configuration-management", "configure-device", device.ciId]);
		}
		// else {
		// 	this.tableModel.deviceStatusMessage = device.decommissioned ? GLOBAL_NAMES.REMOVE : GLOBAL_NAMES.OFFLINE;
		// 	this.isShowOverlayCard = true;
		// }
	}

	onChangeRecordsPerPage(event: any): void {
		if (this.showPaginator) {
			this.paginator.pageIndex = 0;
		}
		const recordsPerPage = +event.target.value;
		this.pageChanged.emit({ pageIndex: 0, pageSize: recordsPerPage });
		if (this.isClientSidePagination) {
			this.paginator.pageIndex = 0;
			this.paginator.pageSize = recordsPerPage;
			this.getPaginatedData();
		}
	}
	onClickLaunchWebConsole(element: Element): void {
		this.launchWebConsoleClicked.emit(element);
	}

	validatePageNumber(event: any): void {
		const pattern = /^[1-9]\d*$/;
		const inputValue = event?.target?.value + event.key;
		if (!pattern.test(inputValue)) {
			event.preventDefault();
		}
	}

	onConfigurationsTwo(element: Element): void {
		this.configurationsTwoClicked.emit(element);
		const elem = this.dataTable.nativeElement.querySelector("div.action-container.active");
		if (elem) {
			this.renderer2.removeClass(elem, "active");
		}
	}

	onConfigurationsThree(element: any) {
		this.configurationsThreeClicked.emit(element);
		const elem = this.dataTable.nativeElement.querySelector("div.action-container.active");
		if (elem) {
			this.renderer2.removeClass(elem, "active");
		}
	}

	onConfigureTunnel(element: any) {
		this.onConfigureTunnelClicked.emit(element);
	}

	onConfigurestrictMode(element: any) {
		this.onConfigureStrictModeClicked.emit(element);
	}
}
