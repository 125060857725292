import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, mergeMap, Observable, of } from "rxjs";
import { environment } from "../../../../environments/environment";
import { ApiUrlService } from "src/app/shared/services/api-url.service";

@Injectable({
	providedIn: "root"
})
export class ConfigInitService {
	private config: any;
	public envConfigFilePath = "./assets/data/config.json";
	public envUrls: any;

	constructor(private httpClient: HttpClient, private apiUrlService: ApiUrlService) {}

	public getConfig(): Observable<any> {
		return this.httpClient
			.get(this.getConfigFile(), {
				observe: "response"
			})
			.pipe(
				catchError((error) => {
					return of(null);
				}),
				mergeMap((response) => {
					if (response && response.body) {
						this.config = response.body;
						return of(this.config);
					} else {
						return of(null);
					}
				})
			);
	}

	public initializeEnvUrls() {
		return this.httpClient
			.get(this.envConfigFilePath, {
				observe: "response"
			})
			.pipe(
				catchError((error) => {
					this.envUrls = environment;
					return of(this.envUrls);
				}),
				mergeMap((response) => {
					if (response && response.body) {
						this.envUrls = response.body;
					} else {
						this.envUrls = environment;
					}
					this.apiUrlService.setEnvUrls(this.envUrls);
					return of(this.envUrls);
				})
			);
	}

	private getConfigFile(): string {
		//return environment.keyCloakConfigFile;
		return this.apiUrlService.getEnvUrlByKey("keyCloakConfigFile");
	}
}
