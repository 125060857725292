import { Component, ViewChild, TemplateRef, Input, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChange, OnChanges, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { pageOptions, TableColumns, TableLazyLoadOptions } from "../../models/table";
import { Clipboard } from "@angular/cdk/clipboard";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ToasterService } from "src/app/shared/toaster.service";
import { aesdecrypt,encodeString } from "src/app/shared/utils/commonutils";
import { GlobalConstants, GLOBAL_NAMES, INFO_MESSAGES } from "src/app/shared/utils/global-constants";
import { DataParams, DeviceStatus } from "src/app/shared/models/utils";
import { Device } from "src/app/shared/models/device-spaed";
import { AppService } from "src/app/app.service";
import { of, Subject, Subscription } from "rxjs";
import { User } from "src/app/shared/models/user";
import { ConfirmDialogModel, ConfirmDialogComponent } from "src/app/common/confirm-dialog/confirm-dialog.component";
import { take, switchMap, takeUntil } from "rxjs/operators";
import { AuthenticationService } from "src/app/shared/services";
import { Role } from "src/app/shared/models/role";
import { DataSharedService } from "src/app/shared/services/data-shared.service";
import { DeviceManagementService } from "../../services/device-management.service";
import { PreviousRouteService } from "../../services/route-service";
import { attr } from "highcharts";
import { CommonService } from "../../services/common.service";
import { TimeParserUtil } from "src/app/utility/time-parser-utility";
import { HttpEventType } from "@angular/common/http";
import { SitesManagementService } from "src/app/sites-management/sites-management.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";


@Component({
	selector: "app-shared-device-list",
	templateUrl: "./device-list.component.html",
	styleUrls: [ "./device-list.component.scss" ]
})
export class DeviceListComponent  {
	private _columns: Array<any> = [];
	@Input() set columns(value: Array<any>) {
		this._columns = value;
		this.parseTableData(value);
	}
	get columns(): Array<any> {
	return this._columns;
	}
	
	@Input() canShowAddBtn!: boolean;
	siteModel: any = {};
	tunnelConfigModel: any = {};
	data: { [ key: string ]: any }[] = [];
	totalSize: any;
	showProgress = true;
	maskedKey!: string;
	pageIndex = 0;
	searchTerm = "";
	pageSize = GlobalConstants.Default_PageSize;
	dataParams: DataParams = { showSearchInput: true, searchDivClass: "site-search" };
	customerSelectList: any[] = [];
	customerValueSelected: any[] = [];
	isUserInternal = false;
	userSubscription!: Subscription;
	showCustomerSelect = false;
	selectedCustomerId: any;
	flags: any = {
		isErrorFetchingData: false,
		resetSelectedData: false, // this flag is used to reset the selected data in data table
		isSaseCustomer : false
	};
	dataSource: any = {};
	deviceModel: any = {};
	sortQuery = "";
	customerAssetActions: string[] = [ "received", "approved" ];
	deviceStatus = DeviceStatus;
	paginator: any;
	statusSubscription: Subscription | undefined;
	public loggedInUser: any = {};
	constructor(
		private route: ActivatedRoute,
		private authService: AuthenticationService,
		private appService: AppService,
		private router: Router,
		private toasterService: ToasterService,
		private snackBar: MatSnackBar,
		private clipboard: Clipboard,
		private dialog: MatDialog,
		private deviceManagementService: DeviceManagementService,
		private dataSharedService: DataSharedService,
		private previousRouteService: PreviousRouteService,
		private cd: ChangeDetectorRef,
		private commonService: CommonService,
		private timeParser: TimeParserUtil,
		private siteManagementService: SitesManagementService,
		private fb: FormBuilder

	) {}
	@ViewChild("attributesTemplate") attributesTemplate!: TemplateRef<any>;
	@ViewChild("fsKeyTemplateSase") fsKeyTemplateSase!: TemplateRef<any>;
	@ViewChild("fsKeyTemplate") fsKeyTemplate!: TemplateRef<any>;
	@ViewChild("actionMenuTemplate", { static: true }) actionMenuTemplate!: TemplateRef<any>;
	@ViewChild("statusFieldTemplate", { static: true }) statusFieldTemplate!: TemplateRef<any>;
	@ViewChild("nameFieldTemplate", { static: true }) nameFieldTemplate!: TemplateRef<any>;
	@ViewChild("fsKeyFieldTemplate", { static: true }) fsKeyFieldTemplate!: TemplateRef<any>;
	@ViewChild("assestFieldTemplate", { static: true }) assestFieldTemplate!: TemplateRef<any>;
	@ViewChild("tunnelConfigurationFormTemplate") tunnelConfigurationFormTemplate!: TemplateRef<any>;
	public tunnelConfigurationForm!: FormGroup;
	
	refreshProgress = false;
	cpeTransitionMessage: any = {
		shipping: INFO_MESSAGES.DEVICE_TRACK_DEVICE_SHIP_CONFIRMATION,
		configure: INFO_MESSAGES.DEVICE_TRACK_DEVICE_CONFIGURE_CONFIRMATION,
		received: INFO_MESSAGES.DEVICE_TRACK_DEVICE_RCVD_CONFIRMATION,
		approved: INFO_MESSAGES.DEVICE_TRACK_DEVICE_APPROVE_CONFIRMATION
	};

	fsDetails: { fsId: number; fsKey: string } | null = null;
	isShowOverlayCard = false;
	cancelgetDeviceAPIReq$ = new Subject<any>();
	webNativeTokenData: any = {};
	launchWebConsoleURL!: string;
	webConsoleExpiryTme!: string;
	downloadProgress = false;
	selectedDevice: any[] = [];
	isOnlineDeviceAvailable = false;
	customerChanged$!: Subscription;

	ngOnInit(): void {
		this.customerChanged$ = this.dataSharedService.getCustomerInfo().subscribe((customerInfo: any) => {
			if(customerInfo) {
				this.flags.isCustomerChanged = false;
				this.flags.isSaseCustomer = (customerInfo?.service?.serviceName === GlobalConstants.serviceTypeSase);
			}
		});
		this.flags.isEnableConfigurations = this.authService.isPrivilegeAvailable(Role.canCreateConfigurationItem);
		this.flags.isEnableSSH = this.authService.isPrivilegeAvailable(Role.canEnableSSH);
		this.flags.isDeleteDevice = this.authService.isPrivilegeAvailable(Role.canDeleteDevice);
		this.flags.isEnableTunnelConfiguration = this.authService.isPrivilegeAvailable(
			Role.canCreateTunnelConfiguration
		);
		this.userSubscription = this.route.queryParamMap
			.pipe(
				switchMap((queryParam: any) => {
					return this.appService.loggedInUserDetailsObservable;
				})
			)
			.subscribe((user: User | null) => {
				if (user) {
					this.isUserInternal = user?.userType?.toLowerCase() === "internal";
					this.flags.isEnableDelete = true;
					this.showProgress = true;
					this.dataSource.pageOptions = { pageIndex: 0, pageSize: GlobalConstants.Default_PageSize };
					this.flags.isEnableCreate = this.authService.isPrivilegeAvailable(Role.canCreateDevice);					
					this.flags.isEnableEdit = this.authService.isPrivilegeAvailable(Role.canEditDevice);
					this.loggedInUser = user;
				}
			});
		this.statusSubscription = this.dataSharedService.trackDeviceStatus$.subscribe((status: string) => {
			if (status) {
				this.dataSource.status = status;
				this.searchTerm = this.dataSource?.status;
				this.dataSharedService.setSearchTerm(this.dataSource?.status);
				this.getDevices();
			}
		});
	}

	async getCustomers(event: any) {
		this.getSelectedCustomerDetails(event);
		this.flags.isCustomerChanged = true;
		const previousUrl: any = this.previousRouteService.getPreviousUrl();
		if ((
			previousUrl?.includes("/monitoring/site-monitoring/") ||
			previousUrl?.includes("/device-management/") ||
			previousUrl?.includes("/configuration-management/configure-device/")) && 
			(!previousUrl.includes("/device-management/device-list") ||
			(previousUrl.includes("/network-management/device-list")))
		) {
			this.searchTerm = this.dataSharedService.getSearchTerm();
			if (!this.searchTerm) {
				this.pageIndex = this.dataSharedService.getPageIndex();
				this.pageSize = this.dataSharedService.getPageSize();
			}
		} else {
			this.dataSharedService.setPageIndex(0);
			this.dataSharedService.setPageSize(GlobalConstants.Default_PageSize);
			this.dataSharedService.setSearchTerm("");
		}
		if (this.dataSource?.status) {
			this.searchTerm = this.dataSource?.status;
			this.dataSharedService.setSearchTerm(this.dataSource?.status);
		} else {
			this.getDevices({ pageIndex: this.pageIndex || 0, pageSize: this.paginator?.pageSize || GlobalConstants.Default_PageSize });
		} 
	}

	getSelectedCustomerDetails(event: any) {
		const existingCustomerSelection: any = this.dataSharedService.getSelectedCustomer();
		if (existingCustomerSelection?.id && existingCustomerSelection?.name) {
			this.deviceModel.selectedCustomer = [existingCustomerSelection];
			this.deviceManagementService.selectedCustomerId = this.deviceModel.selectedCustomer[0].id;
			this.siteManagementService.selectedCustomerId = this.deviceModel.selectedCustomer[0].id;
			this.dataSharedService.setSelectedCustomer(this.deviceModel.selectedCustomer[0]);
		} else {
			this.deviceManagementService.selectedCustomerId = event.length && event[ 0 ].id ? event[ 0 ].id : null;
			this.siteManagementService.selectedCustomerId = event.length && event[ 0 ].id ? event[ 0 ].id : null;
			this.deviceModel.selectedCustomerId = this.deviceManagementService.selectedCustomerId;
			this.dataSharedService.setSelectedCustomer(event[ 0 ]);
		}
		this.dataSharedService.setContextpathOnCustomerselect();
	}

	onSelectCustomer(event: any) {
		this.flags.isCustomerChanged = true;
		this.flags.resetSelectedData = this.deviceManagementService.selectedCustomerId !== event?.id;
		this.deviceManagementService.selectedCustomerId = event?.id ? event.id : null;
		this.siteManagementService.selectedCustomerId = event?.id ? event.id : null;
		this.showProgress = true;
		// this.dataSharedService.setSelectedCustomer(event);
		// this.dataSharedService.setContextpathOnCustomerselect();
		this.getDevices({ pageIndex: 0, pageSize: this.paginator?.pageSize || GlobalConstants.Default_PageSize });
	}

	addCustomer() {
		this.router.navigateByUrl("/device-management/device-action/create-device");
	}

	onViewDevice(element: { [ key: string ]: any }): void {
		let id = encodeString(element["id"]);
		if(this.flags.isSaseCustomer){
			this.router.navigateByUrl(`/device-management/details/${id}`);
		}
		else {
			this.router.navigateByUrl(`/device-management/spaed/details/${id}`);
		}
	}

	onEditDevice(element: Element): void {
		let id = encodeString(element["id"]);
		if(this.flags.isSaseCustomer){
			this.router.navigateByUrl(`/device-management/device-action/edit-device/${id}`);
		}
		else {
			this.router.navigateByUrl(`/device-management/spaed-device-action/edit-device/${id}`);
		}
		// this.router.navigateByUrl(`/device-management/device-action/edit-device/${id}`);
	}

	onDeleteDevice(selectedDevice: any): void {
		let deleteMessage: any;
		if (selectedDevice.siteName) {
			deleteMessage = this.constructMessage(selectedDevice);
		} else {
			deleteMessage = `Are you sure you want to delete the device?`;
		}
		const message = deleteMessage;
		let urlDetails: any = {
			url: `/site-management/` + (this.flags.isSpaedcustomer ? `details/${selectedDevice.siteId}`: `spaed/details/${selectedDevice.siteId}`),
			allowRoute: selectedDevice.siteName ? true : false,
			messages: deleteMessage
		};
		const data = new ConfirmDialogModel(GLOBAL_NAMES.CONFIRMATION_MODAL_TITLE, message, urlDetails);
		const dialogReference = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: "450px",
			data,
			disableClose: true
		});
		dialogReference
			.afterClosed()
			.pipe(take(1))
			.subscribe((agree: boolean) => {
				if (!agree) {
					return;
				} else {
					this.refreshProgress = true;
					this.doDeleteDevice(selectedDevice);
				}
			});
	}

	constructMessage(selectedDevice: any) {
		return {
			message: [
				{
					name: "This device is associated with",
					isNewLine: false,
					routeEvent: false
				},
				{
					name: selectedDevice.siteName,
					isNewLine: false,
					routeEvent: true
				},
				{
					name: "Are you sure you want to delete the device?",
					isNewLine: true,
					routeEvent: false
				}
			]
		};
	}

	doDeleteDevice(selectedDevice: any) {
		this.deviceManagementService.deleteDevice(selectedDevice.id).subscribe({
			next: (response) => {
				if (response.responseCode === 0) {
					this.toasterService.showSuccessMessage("Device is deleted successfully.");
					this.getDevices(this.dataSource.pageOptions);
				} else {
					this.toasterService.showErrorMessage("Unable to proceed with the action , please try again later");
				}
				this.refreshProgress = false;
			},
			error: () => {
				this.refreshProgress = false;
				this.toasterService.showErrorMessage("Unable to proceed with the action , please try again later");
			}
		});
	}

	onLinkClicked(event: { data: any; type: string }) {
		// refactor to switch statement - TODO
		this.deviceModel.deviceStatusMsg = event.data.decommissioned ? GLOBAL_NAMES.REMOVE : GLOBAL_NAMES.OFFLINE;
		if (event.type === "fsKey") {
			this.refreshProgress = true;
			this.deviceManagementService.getDeviceKey(event.data.id).subscribe({
				next: (response) => {
					if (response.responseCode === 0) {
						this.refreshProgress = true;
						const decrytpedFskey = aesdecrypt(GlobalConstants.SECRET_KEY, response.data.fsKey);
						this.fsDetails = { fsId: response.data.fsId, fsKey: decrytpedFskey };
						this.maskedKey = "*".repeat(this.fsDetails.fsKey.length);
						this.dialog.open(this.fsKeyTemplateSase, {
							width: "800px",
							disableClose: true,
							autoFocus: false
						});
					} else {
						this.toasterService.showErrorMessage(
							"Unable to proceed with the action , please try again later"
						);
					}
					this.refreshProgress = false;
				},
				error: (err: any) => {
					this.refreshProgress = false;
					this.toasterService.showErrorMessage("Unable to proceed with the action , please try again later");
				}
			});
		} else if (event.type === "refresh") {
			this.refreshProgress = true;
			this.deviceManagementService.getDevice(event.data.id).subscribe({
				next: (response) => {
					if (response.responseCode === 0) {
						this.refreshProgress = true;
						event.data.online = response.data.online;
						event.data.initialConfigStatus = response.data.initialConfigStatus;

						this.deviceConfiguration(this.data[ event.data.index ]);
						// this.getDevices({pageIndex: event.data.pageIndex, pageSize: event.data.pageSize});
					} else {
						this.toasterService.showErrorMessage(
							"Unable to proceed with the action , please try again later"
						);
					}
					this.refreshProgress = false;
				},
				error: (err: any) => {
					this.refreshProgress = false;
					this.toasterService.showErrorMessage("Unable to proceed with the action , please try again later");
				}
			});
		} else if (event.type.toLowerCase() === "workflow") {
			this.onHandleWorkFlowChange(event.data);
		} else if (event.type?.toLowerCase() === GLOBAL_NAMES.CPE_DEVICE_DETAILS) {
			// if (event.data.online) {
			// 	this.router.navigate(["configuration-management", "configure-device", event.data.id]);
			// } else {
			// 	this.isShowOverlayCard = true;
			// }
			this.router.navigate([ "monitoring", "site-monitoring", event?.data?.siteId ]);
		} else if (event.type === "sort") {
			this.setSortParams(event.data.active, event.data.direction);
			this.refreshProgress = true;
			this.getDevicesData(event.data.pageIndex, event.data.pageSize);
		}
	}

	private setSortParams(active: string, direction: string): void {
		if (active && direction) {
			let sortParam = active;
			if (sortParam === "online") {
				sortParam = "fsMaster.online";
			} else if (sortParam === "customerDisplayName") {
				sortParam = "customer.displayName";
			} else if (sortParam === "assetStatus") {
				sortParam = "assetStatus.name";
			} else if (sortParam === "siteName") {
				sortParam = "site.name";
			} else if (sortParam === "model") {
				sortParam = "oemModel.model";
			} else if (sortParam === "parentCustomerName") {
				sortParam = "deviceOwner.owner";
			}
			this.sortQuery = `sort=${sortParam},${direction}`;
		} else {
			this.sortQuery = "";
		}
	}

	private onHandleWorkFlowChange(event: Device): void {
		const message = this.cpeTransitionMessage[ event!.assetStatus!.action?.toLowerCase() ];
		const data = new ConfirmDialogModel(GLOBAL_NAMES.CONFIRMATION_MODAL_TITLE, message);
		const dialogReference = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: "450px",
			data,
			disableClose: true
		});

		dialogReference
			.afterClosed()
			.pipe(take(1))
			.subscribe((agree: boolean) => {
				if (!agree) {
					return;
				} else {
					this.refreshProgress = true;
					event.isResetDeviceStatusInProgress = true;
					this.deviceManagementService.updateCIWorkfowStatus(event.id as number).subscribe({
						next: (response: any) => {
							if (response.responseCode === 0) {
								if (event) {
									event.assetStatus = response.data;
									event["enableAssetAction"] = response?.data?.action?.length
										? this.enableAssetAction(response.data.action)
										: false;
								}
							} else {
								this.toasterService.showErrorMessage(
									"Unable to do this action at this moment, please try again later"
								);
							}
							this.refreshProgress = false;
							event.isResetDeviceStatusInProgress = false;
						},
						error: (err: any) => {
							this.toasterService.showErrorMessage(
								"Unable to do this action at this moment, please try again later"
							);
							this.refreshProgress = false;
							event.isResetDeviceStatusInProgress = false;
						}
					});
				}
			});
	}

	deviceConfiguration(device: any) {
		if (device && device.online && (!device.initialConfigStatus || device.nitialConfigStatus != "Configured")) {
			device.configuring = true;
			this.deviceManagementService.deviceInitConfiguration(device.id, {}).subscribe({
				next: (response) => {
					device.configuring = false;
					if (response && response.status && response.status.toLowerCase() == "success") {
						device.initialConfigStatus = response.data.initialConfigStatus;
						this.toasterService.showSuccessMessage("", device.name + " Configuration done Successfully");
					} else {
						this.toasterService.showErrorMessage(response.message);
					}
				},
				error: (err: any) => {
					device.configuring = false;
				}
			});
		}
	}

	onPageChanged(event: TableLazyLoadOptions) {
		this.showProgress = true;
		this.dataSource.pageOptions = event;
		this.dataSharedService.setPageIndex(event?.pageIndex);
		this.dataSharedService.setPageSize(event?.pageSize);
		this.flags.resetSelectedData = false;
		this.setSortParams(event.sortField, event.direction);
		this.getDevices(event);
	}

	private getDevices(page: pageOptions = { pageIndex: 0, pageSize: GlobalConstants.Default_PageSize }): void {
		this.showProgress = true;
		this.cd.detectChanges();
		this.getDevicesData(page.pageIndex, page.pageSize);
		this.getAllOnlineDevice();
	}

	private getDevicesData(page: number, pageSize: number): void {
		this.dataSource.deviceDetails = [];
		this.data = [];
		this.deviceManagementService
			.getAllDevices(page, pageSize, this.searchTerm, this.sortQuery)
			.pipe(
				takeUntil(this.cancelgetDeviceAPIReq$),
				switchMap((response) => {
					return of(response);
				})
			)
			.subscribe({
				next: (response: any) => {
					if (response?.responseCode === GlobalConstants.RESPONSE_SUCCESS_CODE) {
						this.data = response.data.content.map((data: any) => {
							return {
								...data,
								enableAssetAction: data?.assetStatus?.action?.length
									? this.enableAssetAction(data?.assetStatus?.action)
									: false,
								aliases: data?.aliases?.map((aliasData: { id: number; name: string }) => aliasData.name),
								model : data?.model?.name,
								parentCustomerName: data.deviceOwner?.name,
								deviceStatus : data?.online ? this.deviceStatus.online : this.deviceStatus.offline
							};
						});
						this.dataSource.deviceDetails = this.data;
						this.pageIndex = response.data.number;
						this.pageSize = pageSize;
						this.totalSize = response.data.totalElements;
						this.dataSource.pageOptions.totalSize = this.totalSize;
						this.paginator = response.data.pageable;
						this.paginator.pageIndex = response.data.number;
					} else {
						this.flags.isErrorFetchingData = true;
					}
					this.showProgress = false;
					this.refreshProgress = false;
				},
				error: (err: any) => {
					this.flags.isErrorFetchingData = true;
					this.showProgress = false;
					this.refreshProgress = false;
				}
			});
	}

	onCopy(): void {
		if(this.flags.isSaseCustomer){
		this.clipboard.copy(this.fsDetails?.fsKey || "");
		}
		else{
			this.clipboard.copy(this.webNativeTokenData?.devicePassword || "");
		}
		this.snackBar.open("Copied to clipboard!!!", "Dismiss", { duration: 3000 });
	}

	public search(value: string) {
		this.flags.resetSelectedData = value !== this.searchTerm;
		this.searchTerm = value;
		this.dataSharedService.setSearchTerm(value);
		this.showProgress = true;
		this.paginator.pageIndex = 0;
		const event = {
			pageIndex: this.paginator.pageIndex,
			pageSize: this.paginator.pageSize
		};
		this.getDevices(event);
	}

	onConfigurationWanAndLanSase(element: { [ key: string ]: any }): void {
		let id = encodeString(element["id"]);
		if(this.flags.isSaseCustomer){
			this.router.navigate(["configuration-management", "configure-device", id]);
		}else{
			this.router.navigate(["configuration-management", "spaed-configure-device", id]);
		}
	}

	onClickLaunchWebConsole(element: any) {
		const navigationExtras: NavigationExtras = {
			state: { selectedDeviceId: element.id, selectedCustomerId: element.customerId }
		};
		this.router.navigate([ "configuration-management", "web-console" ], navigationExtras);
	}

	ngOnDestroy(): void {
		this.userSubscription?.unsubscribe();
		this.statusSubscription?.unsubscribe();
		this.statusSubscription ? this.dataSharedService.setTrackDeviceStatus("") : "";
		this.customerChanged$?.unsubscribe();
	}

	parseTableData(columns:Array<any>) {
		this.dataSource.columnConfig = {
			columnDef: columns,
			displayColumns: columns.map((column: any) => column.header)
		};
		columns.forEach((element: any) => {
			if (element.isTemplateRequired) {
				const columnType = this.filterFieldElement(element.header);
				switch (columnType.header) {
					case "actions": {
						element[ "cellTemplate" ] = this.actionMenuTemplate;
						break;
					}
					case "online": {
						element[ "cellTemplate" ] = this.statusFieldTemplate;
						break;
					}
					case "name": {
						element[ "cellTemplate" ] = this.nameFieldTemplate;
						break;
					}
					// case "fsKey": {
					// 	element["cellTemplate"] = this.fsKeyFieldTemplate;
					// 	break;
					// }
					case "assetStatus": {
						element[ "cellTemplate" ] = this.assestFieldTemplate;
						break;
					}
				}
			}
		});
	}

	filterFieldElement(columName: string) {
		const column = this.columns?.find((fieldElement: any) => fieldElement.header === columName);
		return column;
	}

	onClickNameField(element: any) {
		const deviceListUrl = window.location.href;
		if (deviceListUrl?.includes("network-management/device-list")) {
			let id = encodeString(element.id);
			if(this.flags.isSaseCustomer){
				this.router.navigate(["configuration-management", "configure-device", id]);
			}else{
				this.onConfigurationsClicked(element);
			}
		} else {
			let id = encodeString(element.siteId);
			this.router.navigate(["monitoring", "site-monitoring", id]);
		}
	}

	onClickFsKeyField(element: any) {
		this.refreshProgress = true;
		this.deviceManagementService.getDeviceKey(element.id).subscribe({
			next: (response) => {
				if (response.responseCode === 0) {
					this.refreshProgress = true;
					const decrytpedFskey = aesdecrypt(GlobalConstants.SECRET_KEY, response.data.fsKey);
					this.fsDetails = { fsId: response.data.fsId, fsKey: decrytpedFskey };
					this.maskedKey = "*".repeat(this.fsDetails.fsKey.length);
					this.dialog.open(this.fsKeyTemplateSase, {
						width: "800px",
						disableClose: true,
						autoFocus: false
					});
				} else {
					this.toasterService.showErrorMessage("Unable to proceed with the action , please try again later");
				}
				this.refreshProgress = false;
			},
			error: (err: any) => {
				this.refreshProgress = false;
				this.toasterService.showErrorMessage("Unable to proceed with the action , please try again later");
			}
		});
	}

	onClickDeviceStatus(element: any) {
		this.onHandleWorkFlowChange(element);
	}

	enableAssetAction(assetStatus: string) {
		return this.isUserInternal
			? !this.customerAssetActions.includes(assetStatus?.toLowerCase())
			: this.customerAssetActions.includes(assetStatus?.toLowerCase());
	}

	onConfigurationWanAndLan(element: { [key: string]: any }): void {
		this.setContextPathAndKey(element);
		let isOnline =
			element['online'];
		if (isOnline) {
			let deviceId = element?.['singleTenantDeviceId'];
			this.launchWebPopup(deviceId);
		} else {
			this.isShowOverlayCard = true;
		}		 
	}


	setContextPathAndKey(element: any) {
		if (element?.customer?.contextPath && element?.customer?.secretKey) {
			this.dataSharedService.setSpaedBasePath(element.customer.contextPath);
			this.dataSharedService.setCustomerContextPath(element.customer.contextPath);
			this.dataSharedService.setCustomerSecretKey(element.customer.secretKey);
		}
	}

	public launchWebPopup(deviceId: any) {
		this.showProgress = true;
		this.commonService.getWebNativeUIToken(deviceId).subscribe({
			next: (response) => {
				if (response) {
					this.showProgress = false;
					//this.isShowDeviceWebConsoleCard = true;
					this.webNativeTokenData = response;
					const devicePassword = response?.devicePassword;
					const httpProxyUser = response?.httpProxyUser;
					const httpProxyPassword = response?.httpProxyPassword;
					const hostname = response?.hostname;
					this.maskedKey = "*".repeat(devicePassword.length);
					//https://{httpProxyUser}:{httpProxyPassword}@hostname
					this.webConsoleExpiryTme = this.timeParser.toDateTime(response?.expires);
					//const launchWebConsoleURL = `https://${httpProxyUser}:${httpProxyPassword}@${hostname}`;
					const launchWebConsoleURL = `https://${hostname}`;
					this.launchWebConsoleURL = launchWebConsoleURL;
					this.dialog.open(this.fsKeyTemplate, {
						width: "800px",
						disableClose: true,
						autoFocus: false
					});
				} else {
					this.toasterService.showErrorMessage("Unable to proceed with the action , please try again later");
				}
				this.showProgress = false;
				this.refreshProgress = false;
			},
			error: (err: any) => {
				this.showProgress = false;
				this.refreshProgress = false;
				this.toasterService.showErrorMessage("Unable to proceed with the action , please try again later");
			}
		});
	}

	// onCopy(): void {
	// 	this.clipboard.copy(this.webNativeTokenData?.devicePassword || "");
	// 	this.snackBar.open("Copied to clipboard!!!", "Dismiss", { duration: 3000 });
	// }
	goToLink(url: string) {
		this.onCopy();
		window.open(url, "_blank");
	}

	onDownloadConfig(element: { [key: string]: any }): void {
		this.setContextPathAndKey(element);
		let deviceId = element['singleTenantDeviceId'];
		if (deviceId) {
			//let deviceId = element?.["currentRouterUnits"][0]?.id;
			const devicePayload = {
				deviceId: [deviceId]
			};
			const message = INFO_MESSAGES.DOWNLOAD_CONFIG_CONFIRMATION;
			const data = new ConfirmDialogModel("Confirm Action", message);
			const dialogReference = this.dialog.open(ConfirmDialogComponent, {
				maxWidth: "450px",
				data,
				disableClose: true
			});
			dialogReference.afterClosed().subscribe((agree: any) => {
				if (agree) {
					this.downloadTemplate(devicePayload);
				}
			});
		}else{
			this.isShowOverlayCard = true;
		}
		//this.downloadTemplate(devicePayload);
	}

	downloadTemplate(payload: any): void {
		this.downloadProgress = true;
		this.siteManagementService.getDownloadConfigReport(payload).subscribe({
			next: (event: any) => {
				if (event.type === HttpEventType.DownloadProgress) {
				}
				if (event.type === HttpEventType.Response) {
					const a = document.createElement("a");
					const objectUrl = URL.createObjectURL(event.body);
					a.href = objectUrl;
					/*
					if(payload?.deviceId?.length > 1){
						a.download = 'device_config.zip';
					} else {						
						a.download = 'device_config.xlsx';
					}
					*/
					var contentDisposition = event.headers.get("Content-Disposition");
					if (contentDisposition != null && contentDisposition != undefined) {
						var filename = contentDisposition.split(";")[1].split("filename")[1].split("=")[1].trim();
						a.download = new Date().toISOString() + "_" + filename;
					} else {
						if (payload?.deviceId?.length > 1) {
							a.download = `device_config_${new Date().toISOString()}.zip`;
						} else {
							a.download = `device_config_${new Date().toISOString()}.xlsx`;
						}
					}

					a.click();
					URL.revokeObjectURL(objectUrl);
					this.downloadProgress = false;
				}
			},
			error: (err: any) => {
				this.downloadProgress = false;
				if (err != null && err != undefined && err.indexOf("404")) {
					this.toasterService.showErrorMessage(INFO_MESSAGES.API_ERR_MESSGE);
				} else {
					this.toasterService.showErrorMessage(err);
				}
			}
		});
	}

	onConfigurationsClicked(element: { [key: string]: any }): void {
		this.setContextPathAndKey(element);
		let deviceId = element['id'];
		if (deviceId) {
			let id = encodeString(element["id"]);
			this.router.navigate(["configuration-management","spaed-configure-device", id]);
		}else{
			this.isShowOverlayCard = true;
		}
	}

	onClickDownloadConfigReport(): void {
		this.selectedDevice = [];
		if (this.dataSource?.onlineDeviceList?.length > 0) {
			this.dialog
				.open(this.attributesTemplate, {
					width: "750px",
					data: {},
					autoFocus: false,
					disableClose: true
				})
				.afterClosed()
				.subscribe((isAttributesSelected: boolean) => {
					if (isAttributesSelected) {
						this.onApplyFilters();
					}
				});
		} else {
			this.isShowOverlayCard = true;
		}
	}

	onApplyFilters(): void {
		//   do nothing
	}

	onDeviceDropdownClosed(event: any) {
		this.selectedDevice = event.value;
	}

	downloadConfigReport() {
		let deviceIds = this.selectedDevice;
		if (deviceIds.length == 0) {
			return;
		} else {
			const message = INFO_MESSAGES.DOWNLOAD_CONFIG_CONFIRMATION;
			const data = new ConfirmDialogModel("Confirm Action", message);
			const dialogReference = this.dialog.open(ConfirmDialogComponent, {
				maxWidth: "450px",
				data,
				disableClose: true
			});
			dialogReference.afterClosed().subscribe((agree: any) => {
				if (agree) {
					const devicePayload = {
						deviceId: deviceIds
							.filter((device) => device.value?.singleTenantDeviceId != null && device.value?.singleTenantDeviceId != undefined)
							.map((devicesIds) => devicesIds.value?.singleTenantDeviceId)
					};
					this.downloadTemplate(devicePayload);
				}
			});
		}
	}

	getAllOnlineDevice() {
		if (!this.flags.isAllSelected) {
			try {
				this.dataSource.onlineDeviceList = [];
				//this.commonService.getAllDevice().subscribe({
				//let	page: pageOptions = { pageIndex: 0, pageSize: 5 };
				this.siteManagementService.getAllMtcDevice().subscribe({
					next: (response: any) => {
						if (response?.data?.length > 0) {
							const devices = response?.data;
							this.flags.hasOnlineDevices = true;
							this.dataSource.onlineDeviceList = devices
								.filter(
									(data: any) =>
										!(data?.model?.name == null || data?.manufacturerSerialNumber == null 
											||  data?.singleTenantDeviceId == null || data?.singleTenantDeviceId == undefined)
								)
								.map((data: any) => ({
									value: data,
									label: data?.model?.name + " " + data?.manufacturerSerialNumber,
									
								}));
						} else {
							this.dataSource.onlineDeviceList = [];
							this.flags.hasOnlineDevices = false;
						}
						this.isOnlineDeviceAvailable = this.dataSource.onlineDeviceList?.length > 0 ? true : false;
						//this.showProgress = false;
					},
					error: (err: any) => {
						//this.showProgress = false;
						this.isOnlineDeviceAvailable = false;
					}
				});
			} catch (error) {
				this.isOnlineDeviceAvailable = false;
			}
		}
	}

	onConfigureTunnel(element: any) {
		this.createTunnelConfigurationForm();
		this.dialog.open(this.tunnelConfigurationFormTemplate, {
			width: "650px",
			data: {},
			autoFocus: true,
			disableClose: true
		});
		// .afterClosed()
		// .subscribe((isAttributesSelected: boolean) => {
		// });
		this.siteModel.siteId = element.siteId;
		this.siteModel.customerId = element.customerId;
		this.getTunnelConfigurationFormValues();
	}

	isDataCenter(locationTypeName: string): boolean {
		return locationTypeName === "Data Center";
	  }

	createTunnelConfigurationForm() {
		this.tunnelConfigurationForm = this.fb.group({
			privateIp: this.fb.control<string | null>("", {
				validators: [Validators.required, Validators.pattern(GlobalConstants.IP_WITH_OPTIONAL_SUBNET_PATTERN)]
			}),
			privatePort: ["", Validators.required],
			privatePublicKey: ["", Validators.required],
			privateMtu: this.fb.control<string | null>("", {
				validators: [Validators.pattern(GlobalConstants.NUMER_PATTERN)]
			}),
			privateTunnel: ["", [Validators.pattern(GlobalConstants.IP_WITH_OPTIONAL_SUBNET_PATTERN)]],
			tunnelName: ["", Validators.required],
			publicIp: this.fb.control<string | null>("", {
				validators: [Validators.pattern(GlobalConstants.IP_PATTERN)]
			}),
			publicPort: [""],
			publicPublicKey: [""],
			publicMtu: this.fb.control<string | null>("", {
				validators: [Validators.pattern(GlobalConstants.NUMER_PATTERN)]
			}),
			publicTunnel: ["", Validators.pattern(GlobalConstants.IP_PATTERN)]
		});
	}

	onResetForm() {
		let message;
		message = INFO_MESSAGES.RESET_CHANGES;
		const data = new ConfirmDialogModel("Confirm Action", message);
		const dialogReference = this.dialog.open(ConfirmDialogComponent, {
			maxWidth: "450px",
			data,
			disableClose: true
		});
		dialogReference.afterClosed().subscribe((agree: any) => {
			if (agree) {
				this.tunnelConfigurationForm.reset();
			}
		});
	}

	onCancelClicked() {}

	onSubmitTunnelConfigForm() {
		if (this.tunnelConfigurationForm.valid) {
			// copilot coverage
			// :search string in copilot. group private and public into two different objects
			// Perform form submission logic here
			let privateConfig = {
				// need to change the form object to remove the below unwanted code
				tunnelName: this.tunnelConfigurationForm.get("tunnelName")?.value,
				ip: this.tunnelConfigurationForm.get("privateIp")?.value,
				port: this.tunnelConfigurationForm.get("privatePort")?.value,
				publicKey: this.tunnelConfigurationForm.get("privatePublicKey")?.value,
				overlayIp: this.tunnelConfigurationForm.get("privateTunnel")?.value,
				mtu: this.tunnelConfigurationForm.get("privateMtu")?.value
			};

			let publicConfig = {
				ip: this.tunnelConfigurationForm.get("publicIp")?.value,
				port: this.tunnelConfigurationForm.get("publicPort")?.value,
				publicKey: this.tunnelConfigurationForm.get("publicPublicKey")?.value,
				overlayIp: this.tunnelConfigurationForm.get("publicTunnel")?.value,
				mtu: this.tunnelConfigurationForm.get("publicMtu")?.value
			};

			let payload = {
				siteId: this.siteModel?.siteId,
				privateTunnel: privateConfig
				// publicTunnel: publicConfig
			};
			this.doSaveOrUpdateTunnelConfiguration(payload);
			// copilot coverage
		} else {
			// Handle form validation errors
		}
	}

	doSaveOrUpdateTunnelConfiguration(payload: any) {
		// :search string in copilot. geneate callback for post payload
		this.siteManagementService.saveorupdateTunnelConfig(payload).subscribe({
			next: (response: any) => {
				// Handle the response from the API
				if (response.responseCode === 0) {
					this.toasterService.showSuccessMessage(INFO_MESSAGES.TUNNEL_CONFIG_SUCCESS);
					this.dialog.closeAll();
				}
			},
			error: (error: any) => {
				this.toasterService.showErrorMessage(INFO_MESSAGES.TUNNEL_CONFIG_ERR);
				// Handle the error from the API
			}
		});
	}

	getTunnelConfigurationFormValues() {
		this.siteManagementService.getTunnelConfigById(this.siteModel?.siteId, this.siteModel?.customerId).subscribe({
			next: (response: any) => {
				// Handle the response from the API
				if (response.responseCode === 0) {
					this.tunnelConfigModel = response.data;
					this.tunnelConfigurationForm.patchValue({
						privateIp: this.tunnelConfigModel?.privateTunnel?.ip,
						privatePort: this.tunnelConfigModel?.privateTunnel?.port,
						privatePublicKey: this.tunnelConfigModel?.privateTunnel?.publicKey,
						privateTunnel: this.tunnelConfigModel?.privateTunnel?.overlayIp,
						privateMtu: this.tunnelConfigModel?.privateTunnel?.mtu,
						tunnelName: this.tunnelConfigModel?.privateTunnel?.tunnelName
						// publicIp: this.tunnelConfigModel?.publicTunnel?.ip,
						// publicPort: this.tunnelConfigModel?.publicTunnel?.port,
						// publicPublicKey: this.tunnelConfigModel?.publicTunnel?.publicKey,
						// publicTunnel: this.tunnelConfigModel?.publicTunnel?.overlayIp,
						// publicMtu: this.tunnelConfigModel?.publicTunnel?.mtu
					});
				}
			},
			error: () => {
				// Handle the error from the API
			}
		});
	}
}
