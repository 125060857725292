import { Injectable } from "@angular/core";
import { API_URLS, ENV_URLS } from "../models/utils";
import { APIUrlConstants } from "../utils/api-url-constants";

@Injectable({
	providedIn: "root"
})
export class ApiUrlService {
	private envUrls!: { [key: string]: any };
	private apiUrls!: API_URLS;

	constructor() {}

	setEnvUrls(envUrls: ENV_URLS) {
		this.envUrls = Object.freeze(envUrls);
		this.apiUrls = Object.freeze({
			[APIUrlConstants.BASE_PATH]: envUrls.basePath,
			[APIUrlConstants.FS_BASE_PATH]: envUrls.fsBasePath,
			[APIUrlConstants.DEVICE_MANAGEMENT_BASEPATH]: envUrls.deviceBasePath,
			[APIUrlConstants.CUSTOMER_MANAGEMENT_BASEPATH]: envUrls.customerMgmtBasePath,
			[APIUrlConstants.MONITORING_SERVICE_BASEPATH]: envUrls.monitoringServiceBasePath,
			[APIUrlConstants.NETWORK_MANAGEMENT_BASEPATH]: envUrls.networkMgmtBasePath,
			[APIUrlConstants.SECURITY_MANAGEMENT_BASEPATH]: envUrls.securityMgmtBasePath,
			[APIUrlConstants.USER_MANAGEMENT_BASEPATH]: envUrls.userMgmtBasePath,
			[APIUrlConstants.REMOTE_UPGRADE_BASEPATH]: envUrls.remoteUpgradeBasePath,
			[APIUrlConstants.DEVICE_MANAGEMENT_URL]: `${envUrls.basePath}/${envUrls.deviceBasePath}`,
			[APIUrlConstants.CUSTOMER_MANAGEMENT_URL]: `${envUrls.basePath}/${envUrls.customerMgmtBasePath}`,
			[APIUrlConstants.MONITORING_SERVICE_URL]: `${envUrls.basePath}/${envUrls.monitoringServiceBasePath}`,
			[APIUrlConstants.NETWORK_MANAGEMENT_URL]: `${envUrls.basePath}/${envUrls.networkMgmtBasePath}`,
			[APIUrlConstants.SECURITY_MANAGEMENT_URL]: `${envUrls.basePath}/${envUrls.securityMgmtBasePath}`,
			[APIUrlConstants.USER_MANAGEMENT_URL]: `${envUrls.basePath}/${envUrls.userMgmtBasePath}`,
			[APIUrlConstants.REMOTE_UPGRADE_URL]: `${envUrls.basePath}/${envUrls.remoteUpgradeBasePath}`,
			[APIUrlConstants.AGENT_SERVICE_BASEPATH]: `${envUrls.basePath}/${envUrls.agentServiceBasePath}`
		});
	}

	getEnvUrls() {
		return this.envUrls;
	}

	getAPIUrls(): API_URLS {
		return this.apiUrls;
	}

	getEnvUrlByKey(key: string) {
		return this.envUrls[key];
	}

	getAPIUrlByKey(key: string) {
		return this.apiUrls[key as keyof API_URLS];
	}
}
